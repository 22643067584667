import {Skeleton} from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';

type StatisticsCardSmallProps = {
  title: string;
  data: number | string;
  mode: 'number' | 'percentage';
  premium?: boolean;
  prevProgress?: number;
  ppProgress?: number;
  loadingPpProgress?: boolean;
};

export default function StatisticsCardSmall({
  title,
  data,
  mode,
  premium,
  prevProgress,
  ppProgress,
  loadingPpProgress,
}: StatisticsCardSmallProps) {
  const locale = useAppSelector((state) => state.main.user?.locale);
  const {t} = useTranslation();
  return (
    <Card sx={{width: '100%'}}>
      <SoftBox p={2}>
        <Grid container>
          <Grid item xs={7}>
            <SoftBox mb={0.5} lineHeight={1}>
              <SoftTypography variant="button" fontWeight="medium" color="text" textTransform="capitalize">
                {title}
              </SoftTypography>
              <SoftTypography variant="h5" fontWeight="bold">
                {typeof data === 'string'
                  ? data
                  : mode === 'percentage'
                    ? `${(data * 100).toLocaleString(locale, {maximumFractionDigits: 2})}%`
                    : data.toLocaleString(locale, {maximumFractionDigits: 2})}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={5}>
            {prevProgress && (
              <SoftTypography variant="button" width="100%" textAlign="right" lineHeight={1} textTransform="none">
                <SoftTypography
                  variant="button"
                  fontWeight="bold"
                  color={(prevProgress ?? 0) < 0 ? 'warning' : 'success'}>
                  {((prevProgress ?? 0) * 100).toLocaleString(locale, {
                    maximumFractionDigits: 2,
                    signDisplay: 'always',
                  })}
                  {mode === 'percentage' ? 'pts ' : '% '}
                </SoftTypography>
                {t('statisticcardsmall.myaverage', {defaultValue: 'My average'})}
              </SoftTypography>
            )}
          </Grid>
          {premium && (
            <Grid item xs={12}>
              <SoftBox lineHeight={1}>
                <SoftTypography variant="button" fontWeight="bold">
                  {loadingPpProgress ? (
                    <SoftBox component="span" sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <Skeleton variant="rectangular" width={260} height={16} sx={{borderRadius: '1rem'}} />
                    </SoftBox>
                  ) : (
                    <>
                      {((ppProgress ?? 0) * 100).toLocaleString(locale, {
                        maximumFractionDigits: 2,
                        signDisplay: 'always',
                      })}
                      {mode === 'percentage' ? 'pts ' : '% '}
                      <SoftTypography variant="button" fontWeight="regular" color="secondary" textTransform="none">
                        {t('statisticcardsmall.averageperfectpost', {defaultValue: 'Average PP users'})}
                      </SoftTypography>
                    </>
                  )}
                </SoftTypography>
              </SoftBox>
            </Grid>
          )}
        </Grid>
      </SoftBox>
    </Card>
  );
}
