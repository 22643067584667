import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import type {Draft, SingleAssets} from '@perfectpost/perfect-post-common';
import axios, {AxiosRequestConfig} from 'axios';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useAppThunkDispatch} from 'src/store';
import {editDraft, saveDraft} from 'src/store/draftslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftProgress from 'src/theme/components/SoftProgress';
import SoftTypography from 'src/theme/components/SoftTypography';
import {sanitizer} from 'src/utils/sanitizer';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';

import TipCard from '../tipscard/TipCards';

import 'video.js/dist/video-js.css';

type ImportVideoModalProps = {
  draftId?: string;
  content?: string;
  sharing?: SingleAssets;
  handleClose: () => void;
  handleConfirm: (newDraft: Draft) => void;
};

export default function ImportVideoModal(props: ImportVideoModalProps) {
  const {draftId, content, handleClose, handleConfirm} = props;
  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: {
      'video/*': [],
      'video/mp4': [],
      'video/avi': [],
      'video/webm': [],
      'video/x-ms-wmv': [],
      'video/x-flv': [],
      'video/mpeg': [],
      'video/quicktime': [],
      'video/x-m4v': [],
    },
  });

  const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);

  const form = useMemo(() => {
    if (acceptedFiles.length === 0) {
      return undefined;
    }
    const url = URL.createObjectURL(acceptedFiles[0]);
    const mimetype = acceptedFiles[0].type;
    return {url, mimetype};
  }, [acceptedFiles]);

  const playerRef = useRef<Player | null>(null);
  const videoRef = useCallback((node: HTMLVideoElement) => {
    if (node !== null) {
      // Make sure Video.js player is only initialized once
      if (!playerRef.current) {
        const videoElement = node.querySelector('video');
        if (!videoElement) return;
        const player = (playerRef.current = videojs(
          videoElement,
          {
            html5: true,
            controls: true,
          },
          () => {
            videojs.log('player is ready');
          },
        ));
        if (form !== undefined) {
          player.src({type: form.mimetype, src: form.url});
        }
      } else {
        const player = playerRef.current;
        if (form !== undefined) {
          player.src({type: form.mimetype, src: form.url});
        }
      }
    } else if (playerRef.current) {
      playerRef.current.dispose();
      playerRef.current = null;
    }
  }, []);

  const onSubmit = async () => {
    if (form === undefined) {
      return;
    }
    const blob = await fetch(form.url).then(async (r) => r.blob());
    const sharingData = {
      video: blob.type,
    };
    let result: {
      status: string;
      url?: string | undefined;
      draft: Draft;
    };
    if (draftId === undefined) {
      result = await dispatch(
        saveDraft({
          content: sanitizer(content ?? ''),
          sharing: sharingData,
        }),
      ).unwrap();
    } else {
      result = await dispatch(
        editDraft({
          draftId,
          content: content ? sanitizer(content) : undefined,
          sharing: sharingData,
        }),
      ).unwrap();
    }
    if (result?.url && result.draft._id && blob) {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': blob.type,
        },
        onUploadProgress(progressEvent) {
          if (progressEvent.loaded !== progressEvent.total) {
            setUploadProgress((progressEvent.loaded / (progressEvent.total ?? 100)) * 100);
          }
        },
      };
      playerRef.current?.dispose();
      playerRef.current = null;
      void axios.put(result.url, blob, config).then(() => {
        setUploadProgress(undefined);
        if (draftId === undefined) {
          navigate(`/publish/draft/${result.draft._id}`);
        }
        playerRef.current?.dispose();
        playerRef.current = null;
        handleConfirm(result.draft);
      });
    }
  };

  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>
        <SoftTypography>{t('importvideomodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <TipCard informationkey="modalvideo" />
        {uploadProgress === undefined ? (
          <SoftTypography>{t('importvideomodal.label')}</SoftTypography>
        ) : (
          <SoftBox sx={{display: 'flex', flexDirection: 'column'}}>
            <SoftTypography variant="button" color="text" fontWeight="medium">
              {t('draft.uploadvideo', {defaultValue: 'Uploading video'})}
            </SoftTypography>
            <SoftProgress value={uploadProgress} variant="gradient" />
          </SoftBox>
        )}
        {form !== undefined ? (
          <SoftBox ref={videoRef}>
            <video
              src={form.url}
              datatype={form.mimetype}
              playsInline
              preload="metadata"
              muted
              className="video-js media-player__player vjs-paused vjs-fluid vjs-1-1 media-player--use-mercado vjs-controls-enabled vjs-workinghover vjs-v7 vjs-user-active vjs-layout-medium"
            />
          </SoftBox>
        ) : (
          <SoftBox
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              borderWidth: '2px',
              borderRadius: '2px',
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
              color: '#bdbdbd',
              outline: 'none',
              transition: 'border .24s ease-in-out',
              cursor: 'pointer',
            }}
            {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <SoftTypography>{t('importvideomodal.instruction')}</SoftTypography>
          </SoftBox>
        )}
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={handleClose} autoFocus>
          {t('global.cancel', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton variant="gradient" onClick={onSubmit} disabled={form === undefined}>
          {t('importvideomodal.import', {defaultValue: 'Import into publication'})}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
