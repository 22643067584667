import {ValidateEmailUserCommand} from '@perfectpost/perfect-post-common';
import * as Sentry from '@sentry/react';
import {AuthError, signIn} from 'aws-amplify/auth';
import {isPast, parseISO} from 'date-fns';
import Lottie from 'lottie-react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, Link} from 'react-router-dom';
import animationSuccess from 'src/assets/lottie//animation-success.json';
import SignupChangePass from 'src/components/signupchangepass/signupchangepass';
import {perfectPostServiceClient} from 'src/services';
import {useAppThunkDispatch} from 'src/store';
import {useAppSelector} from 'src/store';
import {authentification, getMe} from 'src/store/mainslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
// import pattern from 'src/assets/svg/pattern-lines.svg';
import {useQuery} from 'src/utils/hooks';

type ConfirmState = {
  state:
    | 'idle'
    | 'loading'
    | 'error'
    | 'NoCredential'
    | 'NotAuthorizedException'
    | 'playinganimationstart'
    | 'playinganimationended';
  error?: string;
};

export default function Confirm() {
  const query = useQuery();

  const [confirmState, setConfirmState] = useState<ConfirmState>({
    state: 'loading',
  });
  const {state, error} = confirmState;

  const {t} = useTranslation();

  const sessionUser = useAppSelector((state) => state.main.user);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  useDocumentTitle(t('confirm.documenttitle', {defaultValue: 'Confirm'}));

  useEffect(() => {
    if (sessionUser === undefined) {
      return;
    }
    if (state === 'playinganimationended') {
      const tokenExpiration = sessionUser.lnExpiresAt ? parseISO(sessionUser.lnExpiresAt) : undefined;
      if (tokenExpiration === undefined || isPast(tokenExpiration)) {
        navigate('/auth/linkedin');
      } else {
        navigate('/');
      }
    }
  }, [sessionUser, state]);

  useEffect(() => {
    const queryEmail = query.get('email');
    const password = query.get('code');
    if (queryEmail !== null && password !== null) {
      const email = queryEmail.replaceAll('%40', '@').replaceAll(' ', '+');
      setConfirmState((state) => ({...state, state: 'loading', email}));
      signIn({username: email, password})
        .then(({nextStep}) => {
          if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
            setConfirmState((state) => ({...state, state: 'idle', error: undefined}));
          } else {
            setConfirmState((state) => ({...state, state: 'error', error: `invalid-code: ${nextStep.signInStep}`}));
          }
        })
        .catch((error) => {
          if (error instanceof AuthError) {
            if (error.name === 'NotAuthorizedException') {
              setConfirmState((state) => ({...state, state: 'NotAuthorizedException', error: undefined}));
            } else if (error.name === 'UserAlreadyAuthenticatedException') {
              onAuthentificationSuccess();
            }
          } else {
            setConfirmState((state) => ({...state, state: 'error', error: `Unknown error: ${error}`}));
            Sentry.captureException(error, {
              extra: {
                email,
                code: password,
              },
            });
          }
        });
    } else {
      setConfirmState((state) => ({...state, state: 'NoCredential'}));
    }
  }, [query]);

  const onAuthentificationSuccess = async () => {
    setConfirmState((state) => ({...state, state: 'playinganimationstart'}));
    await dispatch(authentification());
    perfectPostServiceClient.send(new ValidateEmailUserCommand());
    return dispatch(getMe());
  };

  const onConfirmChangePass = () => {
    const queryEmail = query.get('email');
    if (queryEmail === null) {
      navigate('/signup');
      return;
    }
    onAuthentificationSuccess();
  };

  return (
    <>
      <SoftBox pt={3} px={3}>
        <SoftBox mb={1}>
          <SoftTypography variant="h4" fontWeight="bold">
            {state === 'idle'
              ? t('password.haveToChange', {
                  defaultValue: 'Your password has expired you must choose a new one',
                })
              : state === 'error'
                ? (error ?? 'Unknown error')
                : state === 'NotAuthorizedException'
                  ? t('signup.confirm.invalidcode', {defaultValue: 'Code already used or invalid'})
                  : state === 'NoCredential'
                    ? t('signup.confirm.title', {defaultValue: 'Are you sure you are in the right place? 🧐'})
                    : ''}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox p={3}>
        <SoftBox component="form" role="form">
          <SoftBox mt={3}>
            {state === 'loading' ? (
              <>
                <SoftTypography variant="body2" fontWeight="regular" color="text">
                  {t('signin.confirm.loading', {defaultValue: 'Chargement de vos information...'})}
                </SoftTypography>
              </>
            ) : state === 'idle' ? (
              <SignupChangePass onConfirm={onConfirmChangePass} />
            ) : state === 'playinganimationstart' || state === 'playinganimationended' ? (
              <Lottie
                autoplay
                animationData={animationSuccess}
                initialSegment={[0, 100]}
                loop={false}
                onComplete={() => {
                  setConfirmState((state) => ({...state, state: 'playinganimationended'}));
                }}
                style={{height: 250}}
              />
            ) : state === 'error' || state === 'NotAuthorizedException' ? (
              <>
                <SoftTypography variant="body2" fontWeight="regular" color="text">
                  {t('signin.signup.ctalogin', {defaultValue: 'Already have an account?'})}{' '}
                  {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                         // @ts-ignore */}
                  <SoftButton variant="text" color="info" size="large" component={Link} to="/signin">
                    {t('signin.login.signbtn', {defaultValue: 'sign in'})}
                  </SoftButton>
                </SoftTypography>
              </>
            ) : (
              <>
                <SoftTypography variant="body2" fontWeight="regular" color="text">
                  {t('signin.login.ctasignup', {defaultValue: 'Need to create an account?'})}{' '}
                  {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                         // @ts-ignore */}
                  <SoftButton variant="text" color="info" size="large" component={Link} to="/signup">
                    {t('signin.login.signupbtn', {defaultValue: 'Sign up'})}
                  </SoftButton>
                </SoftTypography>
              </>
            )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
}
