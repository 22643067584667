import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import {Draft, Proofreader} from '@perfectpost/perfect-post-common';
import {Team} from '@perfectpost/perfect-post-common';
import copy from 'copy-to-clipboard';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SingleValue} from 'react-select';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {editProofreader, inviteToDraft, togglePublicSharing, removeProofreader} from 'src/store/draftslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTagInput from 'src/theme/components/SoftTagInput';
import SoftTypography from 'src/theme/components/SoftTypography';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {expand, ...other} = props;
  return <IconButton {...other} />;
})(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type TeamRole = Team & {
  role: 'readonly' | 'readwrite' | 'none';
};

type ShareDraftModalProps = {
  draft: Draft;
  handleClose: () => void;
};

export default function ShareDraftModal(props: ShareDraftModalProps) {
  const {draft, handleClose} = props;
  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();

  const me = useAppSelector((state) => state.main.user);
  const teams = useAppSelector((state) => state.team.teams);

  const teamRoles = useMemo(() => {
    return teams?.map((t) => {
      return {
        ...t,
        role: draft.proofreaders?.find((p) => p.team === t._id)?.role ?? 'none',
      };
    });
  }, [teams, draft]);

  const [shareModalState, setShareModalState] = useState({
    modalState: 'base' as 'base' | 'email',
    email: '',
    invitations: [] as string[],
    formState: 'idle' as 'idle' | 'working',
    role: 'readonly' as 'readonly' | 'readwrite',
    copied: false,
    expanded: {user: true, team: true, public: true},
    publicVisible: draft.public ?? false,
  });

  const {modalState, email, invitations, formState, role, copied, expanded, publicVisible} = shareModalState;

  const onInputEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.trim().length > 0 && value.endsWith(' ')) {
      setShareModalState((s) => ({
        ...s,
        email: '',
        modalState: 'email',
        invitations: [...new Set([...s.invitations, value.trim()])],
      }));
    } else {
      setShareModalState((s) => ({...s, email: value}));
    }
  };

  const onGoBackEmailInvitation = () => {
    setShareModalState((s) => ({...s, modalState: 'base', invitations: []}));
  };

  const onInvite = async () => {
    setShareModalState((s) => ({...s, formState: 'working'}));
    const proofreaders = invitations.map((i) => {
      return {
        email: i,
        role,
      };
    });
    await dispatch(inviteToDraft({draftId: draft._id, proofreaders}));
    setShareModalState((s) => ({...s, formState: 'idle', modalState: 'base', invitations: []}));
  };

  const onRoleChange =
    (proofreader: Proofreader) =>
    async (
      newValue: SingleValue<{
        value: string;
        label: string;
      }>,
    ) => {
      if (newValue?.value === 'delete') {
        setShareModalState((s) => ({...s, formState: 'working'}));
        await dispatch(
          removeProofreader({
            draftId: draft._id,
            proofreader: {
              email: proofreader.email,
              user: proofreader.user?._id,
            },
          }),
        );
        setShareModalState((s) => ({...s, formState: 'idle'}));
      } else {
        const newRole = newValue?.value === 'readwrite' ? 'readwrite' : 'readonly';
        if (newRole !== proofreader.role) {
          setShareModalState((s) => ({...s, formState: 'working'}));
          await dispatch(
            editProofreader({
              draftId: draft._id,
              proofreader: {email: proofreader.email, role: newRole},
            }),
          );
          setShareModalState((s) => ({...s, formState: 'idle'}));
        }
      }
    };

  const onTeamRoleChange =
    (team: TeamRole) =>
    async (
      newValue: SingleValue<{
        value: string;
        label: string;
      }>,
    ) => {
      if (newValue?.value === 'none') {
        setShareModalState((s) => ({...s, formState: 'working'}));
        await dispatch(
          removeProofreader({
            draftId: draft._id,
            proofreader: {
              team: team._id,
            },
          }),
        );
        setShareModalState((s) => ({...s, formState: 'idle'}));
      } else {
        const newRole = newValue?.value === 'readwrite' ? 'readwrite' : 'readonly';
        if (newRole !== team.role) {
          setShareModalState((s) => ({...s, formState: 'working'}));
          await dispatch(
            editProofreader({
              draftId: draft._id,
              proofreader: {team: team._id, role: newRole},
            }),
          );
          setShareModalState((s) => ({...s, formState: 'idle'}));
        }
      }
    };

  const onSharingChange = async () => {
    setShareModalState((s) => ({...s, formState: 'working'}));
    await dispatch(togglePublicSharing(draft._id));
    setShareModalState((s) => ({...s, formState: 'idle', publicVisible: !s.publicVisible}));
  };

  const onCopyPublicLink = () => {
    copy(`https://app.perfectpost.fr/drafts/${draft._id}/sharing`);
    setShareModalState((s) => ({...s, copied: true}));
    setTimeout(() => {
      setShareModalState((s) => ({...s, copied: false}));
    }, 5000);
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth>
      <DialogTitle sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {modalState === 'email' && (
          <IconButton onClick={onGoBackEmailInvitation}>
            <ArrowBackIosIcon />
          </IconButton>
        )}
        <SoftTypography>{t('sharedraftmodal.title')}</SoftTypography>
      </DialogTitle>
      {modalState === 'base' ? (
        <DialogContent>
          <SoftBox py={2}>
            <SoftInput placeholder={t('form.email.placeholder')} value={email} onChange={onInputEmailChange} />
          </SoftBox>
          <SoftBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              borderColor: '#cbd5e0',
              mb: 2,
            }}>
            <SoftTypography>{t('sharedraftmodal.userstitle')}</SoftTypography>
            <ExpandMore
              expand={expanded.user}
              onClick={() => {
                setShareModalState((s) => ({...s, expanded: {...s.expanded, user: !s.expanded.user}}));
              }}
              aria-expanded={`${expanded.user}`}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </SoftBox>
          <Collapse in={expanded.user} timeout="auto" unmountOnExit sx={{mb: 2}}>
            <List dense>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar src={me?.lnPicture} />
                  </ListItemAvatar>
                  <ListItemText primary={`${me?.firstname ?? ''} ${me?.lastname ?? ''}`} />
                  <SoftTypography edge="end" variant="body2">
                    Propriétaire
                  </SoftTypography>
                </ListItemButton>
              </ListItem>
              {draft.proofreaders
                ?.filter((p) => p.team === undefined)
                .map((p) => (
                  <ListItem key={p.user?._id ?? p.email} disablePadding>
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar src={p.user?.lnPicture} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          p.user?.firstname || p.user?.lastname ? `${p.user.firstname} ${p.user.lastname}` : p.email
                        }
                      />
                      <SoftSelect
                        defaultValue={{value: p.role, label: t(`sharedraftmodal.level.${p.role}`)}}
                        options={[
                          {value: 'readonly', label: t('sharedraftmodal.level.readonly')},
                          {value: 'readwrite', label: t('sharedraftmodal.level.readwrite')},
                          {value: 'delete', label: t('global.delete')},
                        ]}
                        onChange={onRoleChange(p)}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Collapse>
          <SoftBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              borderColor: '#cbd5e0',
              mb: 2,
            }}>
            <SoftTypography>{t('sharedraftmodal.teamstitle')}</SoftTypography>
            <ExpandMore
              expand={expanded.team}
              onClick={() => {
                setShareModalState((s) => ({...s, expanded: {...s.expanded, team: !s.expanded.team}}));
              }}
              aria-expanded={`${expanded.team}`}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </SoftBox>
          <Collapse in={expanded.team} timeout="auto" unmountOnExit sx={{mb: 2}}>
            <List dense>
              {teamRoles?.map((team) => (
                <ListItem key={team._id} disablePadding>
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar src={team.picture}>{team.name[0]}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={team.name} />
                    <SoftSelect
                      defaultValue={{value: team.role, label: t(`sharedraftmodal.level.${team.role}`)}}
                      options={[
                        {value: 'none', label: t('sharedraftmodal.level.none')},
                        {value: 'readonly', label: t('sharedraftmodal.level.readonly')},
                        {value: 'readwrite', label: t('sharedraftmodal.level.readwrite')},
                      ]}
                      // @ts-ignore
                      onChange={onTeamRoleChange(team)}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
          <SoftBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              borderColor: '#cbd5e0',
              mb: 2,
            }}>
            <SoftTypography>{t('sharedraftmodal.publictitle')}</SoftTypography>
            <ExpandMore
              expand={expanded.public}
              onClick={() => {
                setShareModalState((s) => ({...s, expanded: {...s.expanded, public: !s.expanded.public}}));
              }}
              aria-expanded={`${expanded.public}`}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </SoftBox>
          <Collapse in={expanded.public} timeout="auto" unmountOnExit>
            <FormControlLabel
              control={<Switch checked={publicVisible} onChange={onSharingChange} />}
              label={
                formState === 'idle' ? (
                  t('sharedraftmodal.togglepubliclabel')
                ) : (
                  <CircularProgress color="info" size={24} />
                )
              }
              sx={{ml: 0}}
            />
            <SoftInput
              disabled={publicVisible !== true}
              value={`https://app.perfectpost.fr/drafts/${draft._id}/sharing`}
              icon={{
                component: publicVisible !== true ? undefined : copied ? 'done' : 'copy',
                direction: 'right',
                onClick: onCopyPublicLink,
              }}
            />
          </Collapse>
        </DialogContent>
      ) : (
        <DialogContent sx={{minHeight: 300}}>
          <SoftBox py={2} sx={{display: 'flex', flexDirection: 'row'}}>
            <SoftBox sx={{flexGrow: 1}}>
              <SoftTagInput
                tags={invitations}
                onChange={(array) => setShareModalState((s) => ({...s, invitations: array}))}
                removeOnBackspace
              />
            </SoftBox>
            <SoftBox ml={2}>
              <SoftSelect
                defaultValue={{value: 'readonly', label: t('sharedraftmodal.level.readonly')}}
                options={[
                  {value: 'readonly', label: t('sharedraftmodal.level.readonly')},
                  {value: 'readwrite', label: t('sharedraftmodal.level.readwrite')},
                ]}
                onChange={(value) =>
                  setShareModalState((s) => ({
                    ...s,
                    role: (value?.value as 'readonly' | 'readwrite' | undefined) ?? 'readonly',
                  }))
                }
              />
            </SoftBox>
          </SoftBox>
        </DialogContent>
      )}
      <DialogActions>
        {modalState === 'base' ? (
          <SoftButton variant="gradient" disabled={formState === 'working'} onClick={handleClose}>
            {formState === 'idle' ? t('global.save') : <CircularProgress color="info" size={24} />}
          </SoftButton>
        ) : (
          <SoftButton variant="gradient" disabled={formState === 'working'} onClick={onInvite}>
            {formState === 'idle' ? t('sharedraftmodal.invite') : <CircularProgress color="info" size={24} />}
          </SoftButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
