import {
  AssignLicenceTeamCommand,
  AssignLicenceTeamInput,
  CreateTeamCommand,
  DeleteTeamCommand,
  EditMemberRoleTeamCommand,
  EditTeamCommand,
  GetOneUserCommand,
  GetTeamCommand,
  InviteMemberTeamCommand,
  RemoveFromTeamCommand,
  RemoveLicenceTeamCommand,
  RemoveLicenceTeamInput,
  SetPrivacyTeamCommand,
  Team,
  TeamMemberPrivacy,
} from '@perfectpost/perfect-post-common';
import {PublicUser} from '@perfectpost/perfect-post-common';
import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import {perfectPostServiceClient} from 'src/services';

import {RootState} from '.';

export interface TeamState {
  loadingTeam: boolean;
  teams: Team[];
  members: PublicUser[];
}
const initialState: TeamState = {
  teams: [],
  members: [],
  loadingTeam: true,
};

const selectTeams = (state: RootState) => state.team.teams;
export const selectTeamById = createSelector(
  [selectTeams, (state: RootState, teamId: string | undefined) => teamId],
  (teams, teamId) => teams.find((item) => item._id === teamId),
);

const selectAllTeamMembers = (state: RootState) => state.team.members;
export const selectMembersOfTeamId = createSelector(
  [selectAllTeamMembers, selectTeamById],
  (members, team) =>
    team?.users
      .map((u) => members.find((m) => m._id === u.user))
      .filter((m): m is PublicUser => m?._id !== undefined) ?? [],
);

export const fetchTeams = createAsyncThunk('teams', async () => {
  const teams = await perfectPostServiceClient.send(new GetTeamCommand());
  const memberIds = [...new Set(teams.flatMap((team) => team.users.map((u) => u.user)))];
  const members = await Promise.all(
    memberIds.map((userId) => perfectPostServiceClient.send(new GetOneUserCommand({id: userId}))),
  );
  return {teams, members};
});
export const createTeam = createAsyncThunk(
  'teams/create',
  async (args: {name: string; invitations: string[]; picture?: string}) => {
    return perfectPostServiceClient.send(new CreateTeamCommand(args));
  },
);
export const editTeam = createAsyncThunk('teams/edit', async (args: {id: string; name?: string; picture?: string}) => {
  return perfectPostServiceClient.send(new EditTeamCommand(args));
});
export const deleteTeam = createAsyncThunk('teams/delete', async (args: {id: string}) => {
  return perfectPostServiceClient.send(new DeleteTeamCommand(args));
});
export const inviteTeam = createAsyncThunk('teams/invite', async (args: {id: string; email: string}) => {
  return perfectPostServiceClient.send(new InviteMemberTeamCommand(args));
});

export const editRoleTeam = createAsyncThunk(
  'teams/editrole',
  async (args: {id: string; user: string; role: 'owner' | 'admin' | 'member'}) => {
    return perfectPostServiceClient.send(new EditMemberRoleTeamCommand(args));
  },
);

export const assignLicenceTeam = createAsyncThunk('teams/assignlicence', async (args: AssignLicenceTeamInput) => {
  return perfectPostServiceClient.send(new AssignLicenceTeamCommand(args));
});

export const removeLicenceTeam = createAsyncThunk('teams/removelicence', async (args: RemoveLicenceTeamInput) => {
  return perfectPostServiceClient.send(new RemoveLicenceTeamCommand(args));
});

export const removeFromTeam = createAsyncThunk(
  'teams/remove',
  async (args: {id: string; body: {user?: string; email?: string}}) => {
    return perfectPostServiceClient.send(new RemoveFromTeamCommand({teamId: args.id, ...args.body}));
  },
);

export const editPrivacyTeam = createAsyncThunk(
  'teams/privacy',
  async (args: {id: string; privacy: TeamMemberPrivacy}) => {
    return perfectPostServiceClient.send(new SetPrivacyTeamCommand({id: args.id, ...args.privacy}));
  },
);

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.loadingTeam = true;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.loadingTeam = false;
        state.teams = action.payload.teams;
        state.members = action.payload.members;
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.teams.push(action.payload.team);
      })
      .addCase(editTeam.fulfilled, (state, action) => {
        const index = state.teams.findIndex((team) => team._id === action.payload.team._id);
        if (index !== -1) {
          state.teams[index] = action.payload.team;
        }
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        state.teams = state.teams.filter((team) => team._id !== action.meta.arg.id);
      })
      .addCase(inviteTeam.fulfilled, (state, action) => {
        const index = state.teams.findIndex((team) => team._id === action.meta.arg.id);
        if (index !== -1) {
          const item = state.teams[index];
          item.invitations.push(action.meta.arg.email);
        }
      })
      .addCase(editRoleTeam.fulfilled, (state, action) => {
        state.teams = state.teams.map((team) => {
          if (team._id === action.payload._id) {
            return action.payload;
          }
          return team;
        });
      })
      .addCase(assignLicenceTeam.fulfilled, (state, action) => {
        state.teams = state.teams.map((team) => {
          if (team._id === action.payload._id) {
            return action.payload;
          }
          return team;
        });
      })
      .addCase(removeLicenceTeam.fulfilled, (state, action) => {
        state.teams = state.teams.map((team) => {
          if (team._id === action.payload._id) {
            return action.payload;
          }
          return team;
        });
      })
      .addCase(removeFromTeam.fulfilled, (state, action) => {
        state.teams = state.teams.map((team) => {
          if (team._id === action.payload._id) {
            return action.payload;
          }
          return team;
        });
      })
      .addCase(editPrivacyTeam.fulfilled, (state, action) => {
        state.teams = state.teams.map((team) => {
          if (team._id === action.payload._id) {
            return action.payload;
          }
          return team;
        });
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = teamsSlice.actions;
export default teamsSlice.reducer;
