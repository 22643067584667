import {Dialog, DialogContent, DialogActions, Button} from '@mui/material';
import {StaticDateTimePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {enGB} from 'date-fns/locale/en-GB';
import {es} from 'date-fns/locale/es';
import {fr} from 'date-fns/locale/fr';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from 'src/store';

type ScheduleDialogProps = {
  handleClose: () => void;
  handleSchedule: (date: Date) => void;
};

export default function ScheduleDialog(props: ScheduleDialogProps) {
  const {handleClose, handleSchedule} = props;
  const [working, setWorking] = useState(false);
  const {t} = useTranslation();
  const locale = useAppSelector((state) => state.main.user?.locale);
  const [dateTab, setDateTab] = useState(new Date());

  const onChange = (date: Date | null) => {
    if (date === null) {
      return;
    }
    setDateTab(date);
  };

  const handleScheduleClick = () => {
    setWorking(true);
    handleSchedule(dateTab);
  };

  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale === 'fr' ? fr : locale === 'es' ? es : enGB}>
          <StaticDateTimePicker
            displayStaticWrapperAs="mobile"
            value={dateTab}
            localeText={{toolbarTitle: t('draftDatePopup.title', {defaultValue: 'Date and hour of the post'})}}
            onChange={onChange}
            minutesStep={5}
            disablePast
            slotProps={{
              toolbar: {
                hidden: false,
                sx: {
                  '& .MuiDateTimePickerToolbar-timeDigitsContainer span': {
                    fontSize: '3rem !important',
                  },
                },
              },
              actionBar: {
                actions: [],
              },
            }}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleScheduleClick} autoFocus disabled={working}>
          {t('scheduledialog.program', {defaultValue: 'Program'})}
        </Button>
        <Button onClick={handleClose} autoFocus disabled={working}>
          {t('scheduledialog.cancelbtn', {defaultValue: 'Cancel'})}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
