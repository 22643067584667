import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {Avatar, CircularProgress} from '@mui/material';
import Icon from '@mui/material/Icon';
import {
  GetOneUserCommand,
  type Draft,
  type Proofreader,
  GetDraftCommentCommand,
  CreateDraftCommentCommand,
  RemoveDraftCommentCommand,
} from '@perfectpost/perfect-post-common';
import type {PublicUser} from '@perfectpost/perfect-post-common';
import type {DraftComment} from '@perfectpost/perfect-post-common';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';
import {localFormatRelativeISOString} from 'src/utils/date-format';

type DraftChatBoxProps = {
  draft: Draft;
  onAddProofreader: () => void;
};

export default function DraftChatBox({draft, onAddProofreader}: DraftChatBoxProps) {
  const utilisateur = useAppSelector((state) => state.main.user);
  const teams = useAppSelector((state) => state.team.teams);
  const chatbox = React.useRef<HTMLDivElement>(null);
  const {t} = useTranslation();

  const [author, setAuthor] = useState<PublicUser | undefined>();
  const [formState, setFormState] = useState<'idle' | 'working' | {comment: string}>('idle');
  const [comment, setComment] = useState<string>('');
  const [comments, setComments] = useState<DraftComment[]>([]);

  useEffect(() => {
    if (draft.user !== utilisateur?._id) {
      void perfectPostServiceClient.send(new GetOneUserCommand({id: draft.user})).then((res) => {
        setAuthor(res);
      });
    } else {
      setAuthor(utilisateur);
    }
  }, [draft.user, utilisateur?._id]);

  useEffect(() => {
    perfectPostServiceClient.send(new GetDraftCommentCommand(draft._id)).then((res) => {
      setComments(res);
    });
  }, [draft._id]);

  useEffect(() => {
    if (chatbox.current) {
      chatbox.current.scrollTop = chatbox.current.scrollHeight;
    }
  }, [comments]);

  const [first4, rest] = useMemo(() => {
    const first4ProofReader = draft.proofreaders?.slice(0, 4) ?? [];
    const restCountProofReader = draft.proofreaders?.slice(4).length ?? 0;

    if (first4ProofReader.length < 4) {
      const teamWithDraftSharing = teams
        .filter((t) => t.users.find((u) => u.user === draft.user)?.privacy?.draft !== 'none')
        .slice(0, 4 - first4ProofReader.length);
      first4ProofReader.push(
        ...teamWithDraftSharing.map((t) => {
          const p: Proofreader = {team: t._id, user: undefined, role: 'readonly'};
          return p;
        }),
      );
    }

    return [first4ProofReader, restCountProofReader];
  }, [draft.proofreaders, teams]);

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const onSubmitComment = async () => {
    setFormState('working');
    await perfectPostServiceClient.send(new CreateDraftCommentCommand({id: draft._id, content: comment}));
    const newComments = await perfectPostServiceClient.send(new GetDraftCommentCommand(draft._id));
    setComments(newComments);
    setComment('');
    setFormState('idle');
  };

  const onDeleteComment = (commentId: string) => async () => {
    setFormState({comment: commentId});
    await perfectPostServiceClient.send(new RemoveDraftCommentCommand({id: draft._id, commentId}));
    const newComments = await perfectPostServiceClient.send(new GetDraftCommentCommand(draft._id));
    setComments(newComments);
    setFormState('idle');
  };

  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      bgColor="white"
      sx={{borderRadius: 3, border: '1px solid #CED4DA', flex: 1}}>
      <SoftBox display="flex" flexDirection="row" sx={{px: 1.5, py: 0.5, borderBottom: '1px solid #CED4DA'}}>
        <SoftBox display="flex" flexDirection="row" flexGrow="1" alignItems="center">
          <SoftBox component="img" src={author?.lnPicture} width={42} height={42} sx={{borderRadius: '100%'}} />
          <SoftTypography variant="subtitle2" sx={{ml: 1, fontWeight: 'bold'}}>
            {author?.firstname} {author?.lastname}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="caption">{t('draftchatbox.proofreaders')}</SoftTypography>
          <SoftBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {utilisateur !== undefined && author?._id === utilisateur?._id && (
              <SoftButton iconOnly variant="text" color="dark" onClick={onAddProofreader}>
                <Icon>add</Icon>
              </SoftButton>
            )}
            <SoftBox
              sx={{
                height: 34,
                width: 142,
                position: 'relative',
                display: first4.length === 0 ? 'flex' : undefined,
                alignItems: first4.length === 0 ? 'center' : undefined,
              }}>
              {first4.length === 0 && (
                <SoftButton sx={{pl: 0}} variant="text" color="dark" onClick={onAddProofreader}>
                  Ajouter
                </SoftButton>
              )}
              {first4.map((p, index) => (
                <SoftBox
                  key={p.user?._id ?? p.team}
                  sx={{
                    position: 'absolute',
                    width: 34,
                    height: 34,
                    left: index * 28,
                    border: '1px solid white',
                    borderRadius: 34,
                  }}>
                  <Avatar
                    src={p.user?.lnPicture ?? teams.find((t) => t._id === p.team)?.picture}
                    sx={{width: '32px', height: '32px'}}>
                    {p.team ? `${teams.find((t) => t._id === p.team)?.name?.[0] ?? ''}` : undefined}
                  </Avatar>
                </SoftBox>
              ))}
              {rest > 0 && (
                <SoftBox
                  sx={(theme) => ({
                    position: 'absolute',
                    width: 34,
                    height: 34,
                    left: 112,
                    border: '1px solid white',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: '100%',
                  })}>
                  <SoftTypography variant="button" fontWeight="medium" color="light">
                    +{4 - rest}
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox ref={chatbox} sx={{flex: 1, maxHeight: '500px', overflow: 'scroll'}}>
        {comments.map((c) => (
          <SoftBox
            key={c._id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              py: 1,
              px: 1.5,
              position: 'relative',
            }}>
            {typeof formState === 'object' && 'comment' in formState && formState.comment === c._id && (
              <SoftBox
                bgColor="dark"
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  opacity: 0.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CircularProgress color="secondary" />
              </SoftBox>
            )}
            <SoftBox component="img" src={c.user?.lnPicture} width={42} height={42} sx={{borderRadius: '100%'}} />
            <SoftBox sx={{ml: 1.5, flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
              <SoftTypography variant="caption" fontWeight="bold">
                {c.user?.firstname} {c.user?.lastname}
              </SoftTypography>
              <SoftTypography variant="caption">{c.content}</SoftTypography>
              <SoftTypography variant="caption" sx={{mt: 0.5, color: '#718096', display: 'flex', alignItems: 'center'}}>
                <AccessTimeIcon sx={{mr: 0.5, height: '14px'}} />
                {localFormatRelativeISOString(c.date, utilisateur?.locale ?? 'en')}
                {c.user?._id === utilisateur?._id && (
                  <SoftButton
                    disabled={formState !== 'idle'}
                    iconOnly
                    variant="text"
                    color="dark"
                    sx={{m: 0, p: 0}}
                    onClick={onDeleteComment(c._id)}>
                    <Icon>delete</Icon>
                  </SoftButton>
                )}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        ))}
      </SoftBox>
      <SoftBox sx={{p: 1.5, borderTop: '1px solid #CED4DA', display: 'flex', flexDirection: 'column'}}>
        <SoftBox sx={{display: 'flex', flexDirection: 'row'}}>
          <SoftBox
            component="img"
            src={utilisateur?.lnPicture}
            width={42}
            height={42}
            sx={{borderRadius: '100%', mr: 1.5}}
          />
          <SoftInput value={comment} onChange={onCommentChange} />
        </SoftBox>
        {comment.length > 0 && (
          <SoftBox sx={{mt: 1, marginLeft: '55px'}}>
            <SoftButton variant="contained" color="dark" size="small" onClick={onSubmitComment}>
              {formState === 'working' ? <CircularProgress size={20} color="info" /> : t('draftchatbox.send')}
            </SoftButton>
          </SoftBox>
        )}
      </SoftBox>
    </SoftBox>
  );
}
