import Quill from 'quill';

import {transform} from './transform';

export default function italic(_quill: Quill | boolean) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const quill: Quill = typeof _quill === 'boolean' ? (this.quill as Quill) : _quill;
  transform(quill, [
    {
      action: 'normalize',
    },
    {
      action: 'map_code_point',
      map: {
        104: [8462],
      },
    },
    {
      action: 'shift_code_point',
      range: [65, 90],
      add: 119_795,
    },
    {
      action: 'shift_code_point',
      range: [97, 122],
      add: 119_789,
    },
  ]);
}
