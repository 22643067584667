import Quill from 'quill';

import {transform} from './transform';
export default function clean(_quill: Quill | boolean) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const quill: Quill = typeof _quill === 'boolean' ? (this.quill as Quill) : _quill;
  transform(quill, [
    {
      action: 'unstack',
    },
    {
      action: 'normalize',
    },
    {
      action: 'strip_diacritics',
    },
    {
      action: 'reverse',
      if_in_map: 'ifspec',
    },
    {
      action: 'map_code_point',
      map: 'freg',
      if_in_map: 'ifspec',
    },
    {
      action: 'map_code_point',
      map: 'ifspec',
      if_in_map: 'ifspec',
    },
    {
      action: 'map_code_point',
      map: 'isc',
    },
    {
      action: 'map_code_point',
      map: 'is',
    },
    {
      action: 'map_code_point',
      map: 'isub',
    },
    {
      action: 'map_code_point',
      map: 'icu',
    },
    {
      action: 'shift_code_point',
      range: [9398, 9423],
      add: -9333,
    },
    {
      action: 'shift_code_point',
      range: [9424, 9449],
      add: -9327,
    },
    {
      action: 'shift_code_point',
      range: [9312, 9320],
      add: -9263,
    },
    {
      action: 'shift_code_point',
      range: [127_312, 127_337],
      add: -127_247,
    },
    {
      action: 'shift_code_point',
      range: [10_122, 10_130],
      add: -10_073,
    },
    {
      action: 'shift_code_point',
      range: [127_248, 127_273],
      add: -127_183,
    },
    {
      action: 'shift_code_point',
      range: [9372, 9397],
      add: -9275,
    },
    {
      action: 'shift_code_point',
      range: [9332, 9340],
      add: -9283,
    },
    {
      action: 'shift_code_point',
      range: [119_808, 119_833],
      add: -119_743,
    },
    {
      action: 'shift_code_point',
      range: [119_834, 119_859],
      add: -119_737,
    },
    {
      action: 'shift_code_point',
      range: [120_782, 120_791],
      add: -120_734,
    },
    {
      action: 'shift_code_point',
      range: [119_860, 119_885],
      add: -119_795,
    },
    {
      action: 'shift_code_point',
      range: [119_886, 119_911],
      add: -119_789,
    },
    {
      action: 'shift_code_point',
      range: [119_912, 119_937],
      add: -119_847,
    },
    {
      action: 'shift_code_point',
      range: [119_938, 119_963],
      add: -119_841,
    },
    {
      action: 'shift_code_point',
      range: [119_964, 119_989],
      add: -119_899,
    },
    {
      action: 'shift_code_point',
      range: [119_990, 120_015],
      add: -119_893,
    },
    {
      action: 'shift_code_point',
      range: [120_016, 120_041],
      add: -119_951,
    },
    {
      action: 'shift_code_point',
      range: [120_042, 120_067],
      add: -119_945,
    },
    {
      action: 'shift_code_point',
      range: [120_120, 120_145],
      add: -120_055,
    },
    {
      action: 'shift_code_point',
      range: [120_146, 120_171],
      add: -120_049,
    },
    {
      action: 'shift_code_point',
      range: [120_792, 120_801],
      add: -120_744,
    },
    {
      action: 'shift_code_point',
      range: [120_068, 120_093],
      add: -120_003,
    },
    {
      action: 'shift_code_point',
      range: [120_094, 120_119],
      add: -119_997,
    },
    {
      action: 'shift_code_point',
      range: [120_172, 120_197],
      add: -120_107,
    },
    {
      action: 'shift_code_point',
      range: [120_198, 120_223],
      add: -120_101,
    },
    {
      action: 'shift_code_point',
      range: [65_281, 65_374],
      add: -65_248,
    },
    {
      action: 'shift_code_point',
      range: [120_276, 120_301],
      add: -120_211,
    },
    {
      action: 'shift_code_point',
      range: [120_302, 120_327],
      add: -120_205,
    },
    {
      action: 'shift_code_point',
      range: [120_812, 120_821],
      add: -120_764,
    },
    {
      action: 'shift_code_point',
      range: [120_328, 120_353],
      add: -120_263,
    },
    {
      action: 'shift_code_point',
      range: [120_354, 120_379],
      add: -120_257,
    },
    {
      action: 'shift_code_point',
      range: [120_380, 120_405],
      add: -120_315,
    },
    {
      action: 'shift_code_point',
      range: [120_406, 120_431],
      add: -120_309,
    },
    {
      action: 'shift_code_point',
      range: [120_432, 120_457],
      add: -120_367,
    },
    {
      action: 'shift_code_point',
      range: [120_458, 120_483],
      add: -120_361,
    },
    {
      action: 'shift_code_point',
      range: [120_823, 120_831],
      add: -120_774,
    },
    {
      action: 'shift_code_point',
      range: [127_280, 127_305],
      add: -127_215,
    },
    {
      action: 'shift_code_point',
      range: [127_344, 127_369],
      add: -127_279,
    },
    {
      action: 'map_code_point',
      map: {
        9450: [48],
      },
    },
    {
      action: 'map_code_point',
      map: {
        9471: [48],
      },
    },
    {
      action: 'map_code_point',
      map: {
        8462: [104],
      },
    },
    {
      action: 'map_code_point',
      map: {
        8450: [67],
        8461: [72],
        8469: [78],
        8473: [80],
        8474: [81],
        8477: [82],
        8484: [90],
      },
    },
    {
      action: 'map_code_point',
      map: {
        8460: [72],
        8465: [73],
        8476: [82],
        8488: [90],
        8493: [67],
      },
    },
    {
      action: 'map_code_point',
      map: {
        923: [65],
        926: [69],
      },
    },
    {
      action: 'map_code_point',
      map: {
        9650: [65],
        9660: [86],
      },
    },
    {
      action: 'map_code_point',
      map: {
        931: [69],
        9826: [79],
      },
    },
    {
      action: 'remove_extra_spaces',
    },
  ]);
}
