/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {styled} from '@mui/material/styles';
import MuiTypography, {TypographyProps as MuiTypographyProps} from '@mui/material/Typography';
import React from 'react';

export type ExtraProps = {
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark'
    | 'text'
    | 'white';
  fontWeight?: 'bold' | 'light' | 'regular' | 'medium';
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  verticalAlign?: 'unset' | 'baseline' | 'sub' | 'super' | 'text-top' | 'text-bottom' | 'middle' | 'top' | 'bottom';
  textGradient?: boolean;
  opacity?: number;
};

type CastedForwardRefTypographyType = <C extends React.ElementType>(
  props: Omit<MuiTypographyProps<C, {component?: C}>, 'color' | 'fontWeight'> & ExtraProps,
  ref?: React.Ref<HTMLButtonElement>,
) => React.ReactElement | null;
const CastedForwardRefTypographyFnc: CastedForwardRefTypographyType = (props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {children, textGradient, ...rest} = props;
  return (
    <MuiTypography ref={ref} {...rest}>
      {children}
    </MuiTypography>
  );
};
const CastedForwardRefTypography = React.forwardRef(CastedForwardRefTypographyFnc) as CastedForwardRefTypographyType;
export default styled(CastedForwardRefTypography)(({
  theme,
  color,
  textTransform,
  verticalAlign,
  fontWeight,
  opacity,
  textGradient,
}) => {
  const {palette, typography, functions} = theme;

  const {gradients, transparent} = palette;
  const {fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold} = typography;
  const {linearGradient} = functions;

  // fontWeight styles
  const fontWeights = {
    light: fontWeightLight,
    regular: fontWeightRegular,
    medium: fontWeightMedium,
    bold: fontWeightBold,
  };

  // styles for the typography with textGradient={true}
  const gradientStyles = () => ({
    backgroundImage:
      color !== 'inherit' && color !== 'text' && color !== 'white' && color !== undefined && gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : linearGradient(gradients.dark.main, gradients.dark.state),
    display: 'inline-block',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: transparent.main,
    // position: 'relative',
    zIndex: 1,
  });

  return {
    opacity,
    textTransform,
    verticalAlign,
    textDecoration: 'none',
    color: color === 'inherit' || color === undefined || !palette[color] ? 'inherit' : palette[color].main,
    fontWeight: fontWeight && fontWeights[fontWeight] ? fontWeights[fontWeight] : 'normal',
    ...(textGradient ? gradientStyles() : {}),
  };
});
