/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import React from 'react';
import SoftBadge from 'src/theme/components/SoftBadge';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';

type PricingCardProps = {
  main?: boolean;
  badge: {
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
    label: string;
  };
  price: {
    currency: string;
    value: string;
    interval: string;
  };
  specifications: {label: string; soon: boolean}[];
  action: {
    label: string;
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
    loading?: boolean;
  };
  onClick: () => void;
};
export default function PricingCard({main, badge, price, specifications, action, onClick}: PricingCardProps) {
  const renderSpecifications = specifications.map(({label, soon}) => (
    <SoftBox key={label} display="flex" alignItems="center" p={1}>
      <SoftTypography
        fontWeight={main ? 'bold' : undefined}
        variant="button"
        textAlign="center"
        color={main ? 'light' : 'text'}
        sx={{lineHeight: 0, width: 55}}>
        {soon ? <SoftBadge size="xs" color="warning" badgeContent="soon" sx={{mr: 1}} /> : '✔'}
      </SoftTypography>
      <SoftTypography
        fontWeight={main && soon === false ? 'bold' : undefined}
        variant="body2"
        color={main ? 'light' : 'text'}>
        {label}
      </SoftTypography>
    </SoftBox>
  ));

  return (
    <Card sx={{backgroundColor: main ? '#0096ff' : undefined}}>
      <SoftBox pt={3} pb={2} px={2} textAlign="center">
        <SoftBadge variant="contained" color={badge.color} size="sm" badgeContent={badge.label} circular container />
        <SoftBox my={1}>
          <SoftTypography variant="h1" color={main ? 'light' : 'text'}>
            {price.value} {price.currency}
            <SoftTypography display="inline" component="small" variant="h2">
              {price.interval}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        {renderSpecifications}
        <SoftBox mt={3}>
          <SoftButton variant="gradient" disabled={action.loading} color={action.color} fullWidth onClick={onClick}>
            {action.loading ? (
              <CircularProgress />
            ) : (
              <>
                {action.label}&nbsp;
                <Icon sx={{fontWeight: 'bold'}}>arrow_forward</Icon>
              </>
            )}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
