/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {forwardRef, createContext, useContext, useMemo} from 'react';

// Soft UI Dashboard PRO React components
import SoftPaginationItemRoot from './SoftPaginationItemRoot';
import SoftBox from '../SoftBox';

// Custom styles for SoftPagination

type Sizes = 'small' | 'medium' | 'large';
// The Pagination main context
const Context = createContext<{
  size?: Sizes;
  color?: 'white' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  variant?: 'contained' | 'text' | 'outlined' | 'gradient';
}>({
  size: 'medium',
  variant: 'contained',
  color: 'primary',
});

type SoftPaginationProps = {
  item?: boolean;
  variant?: 'contained' | 'text' | 'outlined' | 'gradient';
  color?: 'white' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  size?: Sizes;
  active?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
};

const SoftPagination = forwardRef<HTMLButtonElement, SoftPaginationProps>(
  (
    {item = false, variant = 'gradient', color = 'info', size = 'medium', active = false, children, onClick, ...rest},
    ref,
  ) => {
    const context = useContext(Context);
    const paginationSize = context ? context.size : undefined;
    const value = useMemo(() => ({variant, color, size}), [variant, color, size]);

    const isValidVariant = (variant: string | undefined): variant is 'contained' | 'text' | 'outlined' => {
      return ['contained', 'text', 'outlined', undefined].includes(variant);
    };
    const isValidColor = (
      color: string | undefined,
    ): color is 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' => {
      return ['primary', 'secondary', 'info', 'success', 'warning', 'error', undefined].includes(color);
    };

    return (
      <Context.Provider value={value}>
        {item ? (
          <SoftPaginationItemRoot
            {...rest}
            ref={ref}
            variant={active && isValidVariant(context.variant) ? context.variant : 'outlined'}
            color={active && isValidColor(context.color) ? context.color : 'secondary'}
            iconOnly
            circular
            onClick={onClick}
            ownerState={{variant, active, paginationSize}}>
            {children}
          </SoftPaginationItemRoot>
        ) : (
          <SoftBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{listStyle: 'none'}}
            onClick={onClick}>
            {children}
          </SoftBox>
        )}
      </Context.Provider>
    );
  },
);

export default SoftPagination;
