/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {Theme} from '@mui/material';

function collapseItem(theme: Theme, ownerState: {active: boolean | undefined; transparentSidenav: boolean}) {
  const {palette, transitions, breakpoints, boxShadows, borders, functions} = theme;
  const {active, transparentSidenav} = ownerState;

  const {dark, white, text, transparent} = palette;
  const {xxl} = boxShadows;
  const {borderRadius} = borders;
  const {pxToRem} = functions;

  return {
    background: active && transparentSidenav ? white.main : transparent.main,
    color: active ? dark.main : text.main,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(16)}`,
    margin: `0 ${pxToRem(16)}`,
    borderRadius: borderRadius.md,
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    boxShadow: active && transparentSidenav ? xxl : 'none',
    [breakpoints.up('xl')]: {
      boxShadow: () => {
        if (active) {
          return transparentSidenav ? xxl : 'none';
        }

        return 'none';
      },
      transition: transitions.create('box-shadow', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },
  };
}

function collapseIconBox(
  theme: Theme,
  ownerState: {
    active: boolean | undefined;
    transparentSidenav: boolean;
    sidenavColor: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  },
) {
  const {palette, transitions, breakpoints, boxShadows, borders, functions} = theme;
  const {active, transparentSidenav, sidenavColor} = ownerState;

  const {white, info, light, gradients} = palette;
  const {md} = boxShadows;
  const {borderRadius} = borders;
  const {pxToRem} = functions;

  return {
    background: () => {
      if (active) {
        return sidenavColor === 'default' ? info.main : palette[sidenavColor].main;
      }

      return light.main;
    },
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    borderRadius: borderRadius.md,
    display: 'grid',
    placeItems: 'center',
    boxShadow: md,
    transition: transitions.create('margin', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up('xl')]: {
      background: () => {
        let background;

        if (!active) {
          background = transparentSidenav ? white.main : light.main;
        } else if (sidenavColor === 'default') {
          background = info.main;
        } else if (sidenavColor === 'warning') {
          background = gradients.warning.main;
        } else {
          background = palette[sidenavColor].main;
        }

        return background;
      },
    },

    '& svg, svg g': {
      fill: active ? white.main : gradients.dark.state,
    },
  };
}

const collapseIcon = ({palette: {white, gradients}}: Theme, {active}: {active: boolean | undefined}) => ({
  color: active ? white.main : gradients.dark.state,
});

function collapseText(
  theme: Theme,
  ownerState: {miniSidenav: boolean; transparentSidenav: boolean; active: boolean | undefined},
) {
  const {typography, transitions, breakpoints, functions} = theme;
  const {miniSidenav, transparentSidenav, active} = ownerState;

  const {size, fontWeightMedium, fontWeightRegular} = typography;
  const {pxToRem} = functions;

  return {
    marginLeft: pxToRem(12.8),
    height: 'fit-content',
    [breakpoints.up('xl')]: {
      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : '108px',
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
      transition: transitions.create(['opacity', 'margin'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    '& span': {
      fontWeight: active ? fontWeightMedium : fontWeightRegular,
      fontSize: size.sm,
      lineHeight: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
}

function collapseArrow(
  theme: Theme,
  ownerState: {
    noCollapse: boolean | undefined;
    transparentSidenav: boolean;
    miniSidenav: boolean;
    open: boolean | undefined;
  },
) {
  const {palette, typography, transitions, breakpoints, functions} = theme;
  const {noCollapse, transparentSidenav, miniSidenav, open} = ownerState;

  const {dark, gradients} = palette;
  const {size} = typography;
  const {pxToRem, rgba} = functions;

  return {
    fontSize: `${size.md} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? 'rotate(0)' : 'rotate(-180deg)',
    color: open ? dark.main : rgba(gradients.dark.state, 0.4),
    transition: transitions.create(['color', 'transform', 'opacity'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('xl')]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav ? 'none !important' : 'block !important',
    },
  };
}

export {collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow};
