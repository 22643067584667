import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import {Amplify} from 'aws-amplify';
import {ConsoleLogger} from 'aws-amplify/utils';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import './i18n';
import {useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';

import {default as awsExports} from './amplify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {persistor, store} from './store';
import {SoftUIControllerProvider} from './theme/context';
import theme from './theme/theme';

import './index.css';

Amplify.configure(awsExports);
ConsoleLogger.LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL ?? 'WARN';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://6ffd39fd25904e7ca44b09fac179ac27@o70309.ingest.sentry.io/6374189',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      /*new Replay({
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        sessionSampleRate: 0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        errorSampleRate: 1.0,
        // Mask all text content with asterisks (*). Passes text
        // content through to `maskTextFn` before sending to server.
        //
        // Defaults to true, uncomment to change
        maskAllText: false,
        // Block all media elements (img, svg, video, object,
        // picture, embed, map, audio)
        //
        // Defaults to true, uncomment to change
        // blockAllMedia: true,
      }),*/
    ],
    autoSessionTracking: true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.0,
    allowUrls: [/app.perfectpost.fr/, /ngikijnhadhcmbfbimljlgimmocnhlpi/],
    ignoreErrors: [/Network Error/i, /Fetch Error/i, 'ResizeObserver loop limit exceeded'],
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<div>Loading...</div>}>
              <BrowserRouter>
                <SoftUIControllerProvider>
                  <CssBaseline />
                  <App />
                </SoftUIControllerProvider>
              </BrowserRouter>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
