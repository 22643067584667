/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import List from '@mui/material/List';
import React from 'react';

// @mui material components

export default function SidenavList({children}: React.PropsWithChildren<unknown>) {
  return (
    <List
      sx={{
        pl: 2,
        ml: 3,
      }}>
      {children}
    </List>
  );
}
