import {Dialog, DialogTitle, DialogContent, DialogActions, Grid} from '@mui/material';
import type {Draft, Poll} from '@perfectpost/perfect-post-common';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useAppThunkDispatch} from 'src/store';
import {editDraft, saveDraft} from 'src/store/draftslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';
import {sanitizer} from 'src/utils/sanitizer';

import TipCard from '../tipscard/TipCards';

type ImportPollModalProps = {
  draftId?: string;
  content?: string;
  sharing?: Poll;
  handleClose: () => void;
  handleConfirm: (draft: Draft) => void;
};

export default function ImportPollModal(props: ImportPollModalProps) {
  const {draftId, content, sharing, handleClose, handleConfirm} = props;
  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const options = [
    {value: '1', label: '1 jour'},
    {value: '3', label: '3 jours'},
    {value: '7', label: '1 semaine'},
    {value: '14', label: '2 semaines'},
  ];
  const [form, setForm] = useState({
    duration: sharing?.duration ?? 7,
    question: sharing?.question ?? '',
    option1: sharing?.option1 ?? '',
    option2: sharing?.option2 ?? '',
    option3: sharing?.option3,
    option4: sharing?.option4,
  });

  const onSubmit = async () => {
    const sharing = {
      poll: {
        question: form.question,
        duration: form.duration,
        option1: form.option1,
        option2: form.option2,
        option3: form.option3,
        option4: form.option4,
      },
    };
    let result;
    if (draftId === undefined) {
      result = await dispatch(
        saveDraft({
          content: sanitizer(content ?? ''),
          sharing,
        }),
      ).unwrap();
      navigate(`/publish/draft/${result.draft._id}`);
    } else {
      result = await dispatch(
        editDraft({
          draftId,
          content: content ? sanitizer(content) : undefined,
          sharing,
        }),
      ).unwrap();
    }
    handleConfirm(result.draft);
  };

  const addOption = () => {
    if (form.option3 === undefined) {
      setForm({...form, option3: ''});
    } else if (form.option4 === undefined) {
      setForm({...form, option4: ''});
    }
  };

  const onRemoveOption = (option: 'option3' | 'option4') => () => {
    if (option === 'option4') {
      setForm({...form, option4: undefined});
    } else {
      setForm({...form, option3: form.option4, option4: undefined});
    }
  };

  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>
        <SoftTypography>{t('importpollmodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <TipCard informationkey="modalpoll" />
        <Grid sx={{mb: 6}} container spacing={2}>
          <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                {t('importpollmodal.surveyduration')}
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              value={options.find((o) => Number.parseInt(o.value) === form.duration)}
              options={options}
              onChange={(e) => {
                setForm({...form, duration: Number.parseInt(e?.value ?? '7') as 1 | 3 | 7 | 14});
              }}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                {t('importpollmodal.question', {defaultValue: 'Question'})}
              </SoftTypography>
            </SoftBox>
            <SoftInput
              value={form.question}
              onChange={(e) => setForm({...form, question: e.target.value})}
              error={form.question.length > 140}
            />
            <SoftTypography variant="caption" sx={{alignSelf: 'flex-end', ml: 0.5, mt: 0.5}}>
              {form.question.length} / 140
            </SoftTypography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                {t('importpollmodal.option', {number: 1, defaultValue: 'Option {{number}}'})}
              </SoftTypography>
            </SoftBox>
            <SoftInput
              value={form.option1}
              onChange={(e) => setForm({...form, option1: e.target.value})}
              error={form.option1.length > 30}
            />
            <SoftTypography variant="caption" sx={{alignSelf: 'flex-end', ml: 0.5, mt: 0.5}}>
              {form.option1.length} / 30
            </SoftTypography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                {t('importpollmodal.option', {number: 2, defaultValue: 'Option {{number}}'})}
              </SoftTypography>
            </SoftBox>
            <SoftInput
              value={form.option2}
              onChange={(e) => setForm({...form, option2: e.target.value})}
              error={form.option2.length > 30}
            />
            <SoftTypography variant="caption" sx={{alignSelf: 'flex-end', ml: 0.5, mt: 0.5}}>
              {form.option2.length} / 30
            </SoftTypography>
          </Grid>
          {form.option3 !== undefined && (
            <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                  {t('importpollmodal.option', {number: 3, defaultValue: 'Option {{number}}'})}
                </SoftTypography>
                <SoftButton variant="text" color="info" size="small" onClick={onRemoveOption('option3')} sx={{py: 0}}>
                  {t('global.delete')}
                </SoftButton>
              </SoftBox>
              <SoftInput
                value={form.option3}
                onChange={(e) => setForm({...form, option3: e.target.value})}
                error={form.option3.length > 30}
              />
              <SoftTypography variant="caption" sx={{alignSelf: 'flex-end', ml: 0.5, mt: 0.5}}>
                {form.option3.length} / 30
              </SoftTypography>
            </Grid>
          )}
          {form.option4 !== undefined ? (
            <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                  {t('importpollmodal.option', {number: 4, defaultValue: 'Option {{number}}'})}
                </SoftTypography>
                <SoftButton variant="text" color="info" size="small" onClick={onRemoveOption('option4')} sx={{py: 0}}>
                  {t('global.delete')}
                </SoftButton>
              </SoftBox>
              <SoftInput
                value={form.option4}
                onChange={(e) => setForm({...form, option4: e.target.value})}
                error={form.option4.length > 30}
              />
              <SoftTypography variant="caption" sx={{alignSelf: 'flex-end', ml: 0.5, mt: 0.5}}>
                {form.option4.length} / 30
              </SoftTypography>
            </Grid>
          ) : (
            <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SoftButton variant="text" color="info" size="small" onClick={addOption}>
                {t('importpollmodal.addoption', {defaultValue: '+ Add option'})}
              </SoftButton>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={handleClose} autoFocus>
          {t('global.cancel', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton
          variant="gradient"
          onClick={onSubmit}
          disabled={
            form.question.length > 140 ||
            form.option1.length > 30 ||
            form.option2.length > 30 ||
            (form.option3 !== undefined && form.option3.length > 30) ||
            (form.option4 !== undefined && form.option4.length > 30)
          }>
          {t('importpollmodal.import', {defaultValue: 'Import into publication'})}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
