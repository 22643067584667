/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppBar, CircularProgress, Grid, Tab, Tabs, TextField} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {PersonURN} from '@perfectpost/linkedin-privateapi-ts-client';
import {LinkedinPost, SocialData} from '@perfectpost/perfect-post-common';
import {
  addDays,
  isBefore,
  isWithinInterval,
  parseISO,
  startOfToday,
  endOfToday,
  differenceInDays,
  isAfter,
  endOfDay,
  NormalizedInterval,
} from 'date-fns';
import {enGB} from 'date-fns/locale/en-GB';
import {es} from 'date-fns/locale/es';
import {fr} from 'date-fns/locale/fr';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {CellProps, Column} from 'react-table';
import Chart, {Data} from 'src/components/chart/Chart';
import Datatable from 'src/components/datatable';
import NumberCard from 'src/components/numbercard/NumberCard';
import StatisticCardRow from 'src/components/statisticscard/StatisticCardRow';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {
  loadLinkedInPostAveragePerfectPost,
  loadTopCommenter,
  loadTopLiker,
  selectPPPostProgress,
} from 'src/store/dataslice';
import {
  listPost,
  selectGlobalStats,
  selectGlobalStatsPrevious,
  selectGlobalStatsProgress,
  selectPostWithinInterval,
  selectPreviousInterval,
} from 'src/store/postslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormat, localFormatISOString} from 'src/utils/date-format';

type DataType = 'impression' | 'engagement' | 'like' | 'comment' | 'sharing' | 'view';
type GraphData = {
  [key in DataType]: Data[];
};

type TableRow = {
  _id: PersonURN;
  href?: string;
  name?: string;
  picture?: string;
  count: number;
};

const DefaultCell = (props: CellProps<TableRow, number>) => (
  <SoftTypography
    variant="button"
    textTransform="none"
    fontWeight="regular"
    color="secondary"
    sx={{display: 'inline-block', width: 'max-content'}}>
    {props.value}
  </SoftTypography>
);

type StatisticsState = {
  select: -1 | 7 | 14 | 30 | 90 | 180 | 365;
  graphData?: GraphData;
};

export default function Statistics() {
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();
  useDocumentTitle(t('statistics.documenttitle', {defaultValue: 'Statistics'}));

  const locale = useAppSelector((state) => state.main.user?.locale);
  const lastCommentFetch = useAppSelector((state) => state.main.user?.lastCommentFetch);
  const premium = useAppSelector((state) => state.main.premium);

  const loadingLinkedinPosts = useAppSelector((state) => state.post.loadingLinkedinPosts);
  const interval = useAppSelector((state) => state.post.interval);
  const previousInterval = useAppSelector(selectPreviousInterval);
  const postWithinInterval = useAppSelector(selectPostWithinInterval);
  const globalStats = useAppSelector(selectGlobalStats);
  const globalStatsPreviousInterval = useAppSelector(selectGlobalStatsPrevious);
  const globalStatsProgress = useAppSelector(selectGlobalStatsProgress);

  const loadingAveragePostData = useAppSelector((state) => state.data.loadingPostData);
  const averagePerfectPost = useAppSelector((state) => state.data.linkedinPostAveragePerfectPost);
  const ppProgress = useAppSelector(selectPPPostProgress);
  const topCommenter = useAppSelector((state) => state.data.topCommenter) ?? [];
  const loadingTopCommenter = useAppSelector((state) => state.data.loadingTopCommenterData);
  const topLiker = useAppSelector((state) => state.data.topLiker) ?? [];
  const loadingTopLiker = useAppSelector((state) => state.data.loadingTopLikerData);

  const [statisticsState, setStatisticsState] = useState<StatisticsState>(() => {
    const diff = differenceInDays(interval.end, interval.start);
    console.log('diff', diff, interval);

    return {
      select:
        diff === 7
          ? 7
          : diff === 14
            ? 14
            : diff === 30
              ? 30
              : diff === 90
                ? 90
                : diff === 180
                  ? 180
                  : diff === 365
                    ? 365
                    : -1,
    };
  });

  const {select, graphData} = statisticsState;

  const commenterColumnDefinition: Column<TableRow>[] = useMemo(
    () => [
      {
        Header: t('tablepost.name', {defaultValue: 'Nom'}),
        accessor: 'name',
        width: '50%',
        Cell: (props) => {
          return (
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftBox component="img" src={props.row.original.picture} sx={{borderRadius: '50%', width: 20}} />
              </SoftBox>
              <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
                {props.row.original.name ?? 'LinkedIn User'}
              </SoftTypography>
            </SoftBox>
          );
        },
      },
      {Header: t('tablepost.count', {defaultValue: 'Nombre'}), accessor: 'count', width: '10%', Cell: DefaultCell},
    ],
    [t],
  );

  const commenterTableRows = useMemo<TableRow[]>(() => {
    if (topCommenter === undefined || loadingTopCommenter === true) {
      return [];
    }
    return topCommenter.map((c) => ({
      _id: c._id,
      name:
        c.actorDetail?.firstName || c.actorDetail?.lastName
          ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
          : undefined,
      picture: c.actorDetail?.profilePicture,
      count: c.count ?? 0,
      href: c.actorDetail?.vanityName ? `https://www.linkedin.com/in/${c.actorDetail?.vanityName}` : undefined,
    }));
  }, [loadingTopCommenter, topCommenter]);

  const likerTableRows = useMemo<TableRow[]>(() => {
    if (topLiker === undefined || loadingTopLiker === true) {
      return [];
    }
    return topLiker.map((c) => ({
      _id: c._id,
      name:
        c.actorDetail?.firstName || c.actorDetail?.lastName
          ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
          : undefined,
      picture: c.actorDetail?.profilePicture,
      count: c.count ?? 0,
      href: c.actorDetail?.vanityName ? `https://www.linkedin.com/in/${c.actorDetail?.vanityName}` : undefined,
    }));
  }, [loadingTopLiker, topLiker]);

  const loadData = useCallback(
    (interval: NormalizedInterval<Date>) => {
      const cancelFunc: ((reason?: string | undefined) => void)[] = [];
      if (premium) {
        cancelFunc.push(dispatch(loadLinkedInPostAveragePerfectPost({begin: interval.start, end: interval.end})).abort);
        cancelFunc.push(dispatch(loadTopCommenter({begin: interval.start, end: interval.end})).abort);
        cancelFunc.push(dispatch(loadTopLiker({begin: interval.start, end: interval.end})).abort);
      }
      cancelFunc.push(dispatch(listPost(interval)).abort);
      return cancelFunc;
    },
    [premium],
  );

  useEffect(() => {
    const brutData: GraphData = {
      engagement: [
        {
          yAxisID: 'A',
          id: 'engagement',
          label: t('global.engagementrate', {defaultValue: 'Engagement'}),
          color: 'primary',
          data: [],
        },
      ],
      impression: [
        {
          yAxisID: 'A',
          id: 'impression',
          label: t('global.impression', {defaultValue: 'Impression'}),
          color: 'primary',
          data: [],
        },
      ],
      like: [
        {
          yAxisID: 'A',
          id: 'like',
          label: t('global.like', {defaultValue: 'Like'}),
          color: 'primary',
          data: [],
        },
      ],
      comment: [
        {
          yAxisID: 'A',
          id: 'comment',
          label: t('global.comment', {defaultValue: 'Comment'}),
          color: 'primary',
          data: [],
        },
      ],
      sharing: [
        {
          yAxisID: 'A',
          id: 'sharing',
          label: t('global.sharing', {defaultValue: 'Sharing'}),
          color: 'primary',
          data: [],
        },
      ],
      view: [
        {
          yAxisID: 'A',
          id: 'view',
          label: t('global.view', {defaultValue: 'View'}),
          color: 'primary',
          data: [],
        },
      ],
    };
    for (const post of postWithinInterval) {
      const d = parseISO(post.date);
      if (isBefore(d, interval.start) || isAfter(d, interval.end)) {
        continue;
      }
      const lastRelever = post.latestSocialData;

      brutData.engagement[0].data.push({
        x: d,
        y:
          (((lastRelever?.numLikes ?? 0) + (lastRelever?.numComments ?? 0)) /
            Math.max(lastRelever?.numImpressions ?? 0, lastRelever?.numImpressions ?? 0)) *
          100,
      });
      brutData.impression[0].data.push({x: d, y: lastRelever?.numImpressions ?? 0});
      brutData.like[0].data.push({x: d, y: lastRelever?.numLikes ?? 0});
      brutData.comment[0].data.push({x: d, y: lastRelever?.numComments ?? 0});
      brutData.sharing[0].data.push({x: d, y: lastRelever?.numShares ?? 0});
      brutData.view[0].data.push({x: d, y: lastRelever?.numImpressions ?? 0});
    }
    for (let i = 1; i < brutData.like[0].data.length; i++) {
      brutData.impression[0].data[i].y = brutData.impression[0].data[i].y + brutData.impression[0].data[i - 1].y;
      brutData.like[0].data[i].y = brutData.like[0].data[i].y + brutData.like[0].data[i - 1].y;
      brutData.comment[0].data[i].y = brutData.comment[0].data[i].y + brutData.comment[0].data[i - 1].y;
      brutData.sharing[0].data[i].y = brutData.sharing[0].data[i].y + brutData.sharing[0].data[i - 1].y;
      brutData.view[0].data[i].y = brutData.view[0].data[i].y + brutData.view[0].data[i - 1].y;
    }

    setStatisticsState((prevState) => ({
      ...prevState,
      graphData: brutData,
    }));
  }, [postWithinInterval]);

  useEffect(() => {
    const cancelFunc = loadData(interval);
    return () => {
      cancelFunc.forEach((p) => p());
    };
  }, [premium]);

  const onSelectChange = (e: React.SyntheticEvent<Element, Event>, v: number) => {
    if (premium || v <= 14) {
      setStatisticsState((prevState) => ({
        ...prevState,
        select: v as -1 | 7 | 14 | 30 | 90 | 180 | 365,
      }));
      const finalInterval = {
        start: addDays(startOfToday(), v === -1 ? -60 : -1 * v),
        end: endOfToday(),
      };
      loadData(finalInterval);
    }
  };

  const onCustomIntervalChange = (key: 'start' | 'end') => (date: Date | null) => {
    if (date === null) {
      return;
    }
    const newInterval = {
      start: key === 'start' ? date : interval.start,
      end: key === 'end' ? date : interval.end,
    };
    loadData(newInterval);
  };

  const exportTopCommenterToCsv = () => {
    if (topCommenter === undefined) {
      return;
    }
    const csv = topCommenter
      .filter((e) => e.actorDetail?.vanityName !== undefined)
      .map((c) => ({
        name:
          c.actorDetail?.firstName || c.actorDetail?.lastName
            ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
            : undefined,
        picture: c.actorDetail?.profilePicture,
        count: c.count ?? 0,
        href: c.actorDetail?.vanityName ? `https://www.linkedin.com/in/${c.actorDetail?.vanityName}` : undefined,
      }));
    // export as csv in html5 way
    const csvContent =
      'data:text/csv;charset=utf-8,' + csv.map((e) => e.name + ',' + e.href + ',' + e.count).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'top-commenter.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
  };

  const exportTopLikerToCsv = () => {
    if (topLiker === undefined) {
      return;
    }
    const csv = topLiker
      .filter((e) => e.actorDetail?.vanityName !== undefined)
      .map((c) => ({
        name:
          c.actorDetail?.firstName || c.actorDetail?.lastName
            ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
            : undefined,
        picture: c.actorDetail?.profilePicture,
        count: c.count ?? 0,
        href: c.actorDetail?.vanityName ? `https://www.linkedin.com/in/${c.actorDetail?.vanityName}` : undefined,
      }));
    // export as csv in html5 way
    const csvContent =
      'data:text/csv;charset=utf-8,' + csv.map((e) => e.name + ',' + e.href + ',' + e.count).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'top-liker.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
  };

  return (
    <SoftBox>
      <SoftBox mb={3}>
        <SoftBox>
          <SoftTypography variant="h2" fontWeight="bold">
            {t('statistics.title', {defaultValue: 'Overall results'})}
          </SoftTypography>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <AppBar
              position="static"
              sx={({palette}) => ({border: 1, borderColor: palette.grey[400], borderRadius: '0.75rem'})}>
              <Tabs value={select} onChange={onSelectChange}>
                <Tab label="7" value={7} />
                <Tab label="14" value={14} />
                <Tab
                  label="30"
                  value={30}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="90"
                  value={90}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="180"
                  value={180}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="365"
                  value={365}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="Custom"
                  value={-1}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={3}>
            {select !== -1 ? (
              <SoftTypography sx={{height: '100%'}}>{t('global.days', {defaultValue: 'Days'})}</SoftTypography>
            ) : (
              <SoftBox display="flex" flexDirection="row" sx={{height: '100%'}}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale === 'fr' ? fr : locale === 'es' ? es : enGB}>
                  <DatePicker
                    value={interval.start}
                    minDate={new Date('2021-01-01')}
                    maxDate={interval.end}
                    onChange={onCustomIntervalChange('start')}
                    slotProps={{
                      textField: (params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{height: '100%', justifyContent: 'center', mr: 1}}
                        />
                      ),
                    }}
                  />
                  <DatePicker
                    value={interval.end}
                    minDate={interval.start}
                    disableFuture
                    onChange={onCustomIntervalChange('end')}
                    slotProps={{
                      textField: (params) => (
                        <TextField {...params} variant="outlined" sx={{height: '100%', justifyContent: 'center'}} />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </SoftBox>
            )}
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={3}>
            <NumberCard
              title={t('statistics.distinctslike', {defaultValue: 'Nombre de likes distincs'})}
              data={topLiker.length}
              mode="number"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <NumberCard
              title={t('statistics.distinctscomment', {defaultValue: 'Nombre de commentateur distincs'})}
              data={topCommenter.length}
              mode="number"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <NumberCard
              title={t('statistics.postcount', {defaultValue: 'Nombre de publication'})}
              data={globalStats.count}
              mode="number"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {/*<NumberCard title="Nombre de click vers mon CTA" data={1900} mode="number" />*/}
          </Grid>
          <Grid item xs={12} md={6} container flexDirection="column" spacing={0} sx={{lineHeight: 0}}>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pt: 2, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative'}}>
              <StatisticCardRow
                title={t('global.engagementrate', {defaultValue: 'Engagement'})}
                count={globalStats.engagement}
                loading={loadingLinkedinPosts}
                mode="percentage"
                period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  locale,
                )}`}
                prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                  previousInterval.end,
                  'dd MMM',
                  locale,
                )}`}
                prevCount={globalStatsPreviousInterval.engagement}
                prevProgress={globalStatsProgress.engagement}
                ppCount={averagePerfectPost?.engagement}
                ppProgress={ppProgress?.engagement}
                loadingPpProgress={loadingAveragePostData}
                premium={premium}
              />
            </SoftBox>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pb: 2, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
              <SoftBox height={350} sx={{mt: 2}}>
                <Chart chartdataset={graphData?.engagement ?? []} type="line" />
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} container flexDirection="column" spacing={0} sx={{lineHeight: 0}}>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pt: 2, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative'}}>
              <StatisticCardRow
                title={t('global.impression', {defaultValue: 'Impression'})}
                count={globalStats.impression}
                loading={loadingLinkedinPosts}
                mode="number"
                period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  locale,
                )}`}
                prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                  previousInterval.end,
                  'dd MMM',
                  locale,
                )}`}
                prevCount={globalStatsPreviousInterval.impression}
                prevProgress={globalStatsProgress.impression}
                ppCount={averagePerfectPost?.avgImpressions}
                ppProgress={ppProgress.impression}
                loadingPpProgress={loadingAveragePostData}
                premium={premium}
              />
            </SoftBox>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pb: 2, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
              <SoftBox height={350} sx={{mt: 2}}>
                <Chart chartdataset={graphData?.impression ?? []} type="line" />
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={6} md={4} container flexDirection="column" spacing={0} sx={{lineHeight: 0}}>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pt: 2, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative'}}>
              <StatisticCardRow
                title={t('global.like', {defaultValue: 'Like'})}
                loading={loadingLinkedinPosts}
                mode="number"
                period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  locale,
                )}`}
                prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                  previousInterval.end,
                  'dd MMM',
                  locale,
                )}`}
                count={globalStats.like}
                prevCount={globalStatsPreviousInterval.like}
                prevProgress={globalStatsProgress.like}
                ppCount={averagePerfectPost?.avgLikes}
                ppProgress={ppProgress?.like}
                loadingPpProgress={loadingAveragePostData}
                premium={premium}
              />
            </SoftBox>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pb: 2, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
              <SoftBox height={150} sx={{mt: 2}}>
                <Chart chartdataset={graphData?.like ?? []} type="line" />
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={6} md={4} container flexDirection="column" spacing={0} sx={{lineHeight: 0}}>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pt: 2, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative'}}>
              <StatisticCardRow
                title={t('global.comment', {defaultValue: 'Comment'})}
                loading={loadingLinkedinPosts}
                mode="number"
                period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  locale,
                )}`}
                prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                  previousInterval.end,
                  'dd MMM',
                  locale,
                )}`}
                count={globalStats.comment}
                prevCount={globalStatsPreviousInterval.comment}
                prevProgress={globalStatsProgress.comment}
                ppCount={averagePerfectPost?.avgComments}
                ppProgress={ppProgress.comment}
                loadingPpProgress={loadingAveragePostData}
                premium={premium}
              />
            </SoftBox>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pb: 2, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
              <SoftBox height={150} sx={{mt: 2}}>
                <Chart chartdataset={graphData?.comment ?? []} type="line" />
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={6} md={4} container flexDirection="column" spacing={0} sx={{lineHeight: 0}}>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pt: 2, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative'}}>
              <StatisticCardRow
                title={t('global.sharing', {defaultValue: 'Sharing'})}
                mode="number"
                loading={loadingLinkedinPosts}
                period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  locale,
                )}`}
                prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                  previousInterval.end,
                  'dd MMM',
                  locale,
                )}`}
                count={globalStats.sharing}
                prevCount={globalStatsPreviousInterval.sharing}
                prevProgress={globalStatsProgress.sharing}
                ppCount={0}
                ppProgress={ppProgress?.sharing}
                loadingPpProgress={loadingAveragePostData}
                premium={premium}
              />
            </SoftBox>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pb: 2, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
              <SoftBox height={150} sx={{mt: 2}}>
                <Chart chartdataset={graphData?.sharing ?? []} type="line" />
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12}>
            <SoftBox py={3}>
              <SoftTypography variant="subtitle1" fontWeight="bold">
                {t('statistics.topcommentator')}
                {lastCommentFetch !== undefined && (
                  <SoftTypography variant="caption" sx={{ml: 2}}>
                    {t('global.lastScrappedAt', {
                      defaultValue: 'Dernière mise à jour : {{date}}',
                      date: localFormatISOString(lastCommentFetch, 'dd MMM HH:mm', locale),
                    })}
                  </SoftTypography>
                )}
              </SoftTypography>
              <SoftTypography variant="button" textTransform="none">
                {t('statistics.topcommentatordetail')}
              </SoftTypography>
            </SoftBox>
            {loadingTopCommenter ? (
              <CircularProgress color="info" />
            ) : premium ? (
              <Datatable
                table={{rows: commenterTableRows, columns: commenterColumnDefinition}}
                entriesPerPage={{defaultValue: 10, entries: [10, 20, 30, 50, 80, 130]}}
                pagination={{variant: 'gradient', color: 'info'}}
                isSorted
                onExportData={exportTopCommenterToCsv}
              />
            ) : (
              <SoftBox
                sx={({breakpoints}) => ({
                  borderRadius: '1rem',
                  p: 1,
                  display: 'flex',
                  backgroundColor: 'rgba(0,0,0,75%)',
                  top: 0,
                  height: 'fit-content',
                  minHeight: '100%',
                  width: '50%',
                  [breakpoints.up('xl')]: {
                    width: '100%',
                  },
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                })}>
                <SoftTypography color="white" variant="button" textTransform="none" sx={{maxWidth: '500px'}}>
                  {t('draft.topcomment.overlaypremium.title')}
                </SoftTypography>
                {/*
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore */}
                <SoftButton variant="gradient" color="dark" component={NavLink} to="/settings/subscription?o=">
                  {t('draft.commentnoanswer.overlaypremium.btn', {defaultValue: 'Go premium'})}
                </SoftButton>
              </SoftBox>
            )}
          </Grid>
          <Grid item xs={12}>
            <SoftBox py={3}>
              <SoftTypography variant="subtitle1" fontWeight="bold">
                {t('statistics.toplike')}
              </SoftTypography>
              <SoftTypography variant="button" textTransform="none">
                {t('statistics.toplikedetail')}
              </SoftTypography>
            </SoftBox>
            {loadingTopLiker ? (
              <CircularProgress color="info" />
            ) : premium ? (
              <Datatable
                table={{rows: likerTableRows, columns: commenterColumnDefinition}}
                entriesPerPage={{defaultValue: 10, entries: [10, 20, 30, 50, 80, 130]}}
                pagination={{variant: 'gradient', color: 'info'}}
                isSorted
                onExportData={exportTopLikerToCsv}
              />
            ) : (
              <SoftBox
                sx={({breakpoints}) => ({
                  borderRadius: '1rem',
                  p: 1,
                  display: 'flex',
                  backgroundColor: 'rgba(0,0,0,75%)',
                  top: 0,
                  height: 'fit-content',
                  minHeight: '100%',
                  width: '50%',
                  [breakpoints.up('xl')]: {
                    width: '100%',
                  },
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                })}>
                <SoftTypography color="white" variant="button" textTransform="none" sx={{maxWidth: '500px'}}>
                  {t('draft.topcomment.overlaypremium.title')}
                </SoftTypography>
                {/*
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore */}
                <SoftButton variant="gradient" color="dark" component={NavLink} to="/settings/subscription?o=">
                  {t('draft.commentnoanswer.overlaypremium.btn', {defaultValue: 'Go premium'})}
                </SoftButton>
              </SoftBox>
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}
