import {Plugin, Chart as ChartJs, ChartType, registerables} from 'chart.js';
import {ChartData, ChartDataset, ChartOptions, ChartTypeRegistry, ScaleOptionsByType} from 'chart.js/auto';
import {DeepPartial} from 'chart.js/dist/types/utils';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import React, {useEffect, useRef} from 'react';
import {Line, Bar} from 'react-chartjs-2';
import SoftBox from 'src/theme/components/SoftBox';
import colors, {isThemeColor} from 'src/theme/theme/base/colors';
import gradientChartLine from 'src/theme/theme/functions/gradientChartLine';
import {merge} from 'ts-deepmerge';

class EmptyMessagePlugin<TType extends ChartType = ChartType> implements Plugin<TType> {
  public id = 'emptyMessagePlugin';
  public afterDraw = (chart: ChartJs<TType>) => {
    const totalSize = chart.data.datasets.reduce((acc, dataset) => acc + dataset.data.length, 0);
    if (totalSize === 0) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = '16px normal "Helvetica Nueue"';
      ctx.fillText('No data to display', width / 2, height / 2);
      ctx.restore();
    }
  };
}

ChartJs.register(annotationPlugin, zoomPlugin, new EmptyMessagePlugin(), ...registerables);

export type Data = {
  id: string;
  yAxisID: string;
  data: {x: Date; y: number}[];
  color: string;
  label: string;
};
export type ChartProps = {
  loading?: boolean;
  chartdataset: Data[];
  type: 'line' | 'bar';
  options?: ChartOptions<ChartProps['type']>;
};
export type OptionGraphType = ChartProps['options'];

type DataGraphType =
  | ChartData<
      'bar',
      {
        x: number;
        y: number;
      }[],
      string
    >
  | ChartData<
      'line',
      {
        x: number;
        y: number;
      }[],
      string
    >;

export type ChartState = {
  data: DataGraphType;
  options: ChartOptions<'line' | 'bar'>;
};

export default function Chart({loading, chartdataset, type: typeProps, options: optionsProps}: ChartProps) {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const type = typeProps ?? 'line';

  const [chartState, setChartState] = React.useState<ChartState>({
    data: {
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl',
          },
          zoom: {
            drag: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          type: 'time',
          grid: {
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
          ticks: {
            display: true,
          },
          time: {
            unit: 'day',
          },
        },
      },
    },
  });
  const {data, options} = chartState;

  useEffect(() => {
    const yAxisIds = [...new Set(chartdataset.map((dd) => dd.yAxisID))];
    const scalesConfig: [string, DeepPartial<ScaleOptionsByType<ChartTypeRegistry[typeof type]['scales']>>][] =
      yAxisIds.map((yAxisId) => [
        yAxisId,
        {
          type: 'linear',
          position: 'left',
          grid: {
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
          ticks: {
            display: true,
          },
        },
      ]);

    const options: ChartOptions<typeof type> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl',
          },
          zoom: {
            drag: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        ...Object.fromEntries(scalesConfig),
        x: {
          type: 'time',
          grid: {
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
          ticks: {
            display: true,
          },
          time: {
            unit: 'day',
          },
        },
      },
    };
    setChartState((f) => ({...f, options: optionsProps ? merge(optionsProps, options) : options}));
  }, [chartdataset, optionsProps]);

  useEffect(() => {
    let datasets: ChartDataset<'line', {x: number; y: number}[]>[] = [];
    const canvas = chartRef.current?.children[0];

    datasets = chartdataset.map((d) => ({
      yAxisID: d.yAxisID,
      label: d.label,
      data: d.data.map(({x, y}) => ({x: x.getTime(), y})),
      // tension: 0.4,
      pointRadius: 0,
      borderWidth: 2,
      borderColor: isThemeColor(d.color) ? colors[d.color].main : d.color,
      fill: true,
      // maxBarThickness: 6,
      backgroundColor:
        isThemeColor(d.color) && canvas instanceof HTMLCanvasElement
          ? gradientChartLine(canvas, colors[d.color].main, 0.075)
          : undefined,
    }));

    const data: DataGraphType = {
      datasets,
    };
    setChartState((f) => ({...f, data}));
  }, [chartdataset, chartRef.current]);

  return (
    <SoftBox ref={chartRef} sx={{height: '100%'}}>
      {loading === true ? null : type === 'bar' ? (
        // @ts-ignore
        <Bar data={data} options={options} />
      ) : (
        // @ts-ignore
        <Line data={data} options={options} />
      )}
    </SoftBox>
  );
}
