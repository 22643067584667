import {DatesSetArg, EventDropArg, EventInput} from '@fullcalendar/core';
import {DropArg, ThirdPartyDraggable} from '@fullcalendar/interaction';
import {CircularProgress, Grid, Icon} from '@mui/material';
import {RefreshScheduledPostCommand, StatusCommand} from '@perfectpost/perfect-post-common';
import copy from 'copy-to-clipboard';
import {addHours, endOfMonth, isFuture, parseISO, startOfMonth} from 'date-fns';
import dragula from 'dragula';
import React, {useCallback, useEffect, useState, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import CalendarComponent from 'src/components/calendar';
import DraftCard from 'src/components/draftcard/DraftCard';
import MessageCard from 'src/components/messagecard/MessageCard';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {draftLoad, editDraft} from 'src/store/draftslice';
import {submitMessage} from 'src/store/extension/slice';
import {listPost} from 'src/store/postslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import 'dragula/dist/dragula.css';

const regex = /(<([^>]+)>)/gi;

export default function Calendar() {
  const drakeInstance = useRef<dragula.Drake | null>(null);
  const thirdPartyDraggableRef = useRef<ThirdPartyDraggable | null>(null);

  const cognitoId = useAppSelector((state) => state.main.user?.cognitoId);
  const extensionInstalled = useAppSelector((state) => state.extension.version !== undefined);
  const posts = useAppSelector((state) => state.post.linkedinPost);
  const drafts = useAppSelector((state) => state.draft.drafts);

  const [state, setState] = useState({
    pageState: 'loading',
    copied: false,
    refreshResult: undefined as
      | undefined
      | {
          posts: number;
          _ids: string[];
        }
      | {error: string},
  });
  const {pageState, copied, refreshResult} = state;

  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();
  const draftContainer = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        if (drakeInstance.current !== null) {
          drakeInstance.current.destroy();
        }
        drakeInstance.current = dragula([node], {
          copy: true,
        });
        if (thirdPartyDraggableRef.current !== null) {
          thirdPartyDraggableRef.current.destroy();
        }
        thirdPartyDraggableRef.current = new ThirdPartyDraggable(node, {
          itemSelector: '.fc-event',
          mirrorSelector: '.gu-mirror', // the dragging element that dragula renders
          eventData: (eventEl) => {
            const id = eventEl.getAttribute('data-id');
            const draft = drafts.find((d) => d._id === id);
            if (draft) {
              const result: EventInput = {
                id: draft._id,
                start: new Date(),
                end: addHours(new Date(), 1),
                title: draft.content.replace(regex, ''),
                resource: draft,
                url: `/publish/draft/${draft._id}`,
                className: 'event-info',
                color: 'transparent',
              };
              return result;
            }
          },
        });
      }
    },
    [drafts],
  );
  useEffect(() => {
    return () => {
      if (drakeInstance.current !== null) {
        drakeInstance.current.destroy();
      }
    };
  }, []);

  useDocumentTitle(t('calendar.documenttitle', {defaultValue: 'Calendar'}));

  useEffect(() => {
    dispatch(draftLoad());
    dispatch(listPost({start: startOfMonth(new Date()), end: endOfMonth(new Date())}));
  }, []);

  const events: EventInput[] = useMemo(() => {
    const d = drafts
      .filter((d) => d.programmingDate !== undefined && isFuture(parseISO(d.programmingDate)))
      .map((d) => {
        const date = parseISO(d.programmingDate as string);
        const e: EventInput = {
          id: d._id,
          start: date,
          end: addHours(date, 1),
          title: d.content.replace(regex, ''),
          resource: d,
          url: `/publish/draft/${d._id}`,
          color: 'transparent',
          type: d.sharing?.type,
          categories: d.categories,
        };
        return e;
      });
    const p = posts.map((post) => {
      const d = parseISO(post.date);
      const e: EventInput = {
        start: d,
        end: addHours(d, 1),
        title: post.text,
        resource: post,
        editable: false,
        className: 'success',
        url: `/analyse/content/${post._id}`,
        color: 'transparent',
        type: post.type,
      };
      return e;
    });
    return [...d, ...p];
  }, [drafts, posts]);

  const draftsReady = useMemo(() => drafts.filter((d) => d.tag === 'ready'), [drafts]);

  const eventDrop = (event: EventDropArg) => {
    const draft = drafts.find((d) => d._id === event.event.id);
    const start = event.event.start;
    if (draft !== undefined && start !== null) {
      dispatch(editDraft({draftId: draft._id, programmingDate: start.toISOString()}));
    }
  };

  const drop = (event: DropArg) => {
    const draft = drafts.find((d) => d._id === event.draggedEl.getAttribute('data-id'));
    const start = event.date;
    start.setHours(9);
    if (draft !== undefined) {
      dispatch(editDraft({draftId: draft._id, programmingDate: start.toISOString()}));
    }
  };

  const onCopyPublicLink = () => {
    copy(`${process.env.REACT_APP_API_ENDPOINT}/user/${cognitoId}/ical`);
    setState((s) => ({...s, copied: true}));
    setTimeout(() => {
      setState((s) => ({...s, copied: false}));
    }, 5000);
  };

  const onDatesChange = ({start, end}: DatesSetArg) => {
    dispatch(listPost({start, end}));
  };

  const onFetchScheduledPost = async () => {
    setState((state) => ({...state, pageState: 'refreshing'}));
    const command = await perfectPostServiceClient.send(new RefreshScheduledPostCommand());
    const commandId = command.command._id;
    if (commandId === undefined) {
      setState((state) => ({...state, pageState: 'idle'}));
      return;
    }
    dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
    const result = await new Promise<
      | {
          posts: number;
          _ids: string[];
        }
      | {error: string}
    >((resolve) => {
      const checkFunc = async () => {
        const cmd = await perfectPostServiceClient.send(new StatusCommand(commandId));
        if (cmd === undefined) {
          clearInterval(timer);
          resolve({error: 'command not found'});
          return;
        }
        if (cmd.result !== undefined) {
          clearInterval(timer);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resolve(cmd.result);
        }
      };
      const timer = setInterval(checkFunc, 2000);
      checkFunc();
    });

    setState((state) => ({...state, refreshResult: result}));
    setTimeout(() => {
      setState((state) => ({...state, refreshResult: undefined}));
    }, 5000);
    await dispatch(draftLoad());
    setState((state) => ({...state, pageState: 'idle'}));
  };

  return (
    <SoftBox>
      <SoftBox sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
        <SoftBox>
          <SoftButton
            color="dark"
            variant="outlined"
            disabled={extensionInstalled === false || pageState === 'refreshing'}
            onClick={onFetchScheduledPost}>
            {pageState === 'refreshing' ? <CircularProgress size={20} /> : t('kanban.refreshlinkedinpost')}
          </SoftButton>
          {refreshResult && (
            <SoftTypography variant="caption" sx={{ml: 1}}>
              {'error' in refreshResult ? refreshResult.error : t('content.postrefreshed', {nb: refreshResult.posts})}
            </SoftTypography>
          )}
        </SoftBox>
      </SoftBox>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={9} sx={{height: 'max-content'}}>
          {useMemo(
            () => (
              <CalendarComponent
                initialView="dayGridMonth"
                initialDate={new Date()}
                events={events}
                selectable
                editable
                droppable
                eventDrop={eventDrop}
                drop={drop}
                datesSet={onDatesChange}
                eventContent={({event, ...eventInfo}) => (
                  <SoftBox sx={{display: 'flex', flexDirection: 'column', padding: 1, gap: '5px', width: '100%'}}>
                    <SoftBox className="fc-event-time" sx={{display: 'flex', gap: '5px'}}>
                      {eventInfo.timeText}{' '}
                      {event.extendedProps.type !== undefined && (
                        <Icon sx={{color: '#000'}}>
                          {event.extendedProps.type === 'video'
                            ? 'video_library'
                            : event.extendedProps.type === 'image'
                              ? 'image'
                              : event.extendedProps.type === 'pdf'
                                ? 'picture_as_pdf'
                                : event.extendedProps.type === 'images'
                                  ? 'collections'
                                  : event.extendedProps.type === 'poll'
                                    ? 'poll'
                                    : ''}
                        </Icon>
                      )}
                    </SoftBox>
                    {Array.isArray(event.extendedProps.categories) && (
                      <SoftBox
                        sx={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {event.extendedProps.categories.join(' / ')}
                      </SoftBox>
                    )}
                    <SoftBox
                      sx={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {event.title}
                    </SoftBox>
                  </SoftBox>
                )}
              />
            ),
            [events],
          )}
        </Grid>
        <Grid item xs={12} xl={3}>
          <SoftTypography sx={{mb: 2}}>{t('calendar.sharetitle')}</SoftTypography>
          <SoftBox
            display="flex"
            flexDirection="column"
            sx={() => ({borderRadius: '1rem', backgroundColor: 'white', mb: 1})}>
            <SoftInput
              disabled
              value={`${process.env.REACT_APP_API_ENDPOINT}/user/${cognitoId}/ical`}
              icon={{
                component: copied ? 'done' : 'copy',
                direction: 'right',
                onClick: onCopyPublicLink,
              }}
            />
          </SoftBox>
          <SoftTypography variant="caption">{t('calendar.sharecaption')}</SoftTypography>
        </Grid>
        <Grid
          item
          xs={12}
          xl={9}
          sx={{
            height: 'max-content',
          }}>
          <SoftBox
            display="flex"
            flexDirection="column"
            sx={({palette}) => ({p: 2, borderRadius: '1rem', backgroundColor: palette.grey['300']})}>
            <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
              {t('calendar.title', {defaultValue: 'Publication ready'})}
            </SoftTypography>
            {draftsReady.length === 0 ? (
              <MessageCard
                description={t('calendar.nopost.description', {defaultValue: "You don't have any posts scheduled."})}
                btnText={t('calendar.nopost.ctabtn', {defaultValue: 'Prepare my first publication'})}
                to="/publish/draft/new"
              />
            ) : (
              <SoftBox
                ref={draftContainer}
                component="ul"
                display="flex"
                p={0}
                sx={{
                  maxHeight: 900,
                  overflowY: 'auto',
                  pr: 1,
                  m: 0,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}>
                {draftsReady.map((draft) => (
                  <SoftBox
                    key={draft._id}
                    component="li"
                    className="fc-event"
                    data-id={draft._id}
                    display="flex"
                    alignItems="baseline"
                    flexDirection="column"
                    py={1}
                    mb={1}
                    sx={{flex: '40%', mx: '2%'}}>
                    <DraftCard
                      user={draft.user}
                      content={draft.content}
                      programmingDate={draft.programmingDate}
                      organization={draft.organization}
                      href={`/publish/draft/${draft._id}`}
                      categories={draft.categories}
                      type={draft.sharing?.type}
                    />
                  </SoftBox>
                ))}
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
