import {GetPostIdeaCommand, Tips} from '@perfectpost/perfect-post-common';
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {perfectPostServiceClient} from 'src/services';

export interface TipsState {
  loadingTips: boolean;
  tips: Tips[];
}
const initialState: TipsState = {
  tips: [],
  loadingTips: true,
};

export const tips = createAsyncThunk<Tips[] | undefined>('tips/get', async () =>
  perfectPostServiceClient.send(new GetPostIdeaCommand()),
);

export const tipsSlice = createSlice({
  name: 'tips',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tips.pending, (state) => {
        state.loadingTips = true;
      })
      .addCase(tips.fulfilled, (state, action) => {
        state.loadingTips = false;
        state.tips = action.payload ?? [];
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = tipsSlice.actions;
export default tipsSlice.reducer;
