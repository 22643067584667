import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {TeamTips} from '@perfectpost/perfect-post-common';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';

type TeamTipsModalProps = {
  tips?: TeamTips;
  handleClose: () => void;
  handleConfirm: ({title, content}: Pick<TeamTips, 'title' | 'content'>) => void;
};

export default function TeamTipsModal(props: TeamTipsModalProps) {
  const {tips, handleClose, handleConfirm} = props;
  const {t} = useTranslation();

  const [form, setForm] = useState<Pick<TeamTips, 'title' | 'content'>>({
    title: tips?.title ?? '',
    content: tips?.content ?? '',
  });

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth>
      <DialogTitle>
        <SoftTypography>{t('teamtipsmodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
              {t('teamtips.datatable.title', {defaultValue: 'Title'})}
            </SoftTypography>
          </SoftBox>
          <SoftInput value={form.title} onChange={(e) => setForm({...form, title: e.target.value})} />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
              {t('teamtips.datatable.content', {defaultValue: 'Content'})}
            </SoftTypography>
          </SoftBox>
          <SoftInput
            placeholder="Type here..."
            multiline
            rows={5}
            value={form.content}
            onChange={(e) => setForm({...form, content: e.target.value})}
          />
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={handleClose}>
          {t('global.cancel', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton variant="gradient" color="primary" onClick={() => handleConfirm(form)}>
          {t('teamtipsmodal.create')}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
