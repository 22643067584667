import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import type {Draft} from '@perfectpost/perfect-post-common';
import {isFuture, parseISO} from 'date-fns';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SoftAlert from 'src/theme/components/SoftAlert';
import SoftBox from 'src/theme/components/SoftBox';

type RemoveAllDraftDialogProps = {
  drafts: Draft[];
  handleClose: () => void;
  handleRemove: () => void;
};

export default function RemoveAllDraftDialog(props: RemoveAllDraftDialogProps) {
  const {drafts, handleClose, handleRemove} = props;
  const [state, setState] = React.useState<'idle' | 'loading'>('idle');
  const {t} = useTranslation();

  const onConfirm = () => {
    setState('loading');
    handleRemove();
  };

  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t('removealldraftdialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t('removealldraftdialog.content')}</DialogContentText>
        {drafts.some(
          (draft) => draft.linkedin && draft.programmingDate && isFuture(parseISO(draft.programmingDate)),
        ) && (
          <SoftBox mt={2}>
            <SoftAlert color="error">
              <p>
                {t('removealldraftdialog.linkedin')}{' '}
                <a
                  href="https://www.linkedin.com/feed/?shareActive=true&view=management"
                  target="_blank"
                  rel="noreferrer">
                  LinkedIn
                </a>
              </p>
            </SoftAlert>
          </SoftBox>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="error" disabled={state === 'loading'}>
          {state === 'loading' ? (
            <CircularProgress color="info" />
          ) : (
            t('removealldraftdialog.deletebtn', {defaultValue: 'Delete draft'})
          )}
        </Button>
        <Button onClick={handleClose} autoFocus>
          {t('removealldraftdialog.cancelbtn', {defaultValue: 'Cancel'})}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
