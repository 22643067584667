/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import nmaps, {isdKey, isidKey, isisKey, issKey} from './nmaps';

class Stacker {
  private readonly dmap = nmaps.d;
  private readonly smap = nmaps.s;
  private readonly idmap = nmaps.id;
  private readonly ismap = nmaps.is;
  private str_pairs: string[][] = [];
  private joiner_str = '';

  public make_pair_parts = (string_: string) => {
    this.joiner_str = '\n';
    let string_parts = string_.split(this.joiner_str);
    if (string_parts && string_parts.length < 2) {
      this.joiner_str = ' ';
      string_parts = string_.split(this.joiner_str);
    }

    return string_parts;
  };

  public make_pairs = (string_: string) => {
    this.str_pairs = [];
    const string_parts = this.make_pair_parts(string_);
    for (let h = 0; h < string_parts.length; h += 2) {
      this.str_pairs.push([string_parts[h], string_parts[h + 1] || '']);
    }
  };

  public shuffle_pair = (pair: string[]) => {
    const c = pair[0];
    const codePointAt = c.codePointAt(0);
    if (isdKey(c) && !isdKey(codePointAt)) {
      pair[1] = ' ' + pair[1];
    }

    const max_length = Math.max(pair[0].length, pair[1].length);
    let pair_string = '';
    for (let i = 0; i < max_length; i++) {
      let b = pair[1][i] || '';
      let t = pair[0][i] || '';
      if (t === ' ') {
        t = '';
      }

      const tCode = t.codePointAt(0);
      if (b && isdKey(tCode)) {
        t = String.fromCodePoint.apply(null, this.dmap[tCode]);
      } else if (issKey(tCode)) {
        if (b === ' ') {
          b = '';
        }

        t = String.fromCodePoint.apply(null, this.smap[tCode]);
      }

      pair_string += b + t;
    }

    return pair_string.trim();
  };

  public make = (string_: string) => {
    this.make_pairs(string_);
    const shuffled_pairs = [];
    for (let i = 0; i < this.str_pairs.length; i++) {
      shuffled_pairs.push(this.shuffle_pair(this.str_pairs[i]));
    }

    return shuffled_pairs.join(this.joiner_str);
  };

  public unstack = (string_: string) => {
    const type = this.get_stack_type(string_);
    if (type === 'spaces') {
      return Array.from(string_)
        .map((c, i, a) => {
          const cp = c.codePointAt(0);
          const cp1 = a[i + 1] ? a[i + 1].codePointAt(0) : undefined;
          if (c === ' ' && isidKey(cp1)) {
            return undefined;
          }

          if (isidKey(cp)) {
            return String.fromCodePoint.apply(null, this.idmap[cp]);
          }

          return c;
        })
        .join('');
    }

    if (type === 'diff') {
      return this.unmake(string_);
    }

    return string_;
  };

  public get_stack_type = (string_: string) => {
    const string_array = Array.from(string_);
    let spaces = 0;
    let same = 0;
    let diff = 0;
    let i;
    for (i = 0; i < string_array.length; i++) {
      const stringCode = string_array[i].codePointAt(0);
      const previousCode = i > 0 ? string_array[i - 1].codePointAt(0) : undefined;
      if (stringCode && isidKey(stringCode)) {
        if (string_[i - 1] === ' ') {
          spaces++;
        } else if (
          isdKey(previousCode) &&
          String.fromCodePoint.apply(null, this.dmap[previousCode]) === string_array[i]
        ) {
          same++;
        } else {
          diff++;
        }
      }
    }

    if (spaces && !same && !diff) {
      return 'spaces';
    }

    if (diff) {
      return 'diff';
    }
  };

  public unmake = (strs: string) => {
    const cleaned_strs = [];
    const string_array = strs.split('\n');
    for (let string_ of string_array) {
      const parts: {t: string[]; b: string[]} = {t: [], b: []};
      string_ = string_.replace(' ', '  ');
      for (let j = 0; j < string_.length; j++) {
        const stringCode = string_[j].codePointAt(0);
        const revert = isidKey(stringCode)
          ? this.idmap[stringCode]
          : isisKey(stringCode)
            ? this.ismap[stringCode]
            : undefined;
        if (revert === undefined) {
          parts.b[j] = string_[j];
        } else {
          parts.t[j] = String.fromCharCode(...revert);
          if (parts.t[j - 1]) {
            parts.b[j] = ' ';
          }
        }
      }

      parts.t = Array.from(parts.t, (a: any, i: number) => {
        if (a === undefined && parts.t[i - 1] === undefined) {
          return ' ';
        }

        return a;
      });
      cleaned_strs.push([parts.t.join('').trim(), parts.b.join('').trim()].join(' ').replace(/ +/g, ' '));
    }

    return cleaned_strs.join('\n');
  };
}

export default new Stacker();
