import {Card, CircularProgress} from '@mui/material';
import Grid from '@mui/material/Grid';
import {GetCustomerTeamAddressStripeCommand, GetTeamPortalSessionStripeCommand} from '@perfectpost/perfect-post-common';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import TeamSubscriptionModal from 'src/components/teamsubscriptionmodal/TeamSubscriptionModal';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {fetchTeams, selectTeamById} from 'src/store/teamslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import {localFormat} from 'src/utils/date-format';
import Stripe from 'stripe';

import NavigationBar from './NavigationBar';

type TeamSettingsSubscriptionState = {
  status: 'idle' | 'loading' | 'working';
  subscription: Stripe.Subscription | undefined;
  address: Stripe.Address | undefined;
  nextInvoice: Stripe.UpcomingInvoice | undefined;
  currentMode: 'yearly' | 'monthly' | undefined;
  currentPlan: 'free' | 'team' | 'business';
  premiumLicenses: number;
  cancellationDate: Date | undefined;
  isOwnerOrAdmin: boolean;
};

export default function TeamSettingsSubscription() {
  const {id} = useParams();
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();

  const loadingTeam = useAppSelector((state) => state.team.loadingTeam);
  const team = useAppSelector((state) => selectTeamById(state, id));
  const user = useAppSelector((state) => state.main.user);

  const [state, setState] = useState<TeamSettingsSubscriptionState>({
    status: loadingTeam ? 'loading' : 'idle',
    subscription: undefined,
    address: undefined,
    nextInvoice: undefined,
    currentMode: undefined,
    currentPlan: 'free',
    premiumLicenses: 0,
    cancellationDate: undefined,
    isOwnerOrAdmin: false,
  });

  const [showTeamSubscriptionModal, setShowTeamSubscriptionModal] = useState({
    open: false,
    newPlan: 'team' as 'team' | 'business',
  });

  const fetchCustomerDetail = useCallback(() => {
    const isOwnerOrAdmin =
      team?.users.some((u) => u.user === user?._id && (u.role === 'owner' || u.role === 'admin')) ?? false;
    if (team?.stripeCustomerId) {
      setState((f) => ({
        ...f,
        status: 'loading',
      }));
      perfectPostServiceClient
        .send(new GetCustomerTeamAddressStripeCommand(team._id))
        .then(({address, subscription, invoice}) => {
          const teamPlan = subscription?.items.data.find(
            (i) =>
              i.plan.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
              i.plan.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID ||
              i.plan.id === process.env.REACT_APP_STRIPE_ENTERPRISE_YEARLY_PRICE_ID ||
              i.plan.id === process.env.REACT_APP_STRIPE_ENTERPRISE_MONTHLY_PRICE_ID,
          );

          const currentPlan =
            teamPlan === undefined
              ? 'free'
              : teamPlan.plan.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                  teamPlan.plan.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID
                ? 'team'
                : 'business';

          const currentMode =
            teamPlan === undefined
              ? undefined
              : teamPlan.plan.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                  teamPlan.plan.id === process.env.REACT_APP_STRIPE_ENTERPRISE_YEARLY_PRICE_ID
                ? 'yearly'
                : 'monthly';

          const premiumLicenses =
            subscription?.items.data.find(
              (i) =>
                i.plan.id === process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_ID ||
                i.plan.id === process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_ID,
            )?.quantity ?? 0;

          const cancellationDate = subscription?.cancel_at ? new Date(subscription.cancel_at * 1000) : undefined;

          setState({
            status: 'idle',
            address,
            nextInvoice: invoice,
            subscription,
            currentPlan,
            currentMode,
            premiumLicenses,
            cancellationDate,
            isOwnerOrAdmin,
          });
        });
    } else {
      setState({
        status: 'idle',
        address: undefined,
        nextInvoice: undefined,
        subscription: undefined,
        currentPlan: 'free',
        currentMode: undefined,
        premiumLicenses: 0,
        cancellationDate: undefined,
        isOwnerOrAdmin,
      });
    }
  }, [team?.stripeCustomerId]);

  useEffect(() => {
    fetchCustomerDetail();
  }, [fetchCustomerDetail]);

  const onSelectPlan = (newPlan: 'team' | 'business') => () => {
    setShowTeamSubscriptionModal({
      open: true,
      newPlan,
    });
  };

  const onTeamSubscriptionModalClose = () => {
    setShowTeamSubscriptionModal((f) => ({
      ...f,
      open: false,
      plan: 'team',
    }));
  };

  const onRedirectTeamPortal = () => {
    if (state.isOwnerOrAdmin === false || team === undefined) {
      return;
    }
    setState((f) => ({
      ...f,
      status: 'working',
    }));
    perfectPostServiceClient.send(new GetTeamPortalSessionStripeCommand(team._id)).then((res) => {
      window.location.href = res.url;
    });
  };

  const handleChangePlan = async () => {
    await dispatch(fetchTeams());
    fetchCustomerDetail();
    onTeamSubscriptionModalClose();
  };

  return (
    <SoftBox>
      <Grid container spacing={3}>
        <Grid item lg={11} xl={10}>
          <SoftBox mb={3}>
            {state.isOwnerOrAdmin && team && showTeamSubscriptionModal.open && (
              <TeamSubscriptionModal
                team={team}
                newPlan={showTeamSubscriptionModal.newPlan}
                address={state.address}
                currentPlan={state.currentPlan}
                currentPremiumCount={state.premiumLicenses}
                currentMode={state.currentMode}
                handleClose={onTeamSubscriptionModalClose}
                handleChangePlan={handleChangePlan}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {id && <NavigationBar teamId={id} value="subscription" teamPremium={team?.teamPlan !== undefined} />}
              </Grid>
              <Grid item xs={12}>
                {state.status === 'loading' ? (
                  <SoftBox sx={{mb: 3}}>
                    <CircularProgress />
                  </SoftBox>
                ) : team?.teamPlan !== undefined ? (
                  <SoftBox sx={{mb: 3}}>
                    <SoftTypography>
                      {t('teamsettingssubscription.subscription.title', {
                        defaultValue: 'Détails de la facturation',
                      })}
                    </SoftTypography>
                    <SoftBox sx={{display: 'flex', flexDirection: 'row', gap: 2, mt: 2}}>
                      <Card sx={{minWidth: 500, padding: 2}}>
                        <SoftTypography variant="subtitle2" sx={{mb: 2}}>
                          {state.subscription?.cancel_at_period_end
                            ? t('teamsettingssubscription.subscription.cancellationplanned', {
                                defaultValue: 'Résiliation prévu',
                              })
                            : t('teamsettingssubscription.subscription.nextpayment', {
                                defaultValue: 'Prochain paiement',
                              })}
                        </SoftTypography>
                        {state.subscription?.cancel_at_period_end === false && (
                          <SoftTypography variant="h4" fontWeight="bold">
                            {state.nextInvoice?.amount_due &&
                              (state.nextInvoice.amount_due / 100).toLocaleString(user?.locale, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}{' '}
                            €
                          </SoftTypography>
                        )}
                        <SoftTypography variant="h5">
                          {state.subscription?.cancel_at_period_end && state.subscription?.cancel_at
                            ? localFormat(
                                new Date(state.subscription?.cancel_at * 1000),
                                'dd MMMM yyyy',
                                user?.locale ?? 'en',
                              )
                            : state.nextInvoice?.period_end &&
                              localFormat(
                                new Date(state.nextInvoice.period_end * 1000),
                                'dd MMMM yyyy',
                                user?.locale ?? 'en',
                              )}
                        </SoftTypography>
                        <SoftBox sx={{my: 2}}>
                          <SoftButton
                            color="info"
                            disabled={state.status === 'working' || state.isOwnerOrAdmin === false}
                            onClick={onRedirectTeamPortal}>
                            {state.subscription?.cancel_at_period_end
                              ? t('teamsettingssubscription.subscription.reactivatesubscription', {
                                  defaultValue: 'Réactiver mon abonnement',
                                })
                              : t('teamsettingssubscription.subscription.managepaymentsandinvoice', {
                                  defaultValue: 'Gérer les paiements et facture',
                                })}
                          </SoftButton>
                        </SoftBox>
                        {state.isOwnerOrAdmin === false && (
                          <SoftTypography variant="caption" color="error">
                            {t('teamsettingssubscription.subscription.onlyownerandadmin', {
                              defaultValue: 'Seul le propriétaire et les administrateurs peuvent gérer les paiements',
                            })}
                          </SoftTypography>
                        )}
                      </Card>
                      <Card sx={{minWidth: 500, padding: 2}}>
                        <SoftTypography variant="subtitle2" sx={{mb: 2}}>
                          {t('teamsettingssubscription.subscription.currentplan', {
                            defaultValue: "Plan d'abonnement actuel",
                          })}
                        </SoftTypography>
                        <SoftTypography variant="h4" fontWeight="bold">
                          {team.teamPlan === 'team'
                            ? t('teamsettingssubscription.subscription.teamplanpricing', {
                                defaultValue: '4€ / mois',
                              })
                            : t('teamsettingssubscription.subscription.businessplanpricing', {
                                defaultValue: '14€ / mois',
                              })}
                        </SoftTypography>
                        <SoftTypography variant="h5">
                          {team.teamPlan === 'team'
                            ? t('teamsettingssubscription.subscription.teamplan', {
                                defaultValue: 'Forfait Equipe {{nb}} places',
                                nb: team.users.length,
                              })
                            : t('teamsettingssubscription.subscription.businessplan', {
                                defaultValue: 'Forfait Entreprise {{nb}} places',
                                nb: team.users.length,
                              })}
                        </SoftTypography>
                        {team.teamPremiumLicense !== undefined && team.teamPremiumLicense > 0 && (
                          <SoftTypography variant="h6">
                            {t('teamsettingssubscription.subscription.premiumlicence', {
                              defaultValue: '+ {{nb}} licence premium',
                              nb: team.teamPremiumLicense,
                            })}
                          </SoftTypography>
                        )}
                        {state.subscription?.schedule !== undefined &&
                          state.subscription.schedule !== null &&
                          typeof state.subscription?.schedule !== 'string' &&
                          state.subscription.schedule.current_phase?.end_date && (
                            <SoftBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2}}>
                              <SoftTypography variant="h6" color="error">
                                {t('teamsettingssubscription.subscription.planchangescheduled', {
                                  defaultValue: 'Changement de plan prévu le {{date}}',
                                  date: localFormat(
                                    new Date(state.subscription.schedule.current_phase.end_date * 1000),
                                    'dd MMMM yyyy',
                                    user?.locale ?? 'en',
                                  ),
                                })}
                              </SoftTypography>
                              <SoftButton
                                color="dark"
                                variant="text"
                                onClick={onRedirectTeamPortal}
                                disabled={state.status === 'working' || state.isOwnerOrAdmin === false}>
                                {t('teamsettingssubscription.subscription.viewdetail', {
                                  defaultValue: 'voir le détail',
                                })}
                              </SoftButton>
                            </SoftBox>
                          )}
                        <SoftBox sx={{mt: 2}}>
                          <SoftButton
                            disabled={state.status === 'working' || state.isOwnerOrAdmin === false}
                            onClick={
                              state.subscription?.cancel_at_period_end ? onRedirectTeamPortal : onSelectPlan('business')
                            }>
                            {state.subscription?.cancel_at_period_end
                              ? t('teamsettingssubscription.subscription.reactivatesubscription', {
                                  defaultValue: 'Réactiver mon abonnement',
                                })
                              : t('teamsettingssubscription.subscription.changeplan', {
                                  defaultValue: 'Changer de forfait',
                                })}
                          </SoftButton>
                        </SoftBox>
                      </Card>
                    </SoftBox>
                  </SoftBox>
                ) : null}

                <SoftTypography variant="title">{t('teamsettingssubscription.freeplan.otherplan')}</SoftTypography>
                <Card sx={{p: 3, my: 3}}>
                  <SoftBox sx={{display: 'flex', flexDirection: 'row'}}>
                    <SoftBox sx={{flex: 1}}>
                      <SoftTypography variant="subtitle">
                        {t('teamsettingssubscription.freeplan.freeaccess')}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.freeplan.pricing')}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.freeplan.activeuser', {nb: team ? team.users.length : 0})}
                      </SoftTypography>
                      {state.subscription?.cancel_at_period_end === false && (
                        <>
                          <SoftTypography variant="subtitle2" sx={{mt: 2}}>
                            {t('teamsettingssubscription.subscription.needabreak', {
                              defaultValue: 'Besoin de prendre une pause ?',
                            })}
                          </SoftTypography>
                          <SoftBox>
                            <SoftButton
                              color="error"
                              variant="outlined"
                              size="small"
                              sx={{
                                textTransform: 'lowercase',
                              }}
                              disabled={state.status === 'working' || state.isOwnerOrAdmin === false}
                              onClick={onRedirectTeamPortal}>
                              {t('teamsettingssubscription.subscription.unsubscribe', {
                                defaultValue: 'Se désabonner de PerfectPost',
                              })}
                            </SoftButton>
                          </SoftBox>
                        </>
                      )}
                    </SoftBox>
                    <SoftBox sx={{flex: 1}}>
                      <SoftTypography>{t('teamsettingssubscription.freeplan.includes')}</SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.freeplan.unlimitedpeople')}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.freeplan.unlimitedpost')}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.freeplan.editorialcalendar')}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
                <Card sx={{p: 3, mb: 3}}>
                  <SoftBox sx={{display: 'flex', flexDirection: 'row'}}>
                    <SoftBox sx={{flex: 1}}>
                      <SoftTypography variant="subtitle">{t('teamsettingssubscription.teamplan.name')}</SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.teamplan.pricing')}
                      </SoftTypography>
                      {team?.teamPlan !== 'team' ? (
                        <SoftButton color="info" size="small" sx={{mt: 1}} onClick={onSelectPlan('team')}>
                          {t('teamsettingssubscription.global.chooseplan', {defaultValue: 'Choisir cet abonnement'})}
                        </SoftButton>
                      ) : (
                        <SoftTypography variant="subtitle1" sx={{mt: 1}}>
                          {t('teamsettingssubscription.global.currentplan', {defaultValue: 'Abonnement actuel'})}
                        </SoftTypography>
                      )}
                    </SoftBox>
                    <SoftBox sx={{flex: 1}}>
                      <SoftTypography>
                        {t('teamsettingssubscription.teamplan.allfreefeatureplus', {
                          defaultValue: 'Toutes les fonctionnalités de l’accès gratuit, mais aussi :',
                        })}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.teamplan.teamstatistics', {
                          defaultValue: "✔ statistiques de l'équipe et des membres",
                        })}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.teamplan.scheduleforsomone', {
                          defaultValue: "✔ programmation de publication pour les autres membres de l'équipe",
                        })}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
                <Card sx={{p: 3}}>
                  <SoftBox sx={{display: 'flex', flexDirection: 'row'}}>
                    <SoftBox sx={{flex: 1}}>
                      <SoftTypography variant="subtitle">
                        {t('teamsettingssubscription.businessplan.name', {defaultValue: 'Forfait entreprise'})}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.businessplan.pricing', {defaultValue: '14 € /membre /mois'})}
                      </SoftTypography>
                      {team?.teamPlan !== 'business' ? (
                        <SoftButton color="info" size="small" sx={{mt: 1}} onClick={onSelectPlan('business')}>
                          {t('teamsettingssubscription.global.chooseplan', {defaultValue: 'Choisir cet abonnement'})}
                        </SoftButton>
                      ) : (
                        <SoftTypography variant="subtitle1" sx={{mt: 1}}>
                          {t('teamsettingssubscription.global.currentplan', {defaultValue: 'Abonnement actuel'})}
                        </SoftTypography>
                      )}
                    </SoftBox>
                    <SoftBox sx={{flex: 1}}>
                      <SoftTypography>
                        {t('teamsettingssubscription.businessplan.allteamfeatureplus', {
                          defaultValue: 'Toutes les fonctionnalités de l’accès équipe, mais aussi :',
                        })}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.businessplan.teamcommentscheduling', {
                          defaultValue: '✔ programmation de commentaire en équipe',
                        })}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.businessplan.sharingresources', {
                          defaultValue:
                            "✔ partage de resources (accroche, cta, template de publication, design, actualité) entre les membres de l'équipe",
                        })}
                      </SoftTypography>
                      <SoftTypography variant="subtitle2">
                        {t('teamsettingssubscription.businessplan.teamfeed', {
                          defaultValue: "✔ fil d'actualité de l'équipe",
                        })}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
