import {components} from '@perfectpost/perfect-post-common/src/cms/api';
import {ConsoleLogger} from 'aws-amplify/utils';
import {Masonry, useInfiniteLoader} from 'masonic';
import React, {useState, useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import ActivityPreview from 'src/components/activitypreview/ActivityPreview';
import {client} from 'src/services/cms';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

const logger = new ConsoleLogger('Inspiration');

function useGalleryInfiniteLoad({
  set,
  locale,
}: {
  set: React.Dispatch<React.SetStateAction<components['schemas']['PublicationListResponseDataItem'][]>>;
  locale: string;
}) {
  const loadedPublications = useRef<{endIndex: number; startIndex: number}[]>([]);
  const fetchMoreItems = async (startIndex: number, endIndex: number) => {
    // the trick start
    const newIndex = {endIndex, startIndex};
    const existing = loadedPublications.current.find((el) => {
      return el.startIndex === newIndex.startIndex && el.endIndex === newIndex.endIndex;
    });
    if (existing) return;
    loadedPublications.current.push(newIndex);
    // trick end
    try {
      const query: {[s: string]: string | number | undefined} = {
        'populate[0]': 'creator',
        'populate[1]': 'creator.picture',
        'populate[2]': 'media',
        'populate[3]': 'publication_tags',
        sort: 'id',
        'pagination[start]': startIndex,
        'pagination[limit]': 20,
        locale,
      };
      const {data} = await client.GET('/publications', {params: {query}});
      if (data?.data) {
        const items = data.data;
        set((prev) => {
          return [...prev, ...items];
        });
      }
    } catch (e) {
      logger.error(e);
    }
  };
  const maybeLoadMore = useRef(
    useInfiniteLoader<
      components['schemas']['PublicationListResponseDataItem'][],
      (startIndex: number, endIndex: number, items: unknown[]) => unknown
    >(fetchMoreItems, {
      isItemLoaded: (index, items) => !!items[index],
    }),
  );

  return {maybeLoadMore};
}

export default function Inspiration() {
  const {t} = useTranslation();
  useDocumentTitle(t('inspiration.documenttitle', {defaultValue: 'Inspiration'}));
  const locale = useAppSelector((state) => state.main.user?.locale ?? 'en');
  const [publications, setPublications] = useState<components['schemas']['PublicationListResponseDataItem'][]>([]);

  const {maybeLoadMore} = useGalleryInfiniteLoad({locale, set: setPublications});

  const cellRenderer = useCallback(({data}: {data: components['schemas']['PublicationListResponseDataItem']}) => {
    const publication = data;
    return (
      <SoftBox sx={{display: 'flex', justifyContent: 'center'}}>
        <ActivityPreview
          content={publication.attributes?.content ?? ''}
          fullname={publication.attributes?.creator?.data?.attributes?.name ?? ''}
          picture={publication.attributes?.creator?.data?.attributes?.picture?.data?.attributes?.url}
          sharing={{
            path: publication.attributes?.media?.data?.attributes?.url ?? '',
            mimetype: publication.attributes?.media?.data?.attributes?.mime ?? '',
            type: publication.attributes?.media?.data?.attributes?.mime?.startsWith('image')
              ? 'image'
              : publication.attributes?.media?.data?.attributes?.mime?.startsWith('application/pdf')
                ? 'pdf'
                : 'video',
          }}
          noToggle
          link={publication.attributes?.url}
        />
      </SoftBox>
    );
  }, []);

  useEffect(() => {
    const query: {[s: string]: string | number | undefined} = {
      'populate[0]': 'creator',
      'populate[1]': 'creator.picture',
      'populate[2]': 'media',
      'populate[3]': 'publication_tags',
      'pagination[limit]': 20,
      sort: 'id',
      locale,
    };
    client.GET('/publications', {params: {query}}).then(({data: nextItems}) => {
      if (nextItems?.data !== undefined) {
        const items = nextItems.data;
        setPublications(items);
      }
    });
  }, []);

  return (
    <SoftBox mb={3}>
      <SoftTypography mb={2} variant="h2" textTransform="none" fontWeight="bold">
        {t('inspiration.title', {defaultValue: 'Example'})}
      </SoftTypography>
      <SoftBox sx={{maxWidth: 1170}}>
        <Masonry
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onRender={maybeLoadMore.current}
          items={publications}
          columnGutter={24}
          rowGutter={24}
          columnWidth={572}
          overscanBy={1.25}
          render={cellRenderer}
        />
      </SoftBox>
    </SoftBox>
  );
}
