import {Grid, Card, Radio, Table, TableRow, TableBody, CircularProgress} from '@mui/material';
import {TeamMemberPrivacy} from '@perfectpost/perfect-post-common';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {editPrivacyTeam, selectTeamById} from 'src/store/teamslice';
import SoftBadge from 'src/theme/components/SoftBadge';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
// Soft UI Dashboard PRO React base styles
import borders from 'src/theme/theme/base/borders';
import colors from 'src/theme/theme/base/colors';

import NavigationBar from './NavigationBar';

type TableCellProps = {
  width?: string;
  align?: 'left' | 'center' | 'right';
  padding: number[];
  noBorder?: boolean;
  children: React.ReactNode;
};
function TableCell({width, align, padding, noBorder, children}: TableCellProps) {
  const {light} = colors;
  const {borderWidth} = borders;

  return (
    <SoftBox
      component="th"
      width={width ?? 'auto'}
      pt={padding[0]}
      pr={padding[1]}
      pb={padding[2]}
      pl={padding[3]}
      textAlign={align ?? 'left'}
      borderBottom={noBorder ? 0 : `${borderWidth[1]} solid ${light.main}`}>
      <SoftTypography component="div" variant="body2" color="text">
        {children}
      </SoftTypography>
    </SoftBox>
  );
}

export default function TeamSettingsPrivacy() {
  const {id} = useParams();
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();
  const team = useAppSelector((state) => selectTeamById(state, id));
  const user = useAppSelector((state) => state.main.user);

  const [formState, setFormState] = useState<'idle' | 'updating' | 'edited'>('idle');
  const [privacy, setPrivacy] = useState<TeamMemberPrivacy>(
    team?.users.find((u) => u.user === user?._id)?.privacy ?? {
      activity: false,
      scheduled: 'none',
      activityData: false,
      customFeed: false,
      draft: 'none',
      post: false,
      postData: false,
      postComment: false,
      publish: false,
    },
  );

  useEffect(() => {
    if (team !== undefined && user !== undefined) {
      setPrivacy(team.users.find((u) => u.user === user._id)?.privacy ?? privacy);
    }
  }, [team, user]);

  const handleCheck = <T extends keyof TeamMemberPrivacy, K extends TeamMemberPrivacy[T]>(key: T, value: K) => {
    setFormState('edited');
    if (key === 'publish' && typeof value === 'boolean') {
      if (value === true) {
        setPrivacy((prev) => ({...prev, publish: true, draft: 'write', scheduled: 'write'}));
      } else {
        setPrivacy((prev) => ({...prev, publish: false}));
      }
    } else if (key === 'draft' && typeof value === 'string' && value !== 'none') {
      setPrivacy((prev) => ({...prev, draft: value, scheduled: value}));
    } else if (key === 'draft' && value === 'none') {
      setPrivacy((prev) => ({...prev, draft: value, scheduled: value}));
    } else if (key === 'postComment' && typeof value === 'boolean' && value) {
      setPrivacy((prev) => ({...prev, postComment: true, postData: true, post: true}));
    } else if (key === 'postData' && typeof value === 'boolean' && value) {
      setPrivacy((prev) => ({...prev, postData: true, post: true}));
    } else if (key === 'postData' && typeof value === 'boolean' && value === false) {
      setPrivacy((prev) => ({...prev, postComment: false, postData: false}));
    } else if (key === 'post' && typeof value === 'boolean' && value === false) {
      setPrivacy((prev) => ({...prev, post: false, postComment: false, postData: false}));
    } else {
      setPrivacy((prev) => ({...prev, [key]: value}));
    }
  };

  const onSave = async () => {
    if (team) {
      setFormState('updating');
      await dispatch(editPrivacyTeam({id: team._id, privacy}));
      setFormState('idle');
    }
  };

  return (
    <SoftBox>
      <Grid container spacing={3}>
        <Grid item lg={11} xl={10}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {id && <NavigationBar teamId={id} value="privacy" />}
              </Grid>
              <Grid item xs={12}>
                <Card sx={{p: 3}}>
                  <SoftBox p={3} lineHeight={1}>
                    <SoftBox mb={1}>
                      <SoftTypography variant="h5">{t('teamsettingsprivacy.title')}</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      {t('teamsettingsprivacy.subtitle')}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox pb={3} px={3}>
                    <SoftBox minWidth="auto">
                      <Table sx={{minWidth: '36rem'}}>
                        <SoftBox component="thead">
                          <TableRow>
                            <TableCell width="60%" padding={[1.5, 3, 1.5, 0.5]}>
                              {t('teamsettingsprivacy.publish.title', {defaultValue: 'Publish post in my name'})}
                            </TableCell>
                            <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.allow', {defaultValue: 'Allow'})}
                            </TableCell>
                            <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.disallow', {defaultValue: 'Disallow'})}
                            </TableCell>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          <TableRow>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.publish.default', {
                                    defaultValue: 'All user in the team can publish in my name',
                                  })}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.publish === true}
                                onChange={() => handleCheck('publish', true)}
                                value="publish"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Publish'}}
                              />
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.publish === false}
                                onChange={() => handleCheck('publish', false)}
                                value="publish"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Publish'}}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pb={3} px={3}>
                    <SoftBox minWidth="auto">
                      <Table sx={{minWidth: '36rem'}}>
                        <SoftBox component="thead">
                          <TableRow>
                            <TableCell width="60%" padding={[1.5, 3, 1.5, 0.5]}>
                              {t('teamsettingsprivacy.draft')}
                            </TableCell>
                            <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.allpostwrite')}
                            </TableCell>
                            <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.allpostread')}
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.none')}
                            </TableCell>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          <TableRow
                            sx={({palette}) => ({
                              backgroundColor: privacy.publish === true ? palette.grey[200] : undefined,
                            })}>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.alldraft.default')}
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('teamsettingsprivacy.alldraft.description')}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.draft === 'write'}
                                onChange={() => handleCheck('draft', 'write')}
                                value="draft"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Draft'}}
                                disabled={privacy.publish}
                              />
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.draft === 'read'}
                                onChange={() => handleCheck('draft', 'read')}
                                value="draft"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Draft'}}
                                disabled={privacy.publish}
                              />
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.draft === 'none'}
                                onChange={() => handleCheck('draft', 'none')}
                                value="no-draft"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'No Draft'}}
                                disabled={privacy.publish}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={({palette}) => ({
                              backgroundColor: privacy.draft !== 'none' ? palette.grey[200] : undefined,
                            })}>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.scheduledpost.default')}
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('teamsettingsprivacy.scheduledpost.description')}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.scheduled === 'write'}
                                onChange={() => handleCheck('scheduled', 'write')}
                                value="scheduled"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Draft'}}
                                disabled={privacy.draft !== 'none'}
                              />
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.scheduled === 'read'}
                                onChange={() => handleCheck('scheduled', 'read')}
                                value="scheduled"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Draft'}}
                                disabled={privacy.draft !== 'none'}
                              />
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.scheduled === 'none'}
                                onChange={() => handleCheck('scheduled', 'none')}
                                value="no-scheduled"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'No Draft'}}
                                disabled={privacy.draft !== 'none'}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pb={3} px={3}>
                    <SoftBox minWidth="auto">
                      <Table sx={{minWidth: '36rem'}}>
                        <SoftBox component="thead">
                          <TableRow>
                            <TableCell width="60%" padding={[1.5, 3, 1.5, 0.5]}>
                              {t('teamsettingsprivacy.linkedinpost')}
                            </TableCell>
                            <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.allpost')}
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.none')}
                            </TableCell>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          <TableRow>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.contentpost.default')}{' '}
                                  <SoftBadge size="xs" color="warning" sx={{mr: 1}} />
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('teamsettingsprivacy.contentpost.description')}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.post}
                                onChange={(e, checked) => handleCheck('post', checked)}
                                value="post"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Post'}}
                              />
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={!privacy.post}
                                onChange={(e, checked) => handleCheck('post', !checked)}
                                value="no-post"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'No Post'}}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.publicationdata.default')}{' '}
                                  <SoftBadge size="xs" color="warning" sx={{mr: 1}} />
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('teamsettingsprivacy.publicationdata.description')}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.postData}
                                onChange={(e, checked) => handleCheck('postData', checked)}
                                value="postData"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Post Data'}}
                              />
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={!privacy.postData}
                                onChange={(e, checked) => handleCheck('postData', !checked)}
                                value="no-postData"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'No Post Data'}}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.publicationlikecomment.default')}{' '}
                                  <SoftBadge size="xs" color="warning" sx={{mr: 1}} />
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('teamsettingsprivacy.publicationlikecomment.description')}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={privacy.postComment}
                                onChange={(e, checked) => handleCheck('postComment', checked)}
                                value="postComment"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Post Comment'}}
                              />
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={!privacy.postComment}
                                onChange={(e, checked) => handleCheck('postComment', !checked)}
                                value="no-postComment"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'No Post Comment'}}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pb={3} px={3}>
                    <SoftBox minWidth="auto">
                      <Table sx={{minWidth: '36rem'}}>
                        <SoftBox component="thead">
                          <TableRow>
                            <TableCell width="60%" padding={[1.5, 3, 1.5, 0.5]}>
                              {t('teamsettingsprivacy.activity')}
                            </TableCell>
                            <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.share')}
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.keep')}
                            </TableCell>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          <TableRow>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.dailyactivity.default')}{' '}
                                  <SoftBadge size="xs" color="warning" badgeContent="soon" sx={{mr: 1}} />
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('teamsettingsprivacy.dailyactivity.description')}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={false}
                                disabled
                                /*checked={privacy.activity}
                                onChange={(e, checked) => handleCheck('activity', checked)}*/
                                value="activity"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Activity'}}
                              />
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={false}
                                disabled
                                /*checked={!privacy.activity}
                                onChange={(e, checked) => handleCheck('activity', !checked)}*/
                                value="no-activity"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'No Activity'}}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.activitydetails.default')}{' '}
                                  <SoftBadge size="xs" color="warning" badgeContent="soon" sx={{mr: 1}} />
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('teamsettingsprivacy.activitydetails.description')}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={false}
                                disabled
                                /*checked={privacy.activityData}
                                onChange={(e, checked) => handleCheck('activityData', checked)}*/
                                value="activityData"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Activity Data'}}
                              />
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={false}
                                disabled
                                /*checked={!privacy.activityData}
                                onChange={(e, checked) => handleCheck('activityData', !checked)}*/
                                value="no-activityData"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'No Activity Data'}}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pb={3} px={3}>
                    <SoftBox minWidth="auto">
                      <Table sx={{minWidth: '36rem'}}>
                        <SoftBox component="thead">
                          <TableRow>
                            <TableCell width="60%" padding={[1.5, 3, 1.5, 0.5]}>
                              {t('teamsettingsprivacy.personalizedfeed')}
                            </TableCell>
                            <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.allfeed')}
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('teamsettingsprivacy.none')}
                            </TableCell>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          <TableRow>
                            <TableCell width="60%" padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="caption" fontWeight="bold">
                                  {t('teamsettingsprivacy.feeddetails.default')}{' '}
                                  <SoftBadge size="xs" color="warning" badgeContent="soon" sx={{mr: 1}} />
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('teamsettingsprivacy.feeddetails.description')}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={false}
                                disabled
                                /*checked={privacy.customFeed}
                                onChange={(e, checked) => handleCheck('customFeed', checked)}*/
                                value="customFeed"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'Custom Feed'}}
                              />
                            </TableCell>
                            <TableCell width="16%" align="center" padding={[1, 1, 1, 0.5]}>
                              <Radio
                                checked={false}
                                disabled
                                /*checked={!privacy.customFeed}
                                onChange={(e, checked) => handleCheck('customFeed', !checked)}*/
                                value="no-customFeed"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'No Custom Feed'}}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="flex-end">
                    <SoftButton
                      variant="outlined"
                      color={formState === 'idle' ? 'white' : 'dark'}
                      disabled={formState === 'idle'}
                      onClick={onSave}>
                      {formState === 'updating' ? <CircularProgress color="info" /> : t('global.save')}
                    </SoftButton>
                  </SoftBox>
                </Card>
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
