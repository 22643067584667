/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Fade from '@mui/material/Fade';
import {SxProps, Theme} from '@mui/system';
import React, {useState} from 'react';

// Soft UI Dashboard PRO React components
import SoftAlertCloseIcon from './SoftAlertCloseIcon';
import SoftAlertRoot from './SoftAlertRoot';
import SoftBox from '../SoftBox';

// Custom styles for the SoftAlert

type SoftAlertProps = {
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  dismissible?: boolean;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};
function SoftAlert({color = 'info', dismissible = false, children, sx, ...rest}: SoftAlertProps) {
  const [alertStatus, setAlertStatus] = useState('mount');

  const handleAlertStatus = () => setAlertStatus('fadeOut');

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      <SoftAlertRoot ownerState={{color}} {...rest}>
        <SoftBox display="flex" alignItems="center" color="white" sx={sx}>
          {children}
        </SoftBox>
        {dismissible ? (
          <SoftAlertCloseIcon onClick={mount ? handleAlertStatus : undefined}>&times;</SoftAlertCloseIcon>
        ) : null}
      </SoftAlertRoot>
    </Fade>
  );

  switch (true) {
    case alertStatus === 'mount':
      return alertTemplate();
    case alertStatus === 'fadeOut':
      setTimeout(() => setAlertStatus('unmount'), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

export default SoftAlert;
