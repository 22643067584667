import {AppBar, Card, Grid, Tab, Tabs, TextField} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {LinkedinPost} from '@perfectpost/perfect-post-common';
import {AnnotationOptions, EventContext} from 'chartjs-plugin-annotation';
import {addDays, parseISO, startOfToday, endOfToday, NormalizedInterval} from 'date-fns';
import {enGB} from 'date-fns/locale/en-GB';
import {es} from 'date-fns/locale/es';
import {fr} from 'date-fns/locale/fr';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Chart, {Data, OptionGraphType} from 'src/components/chart/Chart';
import DraftCard from 'src/components/draftcard/DraftCard';
import StatisticCardRow from 'src/components/statisticscard/StatisticCardRow';
import TipCard from 'src/components/tipscard/TipCards';
import {useAppSelector} from 'src/store';
import {useAppThunkDispatch} from 'src/store';
import {loadUserDataAveragePerfectPost, selectPPProfileProgress} from 'src/store/dataslice';
import {listPost, selectPostWithinInterval, selectPreviousInterval} from 'src/store/postslice';
import {
  getView,
  selectProfileDataWithinInterval,
  selectProfileStatsProgress,
  selectProfileStatsWithinInterval,
  selectProfileStatsWithinPreviousInterval,
} from 'src/store/profileslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormat, localFormatISOString} from 'src/utils/date-format';

function enter({chart}: EventContext) {
  chart.canvas.style.cursor = 'pointer';
}

function leave({chart}: EventContext) {
  chart.canvas.style.cursor = 'default';
}

type DataType = 'follower' | 'relation' | 'view';
type GraphData = {
  [key in DataType]: Data[];
};

type ProfileState = {
  select: -1 | 7 | 14 | 30 | 90 | 180 | 365;
  currentShowPostId?: string;
  impactOptions: OptionGraphType;
  graphData?: GraphData;
};

export default function Profile() {
  const postListRef = useRef<HTMLElement | null>(null);
  const {t} = useTranslation();
  useDocumentTitle(t('profile.documenttitle', {defaultValue: 'Profile'}));
  const dispatch = useAppThunkDispatch();

  const [profileState, setProfileState] = useState<ProfileState>({
    select: 14,
    impactOptions: {
      plugins: {
        annotation: {
          clip: false,
          common: {
            drawTime: 'afterDraw',
          },
          annotations: [],
        },
      },
    },
  });

  const {select, graphData, currentShowPostId, impactOptions} = profileState;

  const user = useAppSelector((state) => state.main.user);
  const premium = useAppSelector((state) => state.main.premium);

  const interval = useAppSelector((state) => state.post.interval);
  const previousInterval = useAppSelector(selectPreviousInterval);
  const postWithinInterval = useAppSelector(selectPostWithinInterval);

  const profileDataWithinInterval = useAppSelector(selectProfileDataWithinInterval);
  const profileStatsWithinInterval = useAppSelector(selectProfileStatsWithinInterval);
  const profileStatsWithinPreviousInterval = useAppSelector(selectProfileStatsWithinPreviousInterval);
  const profileStatsProgress = useAppSelector(selectProfileStatsProgress);

  const loadingAverageUserData = useAppSelector((state) => state.data.loadingUserData);
  const userDataAveragePerfectPost = useAppSelector((state) => state.data.userDataAveragePerfectPost);
  const ppProfileProgress = useAppSelector(selectPPProfileProgress);

  const chartdataset = useMemo(() => {
    return graphData ? [graphData.view[0], graphData.follower[0]] : [];
  }, [graphData]);

  const onAnotationClick = (post: LinkedinPost) => () => {
    setProfileState((prev) => ({...prev, currentShowPostId: post._id}));
    postListRef.current
      ?.querySelector(`#post-${post._id}`)
      ?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
  };

  const loadData = useCallback(
    (interval: NormalizedInterval<Date>) => {
      const cancelFunc: ((reason?: string | undefined) => void)[] = [];
      if (premium) {
        cancelFunc.push(dispatch(loadUserDataAveragePerfectPost({begin: interval.start, end: interval.end})).abort);
      }
      cancelFunc.push(dispatch(getView(interval)).abort);
      cancelFunc.push(dispatch(listPost(interval)).abort);
      return cancelFunc;
    },
    [premium],
  );

  useEffect(() => {
    const annotations: NonNullable<NonNullable<NonNullable<OptionGraphType>['plugins']>['annotation']>['annotations'] =
      [];
    for (const post of postWithinInterval) {
      const date = parseISO(post.date);
      const line: AnnotationOptions = {
        scaleID: `x`,
        type: 'line',
        value: date.getTime(),
        borderColor: currentShowPostId === post._id ? '#0D99FF' : '#D9D9D9',
        borderWidth: 1,
        borderDash: [2, 2],
      };
      annotations.push(line);
      const point: AnnotationOptions = {
        type: 'point',
        xValue: date.getTime(),
        yAdjust: 0,
        yValue: 0,
        radius: 9,
        borderWidth: 0,
        enter,
        leave,
        click: onAnotationClick(post),
        backgroundColor: currentShowPostId === post._id ? '#0D99FF' : '#D9D9D9',
      };
      annotations.push(point);
    }
    const impactOptions: OptionGraphType = {
      plugins: {
        annotation: {
          clip: false,
          common: {
            drawTime: 'afterDraw',
          },
          annotations,
        },
      },
    };
    setProfileState((prevState) => ({
      ...prevState,
      impactOptions,
    }));
  }, [postWithinInterval]);

  useEffect(() => {
    const brutData: GraphData = {
      follower: [
        {
          yAxisID: 'A',
          id: 'follower',
          label: t('global.follower', {defaultValue: 'Follower'}),
          color: 'dark',
          data: [],
        },
      ],
      relation: [
        {
          yAxisID: 'A',
          id: 'relation',
          label: t('global.relation', {defaultValue: 'Relation'}),
          color: 'info',
          data: [],
        },
      ],
      view: [
        {
          yAxisID: 'B',
          id: 'view',
          label: t('global.profileview', {defaultValue: 'Profile View'}),
          color: 'primary',
          data: [],
        },
      ],
    };
    for (const data of profileDataWithinInterval) {
      const date = parseISO(data.date);
      brutData.relation[0].data.push({
        x: date,
        y:
          data.relation ??
          (brutData.relation[0].data.length > 0
            ? brutData.relation[0].data[brutData.relation[0].data.length - 1].y
            : 0),
      });
      brutData.follower[0].data.push({
        x: date,
        y:
          data.follower ??
          (brutData.follower[0].data.length > 0
            ? brutData.follower[0].data[brutData.follower[0].data.length - 1].y
            : 0),
      });
      brutData.view[0].data.push({
        x: date,
        y:
          data.vue ??
          (brutData.view[0].data.length > 0 ? brutData.view[0].data[brutData.view[0].data.length - 1].y : 0),
      });
    }

    setProfileState((prevState) => ({
      ...prevState,
      graphData: brutData,
    }));
  }, [profileDataWithinInterval]);

  useEffect(() => {
    const cancelFunc = loadData(interval);
    return () => {
      cancelFunc.forEach((f) => f());
    };
  }, [loadData]);

  const onSelectChange = (e: React.SyntheticEvent<Element, Event>, v: number) => {
    if (premium || v <= 14) {
      setProfileState((prevState) => ({
        ...prevState,
        select: v as -1 | 7 | 14 | 30 | 90 | 180 | 365,
      }));
      const finalInterval = {
        start: addDays(startOfToday(), v === -1 ? -60 : -1 * v),
        end: endOfToday(),
      };
      loadData(finalInterval);
    }
  };

  const onCustomIntervalChange = (key: 'start' | 'end') => (date: Date | null) => {
    if (date === null) {
      return;
    }
    const newInterval = {
      start: key === 'start' ? date : interval.start,
      end: key === 'end' ? date : interval.end,
    };
    loadData(newInterval);
  };

  return (
    <SoftBox>
      <SoftBox mb={3}>
        <SoftBox>
          <SoftTypography variant="h2" fontWeight="bold">
            {t('profile.title', {defaultValue: 'Your profile'})}
          </SoftTypography>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={4}>
            <Card sx={{p: 2}}>
              <SoftTypography variant="h4" fontWeight="bold" sx={{mb: 2}}>
                {t('profile.vanitynametitle')}
              </SoftTypography>
              <SoftTypography sx={{fontSize: 14}} fontWeight="bold" color="text" textTransform="none">
                {user?.vanityName !== undefined ? '✅' : '❌'} {t('profile.vanityname', {defaultValue: 'Vanityname'})}
                <br />
                <SoftTypography
                  component="a"
                  sx={{fontSize: 14}}
                  fontWeight="regular"
                  color="text"
                  textTransform="none"
                  href={user?.vanityName ? `https://www.linkedin.com/in/${user.vanityName}` : undefined}
                  target="_blank">
                  {user?.vanityName ? `https://www.linkedin.com/in/${user.vanityName}` : undefined}
                </SoftTypography>
              </SoftTypography>
              <SoftTypography sx={{fontSize: 14}} fontWeight="bold" color="text" textTransform="none">
                {t('profile.headline', {defaultValue: 'Headline'})}
                <br />
                <SoftTypography
                  component="span"
                  sx={{fontSize: 14}}
                  fontWeight="regular"
                  color="text"
                  textTransform="none"
                  href={user?.vanityName ? `https://www.linkedin.com/in/${user.vanityName}` : undefined}>
                  {user?.headline}
                </SoftTypography>
              </SoftTypography>
              {/*
              <SoftTypography sx={{fontSize: 14}} fontWeight="bold" color="text" textTransform="none">
                {t('profile.website', {defaultValue: 'Website'})}
                <br />
              </SoftTypography>
               */}
              <SoftBox sx={{mt: 2, mb: 4}}>
                <SoftBox sx={{flexShrink: 0, display: 'flex', position: 'relative', my: 2, justifyContent: 'center'}}>
                  <SoftBox
                    component="img"
                    src={user?.lnPicture}
                    sx={{
                      objectFix: 'contain',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      objectPosition: 'center',
                      backgroundPosition: '50%',
                      borderRadius: '50%',
                    }}
                  />
                </SoftBox>
                <TipCard informationkey="profilepicture" />
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <Card sx={{p: 2}}>
              <SoftTypography variant="h4" fontWeight="bold" sx={{mb: 2}}>
                {t('profile.profileinformationtitle', {defaultValue: 'Profile Information'})}
              </SoftTypography>
              <SoftTypography sx={{fontSize: 14, mb: 2}} fontWeight="regular" color="text" textTransform="none">
                {user?.summary}
              </SoftTypography>
              <SoftBox sx={{mb: 4}}>
                <TipCard informationkey="profilebio" />
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <Card sx={{p: 2}}>
              <SoftTypography variant="h4" fontWeight="bold">
                {t('profile.banner', {defaultValue: 'banner'})}
              </SoftTypography>
              <SoftBox sx={{my: 2}}>
                <SoftBox sx={{flexShrink: 0, display: 'flex', position: 'relative', width: '100%', my: 2}}>
                  <SoftBox
                    component="img"
                    src={user?.lnBackground}
                    sx={{
                      objectFix: 'contain',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      objectPosition: 'center',
                      width: '100%',
                    }}
                  />
                </SoftBox>
                <TipCard informationkey="profilebanner" />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mb={3} p={1}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <AppBar
              position="static"
              sx={({palette}) => ({border: 1, borderColor: palette.grey[400], borderRadius: '0.75rem'})}>
              <Tabs value={select} onChange={onSelectChange}>
                <Tab label="7" value={7} />
                <Tab label="14" value={14} />
                <Tab
                  label="30"
                  value={30}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="90"
                  value={90}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="180"
                  value={180}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="365"
                  value={365}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="Custom"
                  value={-1}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={3}>
            {select !== -1 ? (
              <SoftTypography sx={{height: '100%'}}>{t('global.days', {defaultValue: 'Days'})}</SoftTypography>
            ) : (
              <SoftBox display="flex" flexDirection="row" sx={{height: '100%'}}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={user?.locale === 'fr' ? fr : user?.locale === 'es' ? es : enGB}>
                  <DatePicker
                    value={interval.start}
                    minDate={new Date('2021-01-01')}
                    maxDate={interval.end}
                    onChange={onCustomIntervalChange('start')}
                    slotProps={{
                      textField: (params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{height: '100%', justifyContent: 'center', mr: 1}}
                        />
                      ),
                    }}
                  />
                  <DatePicker
                    value={interval.end}
                    minDate={interval.start}
                    disableFuture
                    onChange={onCustomIntervalChange('end')}
                    slotProps={{
                      textField: (params) => (
                        <TextField {...params} variant="outlined" sx={{height: '100%', justifyContent: 'center'}} />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </SoftBox>
            )}
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4} container flexDirection="column" spacing={0} sx={{lineHeight: 0}}>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pt: 2, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative'}}>
              {user?.lastFollowerUpdateAt !== undefined && (
                <SoftTypography variant="caption" sx={{mb: 1, display: 'block', textAlign: 'right'}}>
                  {t('global.lastScrappedAt', {
                    defaultValue: 'Dernière mise à jour : {{date}}',
                    date: localFormatISOString(user.lastFollowerUpdateAt, 'dd MMM HH:mm', user.locale),
                  })}
                </SoftTypography>
              )}
              <StatisticCardRow
                title={t('global.follower', {defaultValue: 'Follower'})}
                count={profileStatsWithinInterval.follower}
                mode="number"
                period={`${localFormat(interval.start, 'dd MMM', user?.locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  user?.locale,
                )}`}
                prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', user?.locale)} - ${localFormat(
                  previousInterval.end,
                  'dd MMM',
                  user?.locale,
                )}`}
                prevCount={profileStatsWithinPreviousInterval.follower}
                prevProgress={profileStatsProgress.follower}
                ppCount={userDataAveragePerfectPost?.avgFollower}
                ppProgress={ppProfileProgress.follower}
                loadingPpProgress={loadingAverageUserData}
                premium={premium}
              />
            </SoftBox>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pb: 2, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
              <SoftBox height={150} sx={{mt: 2}}>
                <Chart chartdataset={graphData?.follower ?? []} type="line" />
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={6} md={4} container flexDirection="column" spacing={0} sx={{lineHeight: 0}}>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pt: 2, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative'}}>
              {user?.lastRelationUpdateAt !== undefined && (
                <SoftTypography variant="caption" sx={{mb: 1, display: 'block', textAlign: 'right'}}>
                  {t('global.lastScrappedAt', {
                    defaultValue: 'Dernière mise à jour : {{date}}',
                    date: localFormatISOString(user.lastRelationUpdateAt, 'dd MMM HH:mm', user.locale),
                  })}
                </SoftTypography>
              )}
              <StatisticCardRow
                title={t('global.relation', {defaultValue: 'Relation'})}
                count={profileStatsWithinInterval.relation}
                mode="number"
                period={`${localFormat(interval.start, 'dd MMM', user?.locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  user?.locale,
                )}`}
                prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', user?.locale)} - ${localFormat(
                  previousInterval.end,
                  'dd MMM',
                  user?.locale,
                )}`}
                prevCount={profileStatsWithinPreviousInterval.relation}
                prevProgress={profileStatsProgress.relation}
                ppCount={userDataAveragePerfectPost?.avgRelation}
                ppProgress={ppProfileProgress.relation}
                loadingPpProgress={loadingAverageUserData}
                premium={premium}
              />
            </SoftBox>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pb: 2, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
              <SoftBox height={150} sx={{mt: 2}}>
                <Chart chartdataset={graphData?.relation ?? []} type="line" />
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={6} md={4} container flexDirection="column" spacing={0} sx={{lineHeight: 0}}>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pt: 2, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative'}}>
              {user?.lastVueUpdateAt !== undefined && (
                <SoftTypography variant="caption" sx={{mb: 1, display: 'block', textAlign: 'right'}}>
                  {t('global.lastScrappedAt', {
                    defaultValue: 'Dernière mise à jour : {{date}}',
                    date: localFormatISOString(user.lastVueUpdateAt, 'dd MMM HH:mm', user.locale),
                  })}
                </SoftTypography>
              )}
              <StatisticCardRow
                title={t('global.profileview', {defaultValue: 'Profile View'})}
                count={profileStatsWithinInterval.view}
                mode="number"
                period={`${localFormat(interval.start, 'dd MMM', user?.locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  user?.locale,
                )}`}
                prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', user?.locale)} - ${localFormat(
                  previousInterval.end,
                  'dd MMM',
                  user?.locale,
                )}`}
                prevCount={profileStatsWithinPreviousInterval.view}
                prevProgress={profileStatsProgress.view}
                ppCount={userDataAveragePerfectPost?.avgVue}
                ppProgress={ppProfileProgress.view}
                loadingPpProgress={loadingAverageUserData}
                premium={premium}
              />
            </SoftBox>
            <SoftBox
              bgColor="white"
              sx={{px: 2, pb: 2, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
              <SoftBox height={150} sx={{mt: 2}}>
                <Chart chartdataset={graphData?.view ?? []} type="line" />
              </SoftBox>
            </SoftBox>
          </Grid>
          {/*
          <Grid item xs={6} md={4} sx={{lineHeight: 0}}>
            <SoftBox bgColor="white" sx={{p: 2, borderRadius: '1rem'}}>
              <StatisticCardRow
                title={t('global.websiteclick', {defaultValue: 'Click on Website'})}
                count={followerStats.click}
                mode="number"
                period={`${localFormat(interval.start, 'dd MMM', user?.locale)} - ${localFormat(
                  interval.end,
                  'dd MMM',
                  user?.locale,
                )}`}
                prevCount={followerStats.prevClick}
                prevProgress={followerStats.progressClick}
                ppCount={NaN}
                ppProgress={0}
                loadingPpProgress={loadingAverageUserData}
                premium={premium}
              />
              <SoftBox height={150} sx={{mt: 2}}>
                <Chart chartdataset={[]} />
              </SoftBox>
            </SoftBox>
          </Grid>
           */}
        </Grid>
      </SoftBox>
      <SoftBox
        gridTemplateColumns="repeat(4, 1fr)"
        gridTemplateAreas={`"title title title title"
           "graph graph graph post"
           "graph graph graph post"`}
        gap={2}
        sx={({palette}) => ({
          display: 'grid',
          backgroundColor: palette.white.main,
          borderRadius: '1em',
          p: 2,
          mt: 2,
        })}>
        <SoftBox gridArea="title">
          <SoftTypography>{t('profile.contentimpact', {defaultValue: 'Impact of your content'})}</SoftTypography>
        </SoftBox>
        <SoftBox gridArea="graph">
          <SoftBox lineHeight={1} height={420}>
            <Chart chartdataset={chartdataset} options={impactOptions} type="line" />
          </SoftBox>
        </SoftBox>
        <SoftBox gridArea="post">
          <SoftBox
            ref={postListRef}
            component="ul"
            display="flex"
            flexDirection="column"
            sx={{maxHeight: 420, overflowY: 'auto', px: 2, overscrollBehavior: 'contain'}}>
            {postWithinInterval.map((post) => (
              <SoftBox
                key={post._id}
                id={`post-${post._id}`}
                component="li"
                display="flex"
                alignItems="baseline"
                flexDirection="column"
                py={1}
                mb={1}>
                <DraftCard
                  content={post.text ?? ''}
                  programmingDate={post.date}
                  href={`/analyse/content/${post._id}`}
                />
              </SoftBox>
            ))}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}
