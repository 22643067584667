import {useTheme} from '@mui/material/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import styles from './index.module.css';
import SoftBox from '../SoftBox';
import SoftButton from '../SoftButton';

type CustomToolbarProps = {
  premium: boolean;
  copyWrittingAssistantEnabled: boolean;
  setCopyWrittingAssistantEnabled: (b: boolean) => void;
};
export default function CustomToolbar(props: CustomToolbarProps) {
  const {premium, copyWrittingAssistantEnabled, setCopyWrittingAssistantEnabled} = props;
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <SoftBox id="toolbar" sx={{display: 'flex', flexDirection: 'row', zIndex: '1000 !important'}}>
      <SoftBox sx={{flexGrow: 1}}>
        <button className="ql-clean" />
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-emoji" style={{marginTop: '-3px'}}>
          😀
        </button>
        <button style={{width: 'auto', fontWeight: 'bold'}} className="ql-hook">
          {t('editor.hook', {defaultValue: 'HOOK'})}
        </button>
        <button style={{width: 'auto', fontWeight: 'bold'}} className="ql-cta">
          {t('editor.cta', {defaultValue: 'CTA'})}
        </button>
        <p
          style={{
            width: 'auto',
            fontWeight: 'bold',
            cursor: 'inherit',
            fontSize: 13,
            height: 24,
            padding: '3px 5px',
            display: 'inline-block',
            float: 'left',
            lineHeight: 'normal',
          }}
          className="ql-format-separator">
          |
        </p>
        <div className={styles.copywrittingassistant} style={{cursor: premium === false ? 'not-allowed' : undefined}}>
          <div className="switch">
            <input
              type="checkbox"
              id="lol-checkbox"
              className="checkbox"
              disabled={premium === false}
              defaultChecked={premium === true && copyWrittingAssistantEnabled}
              onChange={() => premium && setCopyWrittingAssistantEnabled(!copyWrittingAssistantEnabled)}
            />
            <label
              className="label"
              htmlFor="lol-checkbox"
              style={{
                backgroundColor: copyWrittingAssistantEnabled ? theme.palette.info.light : theme.palette.grey['300'],
                cursor: premium === false ? 'not-allowed' : undefined,
              }}>
              <div className="knob"></div>
              <div className="subscribe"></div>
              <div className="alright"></div>
            </label>
          </div>
          <span>Assistant d&apos;écriture</span>
          {premium === false && (
            <SoftButton
              className="overlay"
              variant="gradient"
              size="small"
              color="dark"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              component={NavLink}>
              {t('statisticcard.overlaypremium.btn', {defaultValue: 'Go premium'})}
            </SoftButton>
          )}
        </div>
      </SoftBox>
    </SoftBox>
  );
}
