import ImageIcon from '@mui/icons-material/Image';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PollIcon from '@mui/icons-material/Poll';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {AppBar, Grid, Tabs, Tab, TextField, CircularProgress} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {LinkedinPost, RefreshPostCommand, StatusCommand} from '@perfectpost/perfect-post-common';
import {AnnotationOptions, EventContext} from 'chartjs-plugin-annotation';
import {addDays, endOfToday, parseISO, startOfToday, differenceInDays, NormalizedInterval} from 'date-fns';
import {enGB} from 'date-fns/locale/en-GB';
import {es} from 'date-fns/locale/es';
import {fr} from 'date-fns/locale/fr';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {CellProps, Column} from 'react-table';
import Chart, {Data, OptionGraphType} from 'src/components/chart/Chart';
import Datatable from 'src/components/datatable';
import DraftCard from 'src/components/draftcard/DraftCard';
import StatisticCard from 'src/components/statisticscard/StatisticCard';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {loadLinkedInPostAveragePerfectPost, selectPPPostProgress} from 'src/store/dataslice';
import {dismissFinishedCommand, submitMessage, selectCommandById} from 'src/store/extension/slice';
import {
  listPost,
  selectPostWithinInterval,
  selectGlobalStatsProgress,
  selectPreviousInterval,
  selectGlobalStats,
  selectGlobalStatsPrevious,
} from 'src/store/postslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormat, localFormatISOString} from 'src/utils/date-format';

function enter({chart}: EventContext) {
  chart.canvas.style.cursor = 'pointer';
}

function leave({chart}: EventContext) {
  chart.canvas.style.cursor = 'default';
}

type TableRow = {
  _id: string;
  href?: string;
  content: string;
  date: Date;
  impression: number;
  comment: number;
  like: number;
  vote?: number;
  engagement: number;
  type?: string;
};

type DataType = 'view' | 'like' | 'comment';
type GraphData = {
  [key in DataType]: Data;
};

type ContentState = {
  loadingPostData: 'basic' | 'full' | false;
  currentShowPostId?: string;
  refreshCommandId?: string;
  refreshResult?:
    | {
        posts: number;
        paginationToken: string;
        count: number;
        start: number;
      }
    | {error: string};
  select: -1 | 7 | 14 | 30 | 90 | 180 | 365;
  impactOptions?: OptionGraphType;
  tableRowData: TableRow[];
  graphData?: GraphData;
};

export default function Content() {
  const {t} = useTranslation();
  const postListRef = useRef<HTMLElement | null>(null);
  const dispatch = useAppThunkDispatch();
  useDocumentTitle(t('content.documenttitle', {defaultValue: 'Posts'}));

  const extensionAuthenticated = useAppSelector((state) => state.extension.isConnected);
  const extensionInstalled = useAppSelector((state) => state.extension.version !== undefined);
  const premium = useAppSelector((state) => state.main.premium);
  const locale = useAppSelector((state) => state.main.user?.locale);
  const lastPostUpdateAt = useAppSelector((state) => state.main.user?.lastPostUpdateAt);
  const lnPicture = useAppSelector((state) => state.main.user?.lnPicture);

  const loadingLinkedinPosts = useAppSelector((state) => state.post.loadingLinkedinPosts);
  const interval = useAppSelector((state) => state.post.interval);
  const previousInterval = useAppSelector(selectPreviousInterval);
  const postWithinInterval = useAppSelector(selectPostWithinInterval);
  const globalStats = useAppSelector(selectGlobalStats);
  const globalStatsPreviousInterval = useAppSelector(selectGlobalStatsPrevious);
  const globalStatsProgress = useAppSelector(selectGlobalStatsProgress);

  const loadingAveragePostData = useAppSelector((state) => state.data.loadingPostData);
  const averagePerfectPost = useAppSelector((state) => state.data.linkedinPostAveragePerfectPost);
  const ppProgress = useAppSelector(selectPPPostProgress);

  const [contentState, setContentState] = useState<ContentState>(() => {
    const diff = differenceInDays(interval.end, interval.start);
    console.log('diff', diff, interval);
    return {
      select:
        diff === 7
          ? 7
          : diff === 14
            ? 14
            : diff === 30
              ? 30
              : diff === 90
                ? 90
                : diff === 180
                  ? 180
                  : diff === 365
                    ? 365
                    : -1,
      loadingPostData: false,
      tableRowData: [],
    };
  });
  const {
    loadingPostData,
    currentShowPostId,
    refreshResult,
    select,
    impactOptions,
    tableRowData,
    graphData,
    refreshCommandId,
  } = contentState;
  const refreshCommand = useAppSelector((state) => selectCommandById(state, refreshCommandId));

  const Cell = useCallback(
    (props: CellProps<TableRow, number | undefined>) => (
      <SoftTypography
        variant="button"
        textTransform="none"
        fontWeight="regular"
        color="secondary"
        sx={{display: 'inline-block', width: 'max-content'}}>
        {props.value ?? '-'}
      </SoftTypography>
    ),
    [],
  );

  const columnDefinition: Column<TableRow>[] = useMemo(
    () => [
      {
        Header: t('tablepost.type', {defaultValue: 'Type'}),
        accessor: 'type',
        width: '5%',
        Cell: (props) => (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            {props.value === 'ImageComponent' ? (
              <ImageIcon sx={{fontSize: 40}} />
            ) : props.value === 'LinkedInVideoComponent' ? (
              <YouTubeIcon sx={{fontSize: 40}} />
            ) : props.value === 'DocumentComponent' ? (
              <PictureAsPdfIcon sx={{fontSize: 40}} />
            ) : props.value === 'PollComponent' ? (
              <PollIcon sx={{fontSize: 40}} />
            ) : props.value === 'ArticleComponent' ? (
              <NewspaperIcon sx={{fontSize: 40}} />
            ) : null}
          </SoftBox>
        ),
      },
      {
        Header: t('tablepost.post', {defaultValue: 'Post'}),
        accessor: 'content',
        width: '45%',
        Cell: (props) => (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
              {props.row.original.content}
            </SoftTypography>
          </SoftBox>
        ),
      },
      {
        Header: t('tablepost.date', {defaultValue: 'Date'}),
        accessor: 'date',
        width: '10%',
        Cell: (props) => (
          <SoftTypography
            variant="button"
            textTransform="none"
            fontWeight="regular"
            color="secondary"
            sx={{display: 'inline-block', width: 'max-content'}}>
            {localFormat(props.value, 'dd/MM/yyyy HH:mm:ss', locale)}
          </SoftTypography>
        ),
      },
      // {Header: {t('tablepost.view', {defaultValue: 'View'}), accessor: 'name', width: '20%'},
      {Header: t('tablepost.impression', {defaultValue: 'Impression'}), accessor: 'impression', width: '10%', Cell},
      {Header: t('tablepost.comment', {defaultValue: 'Comment'}), accessor: 'comment', width: '10%', Cell},
      {Header: t('tablepost.like', {defaultValue: 'Like'}), accessor: 'like', width: '10%', Cell},
      {Header: t('tablepost.vote', {defaultValue: 'Vote'}), accessor: 'vote', width: '10%', Cell},
      {
        Header: t('tablepost.engagement', {defaultValue: 'Engagement'}),
        accessor: 'engagement',
        width: '10%',
        Cell: (props: CellProps<TableRow, number>) => (
          <SoftBox
            component={props.row.values.href ? NavLink : 'span'}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            to={props.row.values.href}
            sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
            <SoftTypography
              variant="button"
              textTransform="none"
              fontWeight="regular"
              color="secondary"
              sx={{display: 'inline-block', width: 'max-content'}}>
              {(props.value * 100).toLocaleString(locale, {maximumFractionDigits: 2})}%
            </SoftTypography>
          </SoftBox>
        ),
      },
    ],
    [locale],
  );

  const chartdataset = useMemo(() => {
    return graphData ? [graphData.comment, graphData.like, graphData.view] : [];
  }, [graphData]);

  const loadData = useCallback(
    (interval: NormalizedInterval<Date>) => {
      const cancelFunc: ((reason?: string | undefined) => void)[] = [];
      if (premium) {
        cancelFunc.push(dispatch(loadLinkedInPostAveragePerfectPost({begin: interval.start, end: interval.end})).abort);
      }
      cancelFunc.push(dispatch(listPost(interval)).abort);
      return cancelFunc;
    },
    [premium],
  );

  useEffect(() => {
    const brutData: GraphData = {
      like: {
        yAxisID: 'A',
        id: 'like',
        label: t('global.like', {defaultValue: 'Like'}),
        color: 'dark',
        data: [] as {x: Date; y: number}[],
      },
      comment: {
        yAxisID: 'A',
        id: 'comment',
        label: t('global.comment', {defaultValue: 'Comment'}),
        color: 'info',
        data: [] as {x: Date; y: number}[],
      },
      view: {
        yAxisID: 'B',
        id: 'view',
        label: t('global.impression', {defaultValue: 'Impression'}),
        color: 'primary',
        data: [] as {x: Date; y: number}[],
      },
    };
    const annotations: AnnotationOptions[] = [];
    const tableRowData: TableRow[] = [];
    for (const post of postWithinInterval) {
      const date = parseISO(post.date);
      const image = document.createElement('img');
      image.src = lnPicture ?? '';
      const line: AnnotationOptions = {
        scaleID: `x`,
        type: 'line',
        value: date.getTime(),
        borderColor: currentShowPostId === post._id ? '#0D99FF' : '#D9D9D9',
        borderWidth: 1,
        borderDash: [2, 2],
      };
      annotations.push(line);
      const point: AnnotationOptions = {
        type: 'label',
        content: image,
        width: 20,
        height: 20,
        xValue: date.getTime(),
        yAdjust: 0,
        yValue: 0,
        borderWidth: 0,
        borderColor: 'transparent',
        enter,
        leave,
        click: onAnotationClick(post),
      };
      annotations.push(point);

      const lastRelever = post.latestSocialData;
      brutData.like.data.push({x: date, y: lastRelever?.numLikes ?? 0});
      brutData.comment.data.push({x: date, y: lastRelever?.numComments ?? 0});
      brutData.view.data.push({x: date, y: lastRelever?.numImpressions ?? 0});

      tableRowData.unshift({
        _id: post._id,
        content: post.text?.substring(0, 80) ?? '',
        comment: lastRelever?.numComments ?? 0,
        date,
        engagement:
          ((lastRelever?.numLikes ?? 0) + (lastRelever?.numComments ?? 0) + (lastRelever?.numVoters ?? 0)) /
          Math.max(lastRelever?.numImpressions ?? 0, lastRelever?.numViews ?? 0),
        impression: lastRelever?.numImpressions ?? 0,
        like: lastRelever?.numLikes ?? 0,
        vote: lastRelever?.numVoters,
        href: `/analyse/content/${post._id}`,
        type: post.type,
      });
    }
    for (let i = 1; i < brutData.like.data.length; i++) {
      brutData.like.data[i].y = brutData.like.data[i].y + brutData.like.data[i - 1].y;
      brutData.comment.data[i].y = brutData.comment.data[i].y + brutData.comment.data[i - 1].y;
      brutData.view.data[i].y = brutData.view.data[i].y + brutData.view.data[i - 1].y;
    }

    setContentState((prevState) => ({
      ...prevState,
      postWithinInterval,
      graphData: brutData,
      tableRowData,
      impactOptions: {
        plugins: {
          annotation: {
            clip: false,
            common: {
              drawTime: 'afterDraw',
            },
            annotations,
          },
        },
      },
    }));
  }, [postWithinInterval]);

  useEffect(() => {
    const cancelFunc = loadData(interval);
    return () => {
      cancelFunc.forEach((f) => f());
    };
  }, [premium]);

  useEffect(() => {
    if (refreshCommand?.finished === true) {
      void perfectPostServiceClient.send(new StatusCommand(refreshCommand.commandId)).then(async (cmd) => {
        const result = cmd?.result;
        if (result === undefined) {
          return;
        }
        void dispatch(dismissFinishedCommand({commandId: refreshCommand.commandId}));
        const rr = result as
          | {
              posts: number;
              paginationToken: string;
              count: number;
              start: number;
            }
          | {error: string};
        if (loadingPostData === 'full' && !('error' in rr) && rr.paginationToken !== '') {
          const command = await perfectPostServiceClient.send(
            new RefreshPostCommand({
              paginationToken: rr.paginationToken,
              count: rr.count,
              start: rr.start + rr.count,
            }),
          );
          const commandId = command.command._id;
          if (commandId === undefined) {
            return;
          }
          setContentState((prevState) => ({
            ...prevState,
            refreshResult: {
              posts:
                rr.posts +
                (prevState.refreshResult !== undefined && 'posts' in prevState.refreshResult
                  ? prevState.refreshResult.posts
                  : 0),
              paginationToken: rr.paginationToken,
              count: rr.count,
              start: rr.start,
            },
            refreshCommandId: commandId,
          }));
          dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
        } else {
          setContentState((prevState) => ({
            ...prevState,
            loadingPostData: false,
            refreshResult:
              'error' in rr
                ? rr
                : {
                    ...rr,
                    posts:
                      rr.posts +
                      (prevState.refreshResult !== undefined && 'posts' in prevState.refreshResult
                        ? prevState.refreshResult.posts
                        : 0),
                  },
            refreshCommandId: undefined,
          }));
          setTimeout(() => {
            setContentState((prevState) => ({...prevState, refreshResult: undefined}));
          }, 5000);
          return dispatch(listPost(interval));
        }
      });
    }
  }, [loadingPostData, refreshCommand]);

  const onAnotationClick = (post: LinkedinPost) => () => {
    setContentState((prevState) => ({...prevState, currentShowPostId: post._id}));
    postListRef.current
      ?.querySelector(`#post-${post._id}`)
      ?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
  };

  const onRefresh = useCallback(() => {
    setContentState((prevState) => ({...prevState, loadingPostData: 'basic'}));
    perfectPostServiceClient.send(new RefreshPostCommand()).then((command) => {
      const commandId = command.command._id;
      if (commandId === undefined) {
        return;
      }
      setContentState((prevState) => ({...prevState, refreshCommandId: commandId}));
      dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
    });
  }, []);

  const onFullRefresh = useCallback(() => {
    setContentState((prevState) => ({...prevState, loadingPostData: 'full'}));
    perfectPostServiceClient.send(new RefreshPostCommand({})).then((command) => {
      const commandId = command.command._id;
      if (commandId === undefined) {
        return;
      }
      setContentState((prevState) => ({...prevState, refreshCommandId: commandId}));
      dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
    });
  }, []);

  const onSelectChange = (e: React.SyntheticEvent<Element, Event>, v: number) => {
    if (premium || v <= 14) {
      setContentState((prevState) => ({
        ...prevState,
        select: v as -1 | 7 | 14 | 30 | 90 | 180 | 365,
      }));
      const interval = {
        start: addDays(startOfToday(), v === -1 ? -60 : -1 * v),
        end: endOfToday(),
      };
      loadData(interval);
    }
  };

  const onCustomIntervalChange = (key: 'start' | 'end') => (date: Date | null) => {
    if (date === null) {
      return;
    }
    const newInterval = {
      start: key === 'start' ? date : interval.start,
      end: key === 'end' ? date : interval.end,
    };
    loadData(newInterval);
  };

  const onExportData = () => {
    const csvRows = [];
    const headers = ['content', 'date', 'url', 'impression', 'comment', 'like', 'vote', 'engagement'];
    csvRows.push(headers.join(','));

    for (const row of postWithinInterval) {
      const lastRelever = row.latestSocialData;

      const values = [
        `"${row.text?.replace(/"/g, '""').replace(/(\r\n|\n|\r)/g, ' ')}"`,
        row.date,
        `https://www.linkedin.com/feed/update/${row.urn}`,
        lastRelever?.numImpressions ?? 0,
        lastRelever?.numComments ?? 0,
        lastRelever?.numLikes ?? 0,
        lastRelever?.numVoters,
        ((lastRelever?.numLikes ?? 0) + (lastRelever?.numComments ?? 0) + (lastRelever?.numVoters ?? 0)) /
          Math.max(lastRelever?.numImpressions ?? 0, lastRelever?.numViews ?? 0),
      ];
      csvRows.push(values.join(','));
    }

    const csvString = csvRows.join('\n');
    // download csv file WITH HTML5
    const blob = new Blob([csvString], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
  };

  return (
    <SoftBox>
      <SoftBox mb={3}>
        <SoftTypography mb={2} variant="h2" textTransform="none" fontWeight="bold">
          {t('content.title', {defaultValue: 'Publication statistics'})}
        </SoftTypography>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <AppBar
              position="static"
              sx={({palette}) => ({border: 1, borderColor: palette.grey[400], borderRadius: '0.75rem'})}>
              <Tabs value={select} onChange={onSelectChange}>
                <Tab label="7" value={7} />
                <Tab label="14" value={14} />
                <Tab
                  label="30"
                  value={30}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="90"
                  value={90}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="180"
                  value={180}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="365"
                  value={365}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="Custom"
                  value={-1}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item>
            {select !== -1 ? (
              <SoftTypography sx={{height: '100%', marginTop: '5px'}}>
                {t('global.days', {defaultValue: 'Days'})}
              </SoftTypography>
            ) : (
              <SoftBox display="flex" flexDirection="row" sx={{height: '100%', gap: '5px'}}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale === 'fr' ? fr : locale === 'es' ? es : enGB}>
                  <DatePicker
                    value={interval.start}
                    minDate={new Date('2021-01-01')}
                    maxDate={interval.end}
                    onChange={onCustomIntervalChange('start')}
                    sx={{height: '100%'}}
                    slotProps={{
                      textField: (params) => (
                        <TextField {...params} variant="outlined" sx={{height: '100%', justifyContent: 'center'}} />
                      ),
                    }}
                  />
                  <DatePicker
                    value={interval.end}
                    minDate={interval.start}
                    disableFuture
                    onChange={onCustomIntervalChange('end')}
                    sx={{height: '100%'}}
                    slotProps={{
                      textField: (params) => (
                        <TextField {...params} variant="outlined" sx={{height: '100%', justifyContent: 'center'}} />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </SoftBox>
            )}
          </Grid>
          <Grid item xs={6} sx={{display: 'flex', gap: '5px'}}>
            <SoftBox>
              <SoftButton
                variant="outlined"
                color="dark"
                onClick={onRefresh}
                size="small"
                disabled={
                  loadingPostData !== false || extensionInstalled === false || extensionAuthenticated === false
                }>
                {loadingPostData ? (
                  <CircularProgress color="info" />
                ) : (
                  t('content.refreshlast50post', {defaultValue: 'Refresh the last 50 posts'})
                )}
              </SoftButton>
            </SoftBox>
            {premium && (
              <SoftBox sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                <SoftButton
                  variant="outlined"
                  color="dark"
                  onClick={onFullRefresh}
                  size="small"
                  disabled={
                    loadingPostData !== false || extensionInstalled === false || extensionAuthenticated === false
                  }>
                  {t('content.refreshfull', {defaultValue: 'Refresh all posts of my account'})}
                </SoftButton>
                <SoftTypography variant="caption" sx={{ml: 1}}>
                  {t('content.refreshfullwarning', {
                    defaultValue:
                      'This action may take a long time and can spam your LinkedIn account not use it too often.',
                  })}
                </SoftTypography>
              </SoftBox>
            )}
          </Grid>
          {refreshResult !== undefined || extensionInstalled === false || extensionAuthenticated === false ? (
            <>
              <Grid item>
                {refreshResult !== undefined && (
                  <SoftTypography variant="caption" sx={{ml: 1}}>
                    {'error' in refreshResult
                      ? refreshResult.error
                      : t('content.postrefreshed', {nb: refreshResult.posts})}
                  </SoftTypography>
                )}
                {extensionInstalled === false ? (
                  <SoftTypography variant="caption" color="error" sx={{ml: 1}}>
                    {t('installextensionmodal.part1')}
                  </SoftTypography>
                ) : extensionAuthenticated === false ? (
                  <SoftTypography variant="caption" color="error" sx={{ml: 1}}>
                    {t('extension.needauthenticate')}
                  </SoftTypography>
                ) : (
                  <></>
                )}
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </SoftBox>
      <SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <StatisticCard
              title={t('global.impression', {defaultValue: 'Impression'})}
              count={globalStats.impression}
              loading={loadingLinkedinPosts}
              mode="number"
              period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                interval.end,
                'dd MMM',
                locale,
              )}`}
              prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                previousInterval.end,
                'dd MMM',
                locale,
              )}`}
              prevCount={globalStatsPreviousInterval.impression}
              prevProgress={globalStatsProgress.impression}
              ppCount={averagePerfectPost?.avgImpressions}
              ppProgress={ppProgress?.impression}
              loadingPpProgress={loadingAveragePostData}
              premium={premium}
            />
          </Grid>
          <Grid item xs={3}>
            <StatisticCard
              title={t('global.like', {defaultValue: 'Like'})}
              count={globalStats.like}
              loading={loadingLinkedinPosts}
              mode="number"
              period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                interval.end,
                'dd MMM',
                locale,
              )}`}
              prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                previousInterval.end,
                'dd MMM',
                locale,
              )}`}
              prevCount={globalStatsPreviousInterval.like}
              prevProgress={globalStatsProgress.like}
              ppCount={averagePerfectPost?.avgLikes}
              ppProgress={ppProgress?.like}
              premium={premium}
              loadingPpProgress={loadingAveragePostData}
            />
          </Grid>
          <Grid item xs={3}>
            <StatisticCard
              title={t('global.comment', {defaultValue: 'Comment'})}
              count={globalStats.comment}
              loading={loadingLinkedinPosts}
              mode="number"
              period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                interval.end,
                'dd MMM',
                locale,
              )}`}
              prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                previousInterval.end,
                'dd MMM',
                locale,
              )}`}
              prevCount={globalStatsPreviousInterval.comment}
              prevProgress={globalStatsProgress.comment}
              ppCount={averagePerfectPost?.avgComments}
              ppProgress={ppProgress?.comment}
              premium={premium}
              loadingPpProgress={loadingAveragePostData}
            />
          </Grid>
          <Grid item xs={3}>
            <StatisticCard
              title={t('global.post', {defaultValue: 'Post'})}
              count={globalStats.count}
              loading={loadingLinkedinPosts}
              mode="number"
              period={`${localFormat(interval.start, 'dd MMM', locale)} - ${localFormat(
                interval.end,
                'dd MMM',
                locale,
              )}`}
              prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', locale)} - ${localFormat(
                previousInterval.end,
                'dd MMM',
                locale,
              )}`}
              prevCount={globalStatsPreviousInterval.count}
              prevProgress={globalStatsProgress.count}
              ppCount={averagePerfectPost?.avgPost}
              ppProgress={ppProgress?.count}
              premium={premium}
              loadingPpProgress={loadingAveragePostData}
            />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox
        gridTemplateColumns="repeat(4, 1fr)"
        gridTemplateAreas={`"title title title title"
           "graph graph graph post"
           "graph graph graph post"`}
        gap={2}
        sx={({palette}) => ({
          display: 'grid',
          backgroundColor: palette.white.main,
          borderRadius: '1em',
          p: 2,
          mt: 2,
        })}>
        <SoftBox
          gridArea="title"
          sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <SoftTypography>{t('content.postimpact', {defaultValue: 'Impact of your publications'})}</SoftTypography>
          {lastPostUpdateAt !== undefined && (
            <SoftTypography variant="caption" sx={{mb: 1, display: 'block', textAlign: 'right'}}>
              {t('global.lastScrappedAt', {
                defaultValue: 'Dernière mise à jour : {{date}}',
                date: localFormatISOString(lastPostUpdateAt, 'dd MMM HH:mm', locale),
              })}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox gridArea="graph">
          <SoftBox lineHeight={1} height={420}>
            <Chart chartdataset={chartdataset} options={impactOptions} type="line" />
          </SoftBox>
        </SoftBox>
        <SoftBox gridArea="post">
          <SoftBox
            ref={postListRef}
            component="ul"
            display="flex"
            flexDirection="column"
            sx={{maxHeight: 420, overflowY: 'auto', px: 2, overscrollBehavior: 'contain'}}>
            {postWithinInterval.map((post) => (
              <SoftBox
                key={post._id}
                id={`post-${post._id}`}
                component="li"
                display="flex"
                alignItems="baseline"
                flexDirection="column"
                py={1}
                mb={1}>
                <DraftCard
                  content={post.text ?? ''}
                  programmingDate={post.date}
                  href={`/analyse/content/${post._id}`}
                />
              </SoftBox>
            ))}
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox sx={{mt: 2}}>
        <SoftBox py={3}>
          <SoftTypography variant="caption" textTransform="none">
            {t('content.explainhowtoseemore', {defaultValue: '(to see more publications change the selected period)'})}
          </SoftTypography>
        </SoftBox>
        <Datatable
          table={{rows: tableRowData, columns: columnDefinition}}
          entriesPerPage={{defaultValue: 30, entries: [10, 20, 30, 50, 80, 130]}}
          pagination={{variant: 'gradient', color: 'info'}}
          onExportData={onExportData}
          isSorted
          canSearch
        />
      </SoftBox>
    </SoftBox>
  );
}
