/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Icon from '@mui/material/Icon';
import 'quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.min.css';
import {type OrganizationURN, isFsdProfileURN} from '@perfectpost/linkedin-privateapi-ts-client';
import {
  transformHtmlPostToText,
  ProfileByUrnCommand,
  SearchCommand,
  TypeheadApiCommand,
  StatusCommand,
} from '@perfectpost/perfect-post-common';
import {isTag, Element} from 'domhandler';
import {Picker} from 'emoji-mart';
import emojiRegex from 'emoji-regex';
import {parseDocument} from 'htmlparser2';
import {debounce} from 'lodash';
import Quill, {EmitterSource, Range} from 'quill';
import {Mention, MentionOption} from 'quill-mention';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import noPic from 'src/assets/images/nopic.png';
import CTAModal from 'src/components/modals/CTAModal';
import HookModal from 'src/components/modals/HookModal';
import CopyWrittingAssistant from 'src/components/previewer/copywrittingassistant';
import {perfectPostServiceClient} from 'src/services';
import {RootState, useAppSelector, useAppThunkDispatch} from 'src/store';
import {subscribe} from 'src/store';
import {submitMessage} from 'src/store/extension/slice';
import Swal from 'sweetalert2';

import HashtagBlot from './blots/hashtag';
import QlMentionBlot from './blots/mention';
import CustomToolbar from './CustomToolbar';
import bold from './formaters/bold';
import clean from './formaters/clean';
import italic from './formaters/italic';
import underline from './formaters/underline';
import Hashtag from './modules/hashtag';
import PPSeemore from './modules/seemore';
import SoftEditorRoot from './SoftEditorRoot';
import SoftBox from '../SoftBox';
import SoftButton from '../SoftButton';
import SoftTypography from '../SoftTypography';

const isOrganizationURN = (urn: unknown): urn is OrganizationURN =>
  typeof urn === 'string' && urn.startsWith('urn:li:organization:');

const getMentionCount = (content: string) => {
  const doc = parseDocument(content);
  return doc.children
    .filter((element): element is Element => isTag(element))
    .flatMap((p) =>
      p.children
        .filter((element): element is Element => isTag(element))
        .map<number>((atag) => {
          if (atag.name === 'a' && atag.attribs.class === 'ql-mention') {
            return 1;
          }

          return 0;
        }),
    )
    .reduce((a: number, b: number) => a + b, 0);
};

const getHashtagCount = (content: string) => {
  const doc = parseDocument(content);
  return doc.children
    .filter((element): element is Element => isTag(element))
    .flatMap((p) =>
      p.children
        .filter((element): element is Element => isTag(element))
        .map<number>((atag) => {
          if (atag.name === 'strong' && atag.attribs.class === 'ql-hashtag') {
            return 1;
          }

          return 0;
        }),
    )
    .reduce((a: number, b: number) => a + b, 0);
};

const itemRenderer = (data: {value: string; picture?: string}) => {
  const picture = data.picture ?? noPic;
  const p = document.createElement('p');
  const img = document.createElement('img');
  img.src = picture;
  const span = document.createElement('span');
  span.innerText = data.value;
  p.appendChild(img);
  p.appendChild(span);
  return p;
};

Quill.register(
  {
    'modules/seemore': PPSeemore,
    'modules/hashtag': Hashtag,
    'modules/mention': Mention,
    'formats/hashtag': HashtagBlot,
    'formats/qlMention': QlMentionBlot,
  },
  true,
);

type SoftEditorState = {
  state: 'default' | 'showHookModal' | 'showCTAModal';
  selection?: Range;
  characterCount: number;
  wordCount: number;
  hashtagCount: number;
  mentionCount: number;
  emojiCount: number;
};

type SoftEditorConfig = {
  modules: Record<string, unknown>;
  copyWrittingAssistantEnabled: boolean;
};

type SoftEditorProps = {
  initialValue?: string;
  premium: boolean;
  noCounter?: boolean;
  noToolbar?: boolean;
  noSeeMore?: boolean;
  readOnly?: boolean;
  mention?: boolean;
  onCharactereCountExceed?: (b: boolean) => void;
  onChange?: (content: string, source?: EmitterSource) => void;
};
export default function SoftEditor(props: SoftEditorProps) {
  const {
    initialValue,
    premium,
    noCounter,
    noToolbar,
    noSeeMore,
    mention: mentionConfig,
    onCharactereCountExceed,
    readOnly,
    onChange,
  } = props;
  const {t} = useTranslation();
  const quillContainerRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);
  const copywrittingassistant = useRef<CopyWrittingAssistant>();
  const locale = useAppSelector((state) => state.main.user?.locale);
  const dispatch = useAppThunkDispatch();

  const [editorState, setEditorState] = useState<SoftEditorState>({
    state: 'default',
    characterCount: 0,
    wordCount: 0,
    hashtagCount: 0,
    mentionCount: 0,
    emojiCount: 0,
  });

  const [editorConfig, setEditorConfig] = useState<SoftEditorConfig>({
    copyWrittingAssistantEnabled: premium ?? false,
    modules: {
      toolbar: false,
      hashtag: true,
      mention: false,
    },
  });

  const searchingCommand = useRef<string[] | undefined>(undefined);

  useEffect(() => {
    if (quillRef.current?.root && editorConfig.copyWrittingAssistantEnabled) {
      if (copywrittingassistant.current !== undefined) {
        copywrittingassistant.current.dispose();
      }
      copywrittingassistant.current = new CopyWrittingAssistant(quillRef.current.root);
      copywrittingassistant.current.process();
    } else {
      copywrittingassistant.current?.dispose();
    }
    return () => {
      copywrittingassistant.current?.dispose();
    };
  }, [editorConfig.copyWrittingAssistantEnabled]);

  useEffect(() => {
    const toolbar =
      noToolbar !== true && readOnly === false
        ? {
            container: '#toolbar',
            handlers: {
              clean,
              bold,
              italic,
              underline,
              emoji: function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const quill: Quill = this.quill;
                const cursorPosition = quill.getSelection()?.index ?? 0;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const node: HTMLButtonElement | null = this.container.querySelector<HTMLButtonElement>('.ql-emoji');
                if (node === null) {
                  return;
                }
                const picker = new Picker({
                  data: async () => {
                    const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');
                    return response.json();
                  },
                  parent: node,
                  autoFocus: true,
                  locale: locale ?? 'en',
                  navPosition: 'bottom',
                  previewPosition: 'none',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onEmojiSelect: (emojiData) => {
                    quill.insertText(cursorPosition, emojiData.native);
                    quill.setSelection(cursorPosition + 2, 0);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    picker.remove();
                  },
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onClickOutside: (event) => {
                    if (event.target === node) {
                      return;
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    picker.remove();
                  },
                });
              },
              hook: function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const quill: Quill = this.quill;
                setEditorState((f) => ({...f, state: 'showHookModal', selection: quill.getSelection() || undefined}));
              },
              cta: function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const quill: Quill = this.quill;
                setEditorState((f) => ({...f, state: 'showCTAModal', selection: quill.getSelection() || undefined}));
              },
            },
          }
        : false;
    const seemore =
      noSeeMore !== true
        ? {
            mode: 'default',
          }
        : undefined;
    const mention: Partial<MentionOption> | boolean =
      mentionConfig === undefined || mentionConfig === false
        ? false
        : {
            allowedChars: /^[A-Za-z!\sÀ-ÿ]*$/,
            mentionDenotationChars: ['@'],
            blotName: 'qlMention',
            minChars: 3,
            spaceAfterInsert: false,
            isolateCharacter: true,
            defaultMenuOrientation: 'bottom',
            dataAttributes: ['objectUrn', 'entityUrn', 'originalText', 'vanityName', 'mentionUrn', 'value'],
            renderLoading: () => {
              return 'Loading...';
            },
            renderItem: itemRenderer,
            source: debounce(async (searchTerm, renderList) => {
              const command = await perfectPostServiceClient.send(new SearchCommand({value: searchTerm}));
              const commandId = command?.command._id;
              if (commandId === undefined) {
                return;
              }
              searchingCommand.current = [commandId];
              dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
              const unsubscribe = subscribe('extension.commandStack', async (state: RootState) => {
                if (
                  state.extension.commandStack
                    .filter((c) => c.finished)
                    .map((c) => c.commandId)
                    .includes(commandId)
                ) {
                  const result = await perfectPostServiceClient.send(new StatusCommand(commandId));
                  if (
                    result?.result?.items !== undefined &&
                    result.result.items !== null &&
                    Array.isArray(result.result.items)
                  ) {
                    renderList(result.result.items);
                  }
                  searchingCommand.current = undefined;
                  unsubscribe();
                }
              });
            }, 500),
            onSelect: async (item, insertItem) => {
              if (isOrganizationURN(item.objectUrn)) {
                insertItem({
                  objectUrn: item.objectUrn,
                  entityUrn: item.entityUrn,
                  originalText: item.value ?? '',
                  vanityName: item.value ?? '',
                  mentionUrn: item.objectUrn,
                });
              } else if (item.vanityName === undefined && isFsdProfileURN(item.entityUrn)) {
                const resolveVanitName = await perfectPostServiceClient.send(
                  new ProfileByUrnCommand({profileUrn: item.entityUrn}),
                );
                const commandId = resolveVanitName?.command._id;
                if (commandId === undefined) {
                  return;
                }
                searchingCommand.current = [commandId];
                dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
                const unsubscribe = subscribe('extension.commandStack', async (state: RootState) => {
                  if (
                    state.extension.commandStack
                      .filter((c) => c.finished)
                      .map((c) => c.commandId)
                      .includes(commandId)
                  ) {
                    const result = await perfectPostServiceClient.send(new StatusCommand(commandId));
                    if (
                      result?.result?.vanityName !== undefined &&
                      result.result.vanityName !== null &&
                      typeof result.result.vanityName === 'string'
                    ) {
                      const vanityName = result.result.vanityName;
                      const search = await perfectPostServiceClient.send(new TypeheadApiCommand({vanityName}));
                      const mentionUrnResult = search.pop();
                      if (mentionUrnResult === undefined) {
                        Swal.fire({
                          title: t('editor.mentionnotfound.title', {
                            defaultValue: 'Impossible de mentionner cette personne',
                          }),
                          html: t('editor.mentionnotfound.text', {
                            vanityName,
                            defaultValue: `<p style="text-align:left">Nous avons bien trouvé le profil <a href="https://www.linkedin.com/in/{{vanityName}}" target="_blank">https://www.linkedin.com/in/{{vanityName}}</a>, mais LinkedIn refusent que nous le mentionnions.</p><br/>
<p style="text-align:left">Cela peut être dû au paramètre de confidentialité de la personne qui interdit à LinkedIn de transmettre des informations sur elle à des tiers. Vous devez faire cette mention et publier votre post depuis LinkedIn directement si vous souhaitez la mentionner.</p>`,
                          }),
                          icon: 'warning',
                          confirmButtonText: t('editor.bouton.understood', {defaultValue: "C'est noté"}),
                        });
                      } else {
                        insertItem({
                          objectUrn: item.objectUrn,
                          entityUrn: item.entityUrn,
                          originalText: item.value,
                          vanityName,
                          mentionUrn: mentionUrnResult.member,
                        });
                      }
                    }
                    searchingCommand.current = undefined;
                    unsubscribe();
                  }
                });
              } else {
                const search = await perfectPostServiceClient.send(
                  new TypeheadApiCommand({vanityName: item.vanityName!}),
                );
                const result = search.pop();
                if (result !== undefined) {
                  insertItem({...item, mentionUrn: result.member});
                }
              }
            },
          };
    const modules = {
      toolbar,
      seemore,
      hashtag: true,
      mention,
    };
    setEditorConfig((f) => ({...f, modules, key: Date.now()}));
    if (quillContainerRef.current === null) {
      return;
    }

    const quill = new Quill(quillContainerRef.current, {
      theme: 'snow',
      modules,
      formats: ['qlMention', 'hashtag'],
      readOnly,
    });
    quillRef.current = quill;
    quill.on('text-change', (delta, oldContents, source) => {
      copywrittingassistant.current?.process();
      if (quillRef.current) {
        onTextUpdate(quillRef.current.root.innerHTML);
        onChange?.(quillRef.current.root.innerHTML, source);
      }
    });
    quill.root.innerHTML = initialValue ?? '';

    //quill.keyboard.addBinding({key: ['b', 'B'], shiftKey: true}, () => bold(quill));
    //quill.keyboard.addBinding({key: ['i', 'I'], shiftKey: true}, () => italic(quill));
    //quill.keyboard.addBinding({key: ['u', 'U'], shiftKey: true}, () => underline(quill));

    quill.root.focus();
    if (editorConfig.copyWrittingAssistantEnabled) {
      copywrittingassistant.current = new CopyWrittingAssistant(quillRef.current.root);
    }
  }, [noToolbar, readOnly]);

  const onTextUpdate = (content: string) => {
    const contentString = transformHtmlPostToText(content);
    const characterCount = contentString.length;
    const regex: RegExp = emojiRegex();
    const match = contentString.match(regex);
    const emojiCount = match !== null ? match.length : 0;
    if (characterCount > 3000) {
      onCharactereCountExceed?.(true);
    } else {
      onCharactereCountExceed?.(false);
    }
    setEditorState((s) => ({
      ...s,
      characterCount,
      wordCount: characterCount === 0 ? 0 : contentString.split(' ').length,
      hashtagCount: getHashtagCount(content),
      mentionCount: getMentionCount(content),
      emojiCount,
    }));
  };

  const onCTASelected = (content: string) => {
    const editor = quillRef.current;
    if (editorState.selection == undefined || editor === undefined || editor === null) {
      return;
    }
    // append to the end of the editor
    const html = editor.root.innerHTML;
    editor.root.innerHTML = html + content;
    setEditorState((f) => ({...f, state: 'default', selection: undefined}));
  };

  const onHookSelected = (content: string) => {
    const editor = quillRef.current;
    if (editorState.selection == undefined || editor === undefined || editor === null) {
      return;
    }
    // append to the end of the editor
    const html = editor.root.innerHTML;
    editor.root.innerHTML = content + html;
    setEditorState((f) => ({...f, state: 'default', selection: undefined}));
  };

  return (
    <SoftEditorRoot>
      {noToolbar !== true && readOnly === false && (
        <CustomToolbar
          premium={premium}
          copyWrittingAssistantEnabled={editorConfig.copyWrittingAssistantEnabled}
          setCopyWrittingAssistantEnabled={(b) => setEditorConfig({...editorConfig, copyWrittingAssistantEnabled: b})}
        />
      )}
      <div style={{backgroundColor: 'white', height: 'fit-content', minHeight: 200}}>
        <div ref={quillContainerRef}></div>
      </div>
      {editorState.state === 'showCTAModal' ? (
        <CTAModal
          handleClose={() => setEditorState((f) => ({...f, state: 'default', selection: undefined}))}
          handleConfirm={onCTASelected}
          mention
        />
      ) : editorState.state === 'showHookModal' ? (
        <HookModal
          handleClose={() => setEditorState((f) => ({...f, state: 'default', selection: undefined}))}
          handleConfirm={onHookSelected}
          mention
        />
      ) : null}
      {noCounter !== true && (
        <SoftBox
          display="flex"
          flexDirection="row"
          bgColor="white"
          sx={({borders}) => ({
            mt: 1,
            p: 1,
            border: '1px solid #CCC',
            borderRadius: `${borders.borderRadius.md} ${borders.borderRadius.md}`,
          })}>
          <SoftBox sx={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
            <SoftButton iconOnly sx={{cursor: 'auto'}} variant="gradient" color="dark">
              <Icon>text_format</Icon>
            </SoftButton>
            <SoftBox sx={{flex: 1, justifyContent: 'center', px: 1}}>
              <SoftTypography fontWeight="bold" fontSize="12" color="text">
                {t('editor.lettercount', {defaultValue: 'Letter'})}
              </SoftTypography>
              <SoftTypography fontWeight="bold" color="dark">
                {editorState.characterCount}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox sx={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
            <SoftButton iconOnly sx={{cursor: 'auto'}} variant="gradient" color="dark">
              <Icon>text_fields</Icon>
            </SoftButton>
            <SoftBox sx={{flex: 1, justifyContent: 'center', px: 1}}>
              <SoftTypography fontWeight="bold" fontSize="12" color="text">
                {t('editor.wordcount', {defaultValue: 'Word'})}
              </SoftTypography>
              <SoftTypography fontWeight="bold" color="dark">
                {editorState.wordCount}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox sx={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
            <SoftButton iconOnly sx={{cursor: 'auto'}} variant="gradient" color="dark">
              <Icon>mood</Icon>
            </SoftButton>
            <SoftBox sx={{flex: 1, justifyContent: 'center', px: 1}}>
              <SoftTypography fontWeight="bold" fontSize="12" color={editorState.emojiCount === 0 ? 'error' : 'text'}>
                {t('editor.emojicount', {defaultValue: 'Emoji'})}
              </SoftTypography>
              <SoftTypography fontWeight="bold" color={editorState.emojiCount === 0 ? 'error' : 'dark'}>
                {editorState.emojiCount}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox sx={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
            <SoftButton iconOnly sx={{cursor: 'auto'}} variant="gradient" color="dark">
              <Icon>alternate_email</Icon>
            </SoftButton>
            <SoftBox sx={{flex: 1, justifyContent: 'center', px: 1}}>
              <SoftTypography fontWeight="bold" fontSize="12" color={editorState.mentionCount >= 5 ? 'error' : 'text'}>
                {t('editor.mentioncount', {defaultValue: 'Mention'})}
              </SoftTypography>
              <SoftTypography fontWeight="bold" color={editorState.mentionCount >= 5 ? 'error' : 'dark'}>
                {editorState.mentionCount}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox sx={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
            <SoftButton iconOnly sx={{cursor: 'auto'}} variant="gradient" color="dark">
              <Icon>tag</Icon>
            </SoftButton>
            <SoftBox sx={{flex: 1, justifyContent: 'center', px: 1}}>
              <SoftTypography
                fontWeight="bold"
                fontSize="12"
                color={editorState.hashtagCount > 5 || editorState.hashtagCount < 3 ? 'error' : 'text'}>
                {t('editor.hashtagcount', {defaultValue: 'Hashtag'})}
              </SoftTypography>
              <SoftTypography
                fontWeight="bold"
                color={editorState.hashtagCount > 5 || editorState.hashtagCount < 3 ? 'error' : 'dark'}>
                {editorState.hashtagCount}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          {/*
        <SoftBox sx={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
          <SoftButton iconOnly sx={{cursor: 'auto'}} variant="gradient" color="dark">
            <Icon>groups</Icon>
          </SoftButton>
          <SoftBox sx={{flex: 1, justifyContent: 'center', px: 1}}>
            <SoftTypography fontWeight="bold" fontSize="12" color="text">
              {t('editor.reachestimated', {defaultValue: 'Reach'})}
            </SoftTypography>
            <SoftTypography fontWeight="bold" color="dark">
              8971
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        */}
        </SoftBox>
      )}
    </SoftEditorRoot>
  );
}
