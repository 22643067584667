import {Skeleton} from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';

export type StatisticCardRowProps = {
  title: string;
  loading?: boolean;
  count?: number;
  mode: 'number' | 'percentage';
  period: string;
  prevPeriod: string;
  premium?: boolean;
  prevCount?: number;
  prevProgress?: number;
  ppCount?: number;
  ppProgress?: number;
  loadingPpProgress?: boolean;
};
export default function StatisticCardRow({
  title,
  count,
  loading,
  mode,
  period,
  premium,
  prevPeriod,
  prevCount,
  prevProgress,
  ppCount,
  ppProgress,
  loadingPpProgress,
}: StatisticCardRowProps) {
  const {t} = useTranslation();
  const locale = useAppSelector((state) => state.main.user?.locale);
  return (
    <Grid container sx={{height: 'fit-content', minHeight: '100%', maxHeight: '200px'}}>
      <Grid item xs={6} xl={4} display="flex" flexDirection="column">
        <SoftBox sx={{lineHeight: 1, mb: 0.5}}>
          <SoftTypography sx={{fontSize: 14}} fontWeight="bold" color="text" textTransform="capitalize">
            {title}
          </SoftTypography>
        </SoftBox>
        <SoftBox sx={{lineHeight: 1, flexGrow: 1}}>
          {loading ? (
            <SoftBox component="span" sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Skeleton variant="rectangular" width={35} height={15} sx={{borderRadius: '1rem'}} />
            </SoftBox>
          ) : (
            <SoftTypography variant="h5" fontWeight="bold">
              {count === undefined || Number.isNaN(count)
                ? '---'
                : mode === 'percentage'
                  ? `${(count * 100).toLocaleString(locale, {maximumFractionDigits: 2})}%`
                  : count.toLocaleString(locale, {maximumFractionDigits: 2})}
            </SoftTypography>
          )}
          <SoftTypography variant="caption" fontWeight="regular" color="secondary" textTransform="capitalize">
            {period}
          </SoftTypography>
        </SoftBox>
      </Grid>
      <Grid
        item
        xs={6}
        xl={8}
        display="flex"
        sx={({breakpoints}) => ({
          flexDirection: 'column',
          [breakpoints.up('xl')]: {
            flexDirection: 'row',
          },
          ['@container (min-width: 250px)']: {
            flexDirection: 'row',
          },
          height: 'fit-content',
          minHeight: '100%',
        })}>
        <SoftBox sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
          <SoftBox sx={{flex: 1, display: 'flex', flexDirection: 'row'}}>
            <SoftBox sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
              <SoftBox sx={{flex: 1, display: 'flex', alignItems: 'end'}}>
                {premium ? (
                  loading ? (
                    <SoftBox component="span" sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <Skeleton variant="rectangular" width={35} height={15} sx={{borderRadius: '1rem'}} />
                      <Skeleton variant="rectangular" width={20} height={10} sx={{borderRadius: '1rem', ml: 1}} />
                    </SoftBox>
                  ) : (
                    <SoftTypography variant="button" fontWeight="bold" color="secondary">
                      {Number.isNaN(prevCount)
                        ? '---'
                        : mode === 'percentage'
                          ? `${((prevCount ?? 0) * 100).toLocaleString(locale, {maximumFractionDigits: 2})}%`
                          : prevCount?.toLocaleString(locale, {maximumFractionDigits: 2})}
                      <SoftTypography
                        ml={1}
                        variant="button"
                        fontWeight="bold"
                        fontSize={10}
                        color={(prevProgress ?? 0) < 0 ? 'warning' : 'success'}>
                        {((prevProgress ?? 0) * 100).toLocaleString(locale, {
                          maximumFractionDigits: 2,
                          signDisplay: 'always',
                        })}
                        {mode === 'percentage' ? 'pts' : '%'}
                      </SoftTypography>
                    </SoftTypography>
                  )
                ) : (
                  <SoftTypography variant="button" fontWeight="bold" color="secondary">
                    ---
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox sx={{flex: 1, display: 'flex', alignItems: 'start'}}>
                <SoftTypography variant="caption" color="secondary" textTransform="none">
                  {prevPeriod}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
              <SoftBox sx={{flex: 1, display: 'flex', alignItems: 'end'}}>
                {premium ? (
                  <SoftTypography variant="button" fontWeight="bold" color="info">
                    {loadingPpProgress ? (
                      <SoftBox component="span" sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Skeleton variant="rectangular" width={35} height={15} sx={{borderRadius: '1rem'}} />
                        <Skeleton variant="rectangular" width={20} height={10} sx={{borderRadius: '1rem', ml: 1}} />
                      </SoftBox>
                    ) : (
                      <>
                        {Number.isNaN(ppCount)
                          ? '---'
                          : mode === 'percentage'
                            ? `${((ppCount ?? 0) * 100).toLocaleString(locale, {
                                maximumFractionDigits: 2,
                              })}%`
                            : ppCount?.toLocaleString(locale, {
                                maximumFractionDigits: 2,
                              })}
                        <SoftTypography
                          ml={1}
                          variant="button"
                          fontWeight="bold"
                          fontSize={10}
                          color={(ppProgress ?? 0) < 0 ? 'warning' : 'success'}>
                          {Number.isNaN(ppProgress)
                            ? '---'
                            : ((ppProgress ?? 0) * 100).toLocaleString(locale, {
                                maximumFractionDigits: 2,
                                signDisplay: 'always',
                              })}
                          {mode === 'percentage' ? 'pts' : '%'}
                        </SoftTypography>
                      </>
                    )}
                  </SoftTypography>
                ) : (
                  <SoftTypography variant="button" fontWeight="bold" color="secondary">
                    ---
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox sx={{flex: 1, display: 'flex', alignItems: 'start'}}>
                <SoftTypography variant="caption" color="info" textTransform="none">
                  {t('statisticcard.averageperfectpost', {defaultValue: 'Average PerfectPost'})}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {premium === false && (
            <>
              <SoftTypography variant="caption" sx={{mt: 2}}>
                {t('statisticcard.overlaypremium.title', {
                  defaultValue: 'View your progress and compare yourself to other PerfecPost users',
                })}
                <SoftButton
                  sx={{display: 'inline', textTransform: 'none', fontWeight: 'bold'}}
                  variant="text"
                  size="small"
                  color="dark"
                  component={NavLink}
                  // @ts-ignore
                  to="/settings/subscription?o=">
                  {t('statisticcard.overlaypremium.btn', {defaultValue: 'Go premium'})}
                </SoftButton>
              </SoftTypography>
            </>
          )}
        </SoftBox>
        {/*<SoftBox
          sx={({breakpoints}) => ({
            borderRadius: '1rem',
            p: 1,
            display: premium ? 'none' : 'flex',
            backgroundColor: 'rgba(0,0,0,75%)',
            position: 'absolute',
            top: 0,
            height: 'fit-content',
            minHeight: '100%',
            width: '50%',
            [breakpoints.up('xl')]: {
              width: '66%',
            },
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          })}>
          <SoftTypography color="white" variant="button" textTransform="none">
            {t('statisticcard.overlaypremium.title', {
              defaultValue: 'View your progress and compare yourself to other PerfecPost users',
            })}
          </SoftTypography>
          {/*
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <SoftButton variant="gradient" color="dark" component={NavLink} to="/settings/subscription?o=">
            {t('statisticcard.overlaypremium.btn', {defaultValue: 'Go premium'})}
          </SoftButton>
        </SoftBox>
        */}
      </Grid>
    </Grid>
  );
}
