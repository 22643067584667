import nmaps from './nmaps';
import Stacker from './stacker';

type nmapsKey = keyof typeof nmaps;
function isnmapsKey(s: string): s is nmapsKey {
  return Object.keys(nmaps).includes(s);
}

type AppendAction = {
  action: 'append';
  chars: number[];
  except?: string[];
};
type PrependAction = {
  action: 'prepend_global';
  chars: number[];
  except?: string[];
};
type ReverseAction = {
  action: 'reverse';
  if_in_map?: string;
};
type MapCodePointAction = {
  action: 'map_code_point';
  map: string | Record<string, number[]>;
  append?: boolean | number[];
  prepend?: boolean;
  if_in_map?: string;
  repeat_count?: number;
};
type NormalizeAction = {
  action: 'normalize';
};
type StackAction = {
  action: 'stack';
  dmap: 'd';
  smap: 's';
};
type ShiftCodePointAction = {
  action: 'shift_code_point';
  range: [number, number];
  add: number;
};
type ReplaceAllAction = {
  action: 'replace_all';
  char: 9608;
};
type WrapAction = {
  action: 'wrap';
  split_char: 32;
  wrap_char: [9996, 65_039];
};
type UnstackAction = {
  action: 'unstack';
};
type StripDiacriticsAction = {
  action: 'strip_diacritics';
};
type RemoveExtraSpacesAction = {
  action: 'remove_extra_spaces';
};

export type ActionsType =
  | AppendAction
  | ReverseAction
  | MapCodePointAction
  | NormalizeAction
  | StackAction
  | ShiftCodePointAction
  | ReplaceAllAction
  | WrapAction
  | UnstackAction
  | StripDiacriticsAction
  | RemoveExtraSpacesAction
  | PrependAction;

function _get_map(v: string | Record<number, number[]>): Record<number, number[]> {
  if (typeof v === 'string') {
    if (isnmapsKey(v)) {
      return nmaps[v];
    }

    return {};
  }

  return v;
}

function _in_map(s: string, _map: string) {
  if (!s) {
    return false;
  }

  const map = _get_map(_map);
  const chars = new Set(Object.keys(map).map((c) => String.fromCodePoint(Number.parseInt(c, 10))));
  const ok = Array.from(s).some((strchar) => {
    if (chars.has(strchar)) {
      return true;
    }

    return false;
  });
  return ok;
}

export function transform(text: string, actions: ActionsType[]) {
  for (const action of actions) {
    switch (action.action) {
      case 'append':
        text = append(text, action);
        break;
      case 'map_code_point':
        text = map_code_point(text, action);
        break;
      case 'normalize':
        text = normalize(text, action);
        break;
      case 'replace_all':
        text = replace_all(text, action);
        break;
      case 'reverse':
        text = reverse(text, action);
        break;
      case 'shift_code_point':
        text = shift_code_point(text, action);
        break;
      case 'stack':
        text = stack(text, action);
        break;
      case 'unstack':
        text = unstack(text);
        break;
      case 'wrap':
        text = wrap(text, action);
        break;
      case 'strip_diacritics':
        text = strip_diacritics(text);
        break;
      case 'remove_extra_spaces':
        text = remove_extra_spaces(text);
        break;
      case 'prepend_global':
        text = prepend_global(text, action);
        break;
      default:
        break;
    }
  }

  return text;
}

function prepend_global(s: string, a: PrependAction) {
  const result = String.fromCodePoint.apply(null, a.chars) + s;
  return result;
}

function append(s: string, a: AppendAction) {
  return Array.from(s, (c) => {
    if (!a.except || !a.except.includes(c)) {
      c += String.fromCodePoint.apply(null, a.chars);
    }

    return c;
  }).join('');
}

function shift_code_point(s: string, a: ShiftCodePointAction) {
  return Array.from(s, (s) => {
    const stringCode = s.codePointAt(0);
    if (stringCode !== undefined && stringCode >= a.range[0] && stringCode <= a.range[1]) {
      return String.fromCodePoint(stringCode + a.add);
    }

    return s;
  }).join('');
}

function map_code_point(s: string, a: MapCodePointAction) {
  let ok = true;
  const map = _get_map(a.map);
  const repeat_count = a.repeat_count ?? 1;
  if (a.if_in_map) {
    ok = _in_map(s, a.if_in_map);
  }

  if (map && ok) {
    const string_array = Array.from(s);
    return string_array
      .map((c, i) => {
        const stringCode = c.codePointAt(0);
        if (stringCode !== undefined && map[stringCode]) {
          let newChar = String.fromCodePoint.apply(null, map[stringCode]);
          if (a.append && typeof a.append === 'boolean') {
            newChar = c + newChar;
          } else if (a.append) {
            newChar = String.fromCodePoint.apply(null, a.append) + newChar;
          }

          if (a.prepend) {
            newChar += c;
          }

          if (repeat_count > 1 && i + 1 - repeat_count >= 0) {
            const repeated_items = string_array.slice(i + 1 - repeat_count, i + 1);
            ok = repeated_items.every((value, ai, array) => value === array[0]);
          }

          return ok ? newChar : c;
        }

        return c;
      })
      .join('');
  }

  return s;
}

function reverse(s: string, a: ReverseAction) {
  let ok = true;
  if (a.if_in_map) {
    ok = _in_map(s, a.if_in_map);
  }

  if (ok) {
    return Array.from(s).reverse().join('');
  }

  return s;
}

function replace_all(s: string, a: ReplaceAllAction) {
  return s.replace(/(\S)/g, String.fromCodePoint(a.char));
}

function wrap(s: string, a: WrapAction) {
  const split_char = String.fromCodePoint(a.split_char);
  const wrap_char = String.fromCodePoint.apply(null, a.wrap_char);
  return s
    .split(split_char)
    .map((w) => wrap_char + w + wrap_char)
    .join(split_char);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function normalize(s: string, _a: NormalizeAction) {
  if (!String.prototype.normalize) {
    return s;
  }

  return s.normalize('NFD');
}

function strip_diacritics(s: string) {
  return s.replace(/[\u0300-\u036F]/g, '');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function stack(s: string, _a: StackAction) {
  return Stacker.make(s);
}

function unstack(s: string) {
  return Stacker.unstack(s);
}

function remove_extra_spaces(s: string) {
  return s.replace(/ +(?= )/g, '');
}
