/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {forwardRef} from 'react';

// Custom styles for SoftSocialButton
import SoftSocialButtonRoot, {SoftSocialButtonRootProps} from './SoftSocialButtonRoot';

type SoftSocialButtonProps = Omit<SoftSocialButtonRootProps, 'ownerState' | 'color'> &
  SoftSocialButtonRootProps['ownerState'];
const SoftSocialButton = forwardRef<HTMLButtonElement, SoftSocialButtonProps>(
  ({color = 'facebook', size = 'medium', iconOnly = false, circular = false, children, ...rest}, ref) => (
    <SoftSocialButtonRoot
      {...rest}
      ref={ref}
      variant="contained"
      color="primary"
      size={size}
      ownerState={{color, size, iconOnly, circular}}>
      {children}
    </SoftSocialButtonRoot>
  ),
);

export default SoftSocialButton;
