import {Checkbox, CircularProgress, FormControlLabel} from '@mui/material';
import {SignUpUserCommand} from '@perfectpost/perfect-post-common';
import {ConsoleLogger} from 'aws-amplify/utils';
import {Crisp} from 'crisp-sdk-web';
import React, {SyntheticEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useOutletContext, Link, useLocation} from 'react-router-dom';
import {crispService, mixPanelService, perfectPostServiceClient} from 'src/services';
import {useAppThunkDispatch} from 'src/store';
import {logout} from 'src/store/mainslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
// import pattern from 'src/assets/svg/pattern-lines.svg';

const logger = new ConsoleLogger('Signup');

type SignupState = {
  state: 'idle' | 'loading' | 'error' | 'emailsent';
  email: string;
  cguchecked?: boolean;
  error?: {message: string};
};

const isErrorMessage = (obj: unknown): obj is {message: string} => {
  const isObject = (obj: unknown): obj is Partial<Record<'message', unknown>> =>
    typeof obj === 'object' && obj !== null;
  return isObject(obj) && 'message' in obj && typeof obj.message === 'string';
};

export default function Signup() {
  const location = useLocation();
  const {lang} = useOutletContext<{lang: 'fr' | 'en' | 'es'}>();

  const [signupState, setSignupState] = useState<SignupState>({
    state: 'idle',
    cguchecked: false,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    email: location.state?.email ?? '',
  });
  const {state, email, cguchecked, error} = signupState;

  const {t} = useTranslation();

  const dispatch = useAppThunkDispatch();
  useDocumentTitle(t('signup.documenttitle', {defaultValue: 'Signup'}));

  const onSignup = async () => {
    if (cguchecked === false) {
      return;
    }
    try {
      await dispatch(logout());
    } catch (e) {
      logger.error(e);
    }
    setSignupState((state) => ({...state, state: 'loading', error: undefined}));
    try {
      await perfectPostServiceClient.send(new SignUpUserCommand({email, locale: lang}));
      setSignupState((state) => ({...state, state: 'emailsent'}));
      mixPanelService.tackSignup();
    } catch (error) {
      if (isErrorMessage(error)) {
        const e = error;
        setSignupState((state) => ({...state, state: 'error', error: e}));
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        setSignupState((state) => ({...state, state: 'error', error: {message: `Unknown error: ${error}`}}));
      }
    }
  };

  const onEmailChange = (email: string) => {
    setSignupState((state) => ({...state, state: 'idle', email: email.toLowerCase(), error: undefined}));
  };

  return (
    <>
      <SoftBox pt={3} px={3}>
        {state === 'emailsent' ? (
          <>
            <SoftBox mb={1}>
              <SoftTypography variant="h4" fontWeight="bold">
                {t('signin.login.justsignup.default', {defaultValue: 'Just one final step! 😇'})}
              </SoftTypography>
            </SoftBox>
          </>
        ) : (
          <>
            <SoftBox mb={1}>
              <SoftTypography variant="h4" fontWeight="bold">
                {t('signin.signup.title', {defaultValue: '🥳 I want to join PerfectPost!'})}
              </SoftTypography>
            </SoftBox>
            <SoftTypography variant="body2" fontWeight="regular" color="text">
              {t('signin.signup.subtitle', {
                defaultValue: 'Enter your email address, you will receive a temporary password by email',
              })}
            </SoftTypography>
          </>
        )}
      </SoftBox>
      <SoftBox p={3}>
        <SoftBox
          component="form"
          role="form"
          onSubmit={(event: SyntheticEvent<HTMLFormElement>) => event.preventDefault()}>
          <SoftBox mt={3}>
            {state === 'emailsent' ? (
              <>
                <SoftTypography variant="body2" fontWeight="bold">
                  {t('signin.login.temppasswordsent', {
                    defaultValue: 'Check your email inbox, you have received a link to complete your registration.',
                    email: email,
                  })}
                </SoftTypography>
                <SoftButton
                  sx={{mt: 2, px: 0}}
                  size="small"
                  variant="text"
                  color="dark"
                  onClick={() => {
                    crispService.openChat();
                    Crisp.message.showText(
                      t('signin.login.justsignup.asksupportmsg', {
                        defaultValue: "Vous êtes sur que c'est pas un spam ?",
                      }),
                    );
                  }}>
                  {t('signin.login.justsignup.asksupport', {defaultValue: "Je n'ai pas reçu d'email"})}
                </SoftButton>
              </>
            ) : (
              <>
                <SoftBox mb={2}>
                  <SoftInput
                    type="email"
                    autoComplete="email"
                    required
                    placeholder={t('form.email.placeholder', {defaultValue: 'E-mail'})}
                    size="large"
                    autoFocus
                    value={email}
                    onChange={(event) => {
                      onEmailChange(event.target.value);
                    }}
                    error={error !== undefined}
                  />
                  {error?.message !== undefined && (
                    <SoftTypography variant="body2" fontWeight="bold" color="error">
                      {error.message}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2} px={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cguchecked}
                        onChange={(e, c) => setSignupState((p) => ({...p, cguchecked: c}))}
                      />
                    }
                    label={<span dangerouslySetInnerHTML={{__html: t('signup.form.acceptcgs')}}></span>}
                  />
                </SoftBox>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color={(state === 'loading' || cguchecked) === false ? 'white' : 'info'}
                    size="large"
                    fullWidth
                    disabled={(state === 'loading' || cguchecked) === false}
                    onClick={onSignup}>
                    {state === 'loading' ? (
                      <CircularProgress />
                    ) : (
                      <span>{t('signin.form.signupbtn', {defaultValue: 'Start using PerfectPost'})}</span>
                    )}
                  </SoftButton>
                </SoftBox>
                <SoftTypography variant="body2" fontWeight="regular" color="text">
                  {t('signin.signup.ctalogin', {defaultValue: 'Already have an account?'})}{' '}
                  {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore */}
                  <SoftButton component={Link} to="/signin" state={{email}} variant="text" color="info" size="large">
                    {t('signin.login.signbtn', {defaultValue: 'sign in'})}
                  </SoftButton>
                </SoftTypography>
              </>
            )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
}
