/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import createTheme from '@mui/material/styles/createTheme';
// import Fade from "@mui/material/Fade";
// Soft UI Dashboard PRO React base styles
import borders from 'src/theme/theme/base/borders';
import boxShadows from 'src/theme/theme/base/boxShadows';
import breakpoints from 'src/theme/theme/base/breakpoints';
import colors from 'src/theme/theme/base/colors';
import globals from 'src/theme/theme/base/globals';
import typography from 'src/theme/theme/base/typography';
// Soft UI Dashboard PRO React helper functions
import appBar from 'src/theme/theme/components/appBar';
import avatar from 'src/theme/theme/components/avatar';
import breadcrumbs from 'src/theme/theme/components/breadcrumbs';
import button from 'src/theme/theme/components/button';
import buttonBase from 'src/theme/theme/components/buttonBase';
import card from 'src/theme/theme/components/card';
import cardContent from 'src/theme/theme/components/card/cardContent';
import cardMedia from 'src/theme/theme/components/card/cardMedia';
import container from 'src/theme/theme/components/container';
import dialog from 'src/theme/theme/components/dialog';
import dialogActions from 'src/theme/theme/components/dialog/dialogActions';
import dialogContent from 'src/theme/theme/components/dialog/dialogContent';
import dialogContentText from 'src/theme/theme/components/dialog/dialogContentText';
import dialogTitle from 'src/theme/theme/components/dialog/dialogTitle';
import divider from 'src/theme/theme/components/divider';
import flatpickr from 'src/theme/theme/components/flatpickr';
import autocomplete from 'src/theme/theme/components/form/autocomplete';
import checkbox from 'src/theme/theme/components/form/checkbox';
import formControlLabel from 'src/theme/theme/components/form/formControlLabel';
import formLabel from 'src/theme/theme/components/form/formLabel';
import input from 'src/theme/theme/components/form/input';
import inputBase from 'src/theme/theme/components/form/inputBase';
import radio from 'src/theme/theme/components/form/radio';
import select from 'src/theme/theme/components/form/select';
import switchButton from 'src/theme/theme/components/form/switchButton';
import icon from 'src/theme/theme/components/icon';
import iconButton from 'src/theme/theme/components/iconButton';
import linearProgress from 'src/theme/theme/components/linearProgress';
import link from 'src/theme/theme/components/link';
import list from 'src/theme/theme/components/list';
import listItem from 'src/theme/theme/components/list/listItem';
import listItemText from 'src/theme/theme/components/list/listItemText';
import menu from 'src/theme/theme/components/menu';
import menuItem from 'src/theme/theme/components/menu/menuItem';
import popover from 'src/theme/theme/components/popover';
import sidenav from 'src/theme/theme/components/sidenav';
import slider from 'src/theme/theme/components/slider';
import stepper from 'src/theme/theme/components/stepper';
import step from 'src/theme/theme/components/stepper/step';
import stepConnector from 'src/theme/theme/components/stepper/stepConnector';
import stepIcon from 'src/theme/theme/components/stepper/stepIcon';
import stepLabel from 'src/theme/theme/components/stepper/stepLabel';
import svgIcon from 'src/theme/theme/components/svgIcon';
import swal from 'src/theme/theme/components/swal';
import tableCell from 'src/theme/theme/components/table/tableCell';
import tableContainer from 'src/theme/theme/components/table/tableContainer';
import tableHead from 'src/theme/theme/components/table/tableHead';
import tabs from 'src/theme/theme/components/tabs';
import tab from 'src/theme/theme/components/tabs/tab';
import tooltip from 'src/theme/theme/components/tooltip';
import boxShadow from 'src/theme/theme/functions/boxShadow';
import hexToRgb from 'src/theme/theme/functions/hexToRgb';
import linearGradient from 'src/theme/theme/functions/linearGradient';
import pxToRem from 'src/theme/theme/functions/pxToRem';
import rgba from 'src/theme/theme/functions/rgba';

export default createTheme({
  breakpoints: {...breakpoints},
  palette: {...colors},
  typography: {...typography},
  boxShadows: {...boxShadows},
  borders: {...borders},
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...flatpickr,
        ...swal,
        ...container,
      },
    },
    MuiDrawer: {...sidenav},
    MuiList: {...list},
    MuiListItem: {...listItem},
    MuiListItemText: {...listItemText},
    MuiCard: {...card},
    MuiCardMedia: {...cardMedia},
    MuiCardContent: {...cardContent},
    MuiButton: {...button},
    MuiIconButton: {...iconButton},
    MuiInputBase: {...inputBase},
    MuiMenu: {...menu},
    MuiMenuItem: {...menuItem},
    MuiSwitch: {...switchButton},
    MuiDivider: {...divider},
    MuiTableContainer: {...tableContainer},
    MuiTableHead: {...tableHead},
    MuiTableCell: {...tableCell},
    MuiLinearProgress: {...linearProgress},
    MuiBreadcrumbs: {...breadcrumbs},
    MuiSlider: {...slider},
    MuiAvatar: {...avatar},
    MuiTooltip: {...tooltip},
    MuiAppBar: {...appBar},
    MuiTabs: {...tabs},
    MuiTab: {...tab},
    MuiStepper: {...stepper},
    MuiStep: {...step},
    MuiStepConnector: {...stepConnector},
    MuiStepLabel: {...stepLabel},
    MuiStepIcon: {...stepIcon},
    MuiSelect: {...select},
    MuiFormControlLabel: {...formControlLabel},
    MuiFormLabel: {...formLabel},
    MuiCheckbox: {...checkbox},
    MuiRadio: {...radio},
    MuiAutocomplete: {...autocomplete},
    MuiInput: {...input},
    MuiOutlinedInput: {...input},
    MuiFilledInput: {...input},
    MuiPopover: {...popover},
    MuiButtonBase: {...buttonBase},
    MuiIcon: {...icon},
    MuiSvgIcon: {...svgIcon},
    MuiLink: {...link},
    MuiDialog: {...dialog},
    MuiDialogTitle: {...dialogTitle},
    MuiDialogContent: {...dialogContent},
    MuiDialogContentText: {...dialogContentText},
    MuiDialogActions: {...dialogActions},
  },
});
