import * as Sentry from '@sentry/react';
import {ConsoleLogger, Hub} from 'aws-amplify/utils';
import {SnackbarProvider} from 'notistack';
import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';

import EstablishingConnection from './components/snackbars/EstablishingConnection';
import NoLinkedInConnection from './components/snackbars/NoLinkedInConnection';
import WorkingCommand from './components/snackbars/WorkingCommand';
import LoginRequiredRoute from './LoginRequiredRoute';
import {useAppThunkDispatch} from './store';
import {startConnecting} from './store/extension/slice';
import Activity from './views/analyse/Activity';
import Content from './views/analyse/Content';
import OneContent from './views/analyse/OneContent';
import Profile from './views/analyse/Profile';
import Statistics from './views/analyse/Statistics';
import Authentication from './views/authentication/Authentication';
import Confirm from './views/authentication/Confirm';
import Reset from './views/authentication/Reset';
import Signin from './views/authentication/Signin';
import Signup from './views/authentication/Signup';
import Dashboard from './views/Dashboard';
import DraftSharing from './views/DraftSharing';
import Feed from './views/Feed';
import LinkedInAuth from './views/LinkedInAuth';
import Guides from './views/progress/Guides';
import Hook from './views/progress/Hook';
import Inspiration from './views/progress/Inspiration';
import Calendar from './views/publish/Calendar';
import Draft from './views/publish/Draft';
import Kanban from './views/publish/Kanban';
import SharedDraft from './views/publish/SharedDraft';
import SharedWihMe from './views/publish/SharedWithMe';
import Settings from './views/settings/Settings';
import StripeSuccess from './views/settings/StripeSuccess';
import Subscription from './views/settings/Subscription';
import TeamCalendar from './views/team/TeamCalendar';
import TeamContent from './views/team/TeamContent';
import TeamKanban from './views/team/TeamKanban';
import TeamSettings from './views/team/TeamSettings';
import TeamSettingsPrivacy from './views/team/TeamSettingsPrivacy';
import TeamSettingsSubscription from './views/team/TeamSettingsSubscription';
import TeamSettingsTeam from './views/team/TeamSettingsTeam';
import TeamStatistics from './views/team/TeamStatistics';
import TeamStripeSuccess from './views/team/TeamStripeSuccess';
import TeamTips from './views/team/TeamTips';
import TeamCreate from './views/TeamCreate';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const logger = new ConsoleLogger('App');

export default function App() {
  const dispatch = useAppThunkDispatch();
  useEffect(() => {
    dispatch(startConnecting());
    const callback = Hub.listen('auth', ({payload}) => {
      switch (payload.event) {
        case 'signedIn':
          console.log('user have been signedIn successfully.');
          break;
        case 'signedOut':
          console.log('user have been signedOut successfully.');
          break;
        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.');
          break;
        case 'tokenRefresh_failure':
          console.log('failure while refreshing auth tokens.');
          break;
        case 'signInWithRedirect':
          console.log('signInWithRedirect API has successfully been resolved.');
          break;
        case 'signInWithRedirect_failure':
          console.log('failure while trying to resolve signInWithRedirect API.');
          break;
        case 'customOAuthState':
          logger.info('custom state returned from CognitoHosted UI');
          break;
      }
    });
    return () => {
      callback();
    };
  }, []);

  return (
    <SnackbarProvider
      maxSnack={5}
      Components={{
        establishingConnection: EstablishingConnection,
        workingCommand: WorkingCommand,
        noLinkedInConnection: NoLinkedInConnection,
      }}>
      <SentryRoutes>
        <Route path="/drafts/:id/sharing" element={<DraftSharing />} />
        <Route element={<Authentication />}>
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
        </Route>
        <Route path="auth/linkedin" element={<LinkedInAuth />} />
        <Route path="teams/create" element={<TeamCreate />} />
        <Route element={<LoginRequiredRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="publish">
            <Route index path="draft" element={<Kanban />} />
            <Route path="draft/new" element={<Draft />} />
            <Route path="draft/:id" element={<Draft />} />
            <Route path="queue" element={<Kanban />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="shared" element={<SharedWihMe />} />
            <Route path="shared/:id" element={<SharedDraft />} />
          </Route>
          <Route path="analyse">
            <Route index path="activity" element={<Activity />} />
            <Route path="content" element={<Content />} />
            <Route path="content/:id" element={<OneContent />} />
            <Route path="profile" element={<Profile />} />
            <Route path="statistics" element={<Statistics />} />
          </Route>
          <Route path="progress">
            <Route index path="guides" element={<Guides />} />
            <Route path="success" />
            <Route path="template" />
            <Route path="inspiration" element={<Inspiration />} />
            <Route path="hook" element={<Hook />} />
          </Route>
          <Route path="team">
            <Route path=":id/kanban" element={<TeamKanban />} />
            <Route path=":id/calendar" element={<TeamCalendar />} />
            <Route path=":id/content" element={<TeamContent />} />
            <Route path=":id/tips" element={<TeamTips />} />
            <Route path=":id/settings" element={<TeamSettings />} />
            <Route path=":id/settings/privacy" element={<TeamSettingsPrivacy />} />
            <Route path=":id/settings/team" element={<TeamSettingsTeam />} />
            <Route path=":id/settings/subscription" element={<TeamSettingsSubscription />} />
            <Route path=":id/statistics" element={<TeamStatistics />} />
            <Route path=":id/stripe-success" element={<TeamStripeSuccess />} />
          </Route>
          <Route path="feed" element={<Feed />} />
          <Route path="settings/subscription" element={<Subscription />} />
          <Route path="settings" element={<Settings />} />
          <Route path="stripe-success" element={<StripeSuccess />} />
        </Route>
      </SentryRoutes>
    </SnackbarProvider>
  );
}
