import {Grid, Icon, Link} from '@mui/material';
import {GetListCommand, List as ListModel} from '@perfectpost/perfect-post-common';
import React, {useEffect, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import borders from 'src/theme/theme/base/borders';
import colors from 'src/theme/theme/base/colors';

function ItemList({list}: {list: ListModel}) {
  const {t} = useTranslation();
  const link = useMemo(() => {
    const url = new URL('https://www.linkedin.com/search/results/content/');
    url.searchParams.set('origin', 'FACETED_SEARCH');
    url.searchParams.set('sortBy', '"date_posted"');
    if (list.members.length > 0) {
      url.searchParams.set('fromMember', `["${list.members.map((member) => member.urn).join('","')}"]`);
    }
    if (list.organizations.length > 0) {
      url.searchParams.set('fromOrganization', `["${list.organizations.map((member) => member.urn).join('","')}"]`);
    }
    return url;
  }, [list]);
  return (
    <SoftBox sx={{pt: 1, pr: 2, display: 'block'}} component={Link} href={link} target="_blank">
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          lineHeight: 0,
          transition: 'all 0.2s cubic-bezier(.34,1.61,.7,1.3)',
          p: 0.5,
          '&:hover, &:focus': {
            transform: 'translateX(5px)',
            backgroundColor: 'background.paper',
            borderRadius: 1,
            cursor: 'pointer',
          },
        }}>
        <SoftBox display="flex" alignItems="center">
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium" gutterBottom>
              {list.name}
            </SoftTypography>
            <SoftTypography variant="caption" color="text">
              {t('feed.members', {count: list.members.length + list.organizations.length})}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftTypography variant="button" color="dark" fontWeight="medium" textGradient>
          <Icon sx={{fontWeight: 'bold'}}>chevron_right</Icon>
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

export default function Feed() {
  const {t} = useTranslation();

  const locale = useAppSelector((state) => state.main.user?.locale);
  const [lists, setLists] = useState<ListModel[]>([]);
  const {borderWidth} = borders;
  const {secondary} = colors;

  useEffect(() => {
    perfectPostServiceClient.send(new GetListCommand()).then((response) => {
      setLists(response);
    });
  }, []);

  return (
    <SoftBox>
      <SoftTypography mb={2} variant="h2" textTransform="none" fontWeight="bold">
        {t('feed.title', {defaultValue: 'Your custom feeds'})}
      </SoftTypography>
      {lists.length === 0 && (
        <SoftBox
          display="flex"
          flexDirection="row"
          borderRadius="md"
          border={`${borderWidth[1]} dashed ${secondary.main}`}
          p={2}>
          <SoftButton
            sx={{mr: 2, height: 24, minHeight: 24, width: 24, minWidth: 24}}
            variant="outlined"
            color="secondary"
            iconOnly
            circular>
            <Icon sx={{cursor: 'pointer'}}>priority_high</Icon>
          </SoftButton>
          <SoftBox>
            <SoftTypography variant="text">
              {t('feed.empty', {
                defaultValue:
                  "Vous n'avez pas encore de fils personnalisés. Créez-en une pour commencer depuis linkedin",
              })}
            </SoftTypography>
            <br />
            <SoftButton
              color="primary"
              variant="text"
              component={Link}
              href={
                locale === 'fr'
                  ? 'https://help.perfectpost.fr/fr/article/comment-fonctionnent-les-fils-dactualite-personnalises-vt6z68/'
                  : locale === 'es'
                    ? 'https://help.perfectpost.fr/es/article/como-funcionan-los-feeds-de-noticias-personalizados-1a8fs5/'
                    : 'https://help.perfectpost.fr/en/article/how-custom-news-feeds-work-1xn9auw/'
              }>
              {t('feed.seedoc', {defaultValue: 'Voir la documentation pour plus d’informations'})}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {lists.map((list) => (
            <ItemList key={list._id} list={list} />
          ))}
        </Grid>
      </Grid>
    </SoftBox>
  );
}
