/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {forwardRef} from 'react';
import {useSoftUIController} from 'src/theme/context';

import SoftInputIconBoxRoot from './SoftInputIconBoxRoot';
import SoftInputIconRoot from './SoftInputIconRoot';
import SoftInputRoot, {SoftInputRootProps} from './SoftInputRoot';
import SoftInputWithIconRoot from './SoftInputWithIconRoot';

// Soft UI Dashboard PRO React contexts

export type SoftInputProps = Omit<SoftInputRootProps, 'size' | 'ownerState'> & {
  size?: 'small' | 'medium' | 'large';
  icon?: {
    component?: React.ReactNode | false;
    direction?: 'none' | 'left' | 'right';
    onClick?: () => void;
  };
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
};
const SoftInput = forwardRef<HTMLDivElement, SoftInputProps>(
  (
    {
      size = 'medium',
      icon = {
        component: false,
        direction: 'none',
      },
      error = false,
      success = false,
      disabled = false,
      ...rest
    },
    ref,
  ) => {
    let template;
    const [controller] = useSoftUIController();
    const {direction} = controller;
    const iconDirection = icon?.direction;

    if (icon?.component && icon.direction === 'left') {
      template = (
        <SoftInputWithIconRoot ref={ref} ownerState={{error, success, disabled}}>
          <SoftInputIconBoxRoot ownerState={{size}}>
            <SoftInputIconRoot fontSize="small" ownerState={{size}} onClick={icon?.onClick}>
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
          <SoftInputRoot
            {...rest}
            ownerState={{size, error, success, iconDirection: iconDirection || undefined, direction, disabled}}
          />
        </SoftInputWithIconRoot>
      );
    } else if (icon?.component && icon.direction === 'right') {
      template = (
        <SoftInputWithIconRoot ref={ref} ownerState={{error, success, disabled}}>
          <SoftInputRoot
            {...rest}
            ownerState={{size, error, success, iconDirection: iconDirection || undefined, direction, disabled}}
          />
          <SoftInputIconBoxRoot ownerState={{size}}>
            <SoftInputIconRoot fontSize="small" ownerState={{size}} onClick={icon?.onClick}>
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
        </SoftInputWithIconRoot>
      );
    } else {
      template = <SoftInputRoot {...rest} ref={ref} ownerState={{size, error, success, disabled}} />;
    }

    return template;
  },
);

export default SoftInput;
