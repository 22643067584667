/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactTagInput, {ReactTagInputProps} from '@pathofdev/react-tag-input';
import React, {forwardRef} from 'react';

// react-tag-input components
import '@pathofdev/react-tag-input/build/index.css';

// Custom styles for SoftTagInput
import SoftTagInputRoot, {SoftTagInputRootProps} from './SoftTagInputRoot';

const SoftTagInput = forwardRef<
  ReactTagInput,
  ReactTagInputProps & Omit<SoftTagInputRootProps, 'ownerState'> & SoftTagInputRootProps['ownerState']
>(({size = 'medium', error = false, success = false, ...rest}, ref) => (
  <SoftTagInputRoot ownerState={{size, error, success}}>
    <ReactTagInput {...rest} ref={ref} />
  </SoftTagInputRoot>
));

export default SoftTagInput;
