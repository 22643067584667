/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Icon from '@mui/material/Icon';
import React from 'react';
import SoftBox from 'src/theme/components/SoftBox';
import borders from 'src/theme/theme/base/borders';
import colors from 'src/theme/theme/base/colors';

type DataTableHeadCellProps = {
  width?: string | number;
  children: React.ReactNode;
  sorted: false | 'none' | 'asce' | 'desc';
  align: 'left' | 'right' | 'center';
};

function DataTableHeadCell({
  width = 'auto',
  children,
  sorted = 'none',
  align = 'left',
  ...rest
}: DataTableHeadCellProps) {
  const {light} = colors;
  const {borderWidth} = borders;

  return (
    <SoftBox component="th" width={width} borderBottom={`${borderWidth[1]} solid ${light.main}`} p={1.5}>
      <SoftBox
        {...rest}
        position="relative"
        textAlign={align}
        color="secondary"
        opacity={0.7}
        sx={({typography}) => ({
          fontSize: typography.size.xxs,
          fontWeight: typography.fontWeightBold ?? 'inherit',
          cursor: sorted ? 'pointer' : 'inherit',
          userSelect: sorted ? 'none' : 'inherit',
        })}>
        {children}
        {sorted && (
          <SoftBox
            position="absolute"
            top={0}
            right={align !== 'right' ? '16px' : 0}
            left={align === 'right' ? '-5px' : 'unset'}
            sx={({typography: {size}}) => ({
              fontSize: size.lg,
            })}>
            <SoftBox
              position="absolute"
              top={-6}
              color={sorted === 'asce' ? 'text' : 'secondary'}
              opacity={sorted === 'asce' ? 1 : 0.5}>
              <Icon>arrow_drop_up</Icon>
            </SoftBox>
            <SoftBox
              position="absolute"
              top={0}
              color={sorted === 'desc' ? 'text' : 'secondary'}
              opacity={sorted === 'desc' ? 1 : 0.5}>
              <Icon>arrow_drop_down</Icon>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    </SoftBox>
  );
}

export default DataTableHeadCell;
