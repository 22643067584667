import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Box, ListItem, IconButton, Card, CardContent, CardHeader, Menu, MenuItem, Avatar} from '@mui/material';
import Icon from '@mui/material/Icon';
import type {Draft} from '@perfectpost/perfect-post-common';
import type {PublicUser} from '@perfectpost/perfect-post-common';
import {transformHtmlPostToText} from '@perfectpost/perfect-post-common';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Draggable, DraggableProvided} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from 'src/store';
import SoftBadge from 'src/theme/components/SoftBadge';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';
import {localFormatISOString} from 'src/utils/date-format';

type Reader = Pick<PublicUser, 'lnPicture' | 'firstname'>;

type PostCardsProps = {
  index: number;
  draft: Draft;
  selectMode?: boolean;
  selected?: boolean;
  onSelect?: () => void;
  onRemove?: () => void;
  onClone?: () => void;
  noDrag?: boolean;
};
export default function PostCards(props: PostCardsProps) {
  const {draft, selectMode, selected, index, onSelect, onRemove, onClone, noDrag} = props;
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  const me = useAppSelector((state) => state.main.user);
  const organizations = useAppSelector((state) => state.main.organizations);
  const teams = useAppSelector((state) => state.team.teams);
  const members = useAppSelector((state) => state.team.members);

  const author = useMemo(() => members.find((member) => member._id === draft.user) ?? me, [me, members, draft.user]);
  const characterCount = useMemo(() => transformHtmlPostToText(draft.content).length, [draft]);

  const {t} = useTranslation();
  const [readers, setReaders] = useState<Reader[]>([]);

  let canWrite: boolean =
    draft.proofreaders?.some((p) => me && p.user?._id === me._id && p.role === 'readwrite') || draft.user === me?._id;

  if (canWrite === false) {
    canWrite = draft.proofreaders?.some((p) => p.role === 'readwrite' && teams.some((t) => t._id === p.team)) ?? false;
  }
  if (canWrite === false) {
    canWrite = teams.some((t) =>
      t.users.some(
        (u) =>
          u.user === draft.user &&
          u.privacy &&
          (u.privacy.draft === 'write' ||
            (u.privacy.scheduled === 'write' && draft.programmingDate !== undefined && draft.urn === undefined)),
      ),
    );
  }

  useEffect(() => {
    const readers =
      draft.proofreaders
        ?.map((proofreader) => {
          if (proofreader.team) {
            const team = teams.find((team) => team._id === proofreader.team);
            const user: Reader = {
              lnPicture: team?.picture,
              firstname: team?.name,
            };
            return user;
          } else if (proofreader.user) {
            return proofreader.user;
          } else {
            return undefined;
          }
        })
        .filter((user): user is Reader => user !== undefined) ?? [];
    setReaders(readers);
  }, [draft.proofreaders]);

  const itemRendered = (noDrag: boolean | undefined, provided?: DraggableProvided) => (
    <ListItem
      sx={{py: 1, px: 0}}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      ref={provided?.innerRef}
      disablePadding>
      <Card
        sx={{
          ...styles.card,
          cursor: noDrag ? '' : 'pointer',
          backgroundColor: selectMode === true && selected === false ? '#EEE' : 'white',
        }}
        onClick={onSelect}>
        <CardHeader
          sx={{px: 0, pt: 0}}
          avatar={
            selectMode === true ? (
              <SoftBox
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 40,
                  height: 40,
                }}>
                <SoftBox
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid',
                    borderColor: selected ? 'primary.main' : 'transparent',
                  }}>
                  {selected && <Icon sx={{fontSize: 12, color: 'primary.main'}}>done</Icon>}
                </SoftBox>
              </SoftBox>
            ) : (
              <Avatar
                src={
                  draft.organization
                    ? organizations.find((org) => org._id === draft.organization)?.picture
                    : author?.lnPicture
                }
              />
            )
          }
          title={
            <SoftBox sx={{ml: 1, lineHeight: 0, display: 'flex', flexDirection: 'column'}}>
              <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize" color="dark">
                {draft.organization
                  ? organizations.find((org) => org._id === draft.organization)?.localizedName
                  : `${author?.firstname} ${author?.lastname}`}
              </SoftTypography>
              {draft.categories && (
                <SoftBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                    flexWrap: 'wrap',
                    maxWidth: 'calc(100% - 40px)',
                  }}>
                  {draft.categories.length > 0 && (
                    <SoftBadge badgeContent={draft.categories[0]} container size="sm" variant="gradient" />
                  )}
                  {draft.categories.length > 1 && (
                    <SoftBadge badgeContent={draft.categories[1]} container size="sm" variant="gradient" />
                  )}
                  {draft.categories.length > 2 && (
                    <SoftBadge
                      badgeContent={`+${draft.categories.length - 2}`}
                      container
                      size="sm"
                      variant="gradient"
                    />
                  )}
                </SoftBox>
              )}
            </SoftBox>
          }
          action={
            <Box sx={{height: 40, display: 'flex', flexDirection: 'row', maxWidth: '300px', alignItems: 'center'}}>
              {selectMode !== true && (
                <IconButton
                  ref={anchorEl}
                  disableRipple
                  sx={{ml: 1}}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setOpen(true);
                  }}>
                  <MoreHorizIcon />
                </IconButton>
              )}
            </Box>
          }
        />
        {(onRemove || onClone) && (
          <Menu
            id="lock-menu"
            anchorEl={anchorEl.current}
            open={open}
            onClose={() => setOpen(false)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}>
            {canWrite && onRemove && (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onRemove();
                }}>
                {t('postcard.menulist.delete', {defaultValue: 'Delete'})}
              </MenuItem>
            )}
            {onClone && (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onClone();
                  setOpen(false);
                }}>
                {t('postcard.menulist.clone', {defaultValue: 'Clone'})}
              </MenuItem>
            )}
          </Menu>
        )}
        <Box sx={{height: 78, overflow: 'hidden', mb: 1}}>
          <CardContent
            sx={{
              p: '0!important',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}>
            {draft.sharing !== undefined && draft.sharing.type !== 'images' && (
              <SoftBox sx={{maxWidth: 48, position: 'relative'}}>
                {(draft.sharing?.type === 'image' || draft.sharing?.type === 'video') && (
                  <SoftBox
                    component={
                      draft.sharing?.type === 'image' ? 'img' : draft.sharing?.type === 'video' ? 'video' : undefined
                    }
                    src={draft.sharing.thumbnail}
                    sx={(theme) => ({
                      width: 48,
                      aspectRatio: '1/1',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.grey[200],
                    })}
                  />
                )}
                {draft.sharing && (
                  <IconButton sx={{position: 'absolute', top: '5px', left: '5px'}}>
                    <>
                      {draft.sharing.type === 'video' && <Icon sx={{color: 'white !important'}}>play_circle</Icon>}
                      {draft.sharing.type === 'pdf' && <Icon>picture_as_pdf</Icon>}
                      {draft.sharing.type === 'poll' && <Icon>equalizer</Icon>}
                    </>
                  </IconButton>
                )}
                {draft.linkedin?.image && draft.sharing === undefined && (
                  <IconButton sx={{position: 'absolute', top: '5px', left: '5px'}}>
                    <>
                      {draft.linkedin.video && <Icon sx={{color: 'white !important'}}>play_circle</Icon>}
                      {draft.linkedin.carrousel && <Icon>picture_as_pdf</Icon>}
                    </>
                  </IconButton>
                )}
              </SoftBox>
            )}
            <Box
              sx={{...styles.content, ...(draft.sharing || draft.linkedin ? styles.withSharingContent : {})}}
              dangerouslySetInnerHTML={{__html: draft.content}}
            />
          </CardContent>
        </Box>
        <SoftBox display="flex" flexDirection="row" justifyContent="space-between">
          {characterCount > 3000 && (
            <SoftTypography color="error" variant="caption" sx={{display: 'flex', alignItems: 'center'}}>
              <Icon sx={{mr: 1}}>warning</Icon>
              {t('postcard.charactercount', {
                count: characterCount,
                defaultValue: 'Your post contains {{count}} characters',
              })}
            </SoftTypography>
          )}
          {draft.programmingDate ? (
            <SoftTypography variant="caption" sx={{display: 'flex', alignItems: 'center'}}>
              {draft.linkedin && <LinkedInIcon sx={{mr: 1}} />}
              <AccessAlarmIcon sx={{mr: 1}} />
              {t('postcard.scheduledat', {
                date: localFormatISOString(draft.programmingDate, 'dd/MM/yyyy HH:mm', me?.locale),
                defaultValue: 'Publication scheduled for {{date}}',
              })}
            </SoftTypography>
          ) : (
            <SoftBox />
          )}
          {selectMode !== true && readers.length > 0 && (
            <SoftBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mt: 1}}>
              {readers.map((reader, index) => (
                <Avatar key={index} src={reader.lnPicture} sx={{width: '32px', height: '32px'}}>
                  {reader.firstname?.[0]}
                </Avatar>
              ))}
            </SoftBox>
          )}
        </SoftBox>
      </Card>
    </ListItem>
  );

  return noDrag ? (
    itemRendered(noDrag)
  ) : (
    <Draggable draggableId={draft._id} index={index} isDragDisabled={draft.linkedin !== undefined}>
      {(provided) => itemRendered(noDrag, provided)}
    </Draggable>
  );
}

const styles = {
  card: {
    width: 390,
    minHeight: 75,
    fontSize: 14,
    lineHeight: 20,
    padding: 2,
  },
  withSharingContent: {
    width: 'calc(100% - 55px)',
  },
  content: {
    color: 'rgba(0,0,0,0.9);',
    fontFamily: 'Lato, sans-serif !important',
    font: 'inherit',
    fontSize: '14px',
    fontWeight: '400',
    textDecoration: 'none',
    border: '0',
    //fontSize: '100%',
    verticalAlign: 'baseline',
    background: 'transparent',
    boxSizing: 'border-box',
    lineHeight: '1.42',
    outline: '0',
    tabSize: '4',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    minHeight: '96px',
    wordBreak: 'break-word',
    position: 'relative',
    zIndex: '0',
    '& > p': {
      margin: '0!important',
    },
    overflow: 'hidden',
  },
  sharingContentHeight: {
    height: 59,
    maxHeight: 59,
    minHeight: 59,
  },
};
