import Quill from 'quill';

import {transform} from './transform';

export default function bold(_quill: Quill | boolean) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const quill: Quill = typeof _quill === 'boolean' ? (this.quill as Quill) : _quill;
  transform(quill, [
    {
      action: 'normalize',
    },
    {
      action: 'shift_code_point',
      range: [65, 90],
      add: 120_211,
    },
    {
      action: 'shift_code_point',
      range: [97, 122],
      add: 120_205,
    },
    {
      action: 'shift_code_point',
      range: [48, 57],
      add: 120_764,
    },
  ]);
}
