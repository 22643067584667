/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import SoftBox from 'src/theme/components/SoftBox';
import {useSoftUIController} from 'src/theme/context';

import {collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow} from './styles/sidenavCollapse';

export type SidenavCollapseProps = {
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  icon: React.ReactNode;
  name: string;
  active?: boolean;
  noCollapse?: boolean;
  open?: boolean;
  onClick?: () => void;
};

function SidenavCollapse({
  icon,
  name,
  children = false,
  active = false,
  noCollapse = false,
  open = false,
  onClick,
  color = 'info',
}: React.PropsWithChildren<SidenavCollapseProps>) {
  const [controller] = useSoftUIController();
  const {miniSidenav, transparentSidenav, sidenavColor} = controller;

  return (
    <>
      <ListItem component="li" onClick={onClick} sx={{maxWidth: 230}}>
        <SoftBox color={color} sx={(theme) => collapseItem(theme, {active, transparentSidenav})}>
          <ListItemIcon sx={(theme) => collapseIconBox(theme, {active, transparentSidenav, sidenavColor})}>
            {typeof icon === 'string' ? <Icon sx={(theme) => collapseIcon(theme, {active})}>{icon}</Icon> : icon}
          </ListItemIcon>

          <ListItemText primary={name} sx={(theme) => collapseText(theme, {miniSidenav, transparentSidenav, active})} />

          <Icon sx={(theme) => collapseArrow(theme, {noCollapse, transparentSidenav, miniSidenav, open})}>
            expand_less
          </Icon>
        </SoftBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default SidenavCollapse;
