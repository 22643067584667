import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from '@mui/material';
import type {Draft} from '@perfectpost/perfect-post-common';
import {isFuture, parseISO} from 'date-fns';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SoftAlert from 'src/theme/components/SoftAlert';
import SoftBox from 'src/theme/components/SoftBox';

type RemoveDraftDialogProps = {
  draft: Draft;
  handleClose: () => void;
  handleRemove: () => void;
};

export default function RemoveDraftDialog(props: RemoveDraftDialogProps) {
  const {draft, handleClose, handleRemove} = props;
  const {t} = useTranslation();
  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {t('removedraftdialog.title', {defaultValue: 'Confirm your draft deletion request'})}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('removedraftdialog.content', {defaultValue: 'Are you sure you want to delete this draft?'})}
        </DialogContentText>
        {draft.linkedin && draft.programmingDate && isFuture(parseISO(draft.programmingDate)) && (
          <SoftBox mt={2}>
            <SoftAlert color="error">
              <p>
                {t('removedraftdialog.linkedin')}{' '}
                <a
                  href="https://www.linkedin.com/feed/?shareActive=true&view=management"
                  target="_blank"
                  rel="noreferrer">
                  LinkedIn
                </a>
              </p>
            </SoftAlert>
          </SoftBox>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRemove} color="error">
          {t('removedraftdialog.deletebtn', {defaultValue: 'Delete draft'})}
        </Button>
        <Button onClick={handleClose} autoFocus>
          {t('removedraftdialog.cancelbtn', {defaultValue: 'Cancel'})}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
