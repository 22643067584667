import {Grid, Typography} from '@mui/material';
import {AssociateTeamSubscriptionStripePostCommand} from '@perfectpost/perfect-post-common';
import Lottie from 'lottie-react';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import cardPaymentInProcess from 'src/assets/lottie/card-payment-in-process.json';
import {perfectPostServiceClient} from 'src/services';
import {useAppThunkDispatch} from 'src/store';
import {getMe} from 'src/store/mainslice';
import {fetchTeams} from 'src/store/teamslice';
import useDocumentTitle from 'src/useDocumentTitle';

export default function TeamStripeSuccess() {
  const {id} = useParams();

  const [searchParams] = useSearchParams();
  const stripeSessionId = searchParams.get('session_id');
  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  useDocumentTitle(t('stripesuccess.documenttitle', {defaultValue: 'Stripe callback'}));

  useEffect(() => {
    if (stripeSessionId !== null && id !== undefined) {
      perfectPostServiceClient
        .send(new AssociateTeamSubscriptionStripePostCommand({teamId: id, sessionId: stripeSessionId}))
        .then(async () => {
          await dispatch(getMe());
          await dispatch(fetchTeams());
          navigate(`/team/${id}/settings/subscription`);
        });
    }
  }, [stripeSessionId, id]);

  return (
    <Grid>
      <Lottie autoplay animationData={cardPaymentInProcess} loop style={{height: 250}} />
      <Typography style={{textAlign: 'center'}}>
        {t('stripesuccess.paymentprocessing', {defaultValue: 'We process your payment'})}
      </Typography>
    </Grid>
  );
}
