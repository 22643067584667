/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {forwardRef} from 'react';
import Select, {GroupBase} from 'react-select';
// Soft UI Dashboard PRO React base styles
import {StateManagerProps} from 'react-select/dist/declarations/src/useStateManager';
import colors from 'src/theme/theme/base/colors';

// Custom styles for SoftSelect
import styles from './styles';

export type SoftSelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<StateManagerProps<Option, IsMulti, Group>, 'error'> & {
  size?: 'small' | 'medium' | 'large';
  error?: boolean;
  success?: boolean;
};

const SoftSelect = forwardRef(
  (
    {size = 'medium', error = false, success = false, ...rest}: SoftSelectProps<{value: string; label: string}, false>,
    ref,
  ) => {
    const {light} = colors;

    return (
      <Select
        {...rest}
        styles={styles<{value: string; label: string}>(size || undefined, error, success)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={ref}
      />
    );
  },
);

export default SoftSelect;
