import {paths} from '@perfectpost/perfect-post-common/src/cms/api';
import createClient from 'openapi-fetch';

const baseUrl = process.env.REACT_APP_CMS_URL;
const client = createClient<paths>({
  baseUrl,
  headers: {
    accept: 'application/json',
  },
});

export {client};
