import {ListPostCommand, LinkedinPost, RemovePostCommand, SocialData} from '@perfectpost/perfect-post-common';
import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import {
  compareAsc,
  isWithinInterval,
  parseISO,
  NormalizedInterval,
  addDays,
  differenceInDays,
  endOfDay,
  startOfToday,
  endOfToday,
} from 'date-fns';
import {perfectPostServiceClient} from 'src/services';

import {RootState} from '.';

const selectPost = (state: RootState) => state.post.linkedinPost;
const selectInterval = (state: RootState) => state.post.interval;
const previousInterval = (interval: NormalizedInterval<Date>) => {
  const previousStart = addDays(interval.start, differenceInDays(interval.start, interval.end));
  return {
    start: previousStart,
    end: endOfDay(interval.start),
  };
};
export const selectPreviousInterval = createSelector([selectInterval], previousInterval);

const postWithinInterval = (linkedinPost: LinkedinPost[], interval: NormalizedInterval<Date>): LinkedinPost[] =>
  linkedinPost.filter((post) => {
    const date = parseISO(post.date);
    return isWithinInterval(date, interval);
  });
export const selectPostWithinInterval = createSelector([selectPost, selectInterval], postWithinInterval);
export const selectPostWithinPreviousInterval = createSelector(
  [selectPost, selectPreviousInterval],
  postWithinInterval,
);

const globalStats = (postWithinInterval: LinkedinPost[]): GlobalStats => {
  const result = postWithinInterval
    .map((post) => post.latestSocialData)
    .reduce<GlobalStats>(
      (prev: GlobalStats, current: SocialData) => {
        return {
          impression: prev.impression + (current.numImpressions ?? 0),
          comment: prev.comment + (current.numComments ?? 0),
          like: prev.like + (current.numLikes ?? 0),
          sharing: prev.sharing + (current.numShares ?? 0),
          count: prev.count + 1,
          interaction: prev.interaction + (current.numComments ?? 0) + (current.numLikes ?? 0),
          engagement: 0,
        };
      },
      {
        impression: 0,
        comment: 0,
        like: 0,
        sharing: 0,
        count: 0,
        interaction: 0,
        engagement: 0,
      },
    );
  result.engagement = result.interaction / result.impression;
  return result;
};
export const selectGlobalStats = createSelector([selectPostWithinInterval], globalStats);
export const selectGlobalStatsPrevious = createSelector([selectPostWithinPreviousInterval], globalStats);

export const selectGlobalStatsProgress = createSelector(
  [selectGlobalStats, selectGlobalStatsPrevious],
  (current, previous) => {
    return {
      impression: (current.impression - previous.impression) / previous.impression,
      comment: (current.comment - previous.comment) / previous.comment,
      like: (current.like - previous.like) / previous.like,
      sharing: (current.sharing - previous.sharing) / previous.sharing,
      count: (current.count - previous.count) / previous.count,
      interaction: (current.interaction - previous.interaction) / previous.interaction,
      engagement: (current.engagement - previous.engagement) / previous.engagement,
    };
  },
);

export const listPost = createAsyncThunk<LinkedinPost[], NormalizedInterval<Date>>(
  'post/list',
  async ({start, end}) => {
    const prev = previousInterval({start, end});
    return perfectPostServiceClient.send(new ListPostCommand({begin: prev.start, end}));
  },
);
export const removePost = createAsyncThunk('post/remove', async (id: string) =>
  perfectPostServiceClient.send(new RemovePostCommand(id)),
);

export const INTERVAL_BASE: NormalizedInterval<Date> = {
  start: addDays(startOfToday(), -14),
  end: endOfToday(),
};

export type GlobalStats = {
  impression: number;
  interaction: number;
  comment: number;
  like: number;
  sharing: number;
  count: number;
  engagement: number;
};

export interface PostState {
  loadingLinkedinPosts: boolean;
  linkedinPost: LinkedinPost[];
  interval: NormalizedInterval<Date>;
}
const initialState: PostState = {
  loadingLinkedinPosts: false,
  linkedinPost: [],
  interval: INTERVAL_BASE,
};

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listPost.pending, (state, action) => {
        state.loadingLinkedinPosts = true;
        state.interval = action.meta.arg;
      })
      .addCase(listPost.fulfilled, (state, action) => {
        state.loadingLinkedinPosts = false;
        state.linkedinPost = action.payload.sort((a, b) => {
          const adate = parseISO(a.date);
          const bdate = parseISO(b.date);
          return compareAsc(adate, bdate);
        });
      })
      .addCase(removePost.pending, (state) => {
        state.loadingLinkedinPosts = true;
      })
      .addCase(removePost.fulfilled, (state, action) => {
        state.loadingLinkedinPosts = false;
        state.linkedinPost = state.linkedinPost.filter((p) => p._id !== action.meta.arg);
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = postSlice.actions;
export default postSlice.reducer;
