import ImageIcon from '@mui/icons-material/Image';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PollIcon from '@mui/icons-material/Poll';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {AppBar, Grid, Tabs, Tab, TextField, CircularProgress} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {GetPostTeamCommand, LinkedinPost} from '@perfectpost/perfect-post-common';
import {AnnotationOptions, EventContext} from 'chartjs-plugin-annotation';
import {addDays, endOfToday, parseISO, startOfToday} from 'date-fns';
import {enGB} from 'date-fns/locale/en-GB';
import {es} from 'date-fns/locale/es';
import {fr} from 'date-fns/locale/fr';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {CellProps, Column} from 'react-table';
import Chart, {Data, OptionGraphType} from 'src/components/chart/Chart';
import Datatable from 'src/components/datatable';
import DraftCard from 'src/components/draftcard/DraftCard';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormat} from 'src/utils/date-format';

function enter({chart}: EventContext) {
  chart.canvas.style.cursor = 'pointer';
}

function leave({chart}: EventContext) {
  chart.canvas.style.cursor = 'default';
}

type TableRow = {
  _id: string;
  owner: string;
  href?: string;
  content: string;
  date: Date;
  impression?: number;
  comment?: number;
  like?: number;
  vote?: number;
  engagement?: number;
  type?: string;
};

type DataType = 'view' | 'like' | 'comment';
type GraphData = {
  [key in DataType]: Data;
};

type TeamContentState = {
  state: 'loading' | 'idle';
  currentShowPostId?: string;
  select: -1 | 7 | 14 | 30 | 90 | 180 | 365;
  interval: {start: Date; end: Date};
  impactOptions?: OptionGraphType;
  posts: LinkedinPost[];
  tableRowData: TableRow[];
  graphData?: GraphData;
  memberFilter?: string;
};

export default function TeamContent() {
  const {id} = useParams();
  const {t} = useTranslation();
  useDocumentTitle(t('teamcontent.documenttitle', {defaultValue: 'Posts'}));

  const postListRef = useRef<HTMLElement | null>(null);

  const premium = useAppSelector((state) => state.main.premium);
  const locale = useAppSelector((state) => state.main.user?.locale);
  const allMembers = useAppSelector((state) => state.team.members);

  const [state, setState] = useState<TeamContentState>({
    state: 'loading',
    select: 14,
    interval: {
      start: addDays(startOfToday(), -14),
      end: endOfToday(),
    },
    posts: [],
    tableRowData: [],
  });

  const Cell = useCallback(
    (props: CellProps<TableRow, number | undefined>) => (
      <SoftTypography
        variant="button"
        textTransform="none"
        fontWeight="regular"
        color="secondary"
        sx={{display: 'inline-block', width: 'max-content'}}>
        {props.value ?? '-'}
      </SoftTypography>
    ),
    [],
  );

  const columnDefinition: Column<TableRow>[] = useMemo(
    () => [
      {
        Header: t('tablepost.owner', {defaultValue: 'Owner'}),
        accessor: 'owner',
        width: '20%',
        Cell: (props) => {
          const member = allMembers.find((m) => m._id === props.value);
          return (
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftBox component="img" width={25} src={member?.lnPicture} />
              </SoftBox>
              <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
                {member?.firstname} {member?.lastname}
              </SoftTypography>
            </SoftBox>
          );
        },
      },
      {
        Header: t('tablepost.type', {defaultValue: 'Type'}),
        accessor: 'type',
        width: '5%',
        Cell: (props) => (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            {props.value === 'ImageComponent' ? (
              <ImageIcon sx={{fontSize: 40}} />
            ) : props.value === 'LinkedInVideoComponent' ? (
              <YouTubeIcon sx={{fontSize: 40}} />
            ) : props.value === 'DocumentComponent' ? (
              <PictureAsPdfIcon sx={{fontSize: 40}} />
            ) : props.value === 'PollComponent' ? (
              <PollIcon sx={{fontSize: 40}} />
            ) : props.value === 'ArticleComponent' ? (
              <NewspaperIcon sx={{fontSize: 40}} />
            ) : null}
          </SoftBox>
        ),
      },
      {
        Header: t('tablepost.post', {defaultValue: 'Post'}),
        accessor: 'content',
        width: '25%',
        Cell: (props) => (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
              {props.row.original.content}
            </SoftTypography>
          </SoftBox>
        ),
      },
      {
        Header: t('tablepost.date', {defaultValue: 'Date'}),
        accessor: 'date',
        width: '10%',
        Cell: (props) => (
          <SoftTypography
            variant="button"
            textTransform="none"
            fontWeight="regular"
            color="secondary"
            sx={{display: 'inline-block', width: 'max-content'}}>
            {localFormat(props.value, 'dd/MM/yyyy HH:mm:ss', locale)}
          </SoftTypography>
        ),
      },
      // {Header: {t('tablepost.view', {defaultValue: 'View'}), accessor: 'name', width: '20%'},
      {Header: t('tablepost.impression', {defaultValue: 'Impression'}), accessor: 'impression', width: '10%', Cell},
      {Header: t('tablepost.comment', {defaultValue: 'Comment'}), accessor: 'comment', width: '10%', Cell},
      {Header: t('tablepost.like', {defaultValue: 'Like'}), accessor: 'like', width: '10%', Cell},
      {Header: t('tablepost.vote', {defaultValue: 'Vote'}), accessor: 'vote', width: '10%', Cell},
      {
        Header: t('tablepost.engagement', {defaultValue: 'Engagement'}),
        accessor: 'engagement',
        width: '10%',
        Cell: (props: CellProps<TableRow, number | undefined>) => (
          <SoftBox
            component={props.row.values.href ? NavLink : 'span'}
            to={props.row.values.href as string}
            sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
            <SoftTypography
              variant="button"
              textTransform="none"
              fontWeight="regular"
              color="secondary"
              sx={{display: 'inline-block', width: 'max-content'}}>
              {props.value ? `${(props.value * 100).toLocaleString(locale, {maximumFractionDigits: 2})}%` : '-'}
            </SoftTypography>
          </SoftBox>
        ),
      },
    ],
    [locale],
  );

  const chartdataset = useMemo(() => {
    return state.graphData ? [state.graphData.comment, state.graphData.like, state.graphData.view] : [];
  }, [state.graphData]);

  useEffect(() => {
    if (id) {
      setState((state) => ({...state, state: 'loading'}));
      const {interval, currentShowPostId} = state;
      perfectPostServiceClient
        .send(new GetPostTeamCommand({id, begin: interval.start, end: interval.end}))
        .then((posts) => {
          const brutData: GraphData = {
            like: {
              yAxisID: 'A',
              id: 'like',
              label: t('global.like', {defaultValue: 'Like'}),
              color: 'dark',
              data: [] as {x: Date; y: number}[],
            },
            comment: {
              yAxisID: 'A',
              id: 'comment',
              label: t('global.comment', {defaultValue: 'Comment'}),
              color: 'info',
              data: [] as {x: Date; y: number}[],
            },
            view: {
              yAxisID: 'B',
              id: 'view',
              label: t('global.impression', {defaultValue: 'Impression'}),
              color: 'primary',
              data: [] as {x: Date; y: number}[],
            },
          };
          const annotations: AnnotationOptions[] = [];
          const tableRowData: TableRow[] = [];
          for (const post of posts) {
            const owner = allMembers.find((m) => m._id === post.user);
            const image = document.createElement('img');
            image.src = owner?.lnPicture ?? '';
            const date = parseISO(post.date);
            const line: AnnotationOptions = {
              scaleID: `x`,
              type: 'line',
              value: date.getTime(),
              borderColor: currentShowPostId === post._id ? '#0D99FF' : '#D9D9D9',
              borderWidth: 1,
              borderDash: [2, 2],
            };
            annotations.push(line);
            const point: AnnotationOptions = {
              type: 'label',
              content: image,
              width: 20,
              height: 20,
              xValue: date.getTime(),
              yAdjust: 0,
              yValue: 0,
              borderWidth: 0,
              borderColor: 'transparent',
              enter,
              leave,
              click: onAnotationClick(post),
            };
            annotations.push(point);

            const lastRelever = post.latestSocialData;
            brutData.like.data.push({x: date, y: lastRelever?.numLikes ?? 0});
            brutData.comment.data.push({x: date, y: lastRelever?.numComments ?? 0});
            brutData.view.data.push({x: date, y: lastRelever?.numImpressions ?? 0});

            tableRowData.unshift({
              _id: post._id,
              owner: post.user,
              content: post.text?.substring(0, 80) ?? '',
              date,
              comment: lastRelever?.numComments,
              impression: lastRelever?.numImpressions,
              like: lastRelever?.numLikes,
              vote: lastRelever?.numVoters,
              engagement:
                lastRelever?.numComments && lastRelever?.numImpressions && lastRelever?.numLikes
                  ? (lastRelever.numLikes + lastRelever.numComments + (lastRelever.numVoters ?? 0)) /
                    lastRelever.numImpressions
                  : undefined,
              type: post.type,
            });
          }
          for (let i = 1; i < brutData.like.data.length; i++) {
            brutData.like.data[i].y = brutData.like.data[i].y + brutData.like.data[i - 1].y;
            brutData.comment.data[i].y = brutData.comment.data[i].y + brutData.comment.data[i - 1].y;
            brutData.view.data[i].y = brutData.view.data[i].y + brutData.view.data[i - 1].y;
          }
          setState((state) => ({
            ...state,
            state: 'idle',
            posts,
            graphData: brutData,
            tableRowData,
            impactOptions: {
              plugins: {
                annotation: {
                  clip: false,
                  common: {
                    drawTime: 'afterDraw',
                  },
                  annotations,
                },
              },
            },
          }));
        });
    }
  }, [id, state.interval, state.currentShowPostId]);

  const onAnotationClick = (post: LinkedinPost) => () => {
    setState((state) => ({...state, currentShowPostId: post._id}));
    postListRef.current
      ?.querySelector(`#post-${post._id}`)
      ?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
  };

  const onSelectChange = (e: React.SyntheticEvent<Element, Event>, v: number) => {
    if (premium || v <= 14) {
      if (v === -1) {
        setState((prevState) => ({
          ...prevState,
          select: -1,
          interval: {
            start: addDays(startOfToday(), -30),
            end: endOfToday(),
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          select: v as -1 | 7 | 14 | 30 | 90 | 180 | 365,
          interval: {
            start: addDays(startOfToday(), -1 * v),
            end: endOfToday(),
          },
        }));
      }
    }
  };

  return (
    <SoftBox>
      <SoftBox mb={3}>
        <SoftTypography mb={2} variant="h2" textTransform="none" fontWeight="bold">
          {t('content.title', {defaultValue: 'Publication statistics'})}
        </SoftTypography>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <AppBar
              position="static"
              sx={({palette}) => ({border: 1, borderColor: palette.grey[400], borderRadius: '0.75rem'})}>
              <Tabs value={state.select} onChange={onSelectChange}>
                <Tab label="7" value={7} />
                <Tab label="14" value={14} />
                <Tab
                  label="30"
                  value={30}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="90"
                  value={90}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="180"
                  value={180}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="365"
                  value={365}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="Custom"
                  value={-1}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={3}>
            {state.select !== -1 ? (
              <SoftTypography sx={{height: '100%', marginTop: '5px'}}>
                {t('global.days', {defaultValue: 'Days'})}
              </SoftTypography>
            ) : (
              <SoftBox display="flex" flexDirection="row" sx={{height: '100%'}}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale === 'fr' ? fr : locale === 'es' ? es : enGB}>
                  <DatePicker
                    value={state.interval.start}
                    minDate={new Date('2021-01-01')}
                    maxDate={state.interval.end}
                    onChange={(newValue) => {
                      setState((prev) => ({...prev, interval: {...prev.interval, start: newValue as Date}}));
                    }}
                    slotProps={{
                      textField: (params) => (
                        <TextField {...params} variant="outlined" sx={{height: '100%', justifyContent: 'center'}} />
                      ),
                    }}
                  />
                  <DatePicker
                    value={state.interval.end}
                    minDate={state.interval.start}
                    disableFuture
                    onChange={(newValue) => {
                      setState((prev) => ({...prev, interval: {...prev.interval, end: newValue as Date}}));
                    }}
                    slotProps={{
                      textField: (params) => (
                        <TextField {...params} variant="outlined" sx={{height: '100%', justifyContent: 'center'}} />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </SoftBox>
            )}
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox
        gridTemplateColumns="repeat(4, 1fr)"
        gridTemplateAreas={`"title title title title"
           "graph graph graph post"
           "graph graph graph post"`}
        gap={2}
        sx={({palette}) => ({
          display: 'grid',
          backgroundColor: palette.white.main,
          borderRadius: '1em',
          p: 2,
          mt: 2,
        })}>
        <SoftBox gridArea="title" display="flex" flexDirection="row">
          <SoftTypography sx={{mr: 2}}>
            {t('teamcontent.postimpact', {defaultValue: 'Impression cummulé de votre équipe'})}
          </SoftTypography>
        </SoftBox>
        <SoftBox gridArea="graph">
          <SoftBox lineHeight={1} height={420}>
            {useMemo(
              () => (
                <Chart chartdataset={chartdataset} options={state.impactOptions} type="line" />
              ),
              [state.graphData],
            )}
          </SoftBox>
        </SoftBox>
        <SoftBox gridArea="post">
          <SoftBox
            ref={postListRef}
            component="ul"
            display="flex"
            flexDirection="column"
            sx={{maxHeight: 420, overflowY: 'auto', px: 2, overscrollBehavior: 'contain'}}>
            {state.posts.map((post) => (
              <SoftBox
                key={post._id}
                id={`post-${post._id}`}
                component="li"
                display="flex"
                alignItems="baseline"
                flexDirection="column"
                py={1}
                mb={1}>
                <DraftCard content={post.text ?? ''} programmingDate={post.date} user={post.user} />
              </SoftBox>
            ))}
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox sx={{mt: 2}}>
        <SoftBox py={3}>
          <SoftTypography variant="caption" textTransform="none">
            {t('content.explainhowtoseemore', {defaultValue: '(to see more publications change the selected period)'})}
          </SoftTypography>
        </SoftBox>
        <SoftBox sx={{position: 'relative'}}>
          <Datatable
            table={{rows: state.tableRowData, columns: columnDefinition}}
            entriesPerPage={{defaultValue: 30, entries: [10, 20, 30, 50, 80, 130]}}
            pagination={{variant: 'gradient', color: 'info'}}
            isSorted
            canSearch
          />
          {state.state === 'loading' && (
            <SoftBox
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.5)',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CircularProgress />
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}
