/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Box, {BoxProps} from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import React from 'react';

type ExtraProps = {
  variant?: 'contained' | 'gradient';
  bgColor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  children?: React.ReactNode;
};

type CastedForwardRefBoxType = <C extends React.ElementType>(
  props: Omit<BoxProps<C, {component?: C}>, 'color' | 'variant'> & ExtraProps,
  ref?: React.Ref<HTMLButtonElement>,
) => React.ReactElement | null;
const CastedForwardRefBoxFnc: CastedForwardRefBoxType = (props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {children, bgColor, ...rest} = props;
  return (
    <Box ref={ref} {...rest}>
      {children}
    </Box>
  );
};
const CastedForwardRefBox = React.forwardRef(CastedForwardRefBoxFnc) as CastedForwardRefBoxType;
// @ts-ignore
export default styled(CastedForwardRefBox)(({theme, variant, bgColor, color, opacity, borderRadius, shadow}) => {
  const {palette, functions, borders, boxShadows} = theme;

  const {gradients, grey, white} = palette;
  const {linearGradient} = functions;
  const {borderRadius: radius} = borders;

  const greyColors = {
    'grey-100': grey[100],
    'grey-200': grey[200],
    'grey-300': grey[300],
    'grey-400': grey[400],
    'grey-500': grey[500],
    'grey-600': grey[600],
    'grey-700': grey[700],
    'grey-800': grey[800],
    'grey-900': grey[900],
  };

  const validGradients = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light'] as const;
  const isValidGradients = (color: string | undefined): color is (typeof validGradients)[number] =>
    validGradients.some((el) => el === color);

  const validColors = [
    'transparent',
    'white',
    'black',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'text',
    'grey-100',
    'grey-200',
    'grey-300',
    'grey-400',
    'grey-500',
    'grey-600',
    'grey-700',
    'grey-800',
    'grey-900',
  ] as const;
  const isValidColors = (color: string | undefined): color is (typeof validColors)[number] =>
    validColors.some((x) => x === color);

  const validBorderRadius = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'section'];
  const validBoxShadows = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset'];

  const isPaletteKeys = (color: string | undefined): color is keyof typeof palette =>
    color !== undefined && Object.keys(palette).includes(color);

  // background value
  let backgroundValue = bgColor;
  if (variant === 'gradient') {
    backgroundValue = isValidGradients(bgColor)
      ? linearGradient(gradients[bgColor].main, gradients[bgColor].state)
      : white.main;
  } else if (isValidColors(bgColor)) {
    backgroundValue = isPaletteKeys(bgColor) ? palette[bgColor].main : greyColors[bgColor];
  } else {
    backgroundValue = bgColor;
  }

  // color value
  let colorValue = color;
  if (isValidColors(color)) {
    colorValue = isPaletteKeys(color) ? palette[color].main : greyColors[color];
  }

  // borderRadius value
  let borderRadiusValue = borderRadius;

  if (borderRadius !== undefined && validBorderRadius.find((el) => el === borderRadius)) {
    // @ts-ignore
    borderRadiusValue = radius[borderRadius];
  }

  // boxShadow value
  let boxShadowValue = boxShadows;

  if (shadow !== undefined && validBoxShadows.find((el) => el === shadow)) {
    // @ts-ignore
    boxShadowValue = boxShadows[shadow];
  }

  return {
    opacity,
    background: backgroundValue,
    color: colorValue,
    borderRadius: borderRadiusValue,
    boxShadow: boxShadowValue,
  };
});
