import Quill from 'quill';
import {ActionsType, transform as trans} from 'src/utils/transform-functions';
const Delta = Quill.import('delta');

export const transform = (quill: Quill, actions: ActionsType[]) => {
  const selection = quill.getSelection();
  if (selection !== null) {
    const selectionString = quill.getText(selection.index, selection.length);
    const transformed = trans(selectionString, actions);
    quill.updateContents(new Delta().retain(selection.index).delete(selection.length).insert(transformed));
  }
};
