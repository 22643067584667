/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {Icon} from '@mui/material';
import {components} from '@perfectpost/perfect-post-common/src/cms/api';
import React, {useEffect, useState} from 'react';
import {client} from 'src/services/cms';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import borders from 'src/theme/theme/base/borders';
import colors from 'src/theme/theme/base/colors';

// eslint-disable-next-line @typescript-eslint/ban-types
type TipCardProps = {
  informationkey: string;
};
function TipCard({informationkey}: TipCardProps) {
  const locale = useAppSelector((state) => state.main.user?.locale);
  const [tips, setTips] = useState<components['schemas']['InformationListResponseDataItem'][]>([]);
  const {secondary} = colors;
  const {borderWidth} = borders;

  useEffect(() => {
    const query = {
      locale,
      'filters[key]': informationkey,
    };
    client.GET('/informations', {params: {query}}).then(({data}) => setTips(data?.data ?? []));
  }, [informationkey]);

  return tips.length > 0 ? (
    <SoftBox
      display="flex"
      flexDirection="row"
      borderRadius="md"
      border={`${borderWidth[1]} dashed ${secondary.main}`}
      p={2}>
      <SoftButton
        sx={{mr: 2, height: 24, minHeight: 24, width: 24, minWidth: 24}}
        variant="outlined"
        color="secondary"
        iconOnly
        circular>
        <Icon sx={{cursor: 'pointer'}}>priority_high</Icon>
      </SoftButton>
      <SoftBox sx={{fontSize: 14}} dangerouslySetInnerHTML={{__html: tips[0]?.attributes?.content}} />
    </SoftBox>
  ) : (
    <div />
  );
}

export default TipCard;
