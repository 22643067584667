import {Grid} from '@mui/material';
import {components} from '@perfectpost/perfect-post-common/src/cms/api';
import {getCurrentUser} from 'aws-amplify/auth';
import {isPast, parseISO} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useNavigate} from 'react-router-dom';
import Slider from 'react-slick';
import i18n from 'src/i18n';
import {client} from 'src/services/cms';
import {useAppThunkDispatch} from 'src/store';
import {authentification, getMe} from 'src/store/mainslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftSelect from 'src/theme/components/SoftSelect';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type AuthenticationState = {
  lang: 'fr' | 'en' | 'es';
  slideshow: NonNullable<
    NonNullable<components['schemas']['SignInSlideshowListResponseDataItem']['attributes']>['slide']
  >;
};

export default function Authentication() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const [authenticationState, setAuthenticationState] = useState<AuthenticationState>({
    lang: i18n.languages[0] as 'fr' | 'en' | 'es',
    slideshow: [],
  });
  const {lang, slideshow} = authenticationState;

  useEffect(() => {
    i18n.changeLanguage(lang);
    const query = {
      'populate[0]': 'slide',
      'populate[1]': 'slide.image',
      locale: lang,
    };
    void client.GET('/sign-in-slideshow', {params: {query}}).then(({data}) => {
      const slideShowItem = data?.data;
      if (typeof slideShowItem === 'object' && slideShowItem !== undefined) {
        setAuthenticationState((state) => ({...state, slideshow: slideShowItem.attributes?.slide ?? []}));
      }
    });
  }, [lang]);

  useEffect(() => {
    const callback = () => {
      console.log('i18n.languages', i18n.languages);
      setAuthenticationState((state) => ({...state, lang: i18n.languages[0] as 'fr' | 'en' | 'es'}));
    };
    i18n.on('languageChanged', callback);
    getCurrentUser()
      .then(async () => {
        await dispatch(authentification());
        const {me} = await dispatch(getMe()).unwrap();
        const tokenExpiration = me.lnExpiresAt ? parseISO(me.lnExpiresAt) : undefined;
        if (me.vanityName === undefined || tokenExpiration === undefined || isPast(tokenExpiration)) {
          navigate('/auth/linkedin');
        } else {
          navigate('/');
        }
      })
      .catch(() => {
        // do nothing
      });
    return () => {
      i18n.off('languageChanged', callback);
    };
  }, []);

  return (
    <SoftBox width="100vw" height="100%" minHeight="100vh" bgColor="white" sx={{overflowX: 'hidden'}}>
      <Grid container>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{mx: 'auto'}}>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
            <Outlet context={{lang}} />
          </SoftBox>
          <SoftBox sx={{position: 'fixed', bottom: 0, left: 0, mb: 2, ml: 2}}>
            <SoftSelect
              menuPlacement="top"
              placeholder={t('global.language.placeholder', {defaultValue: 'Change language'})}
              options={[
                {value: 'fr', label: t('global.language.fr', {defaultValue: 'Français'})},
                {value: 'en', label: t('global.language.en', {defaultValue: 'English'})},
                {value: 'es', label: t('global.language.es', {defaultValue: 'Español'})},
              ]}
              onChange={(e) => {
                setAuthenticationState((state) => ({
                  ...state,
                  lang: e !== null ? (e.value as 'fr' | 'en' | 'es') : 'fr',
                }));
              }}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SoftBox
            display={{xs: 'none', lg: 'flex'}}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            position="relative"
            borderRadius="lg"
            textAlign="center"
            bgColor="info"
            variant="gradient"
            m={2}
            px={2}
            sx={{overflow: 'hidden'}}>
            <Slider
              dots
              infinite
              speed={500}
              autoplaySpeed={5000}
              autoplay
              slidesToShow={1}
              slidesToScroll={1}
              arrows={false}
              className="sign-in-slideshow">
              {slideshow.map((slide) => (
                <SoftBox
                  key={slide.id}
                  component="img"
                  src={slide.image?.data?.attributes?.url}
                  alt="PerfectPost"
                  width={500}
                  sx={{
                    borderRadius: '1rem',
                    border: '0px solid rgba(0, 0, 0, 0.125)',
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
                  }}
                />
              ))}
            </Slider>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
