/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import ListItem, {ListItemProps} from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import SoftBadge from 'src/theme/components/SoftBadge';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';
import {useSoftUIController} from 'src/theme/context';

import {item, itemContent, itemArrow} from './styles/sidenavItem';

export type SidenavItemProp = ListItemProps & {
  name: string;
  active?: boolean;
  nested?: boolean;
  open?: boolean;
  notifications?: number;
};
function SidenavItem({
  name,
  active = false,
  nested = false,
  children = false,
  open = false,
  notifications,
  ...rest
}: React.PropsWithChildren<SidenavItemProp>) {
  const [controller] = useSoftUIController();
  const {miniSidenav} = controller;

  return (
    <>
      <ListItem {...rest} component="li" sx={item}>
        <SoftBox sx={(theme) => itemContent(theme, {active, miniSidenav, name, nested})}>
          <ListItemText
            primary={
              notifications ? (
                <>
                  <SoftTypography varian="body1" component="span">
                    {name}
                  </SoftTypography>
                  <SoftBadge variant="contained" color="secondary" badgeContent={notifications} />
                </>
              ) : (
                name
              )
            }
          />
          {children && (
            <Icon component="i" sx={(theme) => itemArrow(theme, {open, miniSidenav})}>
              expand_less
            </Icon>
          )}
        </SoftBox>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default SidenavItem;
