import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import type {Draft, SingleAssets} from '@perfectpost/perfect-post-common';
import axios, {AxiosRequestConfig} from 'axios';
import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {Document, Page, pdfjs} from 'react-pdf';
import '@enhances/with-resolvers';
import {useNavigate} from 'react-router-dom';
import {useAppThunkDispatch} from 'src/store';
import {editDraft, saveDraft} from 'src/store/draftslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftProgress from 'src/theme/components/SoftProgress';
import SoftTypography from 'src/theme/components/SoftTypography';
import {sanitizer} from 'src/utils/sanitizer';

import TipCard from '../tipscard/TipCards';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

type ImportPDFModalProps = {
  draftId?: string;
  content?: string;
  sharing?: SingleAssets;
  handleClose: () => void;
  handleConfirm: (draft: Draft) => void;
};

type ImportPDFModalState = {
  form?: {
    url: string;
    mimetype: string;
    title?: string;
  };
  uploadProgress?: number;
  numPages?: number;
};

export default function ImportPDFModal(props: ImportPDFModalProps) {
  const {draftId, content, sharing, handleClose, handleConfirm} = props;
  console.log(sharing);
  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: {
      'application/pdf': [],
    },
  });

  const [state, setState] = useState<ImportPDFModalState>({
    form:
      sharing !== undefined ? {url: sharing.url ?? '', mimetype: 'application/pdf', title: sharing.title} : undefined,
  });

  const {form, numPages, uploadProgress} = state;

  useEffect(() => {
    if (sharing !== undefined) {
      return;
    }
    if (acceptedFiles.length === 0) {
      setState((s) => ({...s, form: undefined}));
    } else {
      const url = URL.createObjectURL(acceptedFiles[0]);
      const mimetype = acceptedFiles[0].type;
      setState((s) => ({...s, form: {url, mimetype, title: acceptedFiles[0].name}}));
    }
  }, [sharing, acceptedFiles]);

  const onSubmit = async () => {
    if (form === undefined) {
      return;
    }
    const blob = await fetch(form.url).then(async (r) => r.blob());
    const sharingData = {
      pdf: blob.type,
      title: form.title,
    };
    let result: {
      status: string;
      url?: string | undefined;
      draft: Draft;
    };
    if (draftId === undefined) {
      result = await dispatch(
        saveDraft({
          content: sanitizer(content ?? ''),
          sharing: sharingData,
        }),
      ).unwrap();
    } else {
      result = await dispatch(
        editDraft({
          draftId,
          content: content ? sanitizer(content) : undefined,
          sharing: sharingData,
        }),
      ).unwrap();
    }
    if (result?.url && result.draft._id && blob) {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': blob.type,
        },
        onUploadProgress(progressEvent) {
          if (progressEvent.loaded !== progressEvent.total) {
            setState((s) => ({...s, uploadProgress: (progressEvent.loaded / (progressEvent.total ?? 100)) * 100}));
          }
        },
      };
      void axios.put(result.url, blob, config).then(() => {
        setState((s) => ({...s, uploadProgress: undefined}));
        if (draftId === undefined) {
          navigate(`/publish/draft/${result.draft._id}`);
        }
        handleConfirm(result.draft);
      });
    }
  };

  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>
        <SoftTypography>{t('importpdfmodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <TipCard informationkey="modalpdf" />
        {uploadProgress === undefined ? (
          <SoftTypography>{t('importpdfmodal.label')}</SoftTypography>
        ) : (
          <SoftBox sx={{display: 'flex', flexDirection: 'column'}}>
            <SoftTypography variant="button" color="text" fontWeight="medium">
              {t('draft.uploadvideo', {defaultValue: 'Uploading video'})}
            </SoftTypography>
            <SoftProgress value={uploadProgress} variant="gradient" />
          </SoftBox>
        )}
        {form !== undefined ? (
          <>
            <SoftInput
              sx={{mb: 2}}
              value={form.title}
              onChange={(e) => setState((s) => ({...s, form: {...form, title: e.target.value}}))}
            />
            <SoftBox
              sx={{
                '& .react-pdf__Document': {
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 1,
                  justifyContent: 'center',
                },
              }}>
              <Document
                file={acceptedFiles[0] ?? form.url}
                onLoadSuccess={({numPages}) => {
                  setState((s) => ({...s, numPages}));
                }}>
                {Array.from(Array(numPages).keys()).map((page) => (
                  <Page key={page + 1} pageNumber={page + 1} width={200} />
                ))}
              </Document>
            </SoftBox>
          </>
        ) : (
          <SoftBox
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              borderWidth: '2px',
              borderRadius: '2px',
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
              color: '#bdbdbd',
              outline: 'none',
              transition: 'border .24s ease-in-out',
              cursor: 'pointer',
            }}
            {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <SoftTypography>{t('importpdfmodal.instruction')}</SoftTypography>
          </SoftBox>
        )}
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={handleClose} autoFocus>
          {t('global.cancel', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton variant="gradient" onClick={onSubmit} disabled={form === undefined}>
          {t('importpdfmodal.import', {defaultValue: 'Import into publication'})}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
