import {CircularProgress} from '@mui/material';
import * as Sentry from '@sentry/react';
import {getCurrentUser} from 'aws-amplify/auth';
import {OptionsWithExtraProps, useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import CreateTeamModal from './components/modals/CreateTeamModal';
import TeamInvitationModal from './components/modals/TeamInvitationModal';
import Sidenav from './components/sidenav';
import {crispService as crisp, mixPanelService} from './services';
import {useAppSelector, useAppThunkDispatch} from './store';
import {authentification, getMe} from './store/mainslice';
import {fetchTeams} from './store/teamslice';
import SoftBox from './theme/components/SoftBox';

type LoginRequiredRouteState = {
  checkingSession: boolean;
  showCreateTeamModal: boolean;
};

export default function LoginRequiredRoute() {
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const location = useLocation();
  const {enqueueSnackbar} = useSnackbar();

  const [state, setState] = useState<LoginRequiredRouteState>({
    checkingSession: true,
    showCreateTeamModal: false,
  });
  const {checkingSession, showCreateTeamModal} = state;

  const user = useAppSelector((state) => state.main.user);
  const premium = useAppSelector((state) => state.main.premium);
  const teamInvitations = useAppSelector((state) => state.main.notifications?.teamInvitations);
  const lnAccessTokenValid = useAppSelector((state) => state.main.lnAccessTokenValid);
  const isEstablishingConnection = useAppSelector((state) => state.extension.isEstablishingConnection);
  const currentCommandId = useAppSelector((state) => state.extension.currentCommandId);
  const commandStack = useAppSelector((state) => state.extension.commandStack);

  const overrideTheme = location.pathname === '/publish/draft' || location.pathname.endsWith('/kanban');

  useEffect(() => {
    getCurrentUser()
      .then(async () => {
        await dispatch(authentification());
        dispatch(getMe());
        dispatch(fetchTeams());
        setState((f) => ({...f, checkingSession: false}));
      })
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        console.warn({err});
        navigate('/signin', {state: {from: location.pathname}});
        setState((f) => ({...f, checkingSession: false}));
      });
  }, []);

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.locale);
      Sentry.setUser({
        id: user._id,
        cognitoId: user.cognitoId,
        vanityName: user.vanityName,
      });
      crisp.trackUser(user, premium);
      mixPanelService.trackUser(user);
    }
  }, [user, premium]);

  useEffect(() => {
    commandStack.forEach((command) => {
      console.log('enqueueSnackbar', command);
      const options: OptionsWithExtraProps<'workingCommand'> = {
        preventDuplicate: true,
        variant: 'workingCommand',
        commandId: command.commandId,
        persist: true,
      };
      enqueueSnackbar(command.commandId, options);
    });
  }, [currentCommandId, commandStack]);

  useEffect(() => {
    if (lnAccessTokenValid === false) {
      enqueueSnackbar(user?._id ?? '', {
        persist: true,
        variant: 'noLinkedInConnection',
      });
    }
  }, [lnAccessTokenValid]);

  useEffect(() => {
    if (isEstablishingConnection) {
      enqueueSnackbar('', {variant: 'establishingConnection', persist: true});
    }
  }, [isEstablishingConnection]);

  const onShowCreateTeamModal = () => {
    setState((f) => ({...f, showCreateTeamModal: true}));
  };

  if (user !== undefined) {
    return (
      <SoftBox
        sx={({breakpoints, transitions, functions: {pxToRem}}) => ({
          pt: 3,
          pr: overrideTheme ? 0 : 3,
          position: 'relative',
          minWidth: 1200,
          display: overrideTheme ? 'flex' : undefined,
          flexDirection: overrideTheme ? 'column' : undefined,
          height: '100%',
          [breakpoints.up('xxl')]: {
            marginLeft: pxToRem(282),
            transition: transitions.create(['margin-left', 'margin-right'], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}>
        <Sidenav onShowCreateTeamModal={onShowCreateTeamModal} />
        {showCreateTeamModal && (
          <CreateTeamModal
            handleClose={() => setState((f) => ({...f, showCreateTeamModal: false}))}
            handleConfirm={() => navigate('/teams/create')}
          />
        )}
        {teamInvitations !== undefined && teamInvitations.length > 0 && (
          <TeamInvitationModal team={teamInvitations[0]} />
        )}
        {checkingSession === false && <Outlet />}
      </SoftBox>
    );
  } else {
    return <CircularProgress />;
  }
}
