import {components} from '@perfectpost/perfect-post-common/src/cms/api';
import {useWindowSize} from '@react-hook/window-size';
import {
  LoadMoreItemsCallback,
  MasonryScroller,
  useContainerPosition,
  useInfiniteLoader,
  usePositioner,
  useResizeObserver,
} from 'masonic';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CoursesCard from 'src/components/coursescard/CoursesCard';
import {client} from 'src/services/cms';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

type Creator = NonNullable<NonNullable<components['schemas']['Course']['creator']>['data']>;
type CourseListResponseDataItem = components['schemas']['CourseListResponseDataItem'];

const mainQuery = {
  'populate[0]': 'illustration',
  'populate[1]': 'creator',
  'populate[2]': 'creator.picture',
  'pagination[limit]': 100,
  sort: 'publicationDate',
};

export default function Guides() {
  const {t} = useTranslation();
  useDocumentTitle(t('guides.documenttitle', {defaultValue: 'Guides'}));

  const locale = useAppSelector((state) => state.main.user?.locale ?? 'en');
  const loadedPublications = useRef<{endIndex: number; startIndex: number}[]>([]);
  const containerRef = useRef<null | HTMLElement>(null);

  const [courses, setCourses] = useState<CourseListResponseDataItem[]>([]);
  const [categories, setCategories] = useState<
    NonNullable<components['schemas']['CourseCategoryListResponse']['data']>
  >([]);
  const [creators, setCreators] = useState<Creator[]>([]);
  const [selectedCreator, setSelectedCreator] = useState<{label: string; value: string} | undefined>(undefined);
  const [selectedCategory, setSelectedCategory] = useState<{label: string; value: string} | undefined>(undefined);

  const useGalleryInfiniteLoad = useCallback(() => {
    const fetchMoreItems: LoadMoreItemsCallback<CourseListResponseDataItem> = async (startIndex, endIndex) => {
      // the trick start
      const newIndex = {endIndex, startIndex};
      const existing = loadedPublications.current.find((el) => {
        return el.startIndex === newIndex.startIndex && el.endIndex === newIndex.endIndex;
      });
      if (existing) return;
      loadedPublications.current.push(newIndex);
      // trick end
      try {
        const query = {
          ...mainQuery,
          'pagination[start]': startIndex,
          'filters[creator][id][$eq]': selectedCreator?.value ?? undefined,
          'filters[course_categories][id][$eq]': selectedCategory?.value ?? undefined,
          locale,
        };
        const {data} = await client.GET('/courses', {params: {query}});
        if (data?.data) {
          const items = data.data;
          setCourses((prev) => {
            return [...prev, ...items];
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const maybeLoadMore = useRef(
      useInfiniteLoader<CourseListResponseDataItem, LoadMoreItemsCallback<CourseListResponseDataItem>>(fetchMoreItems, {
        isItemLoaded: (index, items) => !!items[index],
        threshold: 5,
      }),
    );
    return {maybeLoadMore};
  }, [selectedCreator, selectedCategory, loadedPublications, setCourses, locale]);

  const [windowWidth, windowHeight] = useWindowSize();
  const {offset, width} = useContainerPosition(containerRef, [windowWidth, windowHeight]);

  // The key to this entire example lies in the usePositioner()
  // hook
  const positioner = usePositioner({width, columnWidth: 292, columnGutter: 24}, [courses]);

  const resizeObserver = useResizeObserver(positioner);

  useEffect(() => {
    const query = {
      ...mainQuery,
      locale,
    };
    client.GET('/courses', {params: {query}}).then(({data}) => {
      setCourses(data?.data ?? []);
      const all =
        data?.data
          ?.map((course) => course.attributes?.creator?.data)
          .filter((creator): creator is Creator => creator !== undefined) ?? [];
      setCreators([...new Map(all.map((item) => [item.id, item])).values()]);
    });
    client
      .GET('/course-categories', {
        locale,
      })
      .then(({data}) => {
        setCategories(data?.data ?? []);
      });
  }, [locale]);

  const {maybeLoadMore} = useGalleryInfiniteLoad();

  const cellRenderer = useCallback(({data}: {data: components['schemas']['CourseListResponseDataItem']}) => {
    const course = data;
    return <CoursesCard course={course} />;
  }, []);

  const onCreatorChange = (element: {label: string; value: string} | null) => {
    setSelectedCreator(element ?? undefined);
    const query = {
      ...mainQuery,
      'filters[creator][id][$eq]': element?.value ?? undefined,
      'filters[course_categories][id][$eq]': selectedCategory?.value ?? undefined,
      locale,
    };
    client.GET('/courses', {params: {query}}).then(({data}) => {
      setCourses(data?.data ?? []);
    });
  };

  const onCategoryChange = (element: {label: string; value: string} | null) => {
    setSelectedCategory(element ?? undefined);
    const query = {
      ...mainQuery,
      'filters[creator][id][$eq]': selectedCreator?.value ?? undefined,
      'filters[course_categories][id][$eq]': element?.value ?? undefined,
      locale,
    };
    client.GET('/courses', {params: {query}}).then(({data}) => {
      setCourses(data?.data ?? []);
    });
  };

  return (
    <SoftBox>
      <SoftBox sx={{mb: 3, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <SoftTypography variant="h2" sx={{fontWeight: 'bold', textTransform: 'none', mr: 2}}>
          {t('guides.documenttitle', {defaultValue: 'Guides'})}
        </SoftTypography>
        <SoftSelect
          options={creators.map((item) => ({
            label: item.attributes?.name ?? '',
            value: `${item.id ?? ''}`,
          }))}
          value={selectedCreator}
          onChange={onCreatorChange}
          isClearable
        />
        <SoftBox sx={{mr: 2}} />
        <SoftSelect
          options={categories.map((item) => ({
            label: item.attributes?.name ?? '',
            value: `${item.id ?? ''}`,
          }))}
          value={selectedCategory}
          onChange={onCategoryChange}
          isClearable
        />
      </SoftBox>
      <SoftBox>
        {courses.length === 0 && (
          <SoftBox sx={{flex: 1, justifyContent: 'center', height: '100%'}}>
            <SoftTypography>{t('guides.underconstruction')}</SoftTypography>
          </SoftBox>
        )}
        <MasonryScroller
          positioner={positioner}
          resizeObserver={resizeObserver}
          containerRef={containerRef}
          items={courses}
          height={windowHeight}
          offset={offset}
          overscanBy={1.25}
          render={cellRenderer}
          onRender={maybeLoadMore.current}
        />
      </SoftBox>
    </SoftBox>
  );
}
