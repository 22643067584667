/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @fullcalendar components
import {CalendarOptions} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import Card from '@mui/material/Card';
import React from 'react';
import {useAppSelector} from 'src/store';

import CalendarRoot from './CalendarRoot';

type CalendarProps = CalendarOptions;
function Calendar({...rest}: CalendarProps) {
  const user = useAppSelector((store) => store.main.user);
  const validClassNames = [
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'no-bg',
    'mute',
  ];

  const events =
    rest.events && Array.isArray(rest.events)
      ? rest.events.map((el) => ({
          ...el,
          className: validClassNames.find((item) => item === el.className)
            ? `event-${el.className?.toString() ?? 'unknown'}`
            : 'event-info',
        }))
      : [];

  return (
    <Card sx={{height: '100%'}}>
      <CalendarRoot p={2}>
        <FullCalendar
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          height="100%"
          locale={user?.locale}
        />
      </CalendarRoot>
    </Card>
  );
}

export default Calendar;
