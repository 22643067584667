import {Dialog, DialogTitle, DialogContent, DialogActions, Divider, CircularProgress} from '@mui/material';
import {
  GetExampleHookCommand,
  type Hook,
  GetHookCommand,
  EditHookCommand,
  CreateHookCommand,
  DeleteHookCommand,
} from '@perfectpost/perfect-post-common';
import {components} from '@perfectpost/perfect-post-common/src/cms/api';
import {EmitterSource} from 'quill';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SingleValue} from 'react-select';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftEditor from 'src/theme/components/SoftEditor';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';

import TipCard from '../tipscard/TipCards';

const regex = /(<([^>]+)>)/gi;

type HookModalProps = {
  handleClose: () => void;
  handleConfirm: (content: string) => void;
  mention?: boolean;
};

export default function HookModal(props: HookModalProps) {
  const {handleClose, handleConfirm, mention} = props;
  const premium = useAppSelector((state) => state.main.premium);
  const locale = useAppSelector((state) => state.main.user?.locale);
  const {t} = useTranslation();
  const [formState, setFormState] = useState<'idle' | 'working'>('idle');
  const [hook, setHook] = useState<components['schemas']['HookListResponseDataItem'][]>([]);
  const [customHook, setCustomHook] = useState<Hook[]>([]);

  const [selectedItemHook, setSelectedItemHook] = useState<SingleValue<{
    value: string;
    label: string;
  }> | null>(null);
  const hookOption = useMemo<{value: string; label: string}[]>(() => {
    const options = hook.map((item) => ({
      label: item.attributes?.title ?? '',
      value: item.id?.toString() ?? '',
    }));
    if (premium) {
      return options;
    } else {
      return [
        ...options,
        {
          label: t('ctamodal.gopremium'),
          value: 'PREMIUM',
        },
      ];
    }
  }, [hook]);

  const [input, setInput] = useState<string>('');

  const [selectedItemCustomHook, setSelectedItemCustomHook] = useState<SingleValue<{
    value: string;
    label: string;
  }> | null>(null);

  const fetchHooks = useCallback(
    async (page: number = 1) => {
      const data = await perfectPostServiceClient.send(new GetExampleHookCommand({page, locale: locale ?? 'en'}));
      setHook((prev) => [...prev, ...(data.data ?? [])]);
      const pagination = data.meta?.pagination;
      if (
        pagination?.page !== undefined &&
        pagination.pageCount !== undefined &&
        pagination.pageCount !== pagination.page
      ) {
        fetchHooks(pagination.page + 1);
      }
    },
    [locale],
  );

  useEffect(() => {
    setHook([]);
    fetchHooks();
    perfectPostServiceClient.send(new GetHookCommand()).then((data) => setCustomHook(data));
  }, [fetchHooks]);

  const onSubmit = () => {
    if (input !== '') {
      handleConfirm(input);
    }
  };

  const onSelectItem = (newValue: SingleValue<{value: string; label: string}> | null) => {
    if (newValue?.value === 'PREMIUM') {
      return;
    }
    setSelectedItemHook(newValue);
    setSelectedItemCustomHook(null);
    const content = hook.find((item) => item.id?.toString() === newValue?.value)?.attributes?.title;
    if (content) {
      setInput(content.replaceAll('\n', '<br />'));
    }
  };

  const onSelectCustomItem = (newValue: SingleValue<{value: string; label: string}> | null) => {
    setSelectedItemHook(null);
    setSelectedItemCustomHook(newValue);
    const content = customHook.find((item) => item._id === newValue?.value)?.content;
    if (content) {
      setInput(content);
    }
  };

  const onInputChange: (content: string, source?: EmitterSource) => void = (value, sources) => {
    setInput(value);
    if (sources === 'user') {
      setSelectedItemHook(null);
    }
  };

  const saveCustomHook = async () => {
    setFormState('working');
    let updatedHook: Hook;
    if (selectedItemCustomHook) {
      updatedHook = await perfectPostServiceClient.send(
        new EditHookCommand({
          id: selectedItemCustomHook.value,
          content: input,
        }),
      );
      setCustomHook(customHook.map((item) => (item._id === updatedHook._id ? updatedHook : item)));
    } else {
      updatedHook = await perfectPostServiceClient.send(new CreateHookCommand(input));
      setCustomHook([...customHook, updatedHook]);
    }
    setSelectedItemCustomHook({
      label: updatedHook.content.replace(regex, '').slice(0, 25),
      value: updatedHook._id,
    });
    setFormState('idle');
  };

  const onRemoveCustomHook = () => {
    if (selectedItemCustomHook !== null) {
      perfectPostServiceClient.send(new DeleteHookCommand({id: selectedItemCustomHook.value}));
      setCustomHook(customHook.filter((item) => item._id !== selectedItemCustomHook.value));
      setSelectedItemCustomHook(null);
      setInput('');
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md">
      <DialogTitle>
        <SoftTypography>{t('hookmodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <TipCard informationkey="modalhook" />
        <SoftBox my={1} ml={0.5} display="flex" flexDirection="row" alignItems="center">
          <SoftTypography component="label" variant="caption" fontWeight="bold" mr={2}>
            {t('hookmodal.labelselect')}
          </SoftTypography>
          <SoftBox sx={{flexGrow: 1}}>
            <SoftSelect options={hookOption} value={selectedItemHook} onChange={onSelectItem} />
          </SoftBox>
        </SoftBox>
        <SoftBox my={1} ml={0.5} display="flex" flexDirection="row" alignItems="center">
          <SoftTypography component="label" variant="caption" fontWeight="bold" mr={2}>
            {t('hookmodal.labelcustom')}
          </SoftTypography>
          <SoftBox sx={{flexGrow: 1}}>
            <SoftSelect
              options={customHook.map((item) => ({
                label: item.content.replace(regex, '').slice(0, 25),
                value: item._id,
              }))}
              value={selectedItemCustomHook}
              onChange={onSelectCustomItem}
            />
          </SoftBox>
        </SoftBox>

        <Divider />

        <SoftEditor
          key={selectedItemCustomHook?.value ?? selectedItemHook?.value}
          premium={premium}
          noCounter
          noToolbar
          noSeeMore
          initialValue={input}
          onChange={onInputChange}
          mention={mention}
        />
        <SoftBox sx={{mb: 1}} />
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={saveCustomHook} color="primary" disabled={selectedItemHook !== null}>
          {formState === 'idle' ? t('global.save') : <CircularProgress size={20} color="info" />}
        </SoftButton>
        {selectedItemCustomHook && (
          <SoftButton variant="gradient" onClick={onRemoveCustomHook}>
            {t('global.delete')}
          </SoftButton>
        )}
        <SoftButton variant="gradient" onClick={handleClose} autoFocus>
          {t('scheduledialog.cancelbtn', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton variant="gradient" onClick={onSubmit} disabled={input === ''}>
          {t('scheduledialog.import', {defaultValue: 'Import into publication'})}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
