import Card from '@mui/material/Card';
import React from 'react';
import {useAppSelector} from 'src/store';
import SoftBadge from 'src/theme/components/SoftBadge';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';

type NumberCardProps = {
  title: string;
  data: number | string;
  progress?: number;
  mode: 'number' | 'percentage';
};
export default function NumberCard({title, data, progress, mode}: NumberCardProps) {
  const locale = useAppSelector((state) => state.main.user?.locale);
  return (
    <Card>
      <SoftBox p={3}>
        <SoftTypography variant="body2" color="text" fontWeight="regular">
          {title}
        </SoftTypography>
        <SoftBox mt={2} mb={1} lineHeight={0}>
          <SoftTypography variant="h3" fontWeight="bold">
            {typeof data === 'number'
              ? mode === 'percentage'
                ? `${(data * 100).toLocaleString(locale, {
                    maximumFractionDigits: 2,
                  })}%`
                : data.toLocaleString(locale, {
                    maximumFractionDigits: 2,
                  })
              : data}
          </SoftTypography>
        </SoftBox>
        {progress && (
          <SoftBadge
            variant="contained"
            color="success"
            badgeContent={`
          ${(progress * 100).toLocaleString(locale, {
            maximumFractionDigits: 2,
            signDisplay: 'always',
          })}
          ${mode === 'percentage' ? 'pts' : '%'}
        `}
            container
          />
        )}
      </SoftBox>
    </Card>
  );
}
