import ChatIcon from '@mui/icons-material/Chat';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {Card, CircularProgress, Grid, Icon} from '@mui/material';
import {
  CommandQueue,
  RefreshActivityCommand,
  ReloadActivityCommand,
  StatusCommand,
} from '@perfectpost/perfect-post-common';
import {
  addMonths,
  eachDayOfInterval,
  isFuture,
  isSameDay,
  startOfWeek,
  endOfWeek,
  parseISO,
  isBefore,
  isAfter,
} from 'date-fns';
import React, {useCallback, useEffect, useState} from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ActivityRings from 'react-activity-rings';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import ActivityParamsModal from 'src/components/modals/ActivityParamsModal';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {getActivity, selectFullActivity} from 'src/store/activityslice';
import {dismissFinishedCommand, selectCommandById, submitMessage} from 'src/store/extension/slice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormat} from 'src/utils/date-format';

const MIN_BEGIN = new Date(2023, 2, 1);
const activityConfig = {
  width: 70,
  height: 70,
  radius: 16,
  ringSize: 4,
};

type RefreshState =
  | {
      loadingMode: 'full';
      currentMode: 'comments' | 'reactions';
      refreshCommandId: string;
    }
  | {
      loadingMode: 'basic';
      refreshCommandId: string;
      nextRefreshCommandId?: string;
    };

type RefreshResult = {
  comments?:
    | {
        lastDate: string;
        paginationToken: string;
        count: number;
        start: number;
      }
    | {error: string};
  reactions?:
    | {
        lastDate: string;
        paginationToken: string;
        count: number;
        start: number;
      }
    | {error: string};
};

export default function Activity() {
  const {t} = useTranslation();
  useDocumentTitle(t('activity.documenttitle', {defaultValue: 'Activity'}));
  const premium = useAppSelector((state) => state.main.premium);
  const locale = useAppSelector((state) => state.main.user?.locale);
  const interval = useAppSelector((state) => state.activity.interval);
  const datas = useAppSelector(selectFullActivity);
  const dispatch = useAppThunkDispatch();

  const [showActivityParams, setShowActivityParams] = useState(false);
  const [refreshState, setRefreshState] = useState<RefreshState | undefined>();
  const [refreshResult, setRefreshResult] = useState<RefreshResult>({});

  const refreshCommand = useAppSelector((state) => selectCommandById(state, refreshState?.refreshCommandId));

  useEffect(() => {
    dispatch(getActivity(interval));
  }, []);

  useEffect(() => {
    if (refreshCommand?.finished === true && refreshState !== undefined) {
      void perfectPostServiceClient.send(new StatusCommand(refreshCommand.commandId)).then(async (cmd) => {
        const result = cmd?.result;
        if (cmd === undefined || result === undefined) {
          return;
        }
        void dispatch(dismissFinishedCommand({commandId: refreshCommand.commandId}));
        const rr = result as
          | {
              lastDate: string;
              paginationToken: string;
              count: number;
              start: number;
            }
          | {error: string};
        if (refreshState.loadingMode === 'full') {
          let command: CommandQueue | undefined;
          if ('error' in rr) {
            setRefreshResult({
              [cmd.command._internalName === 'get-feed-dash-profile-updates-by-member-comments'
                ? 'comments'
                : 'reactions']: rr,
            });
            setRefreshState(undefined);
            dispatch(getActivity(interval));
          } else if (
            isBefore(parseISO(rr.lastDate), interval.start) &&
            cmd.command._internalName === 'get-feed-dash-profile-updates-by-member-comments'
          ) {
            command = await perfectPostServiceClient.send(
              new RefreshActivityCommand({
                mode: 'reactions',
                count: 20,
                start: 0,
              }),
            );
            setRefreshState({
              refreshCommandId: command.command._id ?? '',
              loadingMode: 'full',
              currentMode: 'reactions',
            });
            setRefreshResult((prev) => ({
              ...prev,
              comments: rr,
            }));
          } else if (isAfter(parseISO(rr.lastDate), interval.start)) {
            command = await perfectPostServiceClient.send(
              new RefreshActivityCommand({
                mode:
                  cmd.command._internalName === 'get-feed-dash-profile-updates-by-member-comments'
                    ? 'comments'
                    : 'reactions',
                paginationToken: rr.paginationToken,
                count: rr.count,
                start: rr.start + rr.count,
                previousCommand: refreshCommand.commandId,
              }),
            );
            setRefreshState({
              refreshCommandId: command.command._id ?? '',
              loadingMode: 'full',
              currentMode:
                cmd.command._internalName === 'get-feed-dash-profile-updates-by-member-comments'
                  ? 'comments'
                  : 'reactions',
            });
            setRefreshResult((prev) => ({
              ...prev,
              [cmd.command._internalName === 'get-feed-dash-profile-updates-by-member-comments'
                ? 'comments'
                : 'reactions']: rr,
            }));
          } else {
            setRefreshResult((prev) => ({
              ...prev,
              [cmd.command._internalName === 'get-feed-dash-profile-updates-by-member-comments'
                ? 'comments'
                : 'reactions']: rr,
            }));
            setTimeout(() => {
              setRefreshResult({});
            }, 10000);
            setRefreshState(undefined);
            dispatch(getActivity(interval));
          }
          dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
        } else {
          setRefreshResult((prev) => ({
            ...prev,
            [refreshState?.nextRefreshCommandId === undefined ? 'comments' : 'reactions']: rr,
          }));
          if (refreshState?.loadingMode === 'basic' && refreshState.nextRefreshCommandId !== undefined) {
            console.log('refreshState.nextRefreshCommandId', refreshState);
            setRefreshState({
              refreshCommandId: refreshState.nextRefreshCommandId,
              loadingMode: 'basic',
              nextRefreshCommandId: undefined,
            });
            dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
          } else {
            setTimeout(() => {
              setRefreshResult({});
            }, 10000);
            setRefreshState(undefined);
            dispatch(getActivity(interval));
          }
        }
      });
    }
  }, [refreshState, refreshCommand]);

  const onChangeMonth = (n: number) => () => {
    if ((n === -1 && isSameDay(MIN_BEGIN, interval.start)) || (n === 1 && isFuture(interval.end))) {
      return;
    }
    dispatch(getActivity({start: addMonths(interval.start, n), end: addMonths(interval.end, n)}));
  };

  const onConfirmSettings = async () => {
    await dispatch(getActivity({start: interval.start, end: interval.end}));
    setShowActivityParams(false);
  };

  const onRefresh = useCallback(() => {
    perfectPostServiceClient.send(new ReloadActivityCommand()).then(({comments, reactions}) => {
      const commandId = comments.command._id;
      if (commandId === undefined) {
        return;
      }
      setRefreshState({refreshCommandId: commandId, loadingMode: 'basic', nextRefreshCommandId: reactions.command._id});
      // call twice to enqueue both comments and reactions
      dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
      dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
    });
  }, []);

  const onFullRefresh = useCallback(() => {
    perfectPostServiceClient
      .send(
        new RefreshActivityCommand({
          mode: 'comments',
          count: 20,
          start: 0,
        }),
      )
      .then((command) => {
        const commandId = command.command._id;
        if (commandId === undefined) {
          return;
        }
        setRefreshState({refreshCommandId: commandId, loadingMode: 'full', currentMode: 'comments'});
        dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
      });
  }, []);

  return (
    <SoftBox>
      <SoftTypography mb={2} variant="h2" textTransform="none" fontWeight="bold">
        {t('activity.title', {defaultValue: 'Activities statistics'})}
      </SoftTypography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Grid container m={0} width="100%" spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                px: 2,
                pt: 2,
                borderTopLeftRadius: '1rem',
                borderTopRightRadius: '1rem',
                backgroundColor: '#fff',
              }}>
              {isSameDay(MIN_BEGIN, interval.start) === false ? (
                <SoftButton iconOnly onClick={onChangeMonth(-1)}>
                  <Icon>arrow_back_ios</Icon>
                </SoftButton>
              ) : (
                <div style={{width: '32px'}} />
              )}
              <SoftBox>
                {t('activity.goalof')} {localFormat(interval.start, 'MMMM yyyy', locale)}
              </SoftBox>
              {isFuture(interval.end) ? (
                <div style={{width: '32px'}} />
              ) : (
                <SoftButton iconOnly onClick={onChangeMonth(1)}>
                  <Icon>arrow_forward_ios</Icon>
                </SoftButton>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                px: 2,
                backgroundColor: '#fff',
              }}>
              {eachDayOfInterval({
                start: startOfWeek(new Date(), {weekStartsOn: 1}),
                end: endOfWeek(new Date(), {weekStartsOn: 1}),
              }).map((value) => (
                <SoftTypography
                  key={`day-${value.getTime()}`}
                  variant="caption"
                  sx={{width: 75, textAlign: 'center', flex: '0 0 calc(100% * 1 / 7)'}}>
                  {localFormat(value, 'EEEE', locale)}
                </SoftTypography>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                px: 2,
                backgroundColor: '#fff',
              }}>
              <>
                {datas.length > 0 &&
                  Array.from({length: datas[0].day.getDay() === 0 ? 6 : datas[0].day.getDay() - 1}, (_, i) => (
                    <SoftBox key={i} sx={{height: 75, flex: '0 0 calc(100% * 1 / 7)'}} />
                  ))}
                {datas.map((data) => (
                  <SoftBox
                    key={data.day.getTime()}
                    sx={{
                      flex: '0 0 calc(100% * 1 / 7)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 2,
                    }}>
                    <SoftTypography>{localFormat(data.day, 'dd MMM', locale)}</SoftTypography>
                    <ActivityRings data={data.values} config={activityConfig} />
                    <SoftTypography
                      variant="caption"
                      color="secondary"
                      sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                      <span style={{display: 'flex', gap: 2, lineHeight: '10px', alignItems: 'center'}}>
                        {data.values[0].realValue} <ThumbUpIcon />
                      </span>
                      <span style={{display: 'flex', gap: 2, lineHeight: '10px', alignItems: 'center'}}>
                        {data.values[1].realValue} <ChatIcon />
                      </span>
                    </SoftTypography>
                  </SoftBox>
                ))}
              </>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                px: 2,
                pb: 2,
                backgroundColor: '#fff',
                borderBottomLeftRadius: '1rem',
                borderBottomRightRadius: '1rem',
              }}>
              <SoftTypography
                variant="caption"
                color="white"
                sx={{
                  color: 'white',
                  fontSize: '0.75rem',
                  fontWeight: 700,
                  backgroundColor: 'rgb(112, 190, 215)',
                  p: 1,
                  borderRadius: '0.5rem',
                  mr: 1,
                }}>
                {t('global.like')}
              </SoftTypography>
              <SoftTypography
                variant="caption"
                color="white"
                sx={{
                  color: 'white',
                  fontSize: '0.75rem',
                  fontWeight: 700,
                  backgroundColor: 'rgb(148, 213, 90)',
                  p: 1,
                  borderRadius: '0.5rem',
                }}>
                {t('global.comment')}
              </SoftTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
          <SoftBox display="flex" flexDirection="row" alignItems="center" gap={2}>
            <SoftButton disabled={refreshState !== undefined} onClick={onRefresh}>
              {refreshState !== undefined ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                t('activity.refreshtoday', {defaultValue: 'Mettre à jour mon activité du jour'})
              )}
            </SoftButton>
            <SoftButton disabled={refreshState !== undefined} onClick={onFullRefresh}>
              {refreshState !== undefined ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                t('activity.refreshfull', {
                  defaultValue: 'Recharger toutes les données depuis le 1er {{month}}',
                  month: localFormat(interval.start, 'MMMM', locale),
                })
              )}
            </SoftButton>
          </SoftBox>
          <SoftBox sx={{px: 1}}>
            {refreshResult !== undefined &&
              ('error' in refreshResult ? (
                <SoftTypography variant="body2" color="error">
                  {refreshResult.error}
                </SoftTypography>
              ) : (
                <>
                  {refreshResult.comments &&
                    ('error' in refreshResult.comments ? (
                      <SoftTypography variant="body2" color="error">
                        {refreshResult.comments.error}
                      </SoftTypography>
                    ) : (
                      <SoftTypography variant="body2">
                        {t('activity.comments.refreshed', {
                          defaultValue: "Commentaire recupéré jusqu'au: {{date}}",
                          date: localFormat(new Date(refreshResult.comments.lastDate), 'dd MMMM', locale),
                        })}
                      </SoftTypography>
                    ))}
                  {refreshResult.reactions &&
                    ('error' in refreshResult.reactions ? (
                      <SoftTypography variant="body2" color="error">
                        {refreshResult.reactions.error}
                      </SoftTypography>
                    ) : (
                      <SoftTypography variant="body2">
                        {t('activity.reactions.refreshed', {
                          defaultValue: "Réactions recupéré jusqu'au: {{date}}",
                          date: localFormat(new Date(refreshResult.reactions.lastDate), 'dd MMMM', locale),
                        })}
                      </SoftTypography>
                    ))}
                </>
              ))}
          </SoftBox>
          <Card>
            <SoftBox bgColor="info" variant="gradient">
              <SoftBox p={2}>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <SoftBox ml={2} lineHeight={1}>
                      <SoftTypography variant="button" color={'white'} opacity={17} fontWeight="medium">
                        {t('activity.titlesettings')}
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={4}>
                    {premium ? (
                      <SoftButton
                        variant="gradient"
                        color="white"
                        sx={{
                          width: '3rem',
                          height: '3rem',
                          marginLeft: 'auto',
                          borderRadius: 'md',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => setShowActivityParams(true)}>
                        <Icon fontSize="small" color="inherit">
                          settings
                        </Icon>
                      </SoftButton>
                    ) : (
                      <SoftButton
                        className="overlay"
                        variant="gradient"
                        size="small"
                        color="dark"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={NavLink}>
                        {t('statisticcard.overlaypremium.btn', {defaultValue: 'Go premium'})}
                      </SoftButton>
                    )}
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </Card>
          {showActivityParams && (
            <ActivityParamsModal handleClose={() => setShowActivityParams(false)} handleConfirm={onConfirmSettings} />
          )}
        </Grid>
      </Grid>
    </SoftBox>
  );
}
