import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {createReduxEnhancer} from '@sentry/react';
import localforage from 'localforage';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {persistStore, persistReducer} from 'redux-persist';
import initSubscriber from 'redux-subscriber';

import activitysliceReducer from './activityslice';
import datasliceReducer from './dataslice';
import draftsliceReducer from './draftslice';
import {extensionMiddleware} from './extension/middleware';
import extensionsliceReducer from './extension/slice';
import mainsliceReducer from './mainslice';
import postsliceReducer from './postslice';
import profilesliceReducer from './profileslice';
import teamssliceReduced from './teamslice';
import tipssliceReducer from './tipsslice';

const persistConfig = {
  key: 'perfectpost',
  storage: localforage,
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const sentryReduxEnhancer = createReduxEnhancer();

const mainPersistedReducer = persistReducer(persistConfig, mainsliceReducer);
const rootReducer = combineReducers({
  main: mainPersistedReducer,
  extension: extensionsliceReducer,
  tips: tipssliceReducer,
  draft: draftsliceReducer,
  post: postsliceReducer,
  profile: profilesliceReducer,
  data: datasliceReducer,
  activity: activitysliceReducer,
  team: teamssliceReduced,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(extensionMiddleware),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export const subscribe = initSubscriber(store);

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppThunkDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
