import Quill from 'quill';
import Delta from 'quill-delta';

declare global {
  interface Window {
    Quill?: typeof Quill;
  }
}

export type HashtagOptions = {
  globalRegularExpression: RegExp;
  hashtagRegularExpression: RegExp;
};

export type Normalizer = (stringToNormalize: string) => string;

const defaults = {
  globalRegularExpression: /(?:^|\s)#[^\s.,;:!?]+/gi,
  hashtagRegularExpression: /(?:^|\s)#[^\s.,;:!?]+/gi,
};

export default class Hashtag {
  quill: Quill;
  options: HashtagOptions;

  constructor(quill: Quill, options?: Partial<HashtagOptions>) {
    this.quill = quill;
    options = options || {};
    this.options = {...defaults, ...options};
    this.registerTypeListener();
    this.registerPasteListener();
    this.registerBlurListener();
  }
  registerPasteListener() {
    // Preserves existing links
    this.quill.clipboard.addMatcher('STRONG', (node, delta) => {
      const attributes = delta.ops[0]?.attributes;
      if (attributes?.hashtag != null) {
        attributes.hashtag = true;
      }
      return delta;
    });
    this.quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
      const data = 'data' in node ? node.data : undefined;
      if (typeof data !== 'string') {
        return delta;
      }
      const urlRegExp = this.options.globalRegularExpression;
      urlRegExp.lastIndex = 0;
      const newDelta = new Delta();
      let index = 0;
      let urlResult = urlRegExp.exec(data);
      const handleMatch = (result: RegExpExecArray, regExp: RegExp) => {
        const head = data.substring(index, result.index);
        newDelta.insert(head);
        const match = result[0];
        newDelta.insert(match, {hashtag: true});
        index = regExp.lastIndex;
        return regExp.exec(data);
      };
      while (urlResult !== null) {
        urlResult = handleMatch(urlResult, urlRegExp);
      }
      if (index > 0) {
        const tail = data.substring(index);
        newDelta.insert(tail);
        delta.ops = newDelta.ops;
      }
      return delta;
    });
  }
  registerTypeListener() {
    this.quill.on('text-change', (delta) => {
      const ops = delta.ops;
      // Only return true, if last operation includes whitespace inserts
      // Equivalent to listening for enter, tab or space
      if (!ops || ops.length < 1 || ops.length > 2) {
        return;
      }
      const lastOp = ops[ops.length - 1];
      if (!lastOp.insert || typeof lastOp.insert !== 'string' || !lastOp.insert.match(/\s/)) {
        return;
      }
      this.checkTextForHashtag(!!lastOp.insert.match(/ |\t/));
    });
  }
  registerBlurListener() {
    this.quill.root.addEventListener('blur', () => {
      this.checkTextForHashtag();
    });
  }
  checkTextForHashtag(triggeredByInlineWhitespace = false) {
    const sel = this.quill.getSelection();
    if (!sel) {
      return;
    }
    const [leaf] = this.quill.getLeaf(sel.index);
    if (leaf === null) {
      return;
    }
    const leafIndex = this.quill.getIndex(leaf);
    const leaf_text = leaf.value() as unknown;
    if (typeof leaf_text !== 'string') {
      return;
    }

    // We only care about the leaf until the current cursor position
    const relevantLength = sel.index - leafIndex;
    const text: string = leaf_text.slice(0, relevantLength);
    if (!text || leaf.parent.domNode.localName === 'strong') {
      return;
    }

    const nextLetter = leaf_text[relevantLength];
    // Do not proceed if we are in the middle of a word
    if (nextLetter != null && nextLetter.match(/\S/)) {
      return;
    }

    const bailOutEndingRegex = triggeredByInlineWhitespace ? /\s\s$/ : /\s$/;
    if (text.match(bailOutEndingRegex)) {
      return;
    }

    const regexMatches = text.match(this.options.hashtagRegularExpression);
    if (regexMatches) {
      this.handleMatches(leafIndex, text, regexMatches);
    }
  }
  handleMatches(leafIndex: number, text: string, matches: RegExpMatchArray) {
    const match = matches.pop();
    if (match === undefined) {
      return;
    }
    const matchIndex = text.lastIndexOf(match.trim());
    const after = text.split(match.trim()).pop();
    if (after === undefined || after.match(/\S/)) {
      return;
    }
    this.updateText(leafIndex + matchIndex, match.trim());
  }
  updateText(index: number, string: string) {
    const ops = new Delta().retain(index).retain(string.length, {hashtag: true});
    this.quill.updateContents(ops);
  }
}
