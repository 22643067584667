/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  MemberURN,
  OrganizationURN,
  PersonURN,
  FsdProfileURN,
  FsdCompanyURN,
} from '@perfectpost/linkedin-privateapi-ts-client';
import type {InlineBlot, ScrollBlot} from 'parchment';
import Quill from 'quill';

const Inline = Quill.import('blots/inline') as typeof InlineBlot;

export interface MentionBlotData {
  objectUrn: MemberURN | OrganizationURN;
  entityUrn: FsdProfileURN | FsdCompanyURN;
  originalText: string;
  vanityName: string;
  mentionUrn: PersonURN | OrganizationURN;
}

class Mention extends Inline {
  static blotName = 'qlMention';
  static tagName = 'A';
  static className = 'ql-mention';

  constructor(scroll: ScrollBlot, node: Node) {
    super(scroll, node);
  }

  static create(data: MentionBlotData) {
    const node = super.create();
    node.contentEditable = 'false';
    node.innerText = data.originalText;
    Object.keys(data).forEach((key) => {
      node.dataset[key] = data[key as keyof MentionBlotData];
    });
    return node;
  }

  static value(domNode: HTMLElement): any {
    return {
      objectUrn: domNode.dataset.objectUrn as MemberURN | OrganizationURN,
      entityUrn: domNode.dataset.entityUrn as FsdProfileURN | FsdCompanyURN,
      originalText: domNode.dataset.originalText as string,
      vanityName: domNode.dataset.vanityName as string,
      mentionUrn: domNode.dataset.mentionUrn as PersonURN | OrganizationURN,
    };
  }

  static formats(domNode: HTMLElement): MentionBlotData | undefined {
    if (
      domNode.dataset.objectUrn === undefined ||
      domNode.dataset.entityUrn === undefined ||
      domNode.dataset.originalText === undefined ||
      domNode.dataset.vanityName === undefined ||
      domNode.dataset.mentionUrn === undefined
    ) {
      return undefined;
    }
    return {
      objectUrn: domNode.dataset.objectUrn as MemberURN | OrganizationURN,
      entityUrn: domNode.dataset.entityUrn as FsdProfileURN | FsdCompanyURN,
      originalText: domNode.dataset.originalText,
      vanityName: domNode.dataset.vanityName,
      mentionUrn: domNode.dataset.mentionUrn as PersonURN | OrganizationURN,
    };
  }
}

export default Mention;
