import {PerfectPostMessage, isPerfectPostMessageResponse, isQueueStateEvent} from '@perfectpost/perfect-post-common';
import {Dispatch, MiddlewareAPI, UnknownAction} from '@reduxjs/toolkit';
import {Middleware} from 'redux';

import {extensionSlice, startConnecting, submitMessage} from './slice';
import {RootState} from '..';

export const extensionMiddleware: Middleware = (store: MiddlewareAPI<Dispatch<UnknownAction>, RootState>) => {
  return (next) => (action) => {
    const isConnectionEstablished = store.getState().extension.version !== undefined;

    if (startConnecting.match(action)) {
      window.addEventListener('message', (event: MessageEvent) => {
        if (event.origin !== process.env.REACT_APP_SAAS_URL) {
          return;
        }
        if (isPerfectPostMessageResponse(event.data)) {
          if (event.data.type === 'check_install_response') {
            clearTimeout(timer);
            store.dispatch(extensionSlice.actions.connectionEstablished(event.data));
          }
        } else if (isQueueStateEvent(event.data)) {
          clearTimeout(timer);
          store.dispatch(extensionSlice.actions.syncQueueState(event.data));
        }
      });

      const message: PerfectPostMessage = {source: 'perfectpost', type: 'check_install'};
      window.postMessage(message, {targetOrigin: process.env.REACT_APP_SAAS_URL});
      const timer = setTimeout(() => {
        store.dispatch(extensionSlice.actions.extensionNotFound());
      }, 30000);
    }

    if (submitMessage.match(action) && isConnectionEstablished) {
      window.postMessage(action.payload, {targetOrigin: process.env.REACT_APP_SAAS_URL});
    }

    next(action);
  };
};
