import {Grid, Container} from '@mui/material';
import {
  Draft,
  GetOnePublicDraftCommand,
  GetOneUserCommand,
  GetUrlSharingDraftCommand,
  MulitpleAssets,
  Poll,
} from '@perfectpost/perfect-post-common';
import {PublicUser} from '@perfectpost/perfect-post-common';
import {SingleAssets} from '@perfectpost/perfect-post-common';
import React, {useState, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, NavLink, useParams} from 'react-router-dom';
import noPic from 'src/assets/images/nopic.png';
import logo from 'src/assets/images/pp_icon512.png';
import ActivityPreview from 'src/components/activitypreview/ActivityPreview';
import {perfectPostServiceClient} from 'src/services';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

export default function DraftSharing() {
  const {id} = useParams();
  const {t} = useTranslation();
  useDocumentTitle(t('draft.documenttitle', {defaultValue: 'Draft'}));

  const [draft, setDraft] = useState<Draft | undefined>();
  const [user, setUser] = useState<PublicUser | undefined>();
  const [sharingUrl, setSharingUrl] = useState<string | undefined>();

  const sharing: SingleAssets | MulitpleAssets | Poll | undefined = useMemo(() => {
    if (
      (draft?.linkedin?.carrousel || draft?.linkedin?.image || draft?.linkedin?.video) &&
      draft?.sharing === undefined
    ) {
      const singleAssets: SingleAssets = {
        type: draft.linkedin.video ? 'video' : draft.linkedin.image ? 'image' : 'pdf',
        url: draft.linkedin.image ?? draft.linkedin.video ?? draft.linkedin.carrousel ?? '',
        mimetype: draft.linkedin.video ? 'video/mp4' : undefined,
        path: '',
      };
      return singleAssets;
    } else if (draft?.sharing !== undefined) {
      return draft?.sharing && draft.sharing.type !== 'poll' && draft.sharing.type !== 'images'
        ? {...draft.sharing, url: sharingUrl ?? ''}
        : draft?.sharing;
    } else {
      return undefined;
    }
  }, [draft?.sharing, sharingUrl]);

  useEffect(() => {
    if (id !== undefined) {
      void perfectPostServiceClient.send(new GetOnePublicDraftCommand(id)).then((d) => {
        setDraft(d);
        if (d.sharing && d.sharing.type !== 'poll') {
          perfectPostServiceClient.send(new GetUrlSharingDraftCommand(id)).then(({url}) => setSharingUrl(url));
        }
        perfectPostServiceClient.send(new GetOneUserCommand({id: d.user})).then(setUser);
      });
    }
  }, [id]);

  return (
    <SoftBox px={3}>
      <Container>
        <SoftBox
          py={1}
          px={{xs: 5, sm: 5, lg: 5}}
          my={2}
          mx={3}
          width="calc(100% - 48px)"
          borderRadius="section"
          shadow={'md'}
          color={'white'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          left={0}
          zIndex={3}
          sx={({palette: {white}, functions: {rgba}}) => ({
            backgroundColor: rgba(white.main, 0.8),
            backdropFilter: `saturate(200%) blur(30px)`,
          })}>
          <SoftBox component={NavLink} to="/signin" display="flex" alignItems="center">
            <SoftBox component="img" src={logo} alt="PerfectPost Logo" width="2rem" />
            <SoftBox ml="2">
              <SoftTypography component="h6" variant="button" fontWeight="medium" color="text">
                PerfectPost
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox display={{xs: 'none', lg: 'inline-block'}}>
            <SoftButton
              component={Link}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              to="/signin"
              target="_blank"
              rel="noreferrer"
              variant="gradient"
              color="info"
              size="small"
              circular>
              CONNEXION
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </Container>
      <Grid container spacing={2}>
        <Grid item sm={false} lg={3} />
        <Grid item sm={12} lg={6}>
          {user && (
            <ActivityPreview
              fullname={user !== undefined && 'firstname' in user ? `${user.firstname} ${user.lastname}` : ''}
              picture={user.lnPicture ?? noPic}
              content={draft?.content ?? ''}
              headline={user !== undefined && 'firstname' in user ? user?.headline : undefined}
              showMore={draft?.sharing !== undefined ? '3-lines' : '5-lines'}
              sharing={sharing}
            />
          )}
        </Grid>
      </Grid>
    </SoftBox>
  );
}
