import React from 'react'; // @mui material components
import {NavLink} from 'react-router-dom';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
type MessageCardProps = {
  description: string;
  btnText?: string;
  to?: string;
  bgColor?: 'white' | 'dark';
};

export default function MessageCard({description, btnText, to, bgColor}: MessageCardProps) {
  return (
    <SoftBox
      bgColor={bgColor ? bgColor : 'white'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'centre',
        textAlign: 'center',
        p: 3,
        borderRadius: '1rem',
        justifyContent: 'center',
      }}>
      <SoftBox mb={1} lineHeight={0}>
        <SoftTypography
          variant="subtitle2"
          color={bgColor === 'dark' ? 'white' : 'text'}
          fontWeight="medium"
          textTransform="none">
          {description}
        </SoftTypography>
      </SoftBox>
      {btnText && to && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <SoftButton component={NavLink} to={to} variant="gradient" color={bgColor === 'dark' ? 'white' : 'dark'}>
          {btnText}
        </SoftButton>
      )}
    </SoftBox>
  );
}
