import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import {type OrganizationURN, isFsdProfileURN} from '@perfectpost/linkedin-privateapi-ts-client';
import {
  Draft,
  ProfileByUrnCommand,
  SearchCommand,
  StatusCommand,
  TypeheadApiCommand,
  GetFirstCommentUrlCommand,
} from '@perfectpost/perfect-post-common';
import axios, {AxiosRequestConfig} from 'axios';
import {debounce} from 'lodash';
import Quill, {EmitterSource} from 'quill';
import {Mention, MentionOption} from 'quill-mention';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import noPic from 'src/assets/images/nopic.png';
import {perfectPostServiceClient} from 'src/services';
import {RootState, useAppThunkDispatch} from 'src/store';
import {subscribe} from 'src/store';
import {editDraft} from 'src/store/draftslice';
import {submitMessage} from 'src/store/extension/slice';
import SoftBox from 'src/theme/components/SoftBox';
import HashtagBlot from 'src/theme/components/SoftEditor/blots/hashtag';
import QlMentionBlot from 'src/theme/components/SoftEditor/blots/mention';
import Hashtag from 'src/theme/components/SoftEditor/modules/hashtag';
import SoftProgress from 'src/theme/components/SoftProgress';
import SoftTypography from 'src/theme/components/SoftTypography';

const isOrganizationURN = (urn: unknown): urn is OrganizationURN =>
  typeof urn === 'string' && urn.startsWith('urn:li:organization:');

const itemRenderer = (data: {value: string; picture?: string}) => {
  const picture = data.picture ?? noPic;
  const p = document.createElement('p');
  const img = document.createElement('img');
  img.src = picture;
  const span = document.createElement('span');
  span.innerText = data.value;
  p.appendChild(img);
  p.appendChild(span);
  return p;
};

Quill.register(
  {
    'modules/hashtag': Hashtag,
    'modules/mention': Mention,
    'formats/hashtag': HashtagBlot,
    'formats/qlMention': QlMentionBlot,
  },
  true,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type CommentEditorProps = {
  draftId: string;
  initialValue: string;
  firstCommentContent?: string;
  onChange: (content: string, source?: EmitterSource) => void;
  onMediaUploaded: (draft: Draft) => void;
};

type CommentEditorState = {
  state: 'idle' | 'uploading';
  url?: string;
  progress?: number;
};

export default function CommentEditor({
  draftId,
  initialValue,
  firstCommentContent,
  onChange,
  onMediaUploaded,
}: CommentEditorProps) {
  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();
  const quillContainerRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);
  const searchingCommand = useRef<string[] | undefined>(undefined);

  const [state, setState] = useState<CommentEditorState>({state: 'idle'});

  useEffect(() => {
    const mention: Partial<MentionOption> = {
      allowedChars: /^[A-Za-z\sÀ-ÿ]*$/,
      mentionDenotationChars: ['@'],
      blotName: 'qlMention',
      minChars: 3,
      spaceAfterInsert: false,
      isolateCharacter: true,
      defaultMenuOrientation: 'bottom',
      dataAttributes: ['objectUrn', 'entityUrn', 'originalText', 'vanityName', 'mentionUrn', 'value'],
      renderLoading: () => {
        return 'Loading...';
      },
      renderItem: itemRenderer,
      source: debounce<MentionOption['source']>(async (searchTerm, renderList) => {
        const command = await perfectPostServiceClient.send(new SearchCommand({value: searchTerm}));
        const commandId = command?.command._id;
        if (commandId === undefined) {
          return;
        }
        searchingCommand.current = [commandId];
        dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
        const unsubscribe = subscribe('extension.commandStack', async (state: RootState) => {
          if (
            state.extension.commandStack
              .filter((c) => c.finished)
              .map((c) => c.commandId)
              .includes(commandId)
          ) {
            const result = await perfectPostServiceClient.send(new StatusCommand(commandId));
            if (
              result?.result?.items !== undefined &&
              result.result.items !== null &&
              Array.isArray(result.result.items)
            ) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              renderList(result.result.items, searchTerm);
            }
            searchingCommand.current = undefined;
            unsubscribe();
          }
        });
      }, 500),
      onSelect: async (item, insertItem) => {
        if (isOrganizationURN(item.objectUrn)) {
          insertItem({
            objectUrn: item.objectUrn,
            entityUrn: item.entityUrn,
            originalText: item.value ?? '',
            vanityName: item.value ?? '',
            mentionUrn: item.objectUrn,
          });
        } else if (item.vanityName === undefined && isFsdProfileURN(item.entityUrn)) {
          const resolveVanitName = await perfectPostServiceClient.send(
            new ProfileByUrnCommand({profileUrn: item.entityUrn}),
          );
          const commandId = resolveVanitName?.command._id;
          if (commandId === undefined) {
            return;
          }
          searchingCommand.current = [commandId];
          dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
          const unsubscribe = subscribe('extension.commandStack', async (state: RootState) => {
            if (
              state.extension.commandStack
                .filter((c) => c.finished)
                .map((c) => c.commandId)
                .includes(commandId)
            ) {
              const result = await perfectPostServiceClient.send(new StatusCommand(commandId));
              if (
                result?.result?.vanityName !== undefined &&
                result.result.vanityName !== null &&
                typeof result.result.vanityName === 'string'
              ) {
                const vanityName = result.result.vanityName;
                const search = await perfectPostServiceClient.send(new TypeheadApiCommand({vanityName}));
                const mentionUrnResult = search.pop();
                if (mentionUrnResult !== undefined) {
                  insertItem({
                    objectUrn: item.objectUrn,
                    entityUrn: item.entityUrn,
                    originalText: item.value,
                    vanityName,
                    mentionUrn: mentionUrnResult.member,
                  });
                }
              }
              searchingCommand.current = undefined;
              unsubscribe();
            }
          });
        } else {
          const search = await perfectPostServiceClient.send(new TypeheadApiCommand({vanityName: item.vanityName!}));
          const result = search.pop();
          if (result !== undefined) {
            insertItem({...item, mentionUrn: result.member});
          }
        }
      },
    };
    const modules = {
      toolbar: false,
      hashtag: true,
      mention,
    };
    if (quillContainerRef.current === null) {
      return;
    }

    const quill = new Quill(quillContainerRef.current, {
      theme: 'snow',
      modules,
      formats: ['qlMention', 'hashtag'],
    });
    quillRef.current = quill;
    quill.root.innerHTML = initialValue ?? '';

    quill.root.focus();
    quill.on('text-change', (delta, oldContents, source) => {
      if (quillRef.current) {
        onChange?.(quillRef.current.root.innerHTML, source);
      }
    });
  }, []);

  useEffect(() => {
    if (firstCommentContent !== undefined) {
      perfectPostServiceClient.send(new GetFirstCommentUrlCommand(draftId)).then(({url}) => {
        setState((f) => ({...f, url}));
      });
    } else {
      setState((f) => ({...f, url: undefined}));
    }
  }, [firstCommentContent]);

  const onCommentFileSelected: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    if (event.target.files === null) {
      return;
    }
    setState({state: 'uploading'});
    const file = event.target.files[0];
    const result = await dispatch(editDraft({draftId, firstCommentContent: file.type})).unwrap();
    if (result?.uploadUrlComment !== undefined && result.draft.firstCommentContent !== undefined) {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress(progressEvent) {
          if (progressEvent.loaded !== progressEvent.total) {
            setState((f) => ({
              ...f,
              state: 'uploading',
              progress: (progressEvent.loaded / (progressEvent.total ?? 1)) * 100,
            }));
          }
        },
      };
      void axios.put(result.uploadUrlComment, file, config).then(() => {
        setState((f) => ({...f, state: 'idle', progress: undefined}));
        onMediaUploaded(result.draft);
      });
    }
  };

  return (
    <SoftBox bgColor="white" sx={{height: 'fit-content', position: 'relative'}}>
      <SoftBox ref={quillContainerRef} sx={({borders}) => ({borderRadius: borders.borderRadius.md})}></SoftBox>
      {state.state === 'uploading' ? (
        <SoftBox sx={{display: 'flex', flexDirection: 'column'}}>
          <SoftTypography variant="button" color="text" fontWeight="medium">
            {t('draft.uploadimage', {defaultValue: 'Uploading video'})}
          </SoftTypography>
          <SoftProgress value={state.progress ?? 0} variant="gradient" />
        </SoftBox>
      ) : state.url !== undefined ? (
        <SoftBox component="img" src={state.url} sx={{width: '100%'}} />
      ) : (
        <IconButton
          component="label"
          tabIndex={-1}
          aria-label="add picture"
          sx={{position: 'absolute', top: 0, right: 0}}>
          <Icon>panorama</Icon>
          <VisuallyHiddenInput type="file" accept="image/*" onChange={onCommentFileSelected} />
        </IconButton>
      )}
    </SoftBox>
  );
}
