import {Icon} from '@mui/material';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, To} from 'react-router-dom';
import {useAppSelector} from 'src/store';
import SoftAvatar from 'src/theme/components/SoftAvatar';
import SoftBadge from 'src/theme/components/SoftBadge';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';
import {localFormatISOString} from 'src/utils/date-format';

type DraftCardProps = {
  content: string;
  programmingDate?: string;
  href?: To;
  user?: string;
  organization?: string;
  categories?: string[];
  type?: 'video' | 'image' | 'pdf' | 'images' | 'poll' | undefined;
};

export default function DraftCard({
  content,
  programmingDate,
  href,
  organization,
  user,
  categories,
  type,
}: DraftCardProps) {
  const me = useAppSelector((state) => state.main.user);
  const organizations = useAppSelector((state) => state.main.organizations);
  const members = useAppSelector((state) => state.team.members);

  const userOrOrganization: {pic: string; name: string} = useMemo(() => {
    if (organization) {
      const org = organizations.find((org) => org._id === organization);
      return {pic: org?.picture ?? '', name: org?.localizedName ?? ''};
    } else {
      const author = members.find((member) => member._id === user);
      return {pic: author?.lnPicture ?? '', name: `${author?.firstname ?? ''} ${author?.lastname ?? ''}`};
    }
  }, [members, organizations, organization]);

  const {t} = useTranslation();
  /*
  const renderMembers = members.map(({image: media, name}) => (
    <Tooltip key={name} title={name} placement="bottom">
      <SoftAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({borders: {borderWidth}, palette: {white}}) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          ml: -1.25,
          cursor: 'pointer',
          position: 'relative',

          '&:hover, &:focus': {
            zIndex: '10',
          },
        })}
      />
    </Tooltip>
  ));
  */

  return (
    <Card sx={{width: '100%'}}>
      <SoftBox component={href !== undefined ? NavLink : undefined} p={2} to={href}>
        <SoftBox display="flex" alignItems="center">
          <SoftAvatar src={userOrOrganization.pic} alt={userOrOrganization.name} size="lg" variant="rounded" />
          <SoftBox sx={{ml: 1, flex: 1, display: 'flex', flexDirection: 'column'}}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize" color="dark">
              {userOrOrganization.name}
            </SoftTypography>
            <SoftBox sx={{flexDirection: 'row', display: 'flex'}}>
              {type !== undefined && (
                <Icon sx={{color: '#000'}}>
                  {type === 'video'
                    ? 'video_library'
                    : type === 'image'
                      ? 'image'
                      : type === 'pdf'
                        ? 'picture_as_pdf'
                        : type === 'images'
                          ? 'collections'
                          : type === 'poll'
                            ? 'poll'
                            : ''}
                </Icon>
              )}
              {categories?.map((category) => <SoftBadge key={category} badgeContent={category} />)}
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox my={2}>
          <SoftBox
            dangerouslySetInnerHTML={{__html: content.replaceAll('\n', '<br/>')}}
            sx={({typography, palette}) => ({
              ...typography.body2,
              height: '90px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: '14px',
              color: palette.text.main,
            })}
          />
        </SoftBox>
        {programmingDate && (
          <>
            <Divider />
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              <SoftTypography variant="caption" fontWeight="regular" color="text" textTransform="none">
                {t('draftcard.scheduledat', {
                  date: localFormatISOString(programmingDate, 'dd/MM/yyyy HH:mm', me?.locale),
                  defaultValue: 'Publication scheduled for {{date}}',
                })}
              </SoftTypography>
              {/*<SoftBox display="flex">{renderMembers}</SoftBox>*/}
            </SoftBox>
          </>
        )}
      </SoftBox>
    </Card>
  );
}
