/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React Button Styles
import {ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme} from '@mui/material/styles';
import contained from 'src/theme/theme/components/button/contained';
import outlined from 'src/theme/theme/components/button/outlined';
import root from 'src/theme/theme/components/button/root';
import buttonText from 'src/theme/theme/components/button/text';
type MuiButtonComponentsProps = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
};

const button: MuiButtonComponentsProps = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    root: {...root},
    contained: {...contained.base},
    containedSizeSmall: {...contained.small},
    containedSizeLarge: {...contained.large},
    containedPrimary: {...contained.primary},
    containedSecondary: {...contained.secondary},
    outlined: {...outlined.base},
    outlinedSizeSmall: {...outlined.small},
    outlinedSizeLarge: {...outlined.large},
    outlinedPrimary: {...outlined.primary},
    outlinedSecondary: {...outlined.secondary},
    text: {...buttonText.base},
    textSizeSmall: {...buttonText.small},
    textSizeLarge: {...buttonText.large},
    textPrimary: {...buttonText.primary},
    textSecondary: {...buttonText.secondary},
  },
};

export default button;
