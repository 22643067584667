import {AppBar, Tab, Tabs} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type NavigationBarProps = {
  teamId: string;
  teamPremium?: boolean;
  value: 'settings' | 'privacy' | 'subscription' | 'team';
};

export default function NavigationBar({teamId, value}: NavigationBarProps) {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const onChangeTab = (e: React.SyntheticEvent<Element, Event>, value: string) => {
    if (value === 'privacy') {
      navigate(`/team/${teamId}/settings/privacy`);
    } else if (value === 'team') {
      navigate(`/team/${teamId}/settings/team`);
    } else if (value === 'subscription') {
      navigate(`/team/${teamId}/settings/subscription`);
    } else {
      navigate(`/team/${teamId}/settings`);
    }
  };
  return (
    <AppBar position="static">
      <Tabs value={value} onChange={onChangeTab}>
        <Tab value="settings" label={t('teamsettings.nav.info')} />
        <Tab value="privacy" label={t('teamsettings.nav.privacy')} />
        {/*<Tab
          value="subscription"
          label={teamPremium ? t('teamsettings.nav.subscription') : t('teamsettings.nav.upgrade')}
        />*/}
        <Tab value="team" label={t('teamsettings.nav.team')} />
      </Tabs>
    </AppBar>
  );
}
