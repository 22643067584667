import Icon from '@mui/material/Icon';
import type {SingleAssets, MulitpleAssets, Poll} from '@perfectpost/perfect-post-common';
import React, {useMemo, useEffect, useState, useRef, useCallback} from 'react';
import {Link} from 'react-router-dom';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';

import CarrouselPlayer from '../carrouselplayer/CarrouselPlayer';

import 'video.js/dist/video-js.css';

type ActivityPreviewProps = {
  picture?: string;
  fullname?: string;
  headline?: string;
  content: string;
  sharing?: SingleAssets | MulitpleAssets | Poll;
  firstComment?: string;
  showMore?: '5-lines' | '3-lines';
  noToggle?: boolean;
  link?: string;
};
export default function ActivityPreview({
  showMore,
  picture,
  fullname,
  headline,
  content,
  sharing,
  firstComment,
  noToggle,
  link,
}: ActivityPreviewProps) {
  const [previewMode, setPreviewMode] = useState<'laptop' | 'smartphone'>('laptop');
  const [showMoreActive, setShowMoreActive] = useState<undefined | '5-lines' | '3-lines'>(showMore);
  useEffect(() => {
    if (showMoreActive !== undefined) {
      setShowMoreActive(showMore);
    }
  }, [showMore, showMoreActive]);

  const width = useMemo(() => (previewMode === 'laptop' ? '540px' : '443px'), [previewMode]);

  const playerRef = useRef<Player | null>(null);
  const videoRef = useCallback(
    (node: HTMLVideoElement) => {
      if (sharing?.type !== 'video') {
        return undefined;
      }
      if (node !== null) {
        // Make sure Video.js player is only initialized once
        if (playerRef.current === null) {
          playerRef.current = videojs(node, {
            html5: true,
            controls: true,
          });
          if (sharing.url !== '') {
            playerRef.current.src({type: sharing.mimetype, src: sharing.url});
          }
        }
      } else if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    },
    [sharing?.type],
  );

  return (
    <SoftBox
      sx={{
        borderRadius: '1rem',
        backgroundColor: '#f3f2ef',
        width: '100%',
      }}>
      <SoftBox
        sx={{
          p: 2,
          width: `calc(${width} + 16px * 2)`,
          color: 'initial',
          margin: '0 auto',
        }}>
        {noToggle !== true && (
          <SoftBox sx={{display: 'flex', flexDirection: 'row', mb: 2, justifyContent: 'center'}}>
            <SoftButton
              onClick={() => setPreviewMode('smartphone')}
              sx={{mr: 2}}
              color={previewMode === 'smartphone' ? 'info' : 'white'}
              variant="contained"
              iconOnly>
              <Icon>smartphone</Icon>
            </SoftButton>
            <SoftButton
              onClick={() => setPreviewMode('laptop')}
              color={previewMode === 'laptop' ? 'info' : 'white'}
              variant="contained"
              iconOnly>
              <Icon>laptop</Icon>
            </SoftButton>
          </SoftBox>
        )}
        <SoftBox sx={{borderRadius: '1rem', backgroundColor: '#fff', py: 2, width}}>
          <SoftBox
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              padding: '12px 4px 0 16px',
              marginBottom: '8px',
              alignItem: 'center',
            }}>
            <SoftBox sx={{flexShrink: 0, display: 'flex', position: 'relative', width: 48, height: 48}}>
              <SoftBox
                component="img"
                sx={{
                  objectFix: 'contain',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  objectPosition: 'center',
                  backgroundPosition: '50%',
                  borderRadius: '50%',
                }}
                src={picture}
                loading="lazy"
              />
            </SoftBox>

            <SoftBox sx={{flexGrow: 1, flexBasis: 0, marginLeft: '8px', overflow: 'hidden', position: 'relative'}}>
              <SoftBox component="span" sx={{display: 'flex'}}>
                <SoftBox
                  component="span"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    verticalAlign: 'top',
                    display: 'inline-block',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 600,
                  }}>
                  {fullname}
                </SoftBox>
                <SoftBox
                  component="span"
                  sx={{
                    flexShrink: 0,
                    verticalAlign: 'top',
                    whiteSpace: 'nowrap',
                    marginLeft: '4px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}>
                  • 1er
                </SoftBox>
              </SoftBox>
              <SoftBox
                component="span"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 400,
                }}>
                {headline}
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox className="feed-shared-update-v2__description-wrapper" sx={{width}}>
            <SoftBox
              className="feed-shared-update-v2__description"
              sx={{
                position: 'relative',
                display: 'block',
                margin: '0 16px',
                lineHeight: '20px',
                ...(showMoreActive === undefined
                  ? {
                      paddingBottom: '25px',
                    }
                  : {
                      maxWidth: '928px',
                      maxHeight: '60px',
                      minHeight: '60px',
                      overflow: 'hidden',
                    }),
              }}>
              <SoftBox sx={{lineHeight: 'inherit', fontSize: '14px', position: 'relative'}}>
                <SoftBox
                  component={link ? Link : 'div'}
                  to={link}
                  target={link ? '_blank' : undefined}
                  sx={{
                    color: 'inherit',
                    lineHeight: 'inherit',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                  }}
                  className="break-words"
                  dangerouslySetInnerHTML={{__html: content.replaceAll('\n', '<br/>')}}></SoftBox>
              </SoftBox>
              {showMore !== undefined && (
                <SoftBox
                  component="button"
                  role="button"
                  sx={{
                    backgroundColor: '#fff',
                    lineHeight: 'inherit',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    marginBottom: 0,
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    paddingLeft: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    showMoreActive === undefined ? setShowMoreActive(showMore) : setShowMoreActive(undefined)
                  }
                  type="button">
                  {showMoreActive === undefined ? '…see less' : '…see more'}
                </SoftBox>
              )}
            </SoftBox>
            <SoftBox sx={{marginTop: '8px', width: '100%'}}>
              {sharing?.type === 'image' && <SoftBox component="img" src={sharing.url} sx={{width: '100%'}} />}
              {sharing?.type === 'pdf' && sharing.url && sharing.url.trim().length > 0 && (
                <SoftBox sx={{width: 540}}>
                  <CarrouselPlayer url={sharing.url} />
                </SoftBox>
              )}
              {sharing?.type === 'video' && sharing.url !== '' && (
                <SoftBox sx={{width: '100%'}}>
                  <video
                    ref={videoRef}
                    src={sharing.url}
                    datatype={sharing.mimetype}
                    playsInline
                    preload="metadata"
                    muted
                    className="video-js media-player__player vjs-paused vjs-fluid vjs-1-1 media-player--use-mercado vjs-controls-enabled vjs-workinghover vjs-v7 vjs-user-active vjs-layout-medium"
                  />
                </SoftBox>
              )}
              {sharing?.type === 'poll' && (
                <SoftBox
                  sx={{
                    padding: '16px',
                    margin: '8px 16px 0 16px',
                    border: '0 none rgba(0,0,0, 0.9)',
                    boxShadow: 'rgba(0,0,0,0.08) 0px 0px 0px 1px',
                    borderRadius: '8px',
                  }}>
                  <SoftBox sx={{marginBottom: '16px'}}>
                    <SoftTypography sx={{fontSize: '16px', fontWeight: 600}}>{sharing.question}</SoftTypography>
                  </SoftBox>
                  <SoftBox component="fieldset" sx={{border: 'none'}}>
                    <SoftBox
                      sx={{
                        borderRadius: '16px',
                        border: '16px none rgb(10, 102, 194)',
                        boxShadow: 'rgb(10, 102, 194) 0 0 0 1px inset',
                        marginBottom: '8px',
                        textAlign: 'center',
                        fontWeight: 600,
                        fontSize: '16px',
                        color: 'rgb(10,102,194)',
                      }}>
                      {sharing.option1}
                    </SoftBox>
                    <SoftBox
                      sx={{
                        borderRadius: '16px',
                        border: '16px none rgb(10, 102, 194)',
                        boxShadow: 'rgb(10, 102, 194) 0 0 0 1px inset',
                        marginBottom: '8px',
                        textAlign: 'center',
                        fontWeight: 600,
                        fontSize: '16px',
                        color: 'rgb(10,102,194)',
                      }}>
                      {sharing.option2}
                    </SoftBox>
                    {sharing.option3 && (
                      <SoftBox
                        sx={{
                          borderRadius: '16px',
                          border: '16px none rgb(10, 102, 194)',
                          boxShadow: 'rgb(10, 102, 194) 0 0 0 1px inset',
                          marginBottom: '8px',
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: 'rgb(10,102,194)',
                        }}>
                        {sharing.option3}
                      </SoftBox>
                    )}
                    {sharing.option4 && (
                      <SoftBox
                        sx={{
                          borderRadius: '16px',
                          border: '16px none rgb(10, 102, 194)',
                          boxShadow: 'rgb(10, 102, 194) 0 0 0 1px inset',
                          marginBottom: '8px',
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: 'rgb(10,102,194)',
                        }}>
                        {sharing.option4}
                      </SoftBox>
                    )}
                  </SoftBox>
                  <SoftTypography sx={{color: 'rgba(0,0,0,0.6)', fontSize: '14px', paddingTop: '8px'}}>
                    XXX votes • {sharing.duration} days left
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
          </SoftBox>

          {firstComment && (
            <SoftBox
              sx={{display: 'flex', flexDirection: 'row', gap: '4px', paddingLeft: '12px', paddingRight: '12px'}}>
              <SoftBox sx={{flexShrink: 0, display: 'flex', position: 'relative', width: 40, height: 40}}>
                <SoftBox
                  component="img"
                  sx={{
                    objectFix: 'contain',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    objectPosition: 'center',
                    backgroundPosition: '50%',
                    borderRadius: '50%',
                  }}
                  src={picture}
                  loading="lazy"
                />
              </SoftBox>
              <SoftBox
                sx={{
                  backgroundColor: '#f2f2f2',
                  overflow: 'auto',
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  borderTopRightRadius: '8px',
                  padding: '8px 16px 12px 12px',
                }}>
                <SoftBox sx={{flexGrow: 1, flexBasis: 0, marginLeft: '8px', overflow: 'hidden', position: 'relative'}}>
                  <SoftBox component="span" sx={{display: 'flex'}}>
                    <SoftBox
                      component="span"
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: 600,
                      }}>
                      {fullname}
                    </SoftBox>
                    <SoftBox
                      component="span"
                      sx={{
                        flexShrink: 0,
                        verticalAlign: 'top',
                        whiteSpace: 'nowrap',
                        marginLeft: '4px',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontSize: '14px',
                        lineHeight: '20px',
                      }}>
                      • 1er
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    component="span"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: 400,
                    }}>
                    {headline}
                  </SoftBox>
                </SoftBox>
                <SoftBox sx={{fontSize: '14px', lineHeight: '20px', marginTop: '8px'}}>
                  <div dangerouslySetInnerHTML={{__html: firstComment}} />
                </SoftBox>
              </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}
