import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {components} from '@perfectpost/perfect-post-common/src/cms/api';
import React from 'react';
// @mui material components
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import SoftAvatar from 'src/theme/components/SoftAvatar';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';

type CoursesCardProps = {
  course: components['schemas']['CourseListResponseDataItem'];
};

export default function CoursesCard({course}: CoursesCardProps) {
  const {t} = useTranslation();

  const image = course.attributes?.illustration?.data?.attributes?.url ?? '';
  const title = course.attributes?.title ?? '';
  const description = course.attributes?.description ?? '';
  const url = course.attributes?.url ?? '';
  const author = course.attributes?.creator?.data?.attributes?.name ?? '';
  const authorImage = course.attributes?.creator?.data?.attributes?.picture?.data?.attributes?.url ?? '';
  const creatorUrl = course.attributes?.creator?.data?.attributes?.linkedinprofile ?? '';
  const paid = course.attributes?.paid ?? false;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'visible',
        p: 2,
      }}>
      <SoftBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          sx={{
            maxWidth: '100%',
            margin: 0,
            boxShadow: ({boxShadows: {md}}) => md,
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </SoftBox>
      <SoftBox pt={3} px={0.5}>
        <SoftBox mb={1}>
          <SoftBox mb={1} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <SoftBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <SoftAvatar
                src={authorImage}
                size="xs"
                sx={({borders: {borderWidth}, palette: {white}}) => ({
                  border: `${borderWidth[2]} solid ${white.main}`,
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover, &:focus': {
                    zIndex: '10',
                  },
                })}
              />
              <SoftTypography
                component="a"
                href={creatorUrl}
                target="_blank"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                textGradient={true}>
                {author}
              </SoftTypography>
            </SoftBox>
            <SoftBox>{paid ? t('coursescard.paid') : t('coursescard.free')}</SoftBox>
          </SoftBox>
          <SoftTypography component="a" href={url} target="_blank" rel="noreferrer" variant="h5" fontWeight="bold">
            {title}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={3} sx={{fontSize: '14px'}}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          <SoftButton
            component="a"
            href={url}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target="_blank"
            rel="noreferrer"
            variant="outlined"
            size="small"
            color="dark">
            {t('coursescard.accesscourse')}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
