/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Avatar, {AvatarProps} from '@mui/material/Avatar';
import {styled} from '@mui/material/styles';
import React from 'react';

type ExtraProps = {
  bgColor?: 'transparent' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  shadow?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'inset';
};

type CastedForwardRefAvatarType = <C extends React.ElementType>(
  props: Omit<AvatarProps<C, {component?: C}>, 'color' | 'variant'> & ExtraProps,
  ref?: React.Ref<HTMLDivElement>,
) => React.ReactElement | null;
const CastedForwardRefAvatarFnc: CastedForwardRefAvatarType = (props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {children, bgColor, ...rest} = props;
  return (
    <Avatar ref={ref} {...rest}>
      {children}
    </Avatar>
  );
};
const CastedForwardRefAvatar = React.forwardRef(CastedForwardRefAvatarFnc) as CastedForwardRefAvatarType;
export default styled(CastedForwardRefAvatar)(({theme, shadow, bgColor, size}) => {
  const {palette, functions, typography, boxShadows} = theme;

  const {gradients, transparent} = palette;
  const {pxToRem, linearGradient} = functions;
  const {size: fontSize, fontWeightBold} = typography;

  // backgroundImage value
  const backgroundValue =
    bgColor === 'transparent' || bgColor === undefined
      ? transparent.main
      : linearGradient(gradients[bgColor].main, gradients[bgColor].state);

  // size value
  let sizeValue;

  switch (size) {
    case 'xs':
      sizeValue = {
        width: pxToRem(24),
        height: pxToRem(24),
        fontSize: fontSize.xs,
      };
      break;
    case 'sm':
      sizeValue = {
        width: pxToRem(36),
        height: pxToRem(36),
        fontSize: fontSize.sm,
      };
      break;
    case 'lg':
      sizeValue = {
        width: pxToRem(58),
        height: pxToRem(58),
        fontSize: fontSize.sm,
      };
      break;
    case 'xl':
      sizeValue = {
        width: pxToRem(74),
        height: pxToRem(74),
        fontSize: fontSize.md,
      };
      break;
    case 'xxl':
      sizeValue = {
        width: pxToRem(110),
        height: pxToRem(110),
        fontSize: fontSize.md,
      };
      break;
    default: {
      sizeValue = {
        width: pxToRem(48),
        height: pxToRem(48),
        fontSize: fontSize.md,
      };
    }
  }

  return {
    background: backgroundValue,
    fontWeight: fontWeightBold,
    boxShadow: shadow !== undefined && shadow !== 'none' ? boxShadows[shadow] : undefined,
    ...sizeValue,
  };
});
