import {User} from '@perfectpost/perfect-post-common';
import {Locale, format as dateFnsFormat, parseISO, formatRelative} from 'date-fns';
import {enUS, es, fr} from 'date-fns/locale';

export const locales: {[key in User['locale']]: Locale} = {
  en: enUS,
  fr: fr,
  es: es,
};

export function localFormat(date: number | Date, format: string, locale?: User['locale']) {
  return dateFnsFormat(date, format, {locale: locales[locale ?? 'en']});
}

export function localFormatISOString(date: string, format: string, locale?: User['locale']) {
  return dateFnsFormat(parseISO(date), format, {locale: locales[locale ?? 'en']});
}

export function localFormatRelativeISOString(date: string, locale?: User['locale']) {
  return formatRelative(parseISO(date), new Date(), {locale: locales[locale ?? 'en']});
}
