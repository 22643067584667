import {Card, CircularProgress, Grid} from '@mui/material';
import Icon from '@mui/material/Icon';
import {
  CreateTipsTeamCommand,
  DeleteTipsTeamCommand,
  EditTipsTeamCommand,
  GetTipsTeamCommand,
  TeamTips as TeamTipsModel,
} from '@perfectpost/perfect-post-common';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Column} from 'react-table';
import Datatable from 'src/components/datatable';
import TeamTipsModal from 'src/components/modals/TeamTipsModal';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';

type TeamTipsState = {
  state: 'loading' | 'idle';
  showDialogTips: boolean | string;
  tips: TeamTipsModel[];
};

export default function TeamTips() {
  const {id} = useParams();
  const {t} = useTranslation();
  const user = useAppSelector((state) => state.main.user);

  const [state, setState] = useState<TeamTipsState>({
    state: 'loading',
    showDialogTips: false,
    tips: [],
  });
  const {showDialogTips, tips} = state;

  useEffect(() => {
    if (id) {
      setState((s) => ({...s, state: 'loading'}));
      perfectPostServiceClient.send(new GetTipsTeamCommand({id})).then((tips) => {
        setState((s) => ({...s, tips, state: 'idle'}));
      });
    }
  }, [id]);

  const onRemove =
    (tipsId: string): React.MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      event.stopPropagation();
      if (id) {
        setState((s) => ({...s, state: 'loading'}));
        perfectPostServiceClient.send(new DeleteTipsTeamCommand({teamId: id, tipsId})).then(() => {
          setState((s) => ({...s, tips: tips.filter((t) => t._id !== tipsId), state: 'idle'}));
        });
      }
    };

  const columnDefinition: Column<TeamTipsModel>[] = useMemo(
    () => [
      {Header: t('teamtips.datatable.title', {defaultValue: 'Title'}), accessor: 'title', width: '10%'},
      {Header: t('teamtips.datatable.content', {defaultValue: 'Content'}), accessor: 'content', width: '10%'},
      {
        Header: '',
        accessor: '_id',
        width: '5%',
        Cell: (row) => {
          return (
            <SoftButton iconOnly size="small" variant="outlined" color="error" onClick={onRemove(row.value)}>
              <Icon>delete</Icon>
            </SoftButton>
          );
        },
      },
    ],
    [user?.locale],
  );

  const onCreateTips = async ({title, content}: Pick<TeamTipsModel, 'title' | 'content'>) => {
    if (id === undefined) {
      return;
    }
    setState({...state, state: 'loading'});
    if (typeof showDialogTips === 'string') {
      const newOne = await perfectPostServiceClient.send(
        new EditTipsTeamCommand({teamId: id, tipsId: showDialogTips, title, content}),
      );
      setState({
        showDialogTips: false,
        tips: tips.map((t) => (t._id === newOne._id ? newOne : t)),
        state: 'idle',
      });
    } else {
      const newOne = await perfectPostServiceClient.send(new CreateTipsTeamCommand({teamId: id, title, content}));
      setState({showDialogTips: false, tips: [...tips, newOne], state: 'idle'});
    }
  };

  return (
    <SoftBox>
      <Grid container spacing={3}>
        <Grid item lg={11} xl={10}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card sx={{p: 3}}>
                  <SoftBox p={3} lineHeight={1}>
                    <SoftBox mb={1} display="flex" flexDirection="row" justifyContent="space-between">
                      <SoftTypography variant="h5">{t('teamtips.title')}</SoftTypography>
                      <SoftButton onClick={() => setState((s) => ({...s, showDialogTips: true}))}>
                        {t('teamtips.addnewentry', {defaultValue: 'Create new entry'})}
                      </SoftButton>
                    </SoftBox>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      {t('teamtips.subtitle')}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox p={3} lineHeight={1}>
                    {state.state === 'loading' ? (
                      <CircularProgress />
                    ) : (
                      <Datatable
                        table={{rows: tips, columns: columnDefinition}}
                        entriesPerPage={{defaultValue: 30, entries: [10, 20, 30, 50, 80, 130]}}
                        pagination={{variant: 'gradient', color: 'info'}}
                        isSorted
                        canSearch
                        noExport
                        onRowClick={(row) => setState((s) => ({...s, showDialogTips: row._id}))}
                      />
                    )}
                    {showDialogTips && (
                      <TeamTipsModal
                        tips={tips.find((t) => t._id === showDialogTips)}
                        handleClose={() => setState((s) => ({...s, showDialogTips: false}))}
                        handleConfirm={onCreateTips}
                      />
                    )}
                  </SoftBox>
                </Card>
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
