/**
=========================================================
* Soft UI Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {forwardRef} from 'react';

// Custom styles for SoftButton
import SoftButtonRoot, {SoftButtonRootProps} from './SoftButtonRoot';

export type SoftButtonProps = Omit<SoftButtonRootProps, 'ownerState' | 'variant' | 'color'> &
  SoftButtonRootProps['ownerState'];
const SoftButton = forwardRef<HTMLButtonElement, SoftButtonProps>(
  (
    {color = 'white', variant = 'contained', size = 'medium', circular = false, iconOnly = false, children, ...rest},
    ref,
  ) => (
    <SoftButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === 'gradient' ? 'contained' : variant}
      size={size}
      ownerState={{color, variant, size, circular, iconOnly}}>
      {children}
    </SoftButtonRoot>
  ),
);

export default SoftButton;
