import {List} from '@mui/material';
import type {Draft} from '@perfectpost/perfect-post-common';
import {GetDraftTeamCommand} from '@perfectpost/perfect-post-common';
import {cloneDeep} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {SingleValue} from 'react-select';
import RemoveDraftDialog from 'src/components/modals/RemoveDraftDialog';
import PostCards from 'src/components/postcard/PostCards';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import {useAppThunkDispatch} from 'src/store';
import {cloneDraft, removeDraft} from 'src/store/draftslice';
import {selectMembersOfTeamId} from 'src/store/teamslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

// t('draft.tag.idea', {defaultValue: 'Idea'})
// t('draft.tag.draft', {defaultValue: 'Draft'})
// t('draft.tag.proofreading', {defaultValue: 'Proofreading'})
// t('draft.tag.ready', {defaultValue: 'Ready'})
// t('draft.tag.scheduled', {defaultValue: 'Scheduled'})
// t('draft.tag.published', {defaultValue: 'Published'})
type Tags = 'idea' | 'draft' | 'proofreading' | 'ready' | 'scheduled' | 'published';
type ColumnsType = {
  id: Tags;
  name: string;
  rows: Draft[];
};

export const useStrictDroppable = (loading: boolean) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    let animation: number;

    if (!loading) {
      animation = requestAnimationFrame(() => setEnabled(true));
    }

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, [loading]);

  return [enabled];
};

const defaultColumn: ColumnsType[] = [
  {id: 'idea', name: 'idea', rows: []},
  {id: 'draft', name: 'draft', rows: []},
  // {id: 'proofreading', name: 'proofreading', rows: []},
  {id: 'ready', name: 'ready', rows: []},
  {id: 'scheduled', name: 'scheduled', rows: []},
  {id: 'published', name: 'published', rows: []},
];

type TeamKanbanState = {
  columns: ColumnsType[];
  showRemoveDraftDialog?: Draft;
  tags: string[];
  tagFilter?: string;
  memberFilter?: string;
  teamDraft: Draft[];
};

export default function TeamKanban() {
  const {id} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(t('kanban.documenttitle', {defaultValue: 'Kanban'}));
  const dispatch = useAppThunkDispatch();

  const me = useAppSelector((state) => state.main.user);
  const members = useAppSelector((state) => selectMembersOfTeamId(state, id));

  const [teamKanbanState, setTeamKanbanState] = useState<TeamKanbanState>({
    columns: cloneDeep(defaultColumn),
    tags: [],
    teamDraft: [],
  });
  const {columns, showRemoveDraftDialog, tags, tagFilter, memberFilter, teamDraft} = teamKanbanState;

  useEffect(() => {
    if (id !== undefined) {
      setTeamKanbanState((state) => ({...state, teamDraft: [], columns: cloneDeep(defaultColumn)}));
      perfectPostServiceClient.send(new GetDraftTeamCommand({id})).then((drafts) => {
        const newColumns = cloneDeep(defaultColumn);
        const tags: string[] = [];
        for (const draft of drafts) {
          if (draft.categories) {
            tags.push(...draft.categories);
          }
          const found = newColumns.find((c) => c.name === draft.tag);
          if (found) {
            found.rows.push(draft);
          } else {
            newColumns.push({
              id: (draft.tag ?? 'idea') as Tags,
              name: draft.tag ?? 'idea',
              rows: [draft],
            });
          }
        }
        setTeamKanbanState((state) => ({...state, teamDraft: drafts, columns: newColumns, tags: [...new Set(tags)]}));
      });
    }
  }, [id]);

  const onFilterTagChange = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => {
    setTeamKanbanState((state) => ({...state, tagFilter: newValue?.value}));
  };

  const onFilterMemberChange = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => {
    if (newValue === null) {
      setTeamKanbanState((state) => ({...state, memberFilter: undefined}));
    } else {
      setTeamKanbanState((state) => ({...state, memberFilter: newValue?.value}));
    }
  };

  const onRemove = async () => {
    const draft = teamDraft.find((d) => d._id === showRemoveDraftDialog?._id);
    if (draft) {
      await dispatch(removeDraft(draft._id));
      setTeamKanbanState((state) => {
        const columns = state.columns;
        const newColumns = cloneDeep(columns);
        for (const col of newColumns) {
          col.rows = col.rows.filter((draft) => draft._id !== showRemoveDraftDialog?._id);
        }
        return {...state, showRemoveDraftDialog: undefined, columns: newColumns};
      });
    }
  };

  const onSelectDraft = (draft: Draft) => {
    const myPost = draft.user === me?._id;
    if (myPost) {
      navigate(`/publish/draft/${draft._id}`);
    } else {
      navigate(`/publish/shared/${draft._id}`);
    }
  };

  const onCloneDraft = (draft: Draft) => {
    dispatch(cloneDraft(draft))
      .unwrap()
      .then(() => {
        if (id !== undefined) {
          perfectPostServiceClient.send(new GetDraftTeamCommand({id})).then((drafts) => {
            setTeamKanbanState((state) => ({...state, teamDraft: drafts}));
          });
        }
      });
  };

  return (
    <SoftBox sx={{flexGrow: 1, pb: 2, overflow: 'hidden'}}>
      <SoftBox sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
        <SoftBox>
          <SoftSelect
            isMulti={false}
            placeholder="Filter tag"
            isClearable
            options={tags.map((s) => ({value: s, label: s}))}
            onChange={onFilterTagChange}
          />
        </SoftBox>
        <SoftBox sx={{ml: 2}}>
          <SoftSelect
            placeholder="Filter member"
            isClearable
            options={members.map((s) => ({value: s._id, label: `${s.firstname ?? ''} ${s.lastname ?? ''}`}))}
            onChange={onFilterMemberChange}
          />
        </SoftBox>
      </SoftBox>
      {showRemoveDraftDialog && (
        <RemoveDraftDialog
          draft={showRemoveDraftDialog}
          handleClose={() => setTeamKanbanState((state) => ({...state, showRemoveDraftDialog: undefined}))}
          handleRemove={onRemove}
        />
      )}
      <SoftBox
        sx={({palette}) => ({
          whiteSpace: 'nowrap',
          display: 'flex',
          gap: '20px',
          overflowX: 'scroll',
          height: 'calc(100% - 59px)',
          width: '100%',
          pr: 3,
          paddingBottom: '10px',
          /* width */
          '::-webkit-scrollbar': {
            width: 20,
          },
          /* Track */
          '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: 10,
          },
          /* Handle */
          '::-webkit-scrollbar-thumb': {
            background: palette.info.main,
            borderRadius: 10,
          },
          /* Handle on hover */
          '::-webkit-scrollbar-thumb:hover': {
            background: palette.info.focus,
          },
        })}>
        {columns.map((column) => {
          const rows = useMemo(() => {
            if (tagFilter !== undefined && memberFilter === undefined) {
              return column.rows.filter((r) => r.categories?.includes(tagFilter));
            } else if (tagFilter === undefined && memberFilter !== undefined) {
              return column.rows.filter((r) => r.user === memberFilter);
            } else if (tagFilter !== undefined && memberFilter !== undefined) {
              return column.rows.filter((r) => r.categories?.includes(tagFilter) && r.user === memberFilter);
            } else {
              return column.rows;
            }
          }, [column, tagFilter, memberFilter]);
          return (
            <SoftBox key={column.id} sx={{minWidth: 420, height: '100%'}}>
              <SoftBox
                sx={({palette}) => ({
                  height: '100%',
                  backgroundColor: palette.grey['200'],
                  borderRadius: '1rem',
                })}>
                <SoftBox
                  sx={({palette}) => ({
                    p: 2,
                    position: 'sticky',
                    backgroundColor: palette.grey['200'],
                    zIndex: 100,
                    height: 65,
                    display: 'flex',
                    alignItems: 'center',
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem',
                  })}>
                  <SoftTypography variant="caption" fontWeight="bold">
                    {t(`draft.tag.${column.id}`)}
                  </SoftTypography>
                  <span style={{fontSize: 14}}>&nbsp;{column.rows.length}</span>
                </SoftBox>
                <List
                  sx={{px: 2, height: 'calc(100% - 110px)', width: 420, overflowX: 'hidden', overflowY: 'auto'}}
                  dense>
                  {rows.map((draft, index) => (
                    <PostCards
                      key={draft._id}
                      draft={draft}
                      index={index}
                      noDrag
                      onSelect={() => onSelectDraft(draft)}
                      onClone={() => onCloneDraft(draft)}
                      onRemove={() => {
                        setTeamKanbanState((state) => ({...state, showRemoveDraftDialog: draft}));
                      }}
                    />
                  ))}
                </List>
                <SoftBox
                  sx={({palette}) => ({
                    p: 1,
                    position: 'sticky',
                    backgroundColor: palette.grey['200'],
                    zIndex: 100,
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    borderBottomLeftRadius: '1rem',
                    borderBottomRightRadius: '1rem',
                  })}>
                  <SoftButton
                    variant="gradient"
                    color="dark"
                    fullWidth
                    size="small"
                    component={Link}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    to={`/publish/draft/new?tag=${column.id}`}>
                    {t('kanban.addpost')}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          );
        })}
      </SoftBox>
    </SoftBox>
  );
}
