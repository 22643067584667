import {Card, Grid, AppBar, Tab, Tabs} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  CreateSubscriptionStripePostCommand,
  GetMeCommand,
  GetPortalSessionStripeCommand,
  Team,
} from '@perfectpost/perfect-post-common';
import {fetchAuthSession} from 'aws-amplify/auth';
import {isFuture, parseISO} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useSearchParams} from 'react-router-dom';
import PricingCard from 'src/components/pricingcard/PricingCard';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {submitMessage} from 'src/store/extension/slice';
import {getMe, reloadGroup} from 'src/store/mainslice';
import {fetchTeams} from 'src/store/teamslice';
import SoftAlert from 'src/theme/components/SoftAlert';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormatISOString} from 'src/utils/date-format';

type SubscriptionState = {
  loading: boolean;
  teamCustomer: Team[];
  teamWhoGiveMeLicense?: Team;
  premiumState: 'free' | 'free_premium' | 'premium' | 'team_premium';
  mode: 'monthly' | 'yearly';
};

export default function Subscription() {
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();

  useDocumentTitle(t('subscription.documenttitle', {defaultValue: 'Subscription'}));
  const user = useAppSelector((state) => state.main.user);
  const premium = useAppSelector((state) => state.main.premium);
  const teams = useAppSelector((state) => state.team.teams);

  const [state, setState] = useState<SubscriptionState>({
    premiumState: 'free',
    loading: false,
    teamCustomer: [],
    mode: 'yearly',
  });
  const {loading, teamCustomer, premiumState, teamWhoGiveMeLicense, mode} = state;
  const dispatch = useAppThunkDispatch();

  const refreshSession = searchParams.get('refresh_session');

  const subscribe = async () => {
    setState((f) => ({...f, loading: true}));
    let referral;
    if (
      window !== undefined &&
      window.Rewardful !== undefined &&
      window.Rewardful.referral !== undefined &&
      window.Rewardful.referral !== ''
    ) {
      referral = window.Rewardful.referral;
    }
    const {url} = await perfectPostServiceClient.send(new CreateSubscriptionStripePostCommand({mode, referral}));
    window.location.href = url;
  };

  const goToPortal = async () => {
    setState((f) => ({...f, loading: true}));
    const {url} = await perfectPostServiceClient.send(new GetPortalSessionStripeCommand());
    window.location.href = url;
  };

  useEffect(() => {
    void dispatch(getMe());
    void dispatch(fetchTeams());
    if (refreshSession === 'true') {
      fetchAuthSession({forceRefresh: true}).then(async ({tokens}) => {
        const idToken = tokens?.idToken;
        if (idToken === undefined) {
          return;
        }
        const userUpdate = await perfectPostServiceClient.send(new GetMeCommand());
        dispatch(submitMessage({source: 'perfectpost', type: 'reload_session'}));
        dispatch(reloadGroup({user: userUpdate, idToken}));
      });
    }
  }, []);

  useEffect(() => {
    const teamCustomer = teams.filter((team) => {
      if (team.stripeCustomerId === undefined) {
        return false;
      }
      const meInTeam = team?.users.find((u) => u.user === user?._id);
      return (
        meInTeam !== undefined &&
        (meInTeam.role === 'owner' || meInTeam.role === 'admin' || meInTeam.premiumLicense !== undefined)
      );
    });

    const teamWhoGiveMeLicense = teamCustomer.find((team) => {
      const meInTeam = team?.users.find((u) => u.user === user?._id);
      return meInTeam !== undefined && meInTeam.premiumLicense !== undefined;
    });

    let premiumState: 'free' | 'free_premium' | 'premium' | 'team_premium';
    if (premium === false) {
      premiumState = 'free';
    } else if (premium && user?.stripeSubscriptionId !== undefined) {
      premiumState = 'premium';
    } else if (premium && user?.stripeSubscriptionId === undefined && teamWhoGiveMeLicense === undefined) {
      premiumState = 'free_premium';
    } else if (premium && user?.stripeSubscriptionId === undefined && teamWhoGiveMeLicense !== undefined) {
      premiumState = 'team_premium';
    }

    setState((f) => ({...f, premiumState, teamCustomer, teamWhoGiveMeLicense}));
  }, [teams]);

  return (
    <SoftBox mb={3} p={2}>
      <SoftTypography mb={2} variant="h2" textTransform="none" fontWeight="bold">
        {premiumState === 'free'
          ? t('subscription.currentplan.title.free', {defaultValue: 'Vous utilisez PerfectPost gratuitement'})
          : t('subscription.title', {defaultValue: 'Details of your subscription'})}
      </SoftTypography>
      {user?.stripeCustomerId !== undefined && user?.stripeSubscriptionId === undefined && (
        <Grid container sx={{my: 3}}>
          <Grid item xs={6}>
            <Card>
              <SoftBox p={2}>
                <SoftTypography>
                  {t('subscription.oldinvoice', {
                    defaultValue: 'Vous avez déjà été abonné à PerfectPost.',
                  })}
                </SoftTypography>
                <SoftBox sx={{pb: 3, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <SoftTypography variant="h5">
                    {t('subscription.manageinvoices', {defaultValue: 'Voir mes factures'})}
                  </SoftTypography>
                  <SoftButton variant="gradient" color="dark" size="small" disabled={loading} onClick={goToPortal}>
                    {loading ? (
                      <CircularProgress color="info" />
                    ) : (
                      t('subscription.manageinvoices', {defaultValue: 'Voir mes factures'})
                    )}
                  </SoftButton>
                </SoftBox>
                <SoftBox sx={{pb: 3, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <SoftTypography variant="h5">
                    {t('subscription.reactivatesubscription', {defaultValue: 'Reactiver mon abonnement'})}
                  </SoftTypography>
                  <SoftButton variant="gradient" color="dark" size="small" disabled={loading} onClick={goToPortal}>
                    {loading ? <CircularProgress color="info" /> : t('subscription.managesubscription')}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
      <SoftTypography>
        {premiumState !== 'free'
          ? t('subscription.detail', {
              defaultValue: 'Find details about the cost of your current plan and the features you can use here.',
            })
          : t('subscriptionmodal.title', {defaultValue: 'Choose the right package for your activity'})}
      </SoftTypography>
      {premiumState === 'free' ? (
        <Card sx={{mt: 3, p: 3}}>
          <Grid container justifyContent="center" sx={{textAlign: 'center', mb: 3, pb: 3}}>
            <Grid item xs={10} lg={5}>
              <Grid container item xs={12} sm={10} md={8} lg={7} sx={{mx: 'auto'}}>
                <SoftBox width="100%">
                  <AppBar position="static">
                    <Tabs value={mode} onChange={(e, v) => setState((f) => ({...f, mode: v as 'monthly' | 'yearly'}))}>
                      <Tab
                        value="monthly"
                        label={
                          <SoftBox py={0.5} px={2}>
                            {t('subscriptionmodal.monthly', {defaultValue: 'Monthly'})}
                          </SoftBox>
                        }
                      />
                      <Tab
                        value="yearly"
                        label={
                          <SoftBox py={0.5} px={2}>
                            {t('subscriptionmodal.annual', {defaultValue: 'Annual'})}
                          </SoftBox>
                        }
                      />
                    </Tabs>
                  </AppBar>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <PricingCard
                main
                badge={{color: 'secondary', label: t('subscription.plan.premiumlabel', {defaultValue: 'premium'})}}
                price={{
                  currency: 'HT €',
                  value: mode === 'monthly' ? '12,90' : '9,90',
                  interval: t('subscriptionmodal.permonth'),
                }}
                specifications={[
                  {label: t('subscription.plan.spec1'), soon: false},
                  {label: t('subscription.plan.spec2'), soon: false},
                  {
                    label: t('subscription.plan.spec3'),
                    soon: false,
                  },
                  {label: t('subscription.plan.spec4'), soon: false},
                  {
                    label: t('subscription.plan.spec5'),
                    soon: false,
                  },
                  {
                    label: t('subscription.plan.spec6'),
                    soon: true,
                  },
                  {
                    label: t('subscription.plan.spec7'),
                    soon: false,
                  },
                  {
                    label: t('subscription.plan.spec8'),
                    soon: false,
                  },
                  {
                    label: t('subscription.plan.spec9'),
                    soon: false,
                  },
                  {
                    label: t('subscription.plan.spec10'),
                    soon: true,
                  },
                ]}
                action={{
                  color: 'light',
                  label: t('subscription.plan.unlockpremiumcta', {defaultValue: 'Unlock now'}),
                  loading,
                }}
                onClick={subscribe}
              />
            </Grid>
            <Grid item xs={6}>
              <PricingCard
                badge={{color: 'info', label: t('subscription.plan.freelabel', {defaultValue: 'free'})}}
                price={{currency: '€', value: '0', interval: t('subscriptionmodal.forever')}}
                specifications={[
                  {label: t('subscription.planfree.spec1'), soon: false},
                  {label: t('subscription.planfree.spec2'), soon: false},
                  {
                    label: t('subscription.planfree.spec3'),
                    soon: false,
                  },
                  {label: t('subscription.planfree.spec4'), soon: false},
                  {
                    label: t('subscription.planfree.spec5'),
                    soon: false,
                  },
                  {
                    label: t('subscription.planfree.spec6'),
                    soon: false,
                  },
                ]}
                action={{
                  color: 'info',
                  label: t('subscription.plan.currentplan', {defaultValue: 'Your current plan'}),
                  loading,
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() => {}}
              />
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Grid container sx={{mt: 3}}>
          <Grid item xs={6}>
            <Card>
              <SoftBox p={2}>
                <Grid container spacing={3} alignItems="center" sx={{flex: 1}}>
                  <Grid
                    item
                    xs={12}
                    sx={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <SoftTypography>
                      {
                        /*
                      t('subscription.currentplan.title.premium', {defaultValue: "Vous bénéficiez de l'abonnement premium"})
                      t('subscription.currentplan.title.free_premium', {defaultValue: "Vous bénéficiez de l'abonnement premium gratuitement"})
                      t('subscription.currentplan.title.team_premium', {defaultValue: "Vous bénéficiez d'une licence premium via l'équipe {{teamName}}"})
                    */
                        t(`subscription.currentplan.title.${premiumState}`, {teamName: teamWhoGiveMeLicense?.name})
                      }
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    {premiumState === 'premium' ? (
                      <>
                        <SoftBox
                          sx={{pb: 3, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                          <SoftTypography variant="h5">
                            {t('subscription.managepayment', {defaultValue: 'Modifier mes moyens de paiement'})}
                          </SoftTypography>
                          <SoftButton
                            variant="gradient"
                            color="dark"
                            size="small"
                            disabled={loading}
                            onClick={goToPortal}>
                            {loading ? <CircularProgress color="info" /> : t('subscription.managesubscription')}
                          </SoftButton>
                        </SoftBox>
                        <SoftBox
                          sx={{pb: 3, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                          <SoftTypography variant="h5">
                            {t('subscription.manageinvoices', {defaultValue: 'Voir mes factures'})}
                          </SoftTypography>
                          <SoftButton
                            variant="gradient"
                            color="dark"
                            size="small"
                            disabled={loading}
                            onClick={goToPortal}>
                            {loading ? (
                              <CircularProgress color="info" />
                            ) : (
                              t('subscription.manageinvoices', {defaultValue: 'Voir mes factures'})
                            )}
                          </SoftButton>
                        </SoftBox>
                        <SoftBox
                          sx={{pb: 3, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                          <SoftTypography variant="h5">
                            {t('subscription.cancelsubscription', {defaultValue: 'Annuler mon abonnement'})}
                          </SoftTypography>
                          <SoftButton
                            variant="gradient"
                            color="error"
                            size="small"
                            disabled={loading}
                            onClick={goToPortal}>
                            {loading ? (
                              <CircularProgress color="info" />
                            ) : (
                              t('subscription.cancelsubscription', {defaultValue: 'Annuler mon abonnement'})
                            )}
                          </SoftButton>
                        </SoftBox>
                      </>
                    ) : premiumState === 'free_premium' &&
                      user?.earlyAdoptersPremiumExpireAt !== undefined &&
                      isFuture(parseISO(user.earlyAdoptersPremiumExpireAt)) ? (
                      <SoftAlert color="success">
                        {t('subscription.premiumfree', {
                          defaultValue: "Cet essai gratuit de l'abonnement premium se termine le {{date}}",
                          date: localFormatISOString(
                            user.earlyAdoptersPremiumExpireAt,
                            'dd MMMM yyyy',
                            user?.locale ?? 'en',
                          ),
                        })}
                      </SoftAlert>
                    ) : premiumState === 'team_premium' ? (
                      <SoftAlert color="success">
                        {t('subscription.teampremium', {
                          defaultValue: "Vous bénéficiez d'une licence premium via l'équipe {{teamName}}",
                          teamName: teamWhoGiveMeLicense?.name,
                        })}
                      </SoftAlert>
                    ) : null}
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      )}
      {teamCustomer.length > 0 && (
        <Grid container sx={{mt: 3, py: 3, gap: 2}}>
          <Grid item xs={12}>
            <SoftTypography variant="h3" fontWeight="bold">
              {t('subscription.team.title', {defaultValue: 'Equipe pour lesquelles je gère une formule payante'})}
            </SoftTypography>
          </Grid>
          {teamCustomer.map((team) => (
            <Grid key={team._id} item xs={6}>
              <Card>
                <SoftBox p={2}>
                  <SoftTypography variant="h5">{team.name}</SoftTypography>
                  <SoftBox
                    sx={{pt: 3, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <SoftTypography variant="h5">
                      {t('subscription.manageinvoices', {defaultValue: 'Voir mes factures'})}
                    </SoftTypography>
                    <SoftButton
                      component={Link}
                      variant="gradient"
                      color="dark"
                      size="small"
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      to={`/team/${team._id}/settings/subscription`}>
                      {loading ? (
                        <CircularProgress color="info" />
                      ) : (
                        t('subscription.manageinvoices', {defaultValue: 'Voir mes factures'})
                      )}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </SoftBox>
  );
}
