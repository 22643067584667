import {DatesSetArg, EventDropArg, EventInput} from '@fullcalendar/core';
import {DropArg, ThirdPartyDraggable} from '@fullcalendar/interaction';
import {Grid} from '@mui/material';
import type {Draft} from '@perfectpost/perfect-post-common';
import {GetDraftTeamCommand, PublicUser} from '@perfectpost/perfect-post-common';
import {addHours, endOfMonth, isFuture, parseISO, startOfMonth} from 'date-fns';
import dragula from 'dragula';
import 'dragula/dist/dragula.css';
import randomcolor from 'randomcolor';
import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import CalendarComponent from 'src/components/calendar';
import DraftCard from 'src/components/draftcard/DraftCard';
import MessageCard from 'src/components/messagecard/MessageCard';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {draftLoad, editDraft} from 'src/store/draftslice';
import {listPost} from 'src/store/postslice';
import {selectMembersOfTeamId, selectTeamById} from 'src/store/teamslice';
import SoftAlert from 'src/theme/components/SoftAlert';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

const regex = /(<([^>]+)>)/gi;

type TeamUser = PublicUser & {
  color: string;
  email: string;
};

export default function TeamCalendar() {
  const {id} = useParams();
  const drakeInstance = useRef<dragula.Drake | null>(null);
  const thirdPartyDraggableRef = useRef<ThirdPartyDraggable | null>(null);
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();

  const team = useAppSelector((state) => selectTeamById(state, id));
  const posts = useAppSelector((state) => state.post.linkedinPost);
  const drafts = useAppSelector((state) => state.draft.drafts);
  const me = useAppSelector((state) => state.main.user);
  const member = useAppSelector((state) => selectMembersOfTeamId(state, id));

  const teamMember: TeamUser[] = useMemo(() => {
    return member.map((member) => ({
      ...member,
      color: randomcolor({luminosity: 'light'}),
      email: team?.users.find((u) => u.user === member._id)?.email ?? '',
    }));
  }, [member]);
  const myPrivacy = useMemo(() => team?.users.find((u) => u.user === me?._id)?.privacy, [team, me]);

  const [teamDraft, setTeamDraft] = useState<Draft[]>([]);

  const draftContainer = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        if (drakeInstance.current !== null) {
          drakeInstance.current.destroy();
        }
        drakeInstance.current = dragula([node], {
          copy: true,
        });
        if (thirdPartyDraggableRef.current !== null) {
          thirdPartyDraggableRef.current.destroy();
        }
        thirdPartyDraggableRef.current = new ThirdPartyDraggable(node, {
          itemSelector: '.fc-event',
          mirrorSelector: '.gu-mirror', // the dragging element that dragula renders
          eventData: (eventEl) => {
            const id = eventEl.getAttribute('data-id');
            const draft = drafts.find((d) => d._id === id);
            if (draft) {
              const result: EventInput = {
                id: draft._id,
                start: new Date(),
                end: addHours(new Date(), 1),
                title: draft.content.replace(regex, ''),
                resource: draft,
                url: `/publish/draft/${draft._id}`,
                className: 'no-bg',
                color: teamMember.find((m) => m._id === draft.user)?.color,
              };
              return result;
            }
          },
        });
      }
    },
    [drafts],
  );
  useEffect(() => {
    return () => {
      if (drakeInstance.current !== null) {
        drakeInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (team?._id !== undefined) {
      perfectPostServiceClient.send(new GetDraftTeamCommand({id: team._id})).then((drafts) => {
        setTeamDraft(drafts);
      });
    }
  }, [team?._id]);

  useDocumentTitle(t('calendar.documenttitle', {defaultValue: 'Calendar'}));

  useEffect(() => {
    dispatch(draftLoad());
    dispatch(listPost({start: startOfMonth(new Date()), end: endOfMonth(new Date())}));
  }, []);

  const events: EventInput[] = useMemo(() => {
    const allDrafts = [...teamDraft, ...drafts].reduce((acc, current) => {
      const x = acc.find((item) => item._id === current._id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [] as Draft[]);

    const d = allDrafts
      .filter((d) => d.programmingDate !== undefined && isFuture(parseISO(d.programmingDate)))
      .map((d) => {
        const myPost = d.user === me?._id;
        const teamVisible = myPost
          ? myPrivacy?.draft !== 'none' ||
            d.proofreaders?.some((p) => p.team === id) ||
            (d.tag === 'scheduled' && myPrivacy.scheduled !== 'none')
          : true;
        const date = parseISO(d.programmingDate as string);
        const e: EventInput = {
          id: d._id,
          start: date,
          end: addHours(date, 1),
          title: d.content.replace(regex, ''),
          resource: d,
          className: teamVisible ? 'no-bg' : 'mute',
          color: teamMember.find((m) => m._id === d.user)?.color,
          editable: myPost,
        };
        if (myPost) {
          e.url = `/publish/draft/${d._id}`;
        } else {
          e.url = `/publish/shared/${d._id}`;
        }
        return e;
      });
    const p = posts.map((post) => {
      const d = parseISO(post.date);
      const myPost = post.user === me?._id;
      const e: EventInput = {
        id: post._id,
        start: d,
        end: addHours(d, 1),
        title: post.text,
        resource: post,
        editable: false,
        className: myPost && myPrivacy?.post === false ? 'mute' : 'no-bg',
        color: teamMember.find((m) => m._id === post.user)?.color,
      };
      if (myPost) {
        e.url = `/analyse/content/${post._id}`;
      }
      return e;
    });
    return [...d, ...p];
  }, [drafts, posts, teamDraft, myPrivacy]);

  const draftsReady = useMemo(() => drafts.filter((d) => d.tag === 'ready'), [drafts]);

  const eventDrop = (event: EventDropArg) => {
    const draft = drafts.find((d) => d._id === event.event.id);
    const start = event.event.start;
    if (draft !== undefined && start !== null) {
      dispatch(editDraft({draftId: draft._id, programmingDate: start.toISOString()}));
    }
  };

  const drop = (event: DropArg) => {
    const draft = drafts.find((d) => d._id === event.draggedEl.getAttribute('data-id'));
    const start = event.date;
    start.setHours(9);
    if (draft !== undefined) {
      dispatch(editDraft({draftId: draft._id, programmingDate: start.toISOString()}));
    }
  };

  const onDatesChange = ({start, end}: DatesSetArg) => {
    dispatch(listPost({start, end}));
  };

  return (
    <SoftBox>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={9} sx={{height: 'max-content'}}>
          {useMemo(
            () => (
              <CalendarComponent
                initialView="dayGridMonth"
                initialDate={new Date()}
                events={events}
                selectable
                editable
                droppable
                eventDrop={eventDrop}
                drop={drop}
                datesSet={onDatesChange}
              />
            ),
            [events],
          )}
          {myPrivacy?.draft === 'none' && (
            <SoftBox sx={{mt: 2}}>
              <SoftAlert color="secondary" dismissible>
                {t('teamcalendar.sharingoffmessage')}
              </SoftAlert>
            </SoftBox>
          )}
        </Grid>
        <Grid item xs={12} xl={3}>
          <SoftBox display="flex" flexDirection="column">
            {teamMember.map((member) => (
              <SoftBox key={member._id} display="flex" alignItems="center" py={0.5} px={1}>
                <SoftBox sx={{backgroundColor: member.color, width: 20, height: 20, m: 2}} />
                <SoftBox mr={2}>
                  <SoftBox component="img" src={member.lnPicture} sx={{borderRadius: '50%', width: 20}} />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
                    {member.firstname + ' ' + member.lastname}
                  </SoftTypography>
                  <SoftTypography variant="caption" textTransform="none" fontWeight="light">
                    {member.email}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            ))}
          </SoftBox>
        </Grid>
        <Grid
          item
          xs={12}
          xl={9}
          sx={{
            height: 'max-content',
          }}>
          <SoftBox
            display="flex"
            flexDirection="column"
            sx={({palette}) => ({p: 2, borderRadius: '1rem', backgroundColor: palette.grey['300']})}>
            <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
              {t('calendar.title', {defaultValue: 'Publication ready'})}
            </SoftTypography>
            {draftsReady.length === 0 ? (
              <MessageCard
                description={t('calendar.nopost.description', {defaultValue: "You don't have any posts scheduled."})}
                btnText={t('calendar.nopost.ctabtn', {defaultValue: 'Prepare my first publication'})}
                to="/publish/draft/new"
              />
            ) : (
              <SoftBox
                ref={draftContainer}
                component="ul"
                display="flex"
                p={0}
                sx={{
                  maxHeight: 900,
                  overflowY: 'auto',
                  pr: 1,
                  m: 0,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}>
                {draftsReady.map((draft) => (
                  <SoftBox
                    key={draft._id}
                    component="li"
                    className="fc-event"
                    data-id={draft._id}
                    display="flex"
                    alignItems="baseline"
                    flexDirection="column"
                    py={1}
                    mb={1}
                    sx={{flex: '40%', mx: '2%'}}>
                    <DraftCard
                      content={draft.content}
                      programmingDate={draft.programmingDate}
                      organization={draft.organization}
                      href={`/publish/draft/${draft._id}`}
                    />
                  </SoftBox>
                ))}
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
