import {
  CheckInstallResponse,
  CommandQueueState,
  PerfectPostMessage,
  QueueStateEvent,
} from '@perfectpost/perfect-post-common';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {compareVersions} from 'compare-versions';
import {crispService} from 'src/services';

import {RootState} from '..';

export interface ExtensionState {
  isEstablishingConnection: boolean;
  isConnected: boolean;
  version?: string;
  vanityName?: string;
  isOutdated?: boolean;
  state: CommandQueueState;
  commandStack: {commandId: string; finished: boolean}[];
  currentCommandId?: string;
}

const initialState: ExtensionState = {
  isEstablishingConnection: true,
  isConnected: false,
  state: CommandQueueState.Idle,
  commandStack: [],
};

const selectCommands = (state: RootState) => state.extension.commandStack;
export const selectCommandById = createSelector(
  [selectCommands, (state: RootState, commandId: string | undefined) => commandId],
  (commandStack, commandId) => commandStack.find((item) => item.commandId === commandId),
);

export const extensionSlice = createSlice({
  name: 'extension',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitMessage: (state, action: PayloadAction<PerfectPostMessage>) => {
      return;
    },
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    extensionNotFound: (state) => {
      state.isEstablishingConnection = false;
      state.isConnected = false;
      state.state = CommandQueueState.Idle;
      state.commandStack = [];
      state.version = undefined;
      state.vanityName = undefined;
      state.isOutdated = undefined;
    },
    connectionEstablished: (state, action: PayloadAction<CheckInstallResponse>) => {
      state.isEstablishingConnection = false;
      state.isConnected = action.payload.payload.authenticated;
      state.version = action.payload.payload.version;
      state.vanityName = action.payload.payload.vanityName;
      crispService.trackExtension(action.payload.payload.version);
      if (
        process.env.REACT_APP_EXTENSION_MIN_VERSION !== undefined &&
        compareVersions(action.payload.payload.version, process.env.REACT_APP_EXTENSION_MIN_VERSION) < 0
      ) {
        state.isOutdated = true;
      }
    },
    syncQueueState: (state, action: PayloadAction<QueueStateEvent>) => {
      if (
        // prev command finished
        state.state === CommandQueueState.Processing &&
        state.currentCommandId !== undefined &&
        state.currentCommandId !== action.payload.payload.currentCommandId
      ) {
        const item = state.commandStack.find(({commandId}) => commandId === state.currentCommandId);
        if (item !== undefined) {
          item.finished = true;
        }
      } else if (
        // a new command has started
        state.state === CommandQueueState.Idle &&
        state.currentCommandId === undefined &&
        action.payload.payload.currentCommandId !== undefined
      ) {
        state.commandStack.push({commandId: action.payload.payload.currentCommandId, finished: false});
      }
      state.state = action.payload.payload.state;
      state.currentCommandId = action.payload.payload.currentCommandId;
      state.commandStack = state.commandStack.concat(
        action.payload.payload.commandStack
          .filter((commandId) => !state.commandStack.some((c) => c.commandId === commandId))
          .map((commandId) => ({commandId, finished: false})),
      );
    },
    dismissFinishedCommand: (state, action: PayloadAction<{commandId: string}>) => {
      state.commandStack = state.commandStack.filter(({commandId}) => commandId !== action.payload.commandId);
    },
  },
});

export const {startConnecting, submitMessage, extensionNotFound, dismissFinishedCommand} = extensionSlice.actions;
export default extensionSlice.reducer;
