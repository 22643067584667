import {CircularProgress} from '@mui/material';
import {StatusCommand} from '@perfectpost/perfect-post-common';
import {CustomContentProps, SnackbarContent, useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {dismissFinishedCommand, selectCommandById} from 'src/store/extension/slice';

import SoftSnackbarContent from './SoftSnackbarContent';

interface WorkingCommandProps extends CustomContentProps {
  commandId: string;
}

const WorkingCommand = React.forwardRef<HTMLDivElement, WorkingCommandProps>(({id, commandId}, ref) => {
  const {closeSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const currentCommandId = useAppSelector((state) => state.extension.currentCommandId);
  const currentCommand = useAppSelector((state) => selectCommandById(state, commandId));
  const dispatch = useAppThunkDispatch();

  const [commandName, setCommandName] = useState<string | undefined>();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  useEffect(() => {
    if (currentCommand?.finished) {
      setTimeout(() => {
        dispatch(dismissFinishedCommand({commandId}));
        handleDismiss();
      }, 3000);
    }
  }, [currentCommand]);

  useEffect(() => {
    perfectPostServiceClient.send(new StatusCommand(commandId)).then((command) => {
      setCommandName(command?.command._internalName);
    });
  }, [commandId]);

  return (
    <SnackbarContent ref={ref}>
      <SoftSnackbarContent
        color={currentCommand?.finished ? 'success' : 'info'}
        icon={commandId === currentCommandId ? <CircularProgress color="inherit" size={20} /> : 'verified'}
        title={t('extension.workingTitle', {defaultValue: "L'extension charge des données depuis LinkedIn"})}
        /*
          t('extension.command.get-premium-dash-analytics-view-by-analytics-entity')
          t('extension.command.get-premium-dash-analytics-view')
          t('extension.command.get-feed-dash-profile-updates-by-member-comments')
          t('extension.command.get-feed-dash-profile-updates-by-member-reactions')
          t('extension.command.get-feed-dash-profile-updates-by-member-share-feed')
          t('extension.command.profile-cards-by-deferred-cards')
          t('extension.command.top-card-supplementary-query')
          t('extension.command.search-reusable-typeahead-by-blended')
          t('extension.command.share-details-by-share-urn')
        */
        content={t(`extension.command.${commandName}`, {
          defaultValue: "Aucune information sur cette commande n'est disponible",
        })}
        onClose={handleDismiss}
        bgWhite
        titleColor={currentCommand?.finished ? 'success' : 'info'}
        dividerColor={false}
      />
    </SnackbarContent>
  );
});

export default WorkingCommand;
