import LinkedinIcon from '@mui/icons-material/LinkedIn';
import {
  Grid,
  Card,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Icon,
  CircularProgress,
  Switch,
  Table,
  TableRow,
  TableBody,
} from '@mui/material';
import {
  EmailSettings,
  GetNotificationsSettingsCommand,
  UpdateNotificationsSettingsCommand,
  type Draft,
} from '@perfectpost/perfect-post-common';
import * as Sentry from '@sentry/react';
import {AuthError, updatePassword} from 'aws-amplify/auth';
import {ConsoleLogger} from 'aws-amplify/utils';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import PasswordChecker from 'src/components/passwordchecker/passwordchecker';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {draftLoad, removeDraft} from 'src/store/draftslice';
import {submitMessage} from 'src/store/extension/slice';
import {getMe, logout, removeAccount, unlinkLinkedin, updateProfile} from 'src/store/mainslice';
import SoftAlert from 'src/theme/components/SoftAlert';
import SoftAvatar from 'src/theme/components/SoftAvatar';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftSocialButton from 'src/theme/components/SoftSocialButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import borders from 'src/theme/theme/base/borders';
import colors from 'src/theme/theme/base/colors';
import useDocumentTitle from 'src/useDocumentTitle';

const logger = new ConsoleLogger('Profile');
const regex = /(<([^>]+)>)/gi;

type TableCellProps = {
  width?: string;
  children: React.ReactNode;
  align?: string;
  padding: number[];
  noBorder?: boolean;
};

function TableCell({width, align, padding, noBorder, children}: TableCellProps) {
  const {light} = colors;
  const {borderWidth} = borders;

  return (
    <SoftBox
      component="th"
      width={width ?? 'auto'}
      pt={padding[0]}
      pr={padding[1]}
      pb={padding[2]}
      pl={padding[3]}
      textAlign={align ?? 'left'}
      borderBottom={noBorder ? 0 : `${borderWidth[1]} solid ${light.main}`}>
      <SoftTypography component="div" variant="body2" color="text">
        {children}
      </SoftTypography>
    </SoftBox>
  );
}

type QueryParams = {
  response_type: string;
  client_id: string;
  redirect_uri: string;
  state: string;
  scope: string;
};

export default function Settings() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(t('settings.documenttitle', {defaultValue: 'Settings'}));
  const user = useAppSelector((state) => state.main.user);
  const organizations = useAppSelector((state) => state.main.organizations);
  const loadingUser = useAppSelector((state) => state.main.loadingUser);
  const drafts = useAppSelector((state) => state.draft.drafts);

  const [basicInfoForm, setBasicInfoForm] = useState({
    firstname: user?.firstname ?? '',
    lastname: user?.lastname ?? '',
    email: user?.email ?? '',
  });
  const [formSubmited, setFormSubmited] = useState(false);
  const [successBasicInfo, setsuccessBasicInfo] = useState(false);
  const [notificationsSettings, setNotificationsSettings] = useState<Omit<EmailSettings, 'user'>>({
    refreshTokenExpiration: false,
    extensionPing: false,
    scheduledPost: false,
  });

  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
  const [formDialogAccountDelete, setformDialogAccountDelete] = useState('');

  const [changePasswordForm, setChangePasswordForm] = useState<{
    state: 'idle' | 'loading' | 'success' | 'error';
    currentPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
    errorMessageNew: string | undefined;
    errorMessageCurrent: string | undefined;
  }>({
    state: 'idle',
    currentPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
    errorMessageNew: undefined,
    errorMessageCurrent: undefined,
  });

  const dispatch = useAppThunkDispatch();
  const [invalidPassword, setInvalidPassword] = useState(false);

  const onSave = async () => {
    setFormSubmited(true);
    setsuccessBasicInfo(false);
    const {firstname, lastname, email} = basicInfoForm;
    await dispatch(updateProfile({firstname, lastname, email}));
    setsuccessBasicInfo(true);
    setFormSubmited(false);
    setTimeout(() => setsuccessBasicInfo(false), 2000);
  };

  const onConfirmPassword = () => {
    const {currentPassword, newPasswordRepeat, newPassword} = changePasswordForm;
    if (newPassword !== newPasswordRepeat && newPassword.length === 0) {
      return;
    }
    setChangePasswordForm({...changePasswordForm, state: 'loading'});
    updatePassword({oldPassword: currentPassword, newPassword})
      .then(() => {
        setChangePasswordForm({
          state: 'success',
          currentPassword: '',
          newPassword: '',
          newPasswordRepeat: '',
          errorMessageCurrent: undefined,
          errorMessageNew: undefined,
        });
      })
      .catch((err) => {
        logger.warn(err);
        if (err instanceof AuthError) {
          if (err.name === 'InvalidPasswordException') {
            setChangePasswordForm({
              ...changePasswordForm,
              errorMessageCurrent: undefined,
              errorMessageNew: err.message,
              state: 'error',
            });
          } else if (err.name === 'NotAuthorizedException') {
            setChangePasswordForm({
              ...changePasswordForm,
              errorMessageCurrent: err.message,
              errorMessageNew: undefined,
              state: 'error',
            });
          } else {
            Sentry.captureException(err);
            setChangePasswordForm({
              ...changePasswordForm,
              errorMessageCurrent: undefined,
              // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
              errorMessageNew: `Unknown error: ${err}`,
              state: 'error',
            });
          }
        } else {
          Sentry.captureException(err);
          setChangePasswordForm({
            ...changePasswordForm,
            errorMessageCurrent: undefined,
            errorMessageNew: `Unknown error: ${err}`,
            state: 'error',
          });
        }
      });
  };

  const onRemoveAccount = async () => {
    if (formDialogAccountDelete === 'DELETE') {
      await dispatch(removeAccount());
      dispatch(logout());
      navigate('/signin');
    }
  };

  const signInWithLinkedIn = () => {
    const getURLWithQueryParams = (base: string, params: QueryParams) => {
      const query = Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');
      return `${base}?${query}`;
    };

    const LINKEDIN_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
      response_type: 'code',
      client_id: `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`,
      redirect_uri: `${process.env.REACT_APP_LINKEDIN_REDIRECT_URI}`,
      state: `${process.env.REACT_APP_LINKEDIN_STATE}`,
      scope: `${process.env.REACT_APP_LINKEDIN_SCOPE}`,
    });
    window.location.href = LINKEDIN_URL;
  };

  const onUnlinkLinkedin = () => {
    dispatch(unlinkLinkedin())
      .unwrap()
      .then(() => {
        dispatch(submitMessage({source: 'perfectpost', type: 'reload_session'}));
        dispatch(getMe());
      });
  };

  const onRemoveDraft = (draft: Draft) => async () => {
    await dispatch(removeDraft(draft._id));
  };

  const onNotificationChange = (key: keyof EmailSettings, value: boolean) => {
    setNotificationsSettings((prev) => ({...prev, [key]: value}));
    perfectPostServiceClient.send(
      new UpdateNotificationsSettingsCommand({
        ...notificationsSettings,
        [key]: value,
      }),
    );
  };

  useEffect(() => {
    void dispatch(getMe());
    dispatch(draftLoad());
    perfectPostServiceClient.send(new GetNotificationsSettingsCommand()).then((response) => {
      setNotificationsSettings(response);
    });
  }, []);

  return (
    <SoftBox>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card id="profile">
                  <SoftBox p={2}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>
                        <SoftAvatar
                          src={user?.lnPicture}
                          alt="profile-image"
                          variant="rounded"
                          size="xl"
                          sx={{borderRadius: '50%'}}
                        />
                      </Grid>
                      <Grid item>
                        <SoftBox height="100%" mt={0.5} lineHeight={1}>
                          <SoftTypography variant="h5" fontWeight="medium">
                            {user?.firstname} {user?.lastname}
                          </SoftTypography>
                          <SoftTypography variant="button" color="text" fontWeight="medium">
                            {user?.headline}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <SoftBox display="flex" justifyContent={{md: 'flex-start'}} alignItems="center" lineHeight={1}>
                          <SoftSocialButton color="linkedin" disabled={formSubmited} onClick={signInWithLinkedIn}>
                            <LinkedinIcon />
                            &nbsp;
                            {t('settings.linkedaccount.updatewithlinkedinbtn', {defaultValue: 'Reconnect LinkedIn'})}
                          </SoftSocialButton>
                        </SoftBox>
                      </Grid>
                      {user?.vanityName !== undefined && (
                        <Grid item xs={12} md={6} lg={8} display="flex" justifyContent="flex-end" flexWrap="wrap">
                          <SoftButton color="error" size="small" disabled={formSubmited} onClick={onUnlinkLinkedin}>
                            {t('settings.linkedaccount.unlinklinkedinaccount', {
                              defaultValue: 'Supprimer le lien avec ce profil LinkedIn',
                            })}
                          </SoftButton>
                        </Grid>
                      )}
                    </Grid>
                  </SoftBox>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card id="basic-info" sx={{overflow: 'visible'}}>
                  <SoftBox p={3}>
                    <SoftTypography variant="h5">
                      {t('settings.profileinformation', {defaultValue: 'Information about your profile'})}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox component="form" pb={3} px={3}>
                    <Grid sx={{mb: 6}} container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize">
                              {t('settings.firstname', {defaultValue: 'First name'})}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            value={basicInfoForm.firstname}
                            onChange={(e) => setBasicInfoForm({...basicInfoForm, firstname: e.target.value})}
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize">
                              {t('settings.lastname', {defaultValue: 'Last name'})}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            value={basicInfoForm.lastname}
                            onChange={(e) => setBasicInfoForm({...basicInfoForm, lastname: e.target.value})}
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize">
                              {t('settings.email', {defaultValue: 'E-mail'})}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            value={basicInfoForm.email}
                            inputProps={{type: 'email'}}
                            onChange={(e) => setBasicInfoForm({...basicInfoForm, email: e.target.value})}
                          />
                        </SoftBox>
                      </Grid>
                    </Grid>
                    {successBasicInfo && (
                      <SoftAlert color="success" dismissible>
                        <SoftTypography variant="body2" color="white">
                          {t('settings.confirmsave', {defaultValue: 'Your information has been updated'})}
                        </SoftTypography>
                      </SoftAlert>
                    )}
                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
                      <SoftBox ml="auto">
                        <SoftButton
                          variant="gradient"
                          color="dark"
                          size="small"
                          onClick={onSave}
                          disabled={formSubmited}>
                          {t('settings.savebtn', {defaultValue: 'Update'})}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card id="change-password">
                  <SoftBox p={3}>
                    <SoftTypography variant="h5">
                      {t('settings.changepassword', {defaultValue: 'Change pasword'})}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox component="form" pb={3} px={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="none">
                              {t('settings.currentpassword', {defaultValue: 'Current password'})}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            inputProps={{type: 'password', autoComplete: ''}}
                            value={changePasswordForm.currentPassword}
                            error={changePasswordForm.errorMessageCurrent !== undefined}
                            onChange={(e) =>
                              setChangePasswordForm({
                                ...changePasswordForm,
                                currentPassword: e.target.value,
                                errorMessageCurrent: undefined,
                                state: 'idle',
                              })
                            }
                          />
                          {changePasswordForm.errorMessageCurrent !== undefined && (
                            <SoftTypography variant="body2" fontWeight="bold" color="error">
                              {changePasswordForm.errorMessageCurrent}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="none">
                              {t('settings.newpassword', {defaultValue: 'New password'})}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            inputProps={{type: 'password', autoComplete: ''}}
                            value={changePasswordForm.newPassword}
                            error={changePasswordForm.errorMessageNew !== undefined}
                            onChange={(e) =>
                              setChangePasswordForm({
                                ...changePasswordForm,
                                errorMessageNew: '',
                                newPassword: e.target.value,
                                state: 'idle',
                              })
                            }
                          />
                          {changePasswordForm.errorMessageNew !== undefined && (
                            <SoftTypography variant="body2" fontWeight="bold" color="error">
                              {changePasswordForm.errorMessageNew}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="none">
                              {t('settings.confirmnewpassword', {defaultValue: 'Confirm new password'})}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            inputProps={{type: 'password', autoComplete: ''}}
                            value={changePasswordForm.newPasswordRepeat}
                            onChange={(e) =>
                              setChangePasswordForm({
                                ...changePasswordForm,
                                newPasswordRepeat: e.target.value,
                                state: 'idle',
                              })
                            }
                          />
                        </SoftBox>
                      </Grid>
                    </Grid>
                    <SoftBox mt={6} mb={1}>
                      <SoftTypography variant="h5">
                        {t('settings.passwordrequirement.title', {defaultValue: 'Password requirements'})}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={1}>
                      <SoftTypography variant="body2" color="text">
                        {t('settings.passwordrequirement.description', {
                          defaultValue: 'Please follow this guide for a strong password',
                        })}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
                      <SoftBox component="ul" m={0} pl={3.25} mb={{xs: 8, sm: 0}}>
                        <PasswordChecker
                          password={changePasswordForm.newPassword}
                          repeatPassword={changePasswordForm.newPasswordRepeat}
                          onPasswordInvalid={() => {
                            setInvalidPassword(true);
                          }}
                          onPasswordValid={() => {
                            setInvalidPassword(false);
                          }}
                        />
                      </SoftBox>
                      <SoftBox ml="auto">
                        <SoftButton
                          disabled={
                            changePasswordForm.state === 'success' ||
                            invalidPassword ||
                            changePasswordForm.newPassword !== changePasswordForm.newPasswordRepeat
                          }
                          variant="gradient"
                          color="dark"
                          size="small"
                          onClick={onConfirmPassword}>
                          {changePasswordForm.state === 'loading' ? (
                            <CircularProgress size={20} />
                          ) : changePasswordForm.state === 'success' ? (
                            <Icon>check</Icon>
                          ) : (
                            t('settings.password.savebtn', {defaultValue: 'Change password'})
                          )}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card id="accounts">
                  <SoftBox display="flex" flexDirection="row" justifyContent="space-between" p={3} lineHeight={1}>
                    <SoftBox display="flex" flexDirection="column" lineHeight={1}>
                      <SoftBox mb={1}>
                        <SoftTypography variant="h5">Compte</SoftTypography>
                      </SoftBox>
                      <SoftTypography variant="button" color="text" fontWeight="regular" textTransform="none">
                        {t('settings.linkedaccount.title', {defaultValue: 'Accounts associated with your account'})}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent={{md: 'flex-end'}} alignItems="center" lineHeight={1}>
                      <SoftSocialButton color="linkedin" disabled={formSubmited} onClick={signInWithLinkedIn}>
                        <LinkedinIcon />
                        &nbsp;{t('settings.linkedaccount.updatewithlinkedinbtn', {defaultValue: 'Reconnect LinkedIn'})}
                      </SoftSocialButton>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pt={2} pb={3} px={3}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{xs: 'flex-start', sm: 'center'}}
                      flexDirection={{xs: 'column', sm: 'row'}}>
                      <SoftBox display="flex" alignItems="center">
                        <SoftAvatar src={user?.lnPicture} variant="rounded" />
                        <SoftBox ml={2} lineHeight={0}>
                          <SoftTypography variant="h5" fontWeight="medium">
                            {user?.firstname} {user?.lastname}
                          </SoftTypography>
                          <SoftTypography variant="button" color="text" fontWeight="regular" textTransform="none">
                            {t('settings.accounts.personalaccount', {defaultValue: 'Personal account'})}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    {organizations.map((organization) => (
                      <React.Fragment key={organization._id}>
                        <Divider />
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems={{xs: 'flex-start', sm: 'center'}}
                          flexDirection={{xs: 'column', sm: 'row'}}>
                          <SoftBox display="flex" alignItems="center">
                            <SoftAvatar src={organization.picture} variant="rounded" />
                            <SoftBox ml={2} lineHeight={0}>
                              <SoftTypography variant="h5" fontWeight="medium">
                                {organization.localizedName}
                              </SoftTypography>
                              <SoftTypography variant="button" color="text" fontWeight="regular" textTransform="none">
                                {t('settings.accounts.organizationaccount', {defaultValue: 'Organization account'})}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </React.Fragment>
                    ))}
                  </SoftBox>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card id="notifications">
                  <SoftBox p={3} lineHeight={1}>
                    <SoftBox mb={1}>
                      <SoftTypography variant="h5">Notifications</SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pb={3} px={3}>
                    <SoftBox minWidth="auto" sx={{overflow: 'scroll'}}>
                      <Table sx={{minWidth: '36rem'}}>
                        <SoftBox component="thead">
                          <TableRow>
                            <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                              {t('settings.notifications.title', {defaultValue: 'Event'})}
                            </TableCell>
                            <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                              {t('settings.notifications.email', {defaultValue: 'Email'})}
                            </TableCell>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          <TableRow>
                            <TableCell padding={[1, 1, 1, 0.5]}>
                              <SoftBox lineHeight={1.4}>
                                <SoftTypography display="block" variant="button" fontWeight="regular">
                                  {t('settings.notifications.expiredlinkedin.title', {
                                    defaultValue: 'LinkedIn token expired',
                                  })}
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  {t('settings.notifications.expiredlinkedin.description', {
                                    defaultValue:
                                      'Notify when the connection between your LinkedIn account and PerfectPost has expired and you need to reconnect.',
                                  })}
                                </SoftTypography>
                              </SoftBox>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Switch
                                checked={notificationsSettings.refreshTokenExpiration}
                                onChange={(e, c) => onNotificationChange('refreshTokenExpiration', c)}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card id="delete-account">
                  <SoftBox p={3} lineHeight={1}>
                    <SoftBox mb={1}>
                      <SoftTypography variant="h5">
                        {t('settings.removeaccount.title', {defaultValue: 'Delete my account'})}
                      </SoftTypography>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="regular" textTransform="none">
                      {t('settings.removeaccount.description', {
                        defaultValue: 'Once you have deleted your account there is no going back.',
                      })}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    pb={3}
                    px={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{xs: 'flex-start', sm: 'center'}}
                    flexDirection={{xs: 'column', sm: 'row'}}>
                    <SoftBox display="flex" flexDirection={{xs: 'column', sm: 'row'}}>
                      <SoftBox ml={{xs: 0, sm: 1}} mt={{xs: 1, sm: 0}}>
                        <SoftButton
                          variant="gradient"
                          color="error"
                          sx={{height: '100%'}}
                          disabled={formSubmited}
                          onClick={() => setShowDeleteAccountDialog(true)}>
                          {t('settings.removeaccount.btn', {defaultValue: 'Delete my account'})}
                        </SoftButton>
                        <Dialog open={showDeleteAccountDialog} onClose={() => setShowDeleteAccountDialog(false)}>
                          <DialogTitle>
                            {t('settings.removeaccount.dialogtitle', {defaultValue: 'Confirm account deletion'})}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              {drafts.length === 0
                                ? t('settings.removeaccount.dialogcontent', {
                                    defaultValue:
                                      'Are you sure you want to delete your account? This action is irreversible! To delete your account you must copy the word "DELETE"',
                                  })
                                : t('settings.removeaccount.needrequirement', {
                                    defaultValue: 'Before deleting your account please delete all your posting drafts',
                                  })}
                            </DialogContentText>
                            {drafts.length === 0 ? (
                              <TextField
                                autoFocus
                                margin="dense"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formDialogAccountDelete}
                                onChange={(e) => setformDialogAccountDelete(e.target.value)}
                              />
                            ) : (
                              drafts.map((draft) => (
                                <SoftBox key={draft._id} sx={{display: 'flex', justifyContent: 'space-between', my: 1}}>
                                  <SoftTypography
                                    variant="caption"
                                    fontWeight="light"
                                    sx={{display: 'flex', alignItems: 'center'}}>
                                    {draft.content.replace(regex, '').slice(0, 75)}
                                  </SoftTypography>
                                  <SoftButton iconOnly onClick={onRemoveDraft(draft)}>
                                    <Icon>delete</Icon>
                                  </SoftButton>
                                </SoftBox>
                              ))
                            )}
                          </DialogContent>
                          <DialogActions>
                            <SoftButton onClick={() => setShowDeleteAccountDialog(false)}>
                              {t('settings.removeaccount.cancelbtn', {defaultValue: 'cancel'})}
                            </SoftButton>
                            <SoftButton
                              onClick={onRemoveAccount}
                              disabled={loadingUser || formDialogAccountDelete !== 'DELETE' || drafts.length > 0}
                              color={
                                loadingUser || formDialogAccountDelete !== 'DELETE' || drafts.length > 0
                                  ? 'white'
                                  : 'error'
                              }>
                              {t('settings.removeaccount.confirmbtn', {defaultValue: 'Permanently delete my account'})}
                            </SoftButton>
                          </DialogActions>
                        </Dialog>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
