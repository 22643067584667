import Card from '@mui/material/Card';
import React from 'react';
import SoftBox from 'src/theme/components/SoftBox';

import StatisticCardRow, {StatisticCardRowProps} from './StatisticCardRow';

type StatisticCardProps = StatisticCardRowProps;
export default function StatisticCard(props: StatisticCardProps) {
  return (
    <Card sx={{flex: 1}}>
      <SoftBox p={2}>
        <StatisticCardRow {...props} />
      </SoftBox>
    </Card>
  );
}
