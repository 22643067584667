import {Card, Grid} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useAppThunkDispatch} from 'src/store';
import {createTeam} from 'src/store/teamslice';
import SoftAvatar from 'src/theme/components/SoftAvatar';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

import SoftBox from '../theme/components/SoftBox';

export default function TeamCreate() {
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();

  useDocumentTitle(t('teamcreate.documenttitle', {defaultValue: 'Create a team'}));

  const [form, setForm] = useState({
    state: 'idle',
    name: '',
    emails: [''],
  });

  const handleEmailChange = (index: number, value: string) => {
    const newEmails = [...form.emails];
    newEmails[index] = value;
    // Ajouter un champ vide lorsque le dernier champ est rempli
    if (index === newEmails.length - 1 && value !== '') {
      newEmails.push('');
    }
    setForm((f) => ({...f, emails: newEmails}));
  };

  const onSubmit = async () => {
    setForm((f) => ({...f, state: 'loading'}));
    const emails = form.emails.filter((e) => e !== '');
    await dispatch(createTeam({name: form.name, invitations: emails})).unwrap();
    setForm((f) => ({...f, state: 'idle'}));
    navigate('/');
  };

  return (
    <SoftBox width="100vw" display="flex" height="100vh" bgColor="white" alignItems="center" justifyContent="center">
      <Card sx={{p: 3, maxWidth: '800px'}}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <SoftAvatar alt="team-image" variant="rounded" size="xl" shadow="sm" sx={{color: 'black !important'}}>
              {form.name[0] ?? 'T'}
            </SoftAvatar>
          </Grid>
          <Grid item xs={8}>
            <SoftBox>
              <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
                {t('teamcreate.title', {defaultValue: "Nom de l'équipe"})}
              </SoftTypography>
              <SoftInput
                type="text"
                value={form.name}
                onChange={(e) => setForm((f) => ({...f, name: e.target.value}))}
              />
            </SoftBox>
            <SoftBox sx={{mt: 2}}>
              <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
                {t('teamcreate.subtitle', {defaultValue: 'Invitez vos coéquipiez'})}
              </SoftTypography>
              <SoftTypography variant="subtitle2" sx={{mb: 2}}>
                {t('teamcreate.subtitle2', {
                  defaultValue:
                    'Mettez en commun vos idées de publications. Voyez quand est-ce qu’ils ont prévu de publier. Partagez les statistiques de vos publications',
                })}
              </SoftTypography>
              {form.emails.map((email, index) => (
                <React.Fragment key={`${index}`}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                      {t('teamcreate.email', {defaultValue: 'Email'})}
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="email"
                    sx={{mb: 2}}
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                  />
                </React.Fragment>
              ))}
              <SoftButton variant="gradient" color="primary" sx={{mt: 2}} onClick={onSubmit}>
                {t('teamcreate.invite')}
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}
