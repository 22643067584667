import {Grid, Typography} from '@mui/material';
import {AssociateSubscriptionStripePostCommand} from '@perfectpost/perfect-post-common';
import {fetchAuthSession} from 'aws-amplify/auth';
import Lottie from 'lottie-react';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import cardPaymentInProcess from 'src/assets/lottie/card-payment-in-process.json';
import {perfectPostServiceClient} from 'src/services';
import {useAppThunkDispatch} from 'src/store';
import {submitMessage} from 'src/store/extension/slice';
import {reloadGroup} from 'src/store/mainslice';
import useDocumentTitle from 'src/useDocumentTitle';

export default function StripeSuccess() {
  const [searchParams] = useSearchParams();
  const stripeSessionId = searchParams.get('session_id');
  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  useDocumentTitle(t('stripesuccess.documenttitle', {defaultValue: 'Stripe callback'}));

  useEffect(() => {
    if (stripeSessionId !== null) {
      perfectPostServiceClient
        .send(new AssociateSubscriptionStripePostCommand({sessionId: stripeSessionId}))
        .then(async (user) => {
          const {tokens} = await fetchAuthSession({forceRefresh: true});
          const idToken = tokens?.idToken;
          if (idToken === undefined) {
            return;
          }
          dispatch(submitMessage({source: 'perfectpost', type: 'reload_session'}));
          dispatch(reloadGroup({user, idToken}));
          navigate('/settings/subscription?refresh_session=true');
        });
    }
  }, []);

  return (
    <Grid>
      <Lottie autoplay animationData={cardPaymentInProcess} loop style={{height: 250}} />
      <Typography style={{textAlign: 'center'}}>
        {t('stripesuccess.paymentprocessing', {defaultValue: 'We process your payment'})}
      </Typography>
    </Grid>
  );
}
