/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {forwardRef} from 'react';

// Soft UI Dashboard PRO React components
import SoftProgressRoot, {SoftProgressRootProps} from './SoftProgressRoot';
import SoftTypography from '../SoftTypography';

// Custom styles for SoftProgress

export type SoftProgressProps = Omit<SoftProgressRootProps, 'ownerState' | 'variant' | 'color'> &
  SoftProgressRootProps['ownerState'];
const SoftProgress = forwardRef<typeof SoftProgressRoot, SoftProgressProps>(
  ({variant = 'contained', color = 'info', value = 0, label = false, ...rest}, ref) => (
    <>
      {label && (
        <SoftTypography variant="button" fontWeight="medium" color="text">
          {value}%
        </SoftTypography>
      )}
      <SoftProgressRoot {...rest} ref={ref} variant="determinate" value={value} ownerState={{color, value, variant}} />
    </>
  ),
);

export default SoftProgress;
