/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import React, {createContext, useContext, useReducer, useMemo, Dispatch} from 'react';

type SoftUIState = {
  miniSidenav: boolean;
  transparentSidenav: boolean;
  sidenavColor: 'info';
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
  direction: 'ltr';
  layout: 'dashboard';
};

// The Soft UI Dashboard PRO React main context
const SoftUI = createContext<[SoftUIState, Dispatch<SoftUIAction>] | undefined>(undefined);

// Setting custom name for the context which is visible on react dev tools
SoftUI.displayName = 'SoftUIContext';

type SoftUIAction =
  | {type: 'MINI_SIDENAV'; value: boolean}
  | {type: 'TRANSPARENT_SIDENAV'; value: boolean}
  | {type: 'SIDENAV_COLOR'; value: 'info'}
  | {type: 'TRANSPARENT_NAVBAR'; value: boolean}
  | {type: 'FIXED_NAVBAR'; value: boolean}
  | {type: 'OPEN_CONFIGURATOR'; value: boolean}
  | {type: 'DIRECTION'; value: 'ltr'}
  | {type: 'LAYOUT'; value: 'dashboard'};

// Soft UI Dashboard PRO React reducer
function reducer(state: SoftUIState, action: SoftUIAction): SoftUIState {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return {...state, miniSidenav: action.value};
    }
    case 'TRANSPARENT_SIDENAV': {
      return {...state, transparentSidenav: action.value};
    }
    case 'SIDENAV_COLOR': {
      return {...state, sidenavColor: action.value};
    }
    case 'TRANSPARENT_NAVBAR': {
      return {...state, transparentNavbar: action.value};
    }
    case 'FIXED_NAVBAR': {
      return {...state, fixedNavbar: action.value};
    }
    case 'OPEN_CONFIGURATOR': {
      return {...state, openConfigurator: action.value};
    }
    case 'DIRECTION': {
      return {...state, direction: action.value};
    }
    case 'LAYOUT': {
      return {...state, layout: action.value};
    }
    default: {
      return state;
    }
  }
}

type SoftUIControllerProviderProps = {
  children: React.ReactNode;
};
// Soft UI Dashboard PRO React context provider
function SoftUIControllerProvider({children}: SoftUIControllerProviderProps) {
  const initialState: SoftUIState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
  };
  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => [controller, dispatch] as [SoftUIState, React.Dispatch<SoftUIAction>],
    [controller, dispatch],
  );

  return <SoftUI.Provider value={value}>{children}</SoftUI.Provider>;
}

// Soft UI Dashboard PRO React custom hook for using context
function useSoftUIController() {
  const context = useContext(SoftUI);

  if (!context) {
    throw new Error('useSoftUIController should be used inside the SoftUIControllerProvider.');
  }

  return context;
}

// Context module functions
const setMiniSidenav = (dispatch: Dispatch<SoftUIAction>, value: boolean) => dispatch({type: 'MINI_SIDENAV', value});
const setTransparentSidenav = (dispatch: Dispatch<SoftUIAction>, value: boolean) =>
  dispatch({type: 'TRANSPARENT_SIDENAV', value});
const setSidenavColor = (dispatch: Dispatch<SoftUIAction>, value: 'info') => dispatch({type: 'SIDENAV_COLOR', value});
const setTransparentNavbar = (dispatch: Dispatch<SoftUIAction>, value: boolean) =>
  dispatch({type: 'TRANSPARENT_NAVBAR', value});
const setFixedNavbar = (dispatch: Dispatch<SoftUIAction>, value: boolean) => dispatch({type: 'FIXED_NAVBAR', value});
const setOpenConfigurator = (dispatch: Dispatch<SoftUIAction>, value: boolean) =>
  dispatch({type: 'OPEN_CONFIGURATOR', value});
const setDirection = (dispatch: Dispatch<SoftUIAction>, value: 'ltr') => dispatch({type: 'DIRECTION', value});
const setLayout = (dispatch: Dispatch<SoftUIAction>, value: 'dashboard') => dispatch({type: 'LAYOUT', value});

export {
  SoftUIControllerProvider,
  useSoftUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
};
