import {Grid, Card, Switch} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {teamLeave} from 'src/store/mainslice';
import {editTeam, deleteTeam, fetchTeams, selectTeamById} from 'src/store/teamslice';
import SoftAvatar from 'src/theme/components/SoftAvatar';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';

import NavigationBar from './NavigationBar';

export default function TeamSettings() {
  const {id} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const team = useAppSelector((state) => selectTeamById(state, id));
  const user = useAppSelector((state) => state.main.user);

  const dispatch = useAppThunkDispatch();

  const isOwner = team?.users.some((u) => u.user === user?._id && u.role === 'owner');
  const [teamName, setTeamName] = useState(team?.name ?? '');
  const [enableDelete, setEnableDelete] = useState(false);

  const onEditTeamName = async () => {
    if (team) {
      await dispatch(editTeam({id: team._id, name: teamName})).unwrap();
    }
  };

  const onLeaveTeam = async () => {
    if (team) {
      await dispatch(teamLeave({teamId: team._id}));
      await dispatch(fetchTeams());
      navigate('/');
    }
  };

  const onDeleteTeam = async () => {
    if (team) {
      await dispatch(deleteTeam({id: team._id}));
      navigate('/');
    }
  };

  return (
    <SoftBox>
      <Grid container spacing={3}>
        <Grid item lg={11} xl={10}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {id && <NavigationBar teamId={id} value="settings" teamPremium={team?.teamPlan !== undefined} />}
              </Grid>
              <Grid item xs={12}>
                <Card sx={{p: 3}}>
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <SoftAvatar
                        alt="team-image"
                        variant="rounded"
                        size="xl"
                        shadow="sm"
                        sx={{color: 'black !important'}}>
                        {team?.name[0]}
                      </SoftAvatar>
                    </Grid>
                    <Grid item xs={8}>
                      <SoftBox>
                        <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
                          {t('teamsettingsinfo.labelname')}
                        </SoftTypography>
                        <SoftInput type="text" value={teamName} onChange={(e) => setTeamName(e.target.value)} />
                        {teamName !== team?.name && (
                          <SoftBox sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
                            <SoftButton variant="gradient" color="info" onClick={onEditTeamName}>
                              {t('global.save')}
                            </SoftButton>
                          </SoftBox>
                        )}
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card id="delete-account">
                  <SoftBox p={3} lineHeight={1}>
                    <SoftBox mb={1}>
                      <SoftTypography variant="h5">
                        {isOwner ? t('teamsettingsinfo.deleteteamtitle') : t('teamsettingsinfo.leaveteamtitle')}
                      </SoftTypography>
                    </SoftBox>
                    {isOwner && (
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        {t('teamsettingsinfo.deleteteamdescription')}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox
                    pb={3}
                    px={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{xs: 'flex-start', sm: 'center'}}
                    flexDirection={{xs: 'column', sm: 'row'}}>
                    <SoftBox display="flex" alignItems="center" mb={{xs: 3, sm: 0}}>
                      <Switch checked={enableDelete} onChange={(e, c) => setEnableDelete(c)} />
                      <SoftBox ml={2} lineHeight={0}>
                        <SoftTypography display="block" variant="button" fontWeight="medium">
                          {t('teamsettingsinfo.confirm')}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox display="flex" flexDirection={{xs: 'column', sm: 'row'}}>
                      {isOwner ? (
                        <SoftBox ml={{xs: 0, sm: 1}} mt={{xs: 1, sm: 0}}>
                          <SoftButton
                            variant="gradient"
                            color={enableDelete ? 'error' : 'secondary'}
                            sx={{height: '100%'}}
                            disabled={!enableDelete}
                            onClick={onDeleteTeam}>
                            {t('teamsettingsinfo.deleteteamtitle')}
                          </SoftButton>
                        </SoftBox>
                      ) : (
                        <SoftButton
                          variant="outlined"
                          color={enableDelete ? 'error' : 'secondary'}
                          disabled={!enableDelete}
                          onClick={onLeaveTeam}>
                          {t('teamsettingsinfo.leaveteamtitle')}
                        </SoftButton>
                      )}
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
