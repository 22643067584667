import {
  AveragePerfectPostCommand,
  GetAveragePerfectPostCommand,
  GetTopCommenterCommand,
  GetTopLikerCommand,
  type LinkedInPostAveragePerfectPostResult,
} from '@perfectpost/perfect-post-common';
import type {GetAveragePerfectPostResult} from '@perfectpost/perfect-post-common';
import type {TopCommenter} from '@perfectpost/perfect-post-common';
import type {TopLiker} from '@perfectpost/perfect-post-common';
import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import {differenceInDays, parseISO, startOfDay, startOfToday} from 'date-fns';
import localforage from 'localforage';
import {perfectPostServiceClient} from 'src/services';

import {GlobalStats, selectGlobalStats} from './postslice';
import {ProfileStats, selectProfileStatsWithinInterval} from './profileslice';

import type {RootState} from '.';

const selectLinkedInPostAveragePerfectPost = (state: RootState) => state.data.linkedinPostAveragePerfectPost;
export const selectPPPostProgress = createSelector(
  [selectGlobalStats, selectLinkedInPostAveragePerfectPost],
  (globalStats, linkedinPostAveragePerfectPost) => {
    if (linkedinPostAveragePerfectPost === undefined) {
      return {
        impression: NaN,
        comment: NaN,
        like: NaN,
        sharing: NaN,
        count: NaN,
        interaction: NaN,
        engagement: NaN,
      };
    }
    const {impression, comment, like, count, interaction, engagement} = globalStats;
    const {avgImpressions, avgComments, avgLikes, avgInteraction, avgPost} = linkedinPostAveragePerfectPost;
    const avgEngagement = avgInteraction / avgImpressions;
    const progress: GlobalStats = {
      impression: (impression - avgImpressions) / avgImpressions,
      comment: (comment - avgComments) / avgComments,
      like: (like - avgLikes) / avgLikes,
      sharing: NaN,
      count: (count - avgPost) / avgPost,
      interaction: (interaction - avgInteraction) / avgInteraction,
      engagement: (engagement - avgEngagement) / avgEngagement,
    };
    return progress;
  },
);

const selectLinkedInProfileAveragePerfectPost = (state: RootState) => state.data.userDataAveragePerfectPost;
export const selectPPProfileProgress = createSelector(
  [selectProfileStatsWithinInterval, selectLinkedInProfileAveragePerfectPost],
  (profileStats, profileAveragePerfectPost) => {
    if (profileAveragePerfectPost === undefined) {
      return {
        follower: NaN,
        newFollower: NaN,
        relation: NaN,
        newRelation: NaN,
        view: NaN,
        click: NaN,
      };
    }
    const result: ProfileStats = {
      follower: (profileStats.follower - profileAveragePerfectPost.avgFollower) / profileAveragePerfectPost.avgFollower,
      newFollower:
        (profileStats.newFollower - profileAveragePerfectPost.avgFollowerDiff) /
        profileAveragePerfectPost.avgFollowerDiff,
      relation: (profileStats.relation - profileAveragePerfectPost.avgRelation) / profileAveragePerfectPost.avgRelation,
      newRelation:
        (profileStats.newRelation - profileAveragePerfectPost.avgRelationDiff) /
        profileAveragePerfectPost.avgRelationDiff,
      view: (profileStats.view - profileAveragePerfectPost.avgVue) / profileAveragePerfectPost.avgVue,
      click: NaN,
    };
    return result;
  },
);

type LinkedInPostAveragePerfectPostCached = {
  result: LinkedInPostAveragePerfectPostResult;
  createdAt: string;
};
function isLinkedInPostAveragePerfectPostCached(obj: unknown): obj is LinkedInPostAveragePerfectPostCached {
  return typeof obj === 'object' && obj !== null && 'createdAt' in obj && 'result' in obj;
}
export const loadLinkedInPostAveragePerfectPost = createAsyncThunk<
  LinkedInPostAveragePerfectPostResult | undefined,
  {begin: Date; end: Date},
  {state: RootState}
>('data/postaverage', async ({begin, end}, {getState}) => {
  if (getState().main.premium === false) {
    return undefined;
  }
  const keyItem = `${begin.toISOString()}-${end.toISOString()}-post`;
  const value = await localforage.getItem(keyItem);
  if (isLinkedInPostAveragePerfectPostCached(value)) {
    const createdAtDate = startOfDay(parseISO(value.createdAt));
    // TTL of 1 day
    if (differenceInDays(createdAtDate, startOfToday()) === 0) {
      return value.result;
    }
  }
  const result = await perfectPostServiceClient.send(new AveragePerfectPostCommand({begin, end}));
  const cache: LinkedInPostAveragePerfectPostCached = {
    result,
    createdAt: startOfToday().toISOString(),
  };
  localforage.setItem(keyItem, cache);
  return result;
});

type UserDataAveragePerfectPostCached = {
  result: GetAveragePerfectPostResult;
  createdAt: string;
};
function isUserDataAveragePerfectPostCached(obj: unknown): obj is UserDataAveragePerfectPostCached {
  return typeof obj === 'object' && obj !== null && 'createdAt' in obj && 'result' in obj;
}
export const loadUserDataAveragePerfectPost = createAsyncThunk<
  GetAveragePerfectPostResult | undefined,
  {begin: Date; end: Date},
  {state: RootState}
>('data/profileaverage', async ({begin, end}, {getState}) => {
  if (getState().main.premium === false) {
    return undefined;
  }
  const keyItem = `${begin.toISOString()}-${end.toISOString()}-data`;
  const value = await localforage.getItem(keyItem);
  if (isUserDataAveragePerfectPostCached(value)) {
    const createdAtDate = startOfDay(parseISO(value.createdAt));
    // TTL of 1 day
    if (differenceInDays(createdAtDate, startOfToday()) === 0) {
      return value.result;
    }
  }
  const result = await perfectPostServiceClient.send(new GetAveragePerfectPostCommand({begin, end}));
  const cache: UserDataAveragePerfectPostCached = {
    result,
    createdAt: startOfToday().toISOString(),
  };
  localforage.setItem(keyItem, cache);
  return result;
});

export const loadTopCommenter = createAsyncThunk<
  TopCommenter[] | undefined,
  {begin: Date; end: Date},
  {state: RootState}
>('data/topcommenter', async ({begin, end}, {getState}) => {
  if (getState().main.premium === false) {
    return undefined;
  }
  return perfectPostServiceClient.send(new GetTopCommenterCommand({begin, end}));
});

export const loadTopLiker = createAsyncThunk<TopLiker[] | undefined, {begin: Date; end: Date}, {state: RootState}>(
  'data/topliker',
  async ({begin, end}, {getState}) => {
    if (getState().main.premium === false) {
      return undefined;
    }
    return perfectPostServiceClient.send(new GetTopLikerCommand({begin, end}));
  },
);

export interface DataState {
  loadingPostData: boolean;
  linkedinPostAveragePerfectPost?: LinkedInPostAveragePerfectPostResult;
  loadingUserData: boolean;
  userDataAveragePerfectPost?: GetAveragePerfectPostResult;
  loadingTopCommenterData: boolean;
  topCommenter?: TopCommenter[];
  loadingTopLikerData: boolean;
  topLiker?: TopLiker[];
}
const initialState: DataState = {
  loadingPostData: false,
  loadingUserData: false,
  loadingTopCommenterData: false,
  loadingTopLikerData: false,
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadLinkedInPostAveragePerfectPost.pending, (state) => {
        state.loadingPostData = true;
      })
      .addCase(loadLinkedInPostAveragePerfectPost.fulfilled, (state, action) => {
        state.loadingPostData = false;
        state.linkedinPostAveragePerfectPost = action.payload;
      })
      .addCase(loadUserDataAveragePerfectPost.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(loadUserDataAveragePerfectPost.fulfilled, (state, action) => {
        state.loadingUserData = false;
        state.userDataAveragePerfectPost = action.payload;
      })
      .addCase(loadTopCommenter.pending, (state) => {
        state.loadingTopCommenterData = true;
      })
      .addCase(loadTopCommenter.fulfilled, (state, action) => {
        state.loadingTopCommenterData = false;
        state.topCommenter = action.payload;
      })
      .addCase(loadTopLiker.pending, (state) => {
        state.loadingTopLikerData = true;
      })
      .addCase(loadTopLiker.fulfilled, (state, action) => {
        state.loadingTopLikerData = false;
        state.topLiker = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = dataSlice.actions;
export default dataSlice.reducer;
