const nmaps = {
  isub: {
    99: [99],
    100: [100],
    102: [102],
    103: [103],
    113: [113],
    119: [119],
    121: [121],
    122: [122],
    7522: [105],
    7523: [114],
    7524: [117],
    7525: [118],
    8337: [101],
    8338: [111],
    8339: [120],
    8341: [104],
    8342: [107],
    8343: [108],
    8344: [109],
    8345: [110],
    8346: [112],
    8347: [115],
    8348: [116],
    11_388: [106],
  },
  isc: {
    115: [115],
    120: [120],
    491: [113],
    610: [103],
    618: [105],
    628: [110],
    640: [114],
    655: [121],
    665: [98],
    668: [104],
    671: [108],
    1171: [102],
    7424: [97],
    7428: [99],
    7429: [100],
    7431: [101],
    7434: [106],
    7435: [107],
    7437: [109],
    7439: [111],
    7448: [112],
    7451: [116],
    7452: [117],
    7456: [118],
    7457: [119],
    7458: [122],
    42_800: [102],
  },
  sub: {
    99: [99],
    100: [100],
    101: [8337],
    102: [102],
    103: [103],
    104: [8341],
    105: [7522],
    106: [11_388],
    107: [8342],
    108: [8343],
    109: [8344],
    110: [8345],
    111: [8338],
    112: [8346],
    113: [113],
    114: [7523],
    115: [8347],
    116: [8348],
    117: [7524],
    118: [7525],
    119: [119],
    120: [8339],
    121: [121],
    122: [122],
  },
  cu: {
    66: [8492],
    69: [8496],
    70: [8497],
    72: [8459],
    73: [8464],
    76: [8466],
    77: [8499],
    82: [8475],
    101: [8495],
    103: [8458],
    111: [8500],
  },
  icu: {
    8458: [103],
    8459: [72],
    8464: [73],
    8466: [76],
    8475: [82],
    8492: [66],
    8495: [101],
    8496: [69],
    8497: [70],
    8499: [77],
    8500: [111],
  },
  scf: {
    97: [7424],
    98: [665],
    99: [7428],
    100: [7429],
    101: [7431],
    102: [1171],
    103: [610],
    104: [668],
    105: [618],
    106: [7434],
    107: [7435],
    108: [671],
    109: [7437],
    110: [628],
    111: [7439],
    112: [7448],
    113: [491],
    114: [640],
    115: [115],
    116: [7451],
    117: [7452],
    118: [7456],
    119: [7457],
    120: [120],
    121: [655],
    122: [7458],
  },
  sc: {
    97: [7424],
    98: [665],
    99: [7428],
    100: [7429],
    101: [7431],
    102: [42_800],
    103: [610],
    104: [668],
    105: [618],
    106: [7434],
    107: [7435],
    108: [671],
    109: [7437],
    110: [628],
    111: [7439],
    112: [7448],
    113: [491],
    114: [640],
    115: [115],
    116: [7451],
    117: [7452],
    118: [7456],
    119: [7457],
    120: [120],
    121: [655],
    122: [7458],
  },
  ifspec: {
    161: [33],
    191: [63],
    305: [105],
    383: [74],
    387: [103],
    398: [69],
    477: [101],
    592: [97],
    596: [99],
    607: [102],
    613: [104],
    623: [109],
    633: [114],
    638: [106],
    647: [116],
    652: [118],
    653: [119],
    654: [121],
    670: [107],
    729: [46],
    741: [76],
    923: [86],
    1021: [67],
    1280: [80],
    1503: [108],
    1563: [59],
    5601: [68],
    7450: [82],
    8009: [81],
    8222: [34],
    8254: [95],
    8256: [8255],
    8262: [8261],
    8498: [70],
    8704: [65],
    8745: [85],
    8757: [8756],
    8869: [84],
  },
  freg: {
    72: [72],
    73: [73],
    77: [87],
    78: [78],
    79: [79],
    83: [83],
    87: [77],
    88: [88],
    90: [90],
    98: [113],
    100: [112],
    110: [117],
    112: [100],
    113: [98],
    117: [110],
  },
  fspec: {
    41: [40],
    44: [39],
    62: [60],
    93: [91],
    125: [123],

    33: [161],
    34: [8222],
    39: [44],
    40: [41],
    46: [729],
    59: [1563],
    60: [62],
    63: [191],
    65: [8704],
    66: [113],
    67: [1021],
    68: [5601],
    69: [398],
    70: [8498],
    71: [387],
    74: [383],
    75: [670],
    76: [741],
    80: [1280],
    81: [8009],
    82: [7450],
    84: [8869],
    85: [8745],
    86: [923],
    89: [654],
    91: [93],
    95: [8254],
    97: [592],
    99: [596],
    101: [477],
    102: [607],
    103: [387],
    104: [613],
    105: [305],
    106: [638],
    107: [670],
    108: [1503],
    109: [623],
    114: [633],
    116: [647],
    118: [652],
    119: [653],
    121: [654],
    123: [125],
    8255: [8256],
    8261: [8262],
    8756: [8757],
  },
  d: {
    97: [867],
    99: [872],
    100: [873],
    101: [868],
    104: [874],
    105: [869],
    109: [875],
    111: [870],
    114: [876],
    116: [877],
    117: [871],
    118: [878],
    120: [879],
  },
  s: {
    40: [8317],
    41: [8318],
    43: [8314],
    45: [8315],
    48: [8304],
    49: [185],
    50: [178],
    51: [179],
    52: [8308],
    53: [8309],
    54: [8310],
    55: [8311],
    56: [8312],
    57: [8313],
    61: [8316],
    65: [7468],
    66: [7470],
    67: [7580],
    68: [7472],
    69: [7473],
    70: [7584],
    71: [7475],
    72: [7476],
    73: [7477],
    74: [7478],
    75: [7479],
    76: [7480],
    77: [7481],
    78: [7482],
    79: [7484],
    80: [7486],
    81: [81],
    82: [7487],
    83: [738],
    84: [7488],
    85: [7489],
    86: [11_389],
    87: [7490],
    88: [739],
    89: [696],
    90: [7611],
    97: [7491],
    98: [7495],
    99: [7580],
    100: [7496],
    101: [7497],
    102: [7584],
    103: [7501],
    104: [688],
    105: [7590],
    106: [690],
    107: [7503],
    108: [737],
    109: [7504],
    110: [8319],
    111: [7506],
    112: [7510],
    113: [5227],
    114: [691],
    115: [738],
    116: [7511],
    117: [7512],
    118: [7515],
    119: [695],
    120: [739],
    121: [696],
    122: [7611],
  },
  id: {
    867: [97],
    868: [101],
    869: [105],
    870: [111],
    871: [117],
    872: [99],
    873: [100],
    874: [104],
    875: [109],
    876: [114],
    877: [116],
    878: [118],
    879: [120],
  },
  is: {
    81: [81],
    178: [50],
    179: [51],
    185: [49],
    688: [104],
    690: [106],
    691: [114],
    695: [119],
    696: [121],
    737: [108],
    738: [115],
    739: [120],
    5227: [113],
    7468: [65],
    7470: [66],
    7472: [68],
    7473: [69],
    7475: [71],
    7476: [72],
    7477: [73],
    7478: [74],
    7479: [75],
    7480: [76],
    7481: [77],
    7482: [78],
    7484: [79],
    7486: [80],
    7487: [82],
    7488: [84],
    7489: [85],
    7490: [87],
    7491: [97],
    7495: [98],
    7496: [100],
    7497: [101],
    7501: [103],
    7503: [107],
    7504: [109],
    7506: [111],
    7510: [112],
    7511: [116],
    7512: [117],
    7515: [118],
    7580: [99],
    7584: [102],
    7590: [105],
    7611: [122],
    8304: [48],
    8308: [52],
    8309: [53],
    8310: [54],
    8311: [55],
    8312: [56],
    8313: [57],
    8314: [43],
    8315: [45],
    8316: [61],
    8317: [40],
    8318: [41],
    8319: [110],
    11_389: [86],
  },
};

export default nmaps;

export type isubKey = keyof typeof nmaps.isub;
export function isisubKey(s: string | number | undefined): s is isubKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.isub).includes(`${s}`);
  }

  return Object.keys(nmaps.isub).includes(s);
}

export type iscKey = keyof typeof nmaps.isc;
export function isiscKey(s: string | number | undefined): s is iscKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.isc).includes(`${s}`);
  }

  return Object.keys(nmaps.isc).includes(s);
}

export type subKey = keyof typeof nmaps.sub;
export function issubKey(s: string | number | undefined): s is subKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.sub).includes(`${s}`);
  }

  return Object.keys(nmaps.sub).includes(s);
}

export type cuKey = keyof typeof nmaps.cu;
export function iscuKey(s: string | number | undefined): s is cuKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.cu).includes(`${s}`);
  }

  return Object.keys(nmaps.cu).includes(s);
}

export type icuKey = keyof typeof nmaps.icu;
export function isicuKey(s: string | number | undefined): s is icuKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.icu).includes(`${s}`);
  }

  return Object.keys(nmaps.icu).includes(s);
}

export type scfKey = keyof typeof nmaps.scf;
export function isscfKey(s: string | number | undefined): s is scfKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.scf).includes(`${s}`);
  }

  return Object.keys(nmaps.scf).includes(s);
}

export type scKey = keyof typeof nmaps.sc;
export function isscKey(s: string | number | undefined): s is scKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.sc).includes(`${s}`);
  }

  return Object.keys(nmaps.sc).includes(s);
}

export type ifspecKey = keyof typeof nmaps.ifspec;
export function isifspecKey(s: string | number | undefined): s is ifspecKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.ifspec).includes(`${s}`);
  }

  return Object.keys(nmaps.ifspec).includes(s);
}

export type fregKey = keyof typeof nmaps.freg;
export function isfregKey(s: string | number | undefined): s is fregKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.freg).includes(`${s}`);
  }

  return Object.keys(nmaps.freg).includes(s);
}

export type fspecKey = keyof typeof nmaps.fspec;
export function isfspecKey(s: string | number | undefined): s is fspecKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.fspec).includes(`${s}`);
  }

  return Object.keys(nmaps.fspec).includes(s);
}

export type dKey = keyof typeof nmaps.d;
export function isdKey(s: string | number | undefined): s is dKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.d).includes(`${s}`);
  }

  return Object.keys(nmaps.d).includes(s);
}

export type sKey = keyof typeof nmaps.s;
export function issKey(s: string | number | undefined): s is sKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.s).includes(`${s}`);
  }

  return Object.keys(nmaps.s).includes(s);
}

export type idKey = keyof typeof nmaps.id;
export function isidKey(s: string | number | undefined): s is idKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.id).includes(`${s}`);
  }

  return Object.keys(nmaps.id).includes(s);
}

export type isKey = keyof typeof nmaps.is;
export function isisKey(s: string | number | undefined): s is isKey {
  if (s === undefined) {
    return false;
  }

  if (typeof s === 'number') {
    return Object.keys(nmaps.is).includes(`${s}`);
  }

  return Object.keys(nmaps.is).includes(s);
}
