import {Client} from '@perfectpost/perfect-post-common';
import {apiName} from 'src/amplify';

import {CrispClient} from './Crisp';
import {MixPanelService} from './MixPanelService';

export const perfectPostServiceClient = new Client(apiName);

export const mixPanelService = new MixPanelService();
export const crispService = new CrispClient();
