import {Dialog, DialogTitle, DialogContent, DialogActions, Divider, CircularProgress} from '@mui/material';
import {
  GetExampleCtaCommand,
  type Cta,
  GetCtaCommand,
  EditCtaCommand,
  CreateCtaCommand,
  DeleteCtaCommand,
} from '@perfectpost/perfect-post-common';
import {components} from '@perfectpost/perfect-post-common/src/cms/api';
import {EmitterSource} from 'quill';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SingleValue} from 'react-select';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftEditor from 'src/theme/components/SoftEditor';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';

import TipCard from '../tipscard/TipCards';

const regex = /(<([^>]+)>)/gi;

type CTAModalProps = {
  handleClose: () => void;
  handleConfirm: (content: string) => void;
  mention?: boolean;
};

export default function CTAModal(props: CTAModalProps) {
  const {handleClose, handleConfirm, mention} = props;
  const premium = useAppSelector((state) => state.main.premium);
  const locale = useAppSelector((state) => state.main.user?.locale);
  const {t} = useTranslation();
  const [formState, setFormState] = useState<'idle' | 'working'>('idle');
  const [cta, setCta] = useState<components['schemas']['CtaListResponseDataItem'][]>([]);
  const [customCta, setCustomCta] = useState<Cta[]>([]);

  const [selectedItemCta, setSelectedItemCta] = useState<SingleValue<{
    value: string;
    label: string;
  }> | null>(null);
  const ctaOption = useMemo<{value: string; label: string}[]>(() => {
    const options = cta.map((item) => ({
      label: item.attributes?.title ?? '',
      value: item.id?.toString() ?? '',
    }));
    if (premium) {
      return options;
    } else {
      return [
        ...options,
        {
          label: t('ctamodal.gopremium'),
          value: 'PREMIUM',
        },
      ];
    }
  }, [cta]);

  const [input, setInput] = useState<string>('');

  const [selectedItemCustomCta, setSelectedItemCustomCta] = useState<SingleValue<{
    value: string;
    label: string;
  }> | null>(null);

  const fetchCtas = useCallback(
    async (page: number = 1) => {
      const data = await perfectPostServiceClient.send(new GetExampleCtaCommand({page, locale: locale ?? 'en'}));
      setCta((prev) => [...prev, ...(data.data ?? [])]);
      const pagination = data.meta?.pagination;
      if (
        pagination?.page !== undefined &&
        pagination.pageCount !== undefined &&
        pagination.pageCount !== pagination.page
      ) {
        fetchCtas(pagination.page + 1);
      }
    },
    [locale],
  );

  useEffect(() => {
    setCta([]);
    fetchCtas();
    perfectPostServiceClient.send(new GetCtaCommand()).then((data) => setCustomCta(data));
  }, [fetchCtas]);

  const onSubmit = () => {
    if (input !== '') {
      handleConfirm(input);
    }
  };

  const onSelectItem = (newValue: SingleValue<{value: string; label: string}> | null) => {
    if (newValue?.value === 'PREMIUM') {
      return;
    }
    setSelectedItemCta(newValue);
    setSelectedItemCustomCta(null);
    const content = cta.find((item) => item.id?.toString() === newValue?.value)?.attributes?.content;
    if (content) {
      setInput(content.replaceAll('\n', '<br />'));
    }
  };

  const onSelectCustomItem = (newValue: SingleValue<{value: string; label: string}> | null) => {
    setSelectedItemCta(null);
    setSelectedItemCustomCta(newValue);
    const content = customCta.find((item) => item._id === newValue?.value)?.content;
    if (content) {
      setInput(content);
    }
  };

  const onInputChange: (content: string, source?: EmitterSource) => void = (value, sources) => {
    setInput(value);
    if (sources === 'user') {
      setSelectedItemCta(null);
    }
  };

  const saveCustomCta = async () => {
    setFormState('working');
    let updatedCta: Cta;
    if (selectedItemCustomCta) {
      updatedCta = await perfectPostServiceClient.send(
        new EditCtaCommand({
          id: selectedItemCustomCta.value,
          content: input,
        }),
      );
      setCustomCta(customCta.map((item) => (item._id === updatedCta._id ? updatedCta : item)));
    } else {
      updatedCta = await perfectPostServiceClient.send(new CreateCtaCommand(input));
      setCustomCta([...customCta, updatedCta]);
    }
    setSelectedItemCustomCta({
      label: updatedCta.content.replace(regex, '').slice(0, 25),
      value: updatedCta._id,
    });
    setFormState('idle');
  };

  const onRemoveCustomCta = () => {
    if (selectedItemCustomCta !== null) {
      perfectPostServiceClient.send(new DeleteCtaCommand({id: selectedItemCustomCta.value}));
      setCustomCta(customCta.filter((item) => item._id !== selectedItemCustomCta.value));
      setSelectedItemCustomCta(null);
      setInput('');
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md">
      <DialogTitle>
        <SoftTypography>{t('ctamodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <TipCard informationkey="modalcta" />
        <SoftBox my={1} ml={0.5} display="flex" flexDirection="row" alignItems="center">
          <SoftTypography component="label" variant="caption" fontWeight="bold" mr={2}>
            {t('ctamodal.labelselect')}
          </SoftTypography>
          <SoftBox sx={{flexGrow: 1}}>
            <SoftSelect options={ctaOption} value={selectedItemCta} onChange={onSelectItem} />
          </SoftBox>
        </SoftBox>
        <SoftBox my={1} ml={0.5} display="flex" flexDirection="row" alignItems="center">
          <SoftTypography component="label" variant="caption" fontWeight="bold" mr={2}>
            {t('ctamodal.labelcustom')}
          </SoftTypography>
          <SoftBox sx={{flexGrow: 1}}>
            <SoftSelect
              options={customCta.map((item) => ({
                label: item.content.replace(regex, '').slice(0, 25),
                value: item._id,
              }))}
              value={selectedItemCustomCta}
              onChange={onSelectCustomItem}
            />
          </SoftBox>
        </SoftBox>

        <Divider />

        <SoftEditor
          key={selectedItemCustomCta?.value ?? selectedItemCta?.value}
          premium={premium}
          noCounter
          noToolbar
          noSeeMore
          initialValue={input}
          onChange={onInputChange}
          mention={mention}
        />
        <SoftBox sx={{mb: 1}} />
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={saveCustomCta} color="primary" disabled={selectedItemCta !== null}>
          {formState === 'idle' ? t('global.save') : <CircularProgress size={20} color="info" />}
        </SoftButton>
        {selectedItemCustomCta && (
          <SoftButton variant="gradient" onClick={onRemoveCustomCta}>
            {t('global.delete')}
          </SoftButton>
        )}
        <SoftButton variant="gradient" onClick={handleClose} autoFocus>
          {t('scheduledialog.cancelbtn', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton variant="gradient" onClick={onSubmit} disabled={input === ''}>
          {t('scheduledialog.import', {defaultValue: 'Import into publication'})}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
