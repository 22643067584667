/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {CircularProgress, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {RefreshScheduledPostCommand, type Draft, StatusCommand} from '@perfectpost/perfect-post-common';
import {cloneDeep} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable, DroppableProvided, DroppableStateSnapshot} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {SingleValue} from 'react-select';
import RemoveAllDraftDialog from 'src/components/modals/RemoveAllDraftDialog';
import RemoveDraftDialog from 'src/components/modals/RemoveDraftDialog';
import ScheduleDialog from 'src/components/modals/ScheduleDialog';
import PostCards from 'src/components/postcard/PostCards';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import {useAppThunkDispatch} from 'src/store';
import {cancelDraft, cloneDraft, draftLoad, editDraft, removeDraft} from 'src/store/draftslice';
import {submitMessage} from 'src/store/extension/slice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

// t('draft.tag.idea', {defaultValue: 'Idea'})
// t('draft.tag.draft', {defaultValue: 'Draft'})
// t('draft.tag.ready', {defaultValue: 'Ready'})
// t('draft.tag.scheduled', {defaultValue: 'Scheduled'})
// t('draft.tag.published', {defaultValue: 'Published'})
type Tags = 'idea' | 'draft' | 'ready' | 'scheduled' | 'published';
type ColumnsType = {
  id: Tags;
  name: string;
  rows: Draft[];
};

export const useStrictDroppable = (loading: boolean) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    let animation: number;

    if (!loading) {
      animation = requestAnimationFrame(() => setEnabled(true));
    }

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, [loading]);

  return [enabled];
};

const defaultColumn: ColumnsType[] = [
  {id: 'idea', name: 'idea', rows: []},
  {id: 'draft', name: 'draft', rows: []},
  {id: 'ready', name: 'ready', rows: []},
  {id: 'scheduled', name: 'scheduled', rows: []},
  {id: 'published', name: 'published', rows: []},
];

type KanbanState = {
  pageState: 'loading' | 'refreshing' | 'multipleselect' | 'showRemoveAllDialog' | 'sorting' | 'idle';
  refreshResult?:
    | {
        posts: number;
        _ids: string[];
      }
    | {error: string};
  selectedDrafts?: Draft[];
  showRemoveDraftDialog?: Draft;
  showScheduleDialog?: string;
  showMenuColumn?: {tag: Tags; anchorEl: EventTarget; mode?: 'sorting'};
  filter?: string;
  tags: string[];
  columns: ColumnsType[];
};

export default function Kanban() {
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(t('kanban.documenttitle', {defaultValue: 'Kanban'}));

  const [state, setState] = useState<KanbanState>({
    pageState: 'loading',
    tags: [] as string[],
    columns: cloneDeep(defaultColumn),
  });

  const {pageState, refreshResult, showRemoveDraftDialog, showScheduleDialog, showMenuColumn, filter, tags, columns} =
    state;

  const me = useAppSelector((state) => state.main.user);
  const drafts = useAppSelector((state) => state.draft.drafts);
  const extensionInstalled = useAppSelector((state) => state.extension.version !== undefined);

  const [enabled] = useStrictDroppable(pageState === 'loading');

  useEffect(() => {
    setState((state) => ({...state, pageState: 'loading'}));
    dispatch(draftLoad());
  }, []);

  useEffect(() => {
    const newColumns = cloneDeep(defaultColumn);
    const tags: string[] = [];
    for (const draft of drafts) {
      if (draft.categories) {
        tags.push(...draft.categories);
      }
      const found = newColumns.find((c) => c.name === draft.tag);
      if (found) {
        found.rows.push(draft);
        found.rows.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
        if (draft.position === undefined) {
          dispatch(
            editDraft({
              draftId: draft._id,
              position: found.rows.length - 1,
              tag: draft.tag ?? 'idea',
              organization: draft.organization,
            }),
          );
        }
      } else {
        newColumns.push({
          id: (draft.tag ?? 'idea') as Tags,
          name: draft.tag ?? 'idea',
          rows: [draft],
        });
        if (draft.position === undefined) {
          dispatch(
            editDraft({
              draftId: draft._id,
              position: 0,
              tag: draft.tag ?? 'idea',
              organization: draft.organization,
            }),
          );
        }
      }
    }
    setState((state) => ({...state, pageState: 'idle', tags: [...new Set(tags)], columns: newColumns}));
  }, [drafts, filter]);

  const onRemove = async () => {
    const draft = drafts.find((d) => d._id === showRemoveDraftDialog?._id);
    if (draft) {
      await dispatch(removeDraft(draft._id));
      setState((state) => {
        const columns = state.columns;
        const newColumns = cloneDeep(columns);
        for (const col of newColumns) {
          col.rows = col.rows.filter((draft) => draft._id !== showRemoveDraftDialog?._id);
        }
        return {...state, showRemoveDraftDialog: undefined, columns: newColumns};
      });
    }
  };

  const onProgrammingDate = async (date: Date): Promise<void> => {
    const draft = drafts.find((d) => d._id === showScheduleDialog);
    if (draft !== undefined) {
      await dispatch(
        editDraft({
          draftId: draft._id,
          programmingDate: date.toISOString(),
          organization: draft.organization,
        }),
      );
      setState((state) => ({...state, showScheduleDialog: undefined}));
    } else {
      return Promise.reject('draft is undefined');
    }
  };

  const onCancelScheduling = async (): Promise<void> => {
    const draft = drafts.find((d) => d._id === showScheduleDialog);
    if (draft !== undefined) {
      await dispatch(
        editDraft({
          draftId: draft._id,
          tag: 'ready',
          organization: draft.organization,
          position: columns.find((c) => c.id === 'ready')?.rows?.length ?? 0,
        }),
      );
      setState((state) => ({...state, showScheduleDialog: undefined}));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragEnd = async (result: any, columns: ColumnsType[]) => {
    if (!result.destination) return;
    const {source, destination} = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns.find((c) => c.id === source.droppableId);
      const destColumn = columns.find((c) => c.id === destination.droppableId);
      const [removed] = sourceColumn?.rows?.splice(source.index, 1) ?? [];
      if (removed === undefined) {
        return;
      }
      destColumn?.rows?.splice(destination.index, 0, removed);
      const draft = drafts.find((d) => d._id === removed._id);

      if (draft !== undefined && draft.linkedin === undefined) {
        if (source.droppableId === 'scheduled' && destination.droppableId !== 'published') {
          await dispatch(cancelDraft(draft));
        }
        if (destination.droppableId === 'scheduled') {
          setState((state) => ({...state, showScheduleDialog: draft._id}));
        }
        dispatch(
          editDraft({
            draftId: draft._id,
            tag: destination.droppableId,
            position: destination.index,
            organization: draft.organization,
          }),
        );
      }
    } else {
      const column = columns.find((c) => c.id === source.droppableId);
      const [removed] = column?.rows?.splice(source.index, 1) ?? [];
      if (removed === undefined) {
        return;
      }
      const draft = drafts.find((d) => d._id === removed._id);
      column?.rows?.splice(destination.index, 0, removed);
      if (draft) {
        dispatch(
          editDraft({
            draftId: draft._id,
            position: destination.index,
            organization: draft.organization,
          }),
        );
      }
    }
    setState((state) => ({...state, columns: columns}));
  };

  const onFilterChange = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => {
    setState((state) => ({...state, filter: newValue?.value}));
  };

  const onFetchScheduledPost = async () => {
    setState((state) => ({...state, pageState: 'refreshing'}));
    const command = await perfectPostServiceClient.send(new RefreshScheduledPostCommand());
    const commandId = command.command._id;
    if (commandId === undefined) {
      setState((state) => ({...state, pageState: 'idle'}));
      return;
    }
    dispatch(submitMessage({type: 'check_queue', source: 'perfectpost'}));
    const result = await new Promise<
      | {
          posts: number;
          _ids: string[];
        }
      | {error: string}
    >((resolve) => {
      const checkFunc = async () => {
        const cmd = await perfectPostServiceClient.send(new StatusCommand(commandId));
        if (cmd === undefined) {
          clearInterval(timer);
          resolve({error: 'command not found'});
          return;
        }
        if (cmd.result !== undefined) {
          clearInterval(timer);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resolve(cmd.result);
        }
      };
      const timer = setInterval(checkFunc, 2000);
      checkFunc();
    });

    setState((state) => ({...state, refreshResult: result}));
    setTimeout(() => {
      setState((state) => ({...state, refreshResult: undefined}));
    }, 5000);
    await dispatch(draftLoad());
    setState((state) => ({...state, pageState: 'idle'}));
  };

  const onSelectDraft = (draft: Draft) => {
    if (pageState === 'multipleselect') {
      setState((state) => {
        const selectedDrafts = state.selectedDrafts ?? [];
        const index = selectedDrafts.findIndex((d) => d._id === draft._id);
        if (index === -1) {
          selectedDrafts.push(draft);
        } else {
          selectedDrafts.splice(index, 1);
        }
        return {...state, selectedDrafts};
      });
    } else {
      const myPost = draft.user === me?._id;
      if (myPost) {
        navigate(`/publish/draft/${draft._id}`);
      } else {
        navigate(`/publish/shared/${draft._id}`);
      }
    }
  };

  const onRemoveSelected = async () => {
    if (state.selectedDrafts !== undefined) {
      setState((state) => ({...state, pageState: 'idle', selectedDrafts: undefined}));
      for (const draft of state.selectedDrafts) {
        await dispatch(removeDraft(draft._id));
      }
    }
  };

  const onCloneDraft = (draft: Draft) => {
    dispatch(cloneDraft(draft));
  };

  const onRemoveAll = async () => {
    console.log('onRemoveAll');
    if (showMenuColumn?.tag !== undefined) {
      const drafts = columns.find((c) => c.id === showMenuColumn.tag)?.rows ?? [];
      for (const draft of drafts) {
        await dispatch(removeDraft(draft._id));
      }
      setState((state) => ({...state, pageState: 'idle', showMenuColumn: undefined}));
    }
  };

  const onCloseColumnMenu = useCallback(() => {
    setState((state) => ({...state, showMenuColumn: undefined}));
  }, [setState]);

  const onSort =
    (field: 'date' | 'content', order: 'asc' | 'desc') => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation();
      if (showMenuColumn?.tag !== undefined) {
        const drafts = columns.find((c) => c.id === showMenuColumn.tag)?.rows ?? [];
        drafts.sort((a, b) => {
          if (field === 'date') {
            return order === 'asc' ? a.date.localeCompare(b.date) : b.date.localeCompare(a.date);
          } else {
            return order === 'asc' ? a.content.localeCompare(b.content) : b.content.localeCompare(a.content);
          }
        });
        setState((state) => ({...state, columns: columns}));
      }
    };

  return (
    <SoftBox sx={{flexGrow: 1, pb: 2, overflow: 'hidden'}}>
      <SoftBox sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
        <SoftBox>
          <SoftSelect
            placeholder="Filter tag"
            isClearable
            options={tags.map((s) => ({value: s, label: s}))}
            onChange={onFilterChange}
          />
        </SoftBox>
        <SoftBox sx={{ml: 2}}>
          <SoftButton
            color="dark"
            variant="outlined"
            disabled={extensionInstalled === false || pageState === 'refreshing'}
            onClick={onFetchScheduledPost}>
            {pageState === 'refreshing' ? <CircularProgress size={20} /> : t('kanban.refreshlinkedinpost')}
          </SoftButton>
          {refreshResult && (
            <SoftTypography variant="caption" sx={{ml: 1}}>
              {'error' in refreshResult ? refreshResult.error : t('content.postrefreshed', {nb: refreshResult.posts})}
            </SoftTypography>
          )}
        </SoftBox>
      </SoftBox>
      {showScheduleDialog && <ScheduleDialog handleClose={onCancelScheduling} handleSchedule={onProgrammingDate} />}
      {showRemoveDraftDialog && (
        <RemoveDraftDialog
          draft={showRemoveDraftDialog}
          handleClose={() => setState((state) => ({...state, showRemoveDraftDialog: undefined}))}
          handleRemove={onRemove}
        />
      )}
      {pageState === 'showRemoveAllDialog' && showMenuColumn !== undefined && (
        <RemoveAllDraftDialog
          drafts={columns.find((c) => c.id === showMenuColumn.tag)?.rows ?? []}
          handleClose={() => setState((f) => ({...f, pageState: 'idle', showMenuColumn: undefined}))}
          handleRemove={onRemoveAll}
        />
      )}
      <DragDropContext onDragEnd={(result) => onDragEnd(result, columns)}>
        <SoftBox
          sx={({palette}) => ({
            whiteSpace: 'nowrap',
            display: 'flex',
            gap: '20px',
            overflowX: 'scroll',
            height: 'calc(100vh - 86px)',
            width: '100%',
            pr: 3,
            paddingBottom: '10px',
            /* width */
            '::-webkit-scrollbar': {
              width: 20,
            },
            /* Track */
            '::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 5px grey',
              borderRadius: 10,
            },
            /* Handle */
            '::-webkit-scrollbar-thumb': {
              background: palette.info.main,
              borderRadius: 10,
            },
            /* Handle on hover */
            '::-webkit-scrollbar-thumb:hover': {
              background: palette.info.focus,
            },
          })}>
          {columns.map((column) => {
            return (
              <SoftBox key={column.id} sx={{minWidth: 420, height: '100%'}}>
                {enabled && (
                  <Droppable droppableId={column.id}>
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                      <SoftBox
                        sx={({palette}) => ({
                          height: '100%',
                          backgroundColor: snapshot.isDraggingOver ? palette.grey['400'] : palette.grey['200'],
                          borderRadius: '1rem',
                        })}>
                        <SoftBox
                          sx={({palette}) => ({
                            p: 2,
                            position: 'sticky',
                            backgroundColor: palette.grey['200'],
                            zIndex: 100,
                            height: 65,
                            display: 'flex',
                            alignItems: 'center',
                            borderTopLeftRadius: '1rem',
                            borderTopRightRadius: '1rem',
                          })}>
                          <>
                            <SoftTypography variant="caption" fontWeight="bold">
                              {t(`draft.tag.${column.id}`)}
                            </SoftTypography>
                            <span style={{fontSize: 14}}>&nbsp;{column.rows.length}</span>
                            <div style={{flexGrow: 1}}></div>
                            <SoftButton
                              variant="text"
                              color="dark"
                              onClick={(event) => {
                                setState((state) => ({
                                  ...state,
                                  showMenuColumn: {tag: column.id, anchorEl: event.target},
                                }));
                              }}>
                              <MoreHorizIcon fontSize="large" />
                            </SoftButton>
                          </>
                        </SoftBox>
                        <List
                          sx={{
                            px: 2,
                            height: pageState === 'multipleselect' ? 'calc(100% - 140px)' : 'calc(100% - 110px)',
                            width: 420,
                            overflowX: 'hidden',
                            overflowY: 'auto',
                          }}
                          dense
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          {column.rows.map((draft, index) =>
                            filter === undefined || draft.categories?.includes(filter) ? (
                              <PostCards
                                key={draft._id}
                                draft={draft}
                                index={index}
                                selectMode={pageState === 'multipleselect'}
                                selected={state.selectedDrafts?.find((d) => d._id === draft._id) !== undefined}
                                onSelect={() => onSelectDraft(draft)}
                                onRemove={() => {
                                  setState((state) => ({...state, showRemoveDraftDialog: draft}));
                                }}
                                onClone={() => onCloneDraft(draft)}
                                noDrag={draft.linkedin !== undefined}
                              />
                            ) : (
                              <Draggable key={draft._id} draggableId={draft._id} index={index}>
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  />
                                )}
                              </Draggable>
                            ),
                          )}
                          {provided.placeholder}
                        </List>
                        <SoftBox
                          sx={({palette}) => ({
                            p: 1,
                            position: 'sticky',
                            backgroundColor: palette.grey['200'],
                            zIndex: 100,
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            borderBottomLeftRadius: '1rem',
                            borderBottomRightRadius: '1rem',
                          })}>
                          {pageState === 'multipleselect' ? (
                            <SoftBox sx={{display: 'flex', flexDirection: 'column', flex: 1, gap: '5px'}}>
                              <SoftButton
                                variant="gradient"
                                color={
                                  state.selectedDrafts === undefined || state.selectedDrafts.length === 0
                                    ? 'light'
                                    : 'error'
                                }
                                fullWidth
                                size="small"
                                disabled={state.selectedDrafts?.length === 0}
                                onClick={onRemoveSelected}>
                                {t('kanban.removeselection')}
                              </SoftButton>
                              <SoftButton
                                variant="gradient"
                                color="dark"
                                fullWidth
                                size="small"
                                onClick={() => {
                                  setState((state) => ({...state, pageState: 'idle', selectedDrafts: undefined}));
                                }}>
                                {t('kanban.cancel')}
                              </SoftButton>
                            </SoftBox>
                          ) : (
                            <SoftButton
                              variant="gradient"
                              color="dark"
                              fullWidth
                              size="small"
                              component={Link}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              to={`/publish/draft/new?tag=${column.id}`}>
                              {t('kanban.addpost')}
                            </SoftButton>
                          )}
                        </SoftBox>
                      </SoftBox>
                    )}
                  </Droppable>
                )}
              </SoftBox>
            );
          })}
        </SoftBox>
      </DragDropContext>
      {showMenuColumn !== undefined && (
        <Menu
          anchorEl={showMenuColumn.anchorEl as HTMLElement}
          open
          onClose={onCloseColumnMenu}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          MenuListProps={{
            role: 'listbox',
          }}>
          {showMenuColumn.mode === 'sorting' ? (
            <>
              <ListItem>
                <ListItemIcon>
                  <IconButton
                    onClick={() =>
                      setState((state) => ({...state, showMenuColumn: {...showMenuColumn, mode: undefined}}))
                    }>
                    <ChevronLeftIcon fontSize="small" />
                  </IconButton>
                </ListItemIcon>
                <ListItemText secondary={t('kanban.availablesort')}></ListItemText>
                <IconButton onClick={onCloseColumnMenu}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </ListItem>
              <MenuItem onClick={onSort('date', 'asc')}>{t('kanban.date.asc')}</MenuItem>
              <MenuItem onClick={onSort('date', 'desc')}>{t('kanban.date.desc')}</MenuItem>
              <MenuItem onClick={onSort('content', 'asc')}>{t('kanban.content.asc')}</MenuItem>
            </>
          ) : (
            <>
              <ListItem>
                <ListItemText sx={{textAlign: 'center'}} secondary={t('kanban.availableaction')}></ListItemText>
                <IconButton onClick={onCloseColumnMenu}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </ListItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setState((state) => ({...state, showMenuColumn: {...showMenuColumn, mode: 'sorting'}}));
                }}>
                {t('kanban.sortby')}
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setState((state) => ({...state, showMenuColumn: undefined, pageState: 'multipleselect'}));
                }}>
                {t('kanban.multipleselect')}
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setState((state) => ({...state, pageState: 'showRemoveAllDialog'}));
                }}>
                {t('kanban.removeall')}
              </MenuItem>
            </>
          )}
        </Menu>
      )}
    </SoftBox>
  );
}
