import {User} from '@perfectpost/perfect-post-common';
import {Crisp} from 'crisp-sdk-web';

export class CrispClient {
  constructor() {
    Crisp.configure('a30c50b9-400f-4fad-9cbc-b6142734b16b');
  }

  public trackUser(user: User, premium: boolean) {
    Crisp.user.setEmail(user.email);
    Crisp.user.setNickname(`${user.firstname} ${user.lastname}`);
    Crisp.session.setData({
      user_id: user._id,
      plan: premium ? 'premium' : 'freemium',
      vanityName: user.vanityName ? user.vanityName : '',
    });
  }

  public trackExtension(version: string) {
    Crisp.session.setData({
      extension_version: version,
    });
  }

  public openChat() {
    if (!Crisp.chat.isChatOpened()) {
      Crisp.chat.open();
    }
  }

  public tryGpt() {
    Crisp.session.pushEvent('try_gpt');
  }
}
