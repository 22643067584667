import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {Card, Divider} from '@mui/material';
import {LatestPostCommand, Tips} from '@perfectpost/perfect-post-common';
import type {Draft, LinkedinPost} from '@perfectpost/perfect-post-common';
import {parseISO, startOfToday, endOfToday, compareAsc, isFuture, addDays, NormalizedInterval} from 'date-fns';
import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Chart, {Data} from 'src/components/chart/Chart';
import DraftCard from 'src/components/draftcard/DraftCard';
import MessageCard from 'src/components/messagecard/MessageCard';
import StatisticCard from 'src/components/statisticscard/StatisticCard';
import {crispService, perfectPostServiceClient} from 'src/services';
import {useAppThunkDispatch} from 'src/store';
import {useAppSelector} from 'src/store';
import {
  loadLinkedInPostAveragePerfectPost,
  loadUserDataAveragePerfectPost,
  selectPPPostProgress,
  selectPPProfileProgress,
} from 'src/store/dataslice';
import {draftLoad} from 'src/store/draftslice';
import {
  listPost,
  selectGlobalStats,
  selectGlobalStatsPrevious,
  selectGlobalStatsProgress,
  selectPreviousInterval,
} from 'src/store/postslice';
import {
  getView,
  selectProfileNewFollowerWithinInterval,
  selectProfileNewFollowerWithinPreviousInterval,
} from 'src/store/profileslice';
import {tips as tipsAction} from 'src/store/tipsslice';
import SoftAlert from 'src/theme/components/SoftAlert';
import SoftBadge from 'src/theme/components/SoftBadge';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormat} from 'src/utils/date-format';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const sampleDatasets: Data[] = [
  {
    yAxisID: 'A',
    id: 'comment',
    label: 'Commentaire',
    color: 'dark',
    data: [],
  },
  {
    yAxisID: 'A',
    id: 'like',
    label: 'Like',
    color: 'info',
    data: [],
  },
  {
    yAxisID: 'B',
    id: 'view',
    label: 'View',
    color: 'primary',
    data: [],
  },
  {
    yAxisID: 'A',
    id: 'share',
    label: 'Share',
    color: 'secondary',
    data: [],
  },
];

export default function Home() {
  const drafts = useAppSelector((state) => state.draft.drafts);
  const draftsScheduled = useMemo(
    () =>
      drafts
        .filter(
          (d): d is Omit<Draft, 'programmingDate'> & {programmingDate: string} =>
            d.programmingDate !== undefined && isFuture(parseISO(d.programmingDate)),
        )
        .sort((a, b) => compareAsc(parseISO(a.programmingDate), parseISO(b.programmingDate))),
    [drafts],
  );
  const user = useAppSelector((state) => state.main.user);
  const premium = useAppSelector((state) => state.main.premium);
  const newFollower = useAppSelector(selectProfileNewFollowerWithinInterval);
  const prevNewFollower = useAppSelector(selectProfileNewFollowerWithinPreviousInterval);
  const tips = useAppSelector((state) => state.tips.tips);

  const loadingLinkedinPosts = useAppSelector((state) => state.post.loadingLinkedinPosts);
  const interval = useAppSelector((state) => state.post.interval);
  const previousInterval = useAppSelector(selectPreviousInterval);
  const globalStats = useAppSelector(selectGlobalStats);
  const globalStatsPreviousInterval = useAppSelector(selectGlobalStatsPrevious);
  const globalStatsProgress = useAppSelector(selectGlobalStatsProgress);

  const loadingAveragePostData = useAppSelector((state) => state.data.loadingPostData);
  const averagePerfectPost = useAppSelector((state) => state.data.linkedinPostAveragePerfectPost);
  const loadingAverageUserData = useAppSelector((state) => state.data.loadingUserData);
  const ppProgress = useAppSelector(selectPPPostProgress);
  const ppProfileProgress = useAppSelector(selectPPProfileProgress);

  const userDataAveragePerfectPost = useAppSelector((state) => state.data.userDataAveragePerfectPost);

  const [latestPost, setLatestPost] = React.useState<LinkedinPost | undefined>(undefined);

  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();
  useDocumentTitle(t('dashboard.documenttitle', {defaultValue: 'Dashboard'}));

  useEffect(() => {
    const forceInterval: NormalizedInterval = {
      start: addDays(startOfToday(), -7),
      end: endOfToday(),
    };
    if (premium) {
      void dispatch(loadLinkedInPostAveragePerfectPost({begin: forceInterval.start, end: forceInterval.end}));
      void dispatch(loadUserDataAveragePerfectPost({begin: forceInterval.start, end: forceInterval.end}));
    }
    void dispatch(tipsAction());
    void dispatch(draftLoad());
    void dispatch(listPost(forceInterval));
    void dispatch(getView(forceInterval));

    try {
      perfectPostServiceClient.send(new LatestPostCommand()).then((res) => {
        setLatestPost(res);
      });
    } catch (e) {
      /* empty */
    }
  }, [premium]);

  const tipsArray: [Tips, Tips, Tips] | undefined = useMemo(() => {
    if (tips !== undefined && tips.length !== 0) {
      const randomIndex1 = Math.floor(Math.random() * tips.length);
      const randomIndex2 = Math.floor(Math.random() * tips.length);
      const randomIndex3 = Math.floor(Math.random() * tips.length);
      return [tips[randomIndex1], tips[randomIndex2], tips[randomIndex3]];
    } else {
      return undefined;
    }
  }, [tips]);

  const datasets = useMemo<Data[]>(() => {
    const datas: Data[] = [...sampleDatasets];
    const comment = datas.find((d) => d.id === 'comment');
    const like = datas.find((d) => d.id === 'like');
    const view = datas.find((d) => d.id === 'view');
    const share = datas.find((d) => d.id === 'share');
    if (latestPost !== undefined && comment !== undefined && like !== undefined && view !== undefined) {
      comment.data = [];
      like.data = [];
      view.data = [];
      const vote: Partial<Data['data'][number]>[] = [];
      latestPost.socialDetails?.forEach((postdata) => {
        const date = parseISO(postdata.date);
        comment.data.push({x: date, y: postdata.numComments ?? 0});
        like.data.push({x: date, y: postdata.numLikes ?? 0});
        view.data.push({x: date, y: postdata.numImpressions ?? 0});
        share?.data.push({x: date, y: postdata.numShares ?? 0});
        vote.push({x: date, y: postdata.numVoters ?? 0});
      });
      if (vote.some((v) => v.y !== undefined)) {
        datas.push({
          yAxisID: 'A',
          id: 'vote',
          label: 'Vote',
          color: 'warning',
          data: vote.map((v) => ({x: v.x, y: v.y ?? 0}) as Data['data'][number]),
        });
      }
    }
    return datas;
  }, [latestPost]);

  const tryGpt = () => {
    crispService.tryGpt();
    window.open('https://chatgpt.com/g/g-rvHlZRFnj-perfectpost', '_blank');
  };

  return (
    <SoftBox>
      <SoftBox mb={2} p={1} display="flex" flexDirection="row" alignItems="baseline">
        <SoftTypography variant="h2" fontWeight="bold">
          {t('dashboard.title.hello', {firstname: user?.firstname, defaultValue: 'Hello {{firstname}} 👋'})}
        </SoftTypography>
        <SoftBox sx={{ml: 2}}>
          <SoftAlert color="success" sx={{fontWeight: 'bold'}}>
            <NewReleasesIcon fontSize="small" />
            &nbsp;
            {t('dashboard.newgpt.title', {
              defaultValue: 'Nouveauté : analyser vos publications depuis ChatGPT avec le GPT gratuit PerfectPost',
            })}
            &nbsp;
            <SoftButton size="small" variant="text" endIcon={<NavigateNextIcon />} onClick={tryGpt}>
              {t('dashboard.newgpt.button', {defaultValue: 'tester'})}
            </SoftButton>
          </SoftAlert>
        </SoftBox>
      </SoftBox>
      <SoftBox
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gridTemplateAreas={`"data data data level"
           "graph graph graph tips"
           "graph graph graph tips"`}
        gap={2}>
        <SoftBox gridArea="data" display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={1}>
          <StatisticCard
            title={t('global.impression', {defaultValue: 'Impression'})}
            count={globalStats.impression}
            loading={loadingLinkedinPosts}
            mode="number"
            period={`${localFormat(interval.start, 'dd MMM', user?.locale)} - ${localFormat(
              interval.end,
              'dd MMM',
              user?.locale,
            )}`}
            prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', user?.locale)} - ${localFormat(
              previousInterval.end,
              'dd MMM',
              user?.locale,
            )}`}
            prevCount={globalStatsPreviousInterval.impression}
            prevProgress={globalStatsProgress.impression}
            ppCount={averagePerfectPost?.avgImpressions}
            ppProgress={ppProgress?.impression}
            loadingPpProgress={loadingAveragePostData}
            premium={premium}
          />
          <StatisticCard
            title={t('global.interaction', {defaultValue: 'Interaction'})}
            count={globalStats.interaction}
            loading={loadingLinkedinPosts}
            mode="number"
            period={`${localFormat(interval.start, 'dd MMM', user?.locale)} - ${localFormat(
              interval.end,
              'dd MMM',
              user?.locale,
            )}`}
            prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', user?.locale)} - ${localFormat(
              previousInterval.end,
              'dd MMM',
              user?.locale,
            )}`}
            prevCount={globalStatsPreviousInterval.interaction}
            prevProgress={globalStatsProgress.interaction}
            ppCount={averagePerfectPost?.avgInteraction}
            ppProgress={ppProgress?.interaction}
            loadingPpProgress={loadingAveragePostData}
            premium={premium}
          />
          <StatisticCard
            title={t('global.engagementrate', {defaultValue: 'Engagement'})}
            count={globalStats.engagement}
            loading={loadingLinkedinPosts}
            mode="percentage"
            period={`${localFormat(interval.start, 'dd MMM', user?.locale)} - ${localFormat(
              interval.end,
              'dd MMM',
              user?.locale,
            )}`}
            prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', user?.locale)} - ${localFormat(
              previousInterval.end,
              'dd MMM',
              user?.locale,
            )}`}
            prevCount={globalStatsPreviousInterval.engagement}
            prevProgress={globalStatsProgress.engagement}
            ppCount={averagePerfectPost?.engagement}
            ppProgress={ppProgress?.engagement}
            loadingPpProgress={loadingAveragePostData}
            premium={premium}
          />
          <StatisticCard
            title={t('global.newfollower', {defaultValue: 'New follower'})}
            count={newFollower}
            loading={loadingLinkedinPosts}
            mode="number"
            period={`${localFormat(interval.start, 'dd MMM', user?.locale)} - ${localFormat(
              interval.end,
              'dd MMM',
              user?.locale,
            )}`}
            prevPeriod={`${localFormat(previousInterval.start, 'dd MMM', user?.locale)} - ${localFormat(
              previousInterval.end,
              'dd MMM',
              user?.locale,
            )}`}
            prevCount={prevNewFollower}
            prevProgress={(newFollower - prevNewFollower) / prevNewFollower}
            ppCount={userDataAveragePerfectPost?.avgFollowerDiff}
            ppProgress={ppProfileProgress.newFollower}
            loadingPpProgress={loadingAverageUserData}
            premium={premium}
          />
        </SoftBox>
        <SoftBox gridArea="level"></SoftBox>
        <SoftBox
          gridArea="graph"
          sx={({palette}) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1px 1fr 1fr',
            backgroundColor: palette.grey['300'],
            borderRadius: '1em',
            py: 2,
          })}>
          <SoftBox display="flex" flexDirection="column" gridColumn="1/4" sx={{px: 2}}>
            <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
              {t('dashboard.graph.yourlastposttitle', {defaultValue: 'Your latest post'})}
            </SoftTypography>
            {latestPost === undefined ? (
              <MessageCard
                description={t('dashboard.graph.nopost', {defaultValue: 'Your latest publication soon here...'})}
              />
            ) : (
              <DraftCard content={latestPost.text ?? ''} />
            )}
            <Card sx={{mt: 2}}>
              <SoftBox p={2} lineHeight={1}>
                <SoftTypography variant="button" fontWeight="medium" color="text">
                  {t('dashboard.graph.graphposttitle', {defaultValue: 'Statistics of your last post'})}
                </SoftTypography>
              </SoftBox>
              <SoftBox p={2} lineHeight={1} height={400}>
                <Chart chartdataset={datasets} type="line" />
                {latestPost === undefined && (
                  <SoftBox
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%,-50%)',
                    }}>
                    <MessageCard
                      bgColor="dark"
                      description={t('dashboard.graph.nographdescription', {
                        defaultValue: 'To view the details of your next publication, track this one',
                      })}
                    />
                  </SoftBox>
                )}
              </SoftBox>
            </Card>
          </SoftBox>
          <SoftBox backgroundColor="#C4C4C4" />
          <SoftBox display="flex" flexDirection="column" gridColumn="5/7" sx={{px: 2}}>
            <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
              {t('dashboard.list.upcomingpost', {defaultValue: 'Upcoming publication'})}
            </SoftTypography>

            {draftsScheduled.length === 0 ? (
              <MessageCard
                description={t('dashboard.list.nopost', {defaultValue: "You don't have any posts scheduled."})}
                btnText={t('dashboard.list.nopostbtn', {defaultValue: 'Prepare my first publication'})}
                to="/publish/draft/new"
              />
            ) : (
              <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                sx={{maxHeight: 900, overflowY: 'auto', pr: 1}}>
                {draftsScheduled.map((draft) => (
                  <SoftBox
                    key={draft._id}
                    component="li"
                    display="flex"
                    alignItems="baseline"
                    flexDirection="column"
                    py={1}
                    mb={1}>
                    <DraftCard
                      content={draft.content}
                      programmingDate={draft.programmingDate}
                      href={`/publish/draft/${draft._id}`}
                      organization={draft.organization}
                    />
                  </SoftBox>
                ))}
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
        <SoftBox gridArea="tips">
          <SoftBox bgColor="white" sx={{p: 2, borderRadius: '1rem'}}>
            <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
              {t('dashboard.tips.title', {defaultValue: 'Our recommendations'})}
            </SoftTypography>
            <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {tipsArray?.map((tips) => (
                <React.Fragment key={tips.idea}>
                  <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" py={1} mb={1}>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                      <SoftTypography variant="button" fontWeight="medium" textTransform="none">
                        {tips.idea}
                      </SoftTypography>
                      <SoftTypography variant="caption" color="text">
                        {tips.description}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBadge sx={{mt: 2}} badgeContent="TIPS" container color="primary" />
                  </SoftBox>
                  <Divider />
                </React.Fragment>
              ))}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}
