import {GetActivityCommand, type Activity} from '@perfectpost/perfect-post-common';
import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import {endOfMonth, NormalizedInterval, startOfMonth, eachDayOfInterval, isSameDay, parseISO} from 'date-fns';
import {perfectPostServiceClient} from 'src/services';

import {RootState} from '.';

const selectActivity = (state: RootState) => state.activity.activities;
const selectInterval = (state: RootState) => state.activity.interval;

export const selectFullActivity = createSelector([selectActivity, selectInterval], (activities, interval) => {
  const alldays = eachDayOfInterval(interval);
  return alldays.map((day) => {
    const a = activities.find((a) => isSameDay(parseISO(a.date), day));
    let like = a?.like ?? 0.001;
    const objectifLike = a?.objectifLike ?? 10;
    const objectifComment = a?.objectifComment ?? 3;
    if (like === 0) {
      like = 0.001;
    }
    let comment = a?.comment ?? 0.001;
    if (comment === 0) {
      comment = 0.001;
    }
    return {
      day,
      values: [
        {
          value: like > objectifLike ? 1 : like / objectifLike,
          color: 'rgb(112, 190, 215)',
          backgroundColor: '#cccccc',
          realValue: like === 0.001 ? 0 : like,
        },
        {
          value: comment > objectifComment ? 1 : comment / objectifComment,
          color: 'rgb(148, 213, 90)',
          backgroundColor: '#cccccc',
          realValue: comment === 0.001 ? 0 : comment,
        },
      ],
    };
  });
});

export interface ActivityState {
  interval: NormalizedInterval<Date>;
  loadingActivity: boolean;
  activities: Activity[];
}
const initialState: ActivityState = {
  interval: {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  },
  activities: [],
  loadingActivity: true,
};

export const getActivity = createAsyncThunk<Activity[] | undefined, NormalizedInterval<Date>>(
  'activity/get',
  async ({start, end}) => perfectPostServiceClient.send(new GetActivityCommand({begin: start, end})),
);

export const activitiesSlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivity.pending, (state) => {
        state.loadingActivity = true;
      })
      .addCase(getActivity.fulfilled, (state, action) => {
        state.loadingActivity = false;
        state.interval = {
          start: action.meta.arg.start,
          end: action.meta.arg.end,
        };
        state.activities = action.payload ?? [];
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = activitiesSlice.actions;
export default activitiesSlice.reducer;
