import {GetUserViewCommand, UserView} from '@perfectpost/perfect-post-common';
import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import {addDays, differenceInDays, endOfDay, isWithinInterval, NormalizedInterval, parseISO} from 'date-fns';
import {perfectPostServiceClient} from 'src/services';

import {INTERVAL_BASE} from './postslice';

import {RootState} from '.';

/**
 * CUSTOM SELECTORS
 */

const selectProfileData = (state: RootState) => state.profile.profileData;
const selectInterval = (state: RootState) => state.profile.interval;
const previousInterval = (interval: NormalizedInterval<Date>) => {
  const previousStart = addDays(interval.start, differenceInDays(interval.start, interval.end));
  return {
    start: previousStart,
    end: endOfDay(interval.start),
  };
};
export const selectPreviousInterval = createSelector([selectInterval], previousInterval);

const profileDataWithinInterval = (profileData: UserView[], interval: NormalizedInterval<Date>): UserView[] =>
  profileData.filter((post) => {
    const date = parseISO(post.date);
    return isWithinInterval(date, interval);
  });
export const selectProfileDataWithinInterval = createSelector(
  [selectProfileData, selectInterval],
  profileDataWithinInterval,
);
export const selectProfileDataWithinPreviousInterval = createSelector(
  [selectProfileData, selectPreviousInterval],
  profileDataWithinInterval,
);

export interface ProfileStats {
  follower: number;
  newFollower: number;
  relation: number;
  newRelation: number;
  view: number;
  click: number;
}
const profileStatsFromProfileData = (profileData: UserView[]): ProfileStats => {
  if (profileData.length === 0) {
    const data: ProfileStats = {
      follower: NaN,
      newFollower: NaN,
      relation: NaN,
      newRelation: NaN,
      view: NaN,
      click: NaN,
    };
    return data;
  }

  const result = profileData.reduce(
    (acc, post) => {
      const postDate = new Date(post.date);
      if (postDate < acc.minDate) {
        acc.minDate = postDate;
        acc.minFollower = post.follower ?? acc.minFollower;
        acc.minRelation = post.relation ?? acc.minRelation;
        acc.minView = post.vue;
      }
      if (postDate > acc.maxDate) {
        acc.maxDate = postDate;
        acc.maxFollower = post.follower ?? acc.maxFollower;
        acc.maxRelation = post.relation ?? acc.maxRelation;
        acc.maxView = post.vue;
      }
      return acc;
    },
    {
      minDate: new Date(profileData[0].date),
      minFollower: profileData.filter((x) => x.follower !== undefined)[0]?.follower ?? NaN,
      minRelation: profileData.filter((x) => x.relation !== undefined)[0]?.relation ?? NaN,
      minView: profileData[0].vue,
      maxDate: new Date(profileData[0].date),
      maxFollower: profileData.filter((x) => x.follower !== undefined)[0]?.follower ?? NaN,
      maxRelation: profileData.filter((x) => x.relation !== undefined)[0]?.relation ?? NaN,
      maxView: profileData[0].vue,
    },
  );
  const profileStats: ProfileStats = {
    follower: 0,
    newFollower: 0,
    relation: 0,
    newRelation: 0,
    view: 0,
    click: 0,
  };
  profileStats.follower = result.maxFollower;
  profileStats.newFollower = result.maxFollower - result.minFollower;
  profileStats.relation = result.maxRelation;
  profileStats.newRelation = result.maxRelation - result.minRelation;
  profileStats.view = result.maxView;

  return profileStats;
};
export const selectProfileStatsWithinInterval = createSelector(
  [selectProfileDataWithinInterval],
  profileStatsFromProfileData,
);
export const selectProfileStatsWithinPreviousInterval = createSelector(
  [selectProfileDataWithinPreviousInterval],
  profileStatsFromProfileData,
);

export const selectProfileStatsProgress = createSelector(
  [selectProfileStatsWithinInterval, selectProfileStatsWithinPreviousInterval],
  (current, previous) => {
    if (isNaN(current.follower) || isNaN(previous.follower)) {
      return {
        follower: NaN,
        newFollower: NaN,
        relation: NaN,
        newRelation: NaN,
        view: NaN,
      };
    }

    return {
      follower: (current.follower - previous.follower) / previous.follower,
      newFollower: current.newFollower - previous.newFollower,
      relation: (current.relation - previous.relation) / previous.relation,
      newRelation: current.newRelation - previous.newRelation,
      view: (current.view - previous.view) / previous.view,
    };
  },
);

const profileFollower = (profileData: UserView[]): {date: string; follower: number}[] =>
  profileData
    .filter((p): p is Required<UserView> => p.follower !== undefined)
    .map((post) => ({date: post.date, follower: post.follower}));
export const selectProfileFollowerWithinInterval = createSelector([selectProfileDataWithinInterval], profileFollower);
export const selectProfileFollowerWithinPreviousInterval = createSelector(
  [selectProfileDataWithinPreviousInterval],
  profileFollower,
);

const profileNewFollower = (profileData: {date: string; follower: number}[]): number => {
  if (profileData.length === 0) {
    return NaN;
  }
  const result = profileData.reduce(
    (acc, post) => {
      const postDate = new Date(post.date);
      if (postDate < acc.minDate) {
        acc.minDate = postDate;
        acc.minFollower = post.follower;
      }
      if (postDate > acc.maxDate) {
        acc.maxDate = postDate;
        acc.maxFollower = post.follower;
      }
      return acc;
    },
    {
      minDate: new Date(profileData[0].date),
      minFollower: profileData[0].follower,
      maxDate: new Date(profileData[0].date),
      maxFollower: profileData[0].follower,
    },
  );
  return result.maxFollower - result.minFollower;
};
export const selectProfileNewFollowerWithinInterval = createSelector(
  [selectProfileFollowerWithinInterval],
  profileNewFollower,
);
export const selectProfileNewFollowerWithinPreviousInterval = createSelector(
  [selectProfileFollowerWithinPreviousInterval],
  profileNewFollower,
);

/**
 * THUNKS
 */

export const getView = createAsyncThunk<UserView[], NormalizedInterval<Date>>('profile/view', async (args) => {
  const previous = previousInterval(args);
  return perfectPostServiceClient.send(
    new GetUserViewCommand({
      begin: previous.start,
      end: args.end,
    }),
  );
});

export interface ProfileState {
  loadingUserView?: boolean;
  profileData: UserView[];
  interval: NormalizedInterval<Date>;
}
const initialState: ProfileState = {
  loadingUserView: false,
  profileData: [],
  interval: INTERVAL_BASE,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getView.pending, (state, action) => {
        state.loadingUserView = true;
        state.interval = {
          start: action.meta.arg.start,
          end: action.meta.arg.end,
        };
      })
      .addCase(getView.fulfilled, (state, action) => {
        state.loadingUserView = false;
        state.profileData = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = profileSlice.actions;
export default profileSlice.reducer;
