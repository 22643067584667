import {AppBar, Grid, Tabs, Tab, TextField} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {PersonURN} from '@perfectpost/linkedin-privateapi-ts-client';
import {
  GetPostTeamCommand,
  GetTeamTopCommenterCommand,
  GetTeamTopLikerCommand,
  LinkedinPost,
  PublicUser,
  TopCommenter,
  TopLiker,
} from '@perfectpost/perfect-post-common';
import {AnnotationOptions, EventContext} from 'chartjs-plugin-annotation';
import {addDays, endOfToday, parseISO, startOfToday} from 'date-fns';
import {enGB} from 'date-fns/locale/en-GB';
import {es} from 'date-fns/locale/es';
import {fr} from 'date-fns/locale/fr';
import randomcolor from 'randomcolor';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {SingleValue} from 'react-select';
import {CellProps, Column} from 'react-table';
import Chart, {Data, OptionGraphType} from 'src/components/chart/Chart';
import Datatable from 'src/components/datatable';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import {selectMembersOfTeamId} from 'src/store/teamslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

function enter({chart}: EventContext) {
  chart.canvas.style.cursor = 'pointer';
}

function leave({chart}: EventContext) {
  chart.canvas.style.cursor = 'default';
}

type DataType = 'view' | 'like' | 'comment' | 'sharing';
type GraphData = {
  [key in DataType]: Record<string, Data>;
};

type TableRow = {
  _id: PersonURN;
  href?: string;
  name?: string;
  picture?: string;
  count: number;
};

const DefaultCell = (props: CellProps<TableRow, number>) => (
  <SoftTypography
    variant="button"
    textTransform="none"
    fontWeight="regular"
    color="secondary"
    sx={{display: 'inline-block', width: 'max-content'}}>
    {props.value}
  </SoftTypography>
);

type TeamStatisticsState = {
  state: 'loading' | 'idle';
  select: -1 | 7 | 14 | 30 | 90 | 180 | 365;
  interval: {start: Date; end: Date};
  impactOptions?: OptionGraphType;
  posts: LinkedinPost[];
  teamMembers: PublicUser[];
  graphData?: GraphData;
  memberFilter?: string;
  commenters: TopCommenter[];
  likers: TopLiker[];
};

export default function TeamStatistics() {
  const {id} = useParams();
  const {t} = useTranslation();
  useDocumentTitle(t('teamstatistics.documenttitle', {defaultValue: "Contenu de l'équipe"}));

  const premium = useAppSelector((state) => state.main.premium);
  const locale = useAppSelector((state) => state.main.user?.locale);
  const members = useAppSelector((state) => selectMembersOfTeamId(state, id));

  const [state, setState] = useState<TeamStatisticsState>({
    state: 'loading',
    select: 14,
    interval: {
      start: addDays(startOfToday(), -14),
      end: endOfToday(),
    },
    posts: [],
    teamMembers: [],
    commenters: [],
    likers: [],
  });

  const commenterColumnDefinition: Column<TableRow>[] = useMemo(
    () => [
      {
        Header: t('tablepost.name', {defaultValue: 'Nom'}),
        accessor: 'name',
        width: '50%',
        Cell: (props) => {
          return (
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftBox component="img" src={props.row.original.picture} sx={{borderRadius: '50%', width: 20}} />
              </SoftBox>
              <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
                {props.row.original.name ?? 'LinkedIn User'}
              </SoftTypography>
            </SoftBox>
          );
        },
      },
      {Header: t('tablepost.count', {defaultValue: 'Nombre'}), accessor: 'count', width: '10%', Cell: DefaultCell},
    ],
    [t],
  );

  const commenterTableRows = useMemo<TableRow[]>(() => {
    return state.commenters.map((c) => ({
      _id: c._id,
      name:
        c.actorDetail?.firstName || c.actorDetail?.lastName
          ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
          : undefined,
      picture: c.actorDetail?.profilePicture,
      count: c.count ?? 0,
      href: c.actorDetail?.vanityName ? `https://www.linkedin.com/in/${c.actorDetail?.vanityName}` : undefined,
    }));
  }, [state.commenters]);

  const likerTableRows = useMemo<TableRow[]>(() => {
    return state.likers.map((c) => ({
      _id: c._id,
      name:
        c.actorDetail?.firstName || c.actorDetail?.lastName
          ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
          : undefined,
      picture: c.actorDetail?.profilePicture,
      count: c.count ?? 0,
      href: c.actorDetail?.vanityName ? `https://www.linkedin.com/in/${c.actorDetail?.vanityName}` : undefined,
    }));
  }, [state.likers]);

  useEffect(() => {
    if (id) {
      setState((state) => ({...state, state: 'loading'}));
      const {interval} = state;
      perfectPostServiceClient
        .send(new GetPostTeamCommand({id, begin: interval.start, end: interval.end, member: state.memberFilter}))
        .then((posts) => {
          const sortedPosts = posts
            .filter((p) => state.memberFilter === undefined || p.user === state.memberFilter)
            .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());
          const owners = [...new Set([...posts.map((p) => p.user)])];

          const like: GraphData['like'] = {};
          const comment: GraphData['comment'] = {};
          const view: GraphData['view'] = {};
          const sharing: GraphData['sharing'] = {};
          const firstData = sortedPosts.shift();
          const firstDate = firstData ? parseISO(firstData.date) : new Date();
          const firstDataOwner = firstData?.user;
          const firstSocialDetails = firstData?.socialDetails?.[0];
          for (const owner of owners) {
            const color = randomcolor({luminosity: 'bright'});
            like[owner] = {
              yAxisID: `A`,
              id: `like-${owner}`,
              label: t('global.like', {defaultValue: 'Like'}),
              color,
              data: [{x: firstDate, y: owner === firstDataOwner ? (firstSocialDetails?.numLikes ?? 0) : 0}] as {
                x: Date;
                y: number;
              }[],
            };
            comment[owner] = {
              yAxisID: 'A',
              id: `comment-${owner}`,
              label: t('global.comment', {defaultValue: 'Comment'}),
              color,
              data: [{x: firstDate, y: owner === firstDataOwner ? (firstSocialDetails?.numComments ?? 0) : 0}] as {
                x: Date;
                y: number;
              }[],
            };
            view[owner] = {
              yAxisID: 'B',
              id: `view-${owner}`,
              label: t('global.impression', {defaultValue: 'Impression'}),
              color,
              data: [{x: firstDate, y: owner === firstDataOwner ? (firstSocialDetails?.numImpressions ?? 0) : 0}] as {
                x: Date;
                y: number;
              }[],
            };
            sharing[owner] = {
              yAxisID: 'B',
              id: `sharing-${owner}`,
              label: t('global.sharing', {defaultValue: 'Sharing'}),
              color,
              data: [{x: firstDate, y: owner === firstDataOwner ? (firstSocialDetails?.numShares ?? 0) : 0}] as {
                x: Date;
                y: number;
              }[],
            };
          }
          const annotations: AnnotationOptions[] = [];
          for (const post of sortedPosts) {
            const owner = members.find((m) => m._id === post.user);
            const image = document.createElement('img');
            image.src = owner?.lnPicture ?? '';
            const date = parseISO(post.date);
            const line: AnnotationOptions = {
              scaleID: `x`,
              type: 'line',
              value: date.getTime(),
              borderColor: '#D9D9D9',
              borderWidth: 1,
              borderDash: [2, 2],
            };
            annotations.push(line);
            const point: AnnotationOptions = {
              type: 'label',
              content: image,
              width: 20,
              height: 20,
              xValue: date.getTime(),
              yAdjust: 0,
              yValue: 0,
              borderWidth: 0,
              borderColor: 'transparent',
              enter,
              leave,
            };
            annotations.push(point);

            const lastRelever = post.latestSocialData;
            like[post.user].data.push({
              x: date,
              y: (lastRelever?.numLikes ?? 0) + (like[post.user].data[like[post.user].data.length - 1]?.y ?? 0),
            });
            comment[post.user].data.push({
              x: date,
              y:
                (lastRelever?.numComments ?? 0) + (comment[post.user].data[comment[post.user].data.length - 1]?.y ?? 0),
            });
            view[post.user].data.push({
              x: date,
              y: (lastRelever?.numImpressions ?? 0) + (view[post.user].data[view[post.user].data.length - 1]?.y ?? 0),
            });
          }
          setState((state) => ({
            ...state,
            state: 'idle',
            posts,
            graphData: {
              like,
              comment,
              view,
              sharing,
            },
            impactOptions: {
              plugins: {
                annotation: {
                  clip: false,
                  common: {
                    drawTime: 'afterDraw',
                  },
                  annotations,
                },
              },
            },
          }));
        });
      perfectPostServiceClient
        .send(new GetTeamTopCommenterCommand({id, begin: interval.start, end: interval.end}))
        .then((topCommenter) => {
          setState((f) => ({...f, commenters: topCommenter}));
        });
      perfectPostServiceClient
        .send(new GetTeamTopLikerCommand({id, begin: interval.start, end: interval.end}))
        .then((topLiker) => {
          setState((f) => ({...f, likers: topLiker}));
        });
    }
  }, [id, state.interval, state.memberFilter]);

  const onSelectChange = (e: React.SyntheticEvent<Element, Event>, v: number) => {
    if (premium || v <= 14) {
      if (v === -1) {
        setState((prevState) => ({
          ...prevState,
          select: -1,
          interval: {
            start: addDays(startOfToday(), -30),
            end: endOfToday(),
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          select: v as -1 | 7 | 14 | 30 | 90 | 180 | 365,
          interval: {
            start: addDays(startOfToday(), -1 * v),
            end: endOfToday(),
          },
        }));
      }
    }
  };

  const onFilterMemberChange = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => {
    if (newValue === null) {
      setState((state) => ({...state, memberFilter: undefined}));
    } else {
      setState((state) => ({...state, memberFilter: newValue?.value}));
    }
  };

  return (
    <SoftBox>
      <SoftBox mb={3}>
        <SoftTypography mb={2} variant="h2" textTransform="none" fontWeight="bold">
          {t('teamstatistics.title', {defaultValue: 'Statistiques de contenu de votre équipe'})}
        </SoftTypography>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <AppBar
              position="static"
              sx={({palette}) => ({border: 1, borderColor: palette.grey[400], borderRadius: '0.75rem'})}>
              <Tabs value={state.select} onChange={onSelectChange}>
                <Tab label="7" value={7} />
                <Tab label="14" value={14} />
                <Tab
                  label="30"
                  value={30}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="90"
                  value={90}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="180"
                  value={180}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="365"
                  value={365}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
                <Tab
                  label="Custom"
                  value={-1}
                  disabled={premium !== true}
                  sx={({palette}) => ({color: premium !== true ? `${palette.grey[400]} !important` : undefined})}
                />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item>
            {state.select !== -1 ? (
              <SoftTypography sx={{height: '100%', marginTop: '5px'}}>
                {t('global.days', {defaultValue: 'Days'})}
              </SoftTypography>
            ) : (
              <SoftBox display="flex" flexDirection="row" sx={{height: '100%'}}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale === 'fr' ? fr : locale === 'es' ? es : enGB}>
                  <DatePicker
                    value={state.interval.start}
                    minDate={new Date('2021-01-01')}
                    maxDate={state.interval.end}
                    onChange={(newValue) => {
                      setState((prev) => ({...prev, interval: {...prev.interval, start: newValue as Date}}));
                    }}
                    slotProps={{
                      textField: (params) => (
                        <TextField {...params} variant="outlined" sx={{height: '100%', justifyContent: 'center'}} />
                      ),
                    }}
                  />
                  <DatePicker
                    value={state.interval.end}
                    minDate={state.interval.start}
                    disableFuture
                    onChange={(newValue) => {
                      setState((prev) => ({...prev, interval: {...prev.interval, end: newValue as Date}}));
                    }}
                    slotProps={{
                      textField: (params) => (
                        <TextField {...params} variant="outlined" sx={{height: '100%', justifyContent: 'center'}} />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </SoftBox>
            )}
          </Grid>
          <Grid item xs={3}>
            <SoftBox sx={{ml: 2}}>
              <SoftSelect
                placeholder="Filter member"
                isClearable
                options={members.map((s) => ({value: s._id, label: `${s.firstname ?? ''} ${s.lastname ?? ''}`}))}
                onChange={onFilterMemberChange}
              />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <Grid container spacing={3}>
        <Grid item xs={12} flexDirection="column" sx={{lineHeight: 0}}>
          <SoftBox bgColor="white" sx={{p: 2, borderRadius: '1rem'}}>
            <SoftBox sx={{lineHeight: 1, mb: 0.5}}>
              <SoftTypography sx={{fontSize: 14}} fontWeight="bold" color="text">
                {t('teamstatistics.postview', {defaultValue: 'Impression par personne de votre équipe'})}
              </SoftTypography>
            </SoftBox>
            <SoftBox height={350}>
              <Chart
                loading={state.state === 'loading'}
                chartdataset={Object.values(state.graphData?.view ?? [])}
                options={state.impactOptions}
                type="line"
              />
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={4} flexDirection="column" sx={{lineHeight: 0}}>
          <SoftBox bgColor="white" sx={{p: 2, borderRadius: '1rem'}}>
            <SoftBox sx={{lineHeight: 1, mb: 0.5}}>
              <SoftTypography sx={{fontSize: 14}} fontWeight="bold" color="text">
                {t('teamstatistics.postcomment', {defaultValue: 'Commentaire par personne de votre équipe'})}
              </SoftTypography>
            </SoftBox>
            <SoftBox height={350}>
              <Chart
                loading={state.state === 'loading'}
                chartdataset={Object.values(state.graphData?.comment ?? [])}
                options={state.impactOptions}
                type="line"
              />
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={4} flexDirection="column" sx={{lineHeight: 0}}>
          <SoftBox bgColor="white" sx={{p: 2, borderRadius: '1rem'}}>
            <SoftBox sx={{lineHeight: 1, mb: 0.5}}>
              <SoftTypography sx={{fontSize: 14}} fontWeight="bold" color="text">
                {t('teamstatistics.postlike', {defaultValue: 'Like par personne de votre équipe'})}
              </SoftTypography>
            </SoftBox>
            <SoftBox height={350}>
              <Chart
                loading={state.state === 'loading'}
                chartdataset={Object.values(state.graphData?.like ?? [])}
                options={state.impactOptions}
                type="line"
              />
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={4} flexDirection="column" sx={{lineHeight: 0}}>
          <SoftBox bgColor="white" sx={{p: 2, borderRadius: '1rem'}}>
            <SoftBox sx={{lineHeight: 1, mb: 0.5}}>
              <SoftTypography sx={{fontSize: 14}} fontWeight="bold" color="text">
                {t('teamstatistics.postsharing', {defaultValue: 'Repartage par personne de votre équipe'})}
              </SoftTypography>
            </SoftBox>
            <SoftBox height={350}>
              <Chart
                loading={state.state === 'loading'}
                chartdataset={Object.values(state.graphData?.sharing ?? [])}
                options={state.impactOptions}
                type="line"
              />
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12}>
          <SoftBox py={3}>
            <SoftTypography variant="subtitle1" fontWeight="bold">
              {t('teamstatistics.topcommentator', {defaultValue: 'Top commentateur'})}
            </SoftTypography>
            <SoftTypography variant="button" textTransform="none">
              {t('teamstatistics.topcommentatordetail', {
                defaultValue:
                  'Voici la liste des personnes qui interagissent le plus sur les publications de votre équipe',
              })}
            </SoftTypography>
          </SoftBox>
          <Datatable
            table={{rows: commenterTableRows, columns: commenterColumnDefinition}}
            entriesPerPage={{defaultValue: 10, entries: [10, 20, 30, 50, 80, 130]}}
            pagination={{variant: 'gradient', color: 'info'}}
            isSorted
          />
        </Grid>
        <Grid item xs={12}>
          <SoftBox py={3}>
            <SoftTypography variant="subtitle1" fontWeight="bold">
              {t('teamstatistics.toplike', {defaultValue: 'Top like'})}
            </SoftTypography>
            <SoftTypography variant="button" textTransform="none">
              {t('teamstatistics.toplikedetail', {
                defaultValue:
                  'Voici la liste des personnes qui interagissent le plus sur les publications de votre équipe',
              })}
            </SoftTypography>
          </SoftBox>
          <Datatable
            table={{rows: likerTableRows, columns: commenterColumnDefinition}}
            entriesPerPage={{defaultValue: 10, entries: [10, 20, 30, 50, 80, 130]}}
            pagination={{variant: 'gradient', color: 'info'}}
            isSorted
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}
