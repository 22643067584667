import React, {useMemo, useRef, useState} from 'react';
import Stories from 'react-insta-stories';
import {Story} from 'react-insta-stories/dist/interfaces';
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '@enhances/with-resolvers';
import {useResizeObserver} from 'usehooks-ts';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

type CarrouselPlayerProps = {
  url: string;
};

export default function CarrouselPlayer({url}: CarrouselPlayerProps) {
  const [numPages, setNumPages] = useState<number | undefined>();
  const [pdfSize, setPdfSize] = useState<{width: number; height: number} | undefined>();
  const wrapperDiv = useRef<HTMLDivElement>(null);
  const {width = 0, height = 0} = useResizeObserver({
    ref: wrapperDiv,
  });

  const file = useMemo(() => ({url}), [url]);
  const storySize = useMemo(() => {
    if (pdfSize === undefined) {
      return {width, height};
    }
    return {
      width: width < pdfSize.width ? width : pdfSize.width,
      height: width < pdfSize.width ? (pdfSize.height * width) / pdfSize.width : pdfSize.height,
    };
  }, [width, height, pdfSize]);
  const stories = useMemo<Story[]>(() => {
    return Array.from(Array(numPages).keys()).map((page) => ({
      content: () => <Page pageNumber={page + 1} width={storySize?.width} height={storySize?.height} />,
    }));
  }, [numPages, storySize]);

  console.log(JSON.stringify(stories));

  return (
    <div ref={wrapperDiv}>
      <Document
        file={file}
        onLoadSuccess={async (pdf) => {
          const page = await pdf.getPage(1);
          const [x, y, w, h] = page.view;
          const width = Math.round(w - x);
          const height = Math.round(h - y);
          setPdfSize({width, height});
          setNumPages(pdf.numPages);
        }}>
        <Stories stories={stories} width={storySize?.width} height={storySize?.height} loop={false} />
      </Document>
    </div>
  );
}
