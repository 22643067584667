/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';
import SoftBox from 'src/theme/components/SoftBox';
import borders from 'src/theme/theme/base/borders';
import colors from 'src/theme/theme/base/colors';
import typography from 'src/theme/theme/base/typography';

type DataTableBodyCellProps = {
  children: React.ReactNode;
  noBorder?: boolean;
  align?: 'left' | 'right' | 'center';
};
function DataTableBodyCell({noBorder, align, children}: DataTableBodyCellProps) {
  const {light} = colors;
  const {size} = typography;
  const {borderWidth} = borders;

  return (
    <SoftBox
      component="td"
      textAlign={align}
      fontSize={size.sm}
      borderBottom={noBorder ? 'none' : `${borderWidth[1]} solid ${light.main}`}
      p={1.5}>
      <SoftBox
        display="inline-block"
        width="max-content"
        sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main, verticalAlign: 'middle'})}>
        {children}
      </SoftBox>
    </SoftBox>
  );
}

export default DataTableBodyCell;
