import {Dialog, DialogTitle, DialogContent, DialogActions, Divider, Icon, CircularProgress} from '@mui/material';
import type {Draft, SingleAssets} from '@perfectpost/perfect-post-common';
import axios, {AxiosRequestConfig} from 'axios';
import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useAppThunkDispatch} from 'src/store';
import {editDraft, saveDraft} from 'src/store/draftslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftProgress from 'src/theme/components/SoftProgress';
import SoftTypography from 'src/theme/components/SoftTypography';
import {sanitizer} from 'src/utils/sanitizer';

import TipCard from '../tipscard/TipCards';

type ImportImageModalProps = {
  draftId?: string;
  content?: string;
  sharing?: SingleAssets;
  handleClose: () => void;
  handleConfirm: (newDraft: Draft) => void;
};

const MAX_FILES = 1;

export default function ImportImageModal(props: ImportImageModalProps) {
  const {draftId, content, handleClose, handleConfirm} = props;
  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const [formState, setFormState] = useState<'idle' | 'working'>('idle');

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    maxFiles: MAX_FILES,
    accept: {
      'image/gif': [],
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
    },
  });
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);
  const [images, setImages] = useState<{file: File; url: string}[]>([]);

  useEffect(() => {
    if (acceptedFiles.length === 0) {
      return undefined;
    }
    setImages(acceptedFiles.map((file) => ({file, url: URL.createObjectURL(file)})));
  }, [acceptedFiles]);

  const onSubmit = async () => {
    setFormState('working');
    const sharingData = {
      image: images[0].file.type,
    };
    let result: {
      status: string;
      url?: string | undefined;
      draft: Draft;
    };
    if (draftId === undefined) {
      result = await dispatch(
        saveDraft({
          content: sanitizer(content ?? ''),
          sharing: sharingData,
        }),
      ).unwrap();
    } else {
      result = await dispatch(
        editDraft({
          draftId,
          content: content ? sanitizer(content) : undefined,
          sharing: sharingData,
        }),
      ).unwrap();
    }
    if (result?.url && result.draft._id) {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': images[0].file.type,
        },
        onUploadProgress(progressEvent) {
          if (progressEvent.loaded !== progressEvent.total) {
            setUploadProgress((progressEvent.loaded / (progressEvent.total ?? 100)) * 100);
          }
        },
      };
      void axios.put(result.url, images[0].file, config).then(() => {
        setFormState('idle');
        setUploadProgress(undefined);
        if (draftId === undefined) {
          navigate(`/publish/draft/${result.draft._id}`);
        }
        handleConfirm(result.draft);
      });
    }
  };

  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>
        <SoftTypography>{t('importimagemodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <TipCard informationkey="modalphoto" />
        {uploadProgress === undefined ? (
          <SoftTypography>{t('importimagemodal.label')}</SoftTypography>
        ) : (
          <SoftBox sx={{display: 'flex', flexDirection: 'column'}}>
            <SoftTypography variant="button" color="text" fontWeight="medium">
              {t('draft.uploadimage', {defaultValue: 'Uploading video'})}
            </SoftTypography>
            <SoftProgress value={uploadProgress} variant="gradient" />
          </SoftBox>
        )}
        <SoftBox
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: '2px',
            borderRadius: '2px',
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            cursor: 'pointer',
          }}
          {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <SoftTypography>{t('importimagemodal.instruction')}</SoftTypography>
        </SoftBox>
        <Divider />
        <SoftTypography>{t('importimagemodal.photolist')}</SoftTypography>
        <SoftBox sx={{display: 'flex', flexDirection: 'row', overflowX: 'scroll', py: 2}}>
          {images.map((image, index) => (
            <SoftBox
              key={`${index}-${image.file.name}`}
              sx={{
                position: 'relative',
                mr: 1,
                '&:hover .tash-overlay': {
                  display: 'block',
                },
              }}>
              <SoftBox sx={{borderRadius: '1rem', width: '60px', height: '60px'}} component="img" src={image.url} />
              <SoftButton
                color="dark"
                variant="gradient"
                className="tash-overlay"
                iconOnly
                onClick={() => setImages(images.filter((_, i) => i !== index))}
                sx={{
                  display: 'none',
                  position: 'absolute',
                  top: '12px',
                  left: '12px',
                  width: '30px',
                  height: '30px',
                }}>
                <Icon sx={{color: 'black'}}>delete</Icon>
              </SoftButton>
            </SoftBox>
          ))}
          {Array.from(Array(MAX_FILES - images.length).keys()).map((i) => (
            <SoftBox
              key={`empty-${i}`}
              sx={{
                position: 'relative',
                borderRadius: '1rem',
                minWidth: '60px',
                minHeight: '60px',
                border: '1px dashed #000',
                mr: 1,
              }}
            />
          ))}
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={handleClose} autoFocus>
          {t('global.cancel', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton variant="gradient" disabled={formState === 'working' || images.length === 0} onClick={onSubmit}>
          {formState === 'idle' ? (
            t('importimagemodal.import', {defaultValue: 'Import into publication'})
          ) : (
            <CircularProgress color="info" size={24} />
          )}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
