/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {styled} from '@mui/material/styles';

export default styled('div')(({theme}) => {
  const {palette, borders} = theme;

  const {borderRadius} = borders;
  const {text} = palette;

  return {
    minWidth: 560,
    '& #emoji-palette': {
      zIndex: 1000000,
    },
    '& .ql-toolbar': {
      position: 'sticky',
      top: 16,
      backgroundColor: theme.palette.background.default,
      zIndex: 999,
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
    },

    '& .ql-container': {
      minWidth: 508,
      minHeight: 250,
      borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md}`,
    },

    '& .ql-editor': {
      padding: 0,
      width: 508,
      margin: '10px auto',
      '& p': {
        fontSize: '14px',
        color: text.main,
      },

      '& ul li': {
        color: text.main,
      },
    },

    '& .pp-seemore-overlay': {
      height: 1113,
      width: 499,
      borderBottom: '1px dashed #2796FF',
      maxHeight: 61,
      left: 'calc((100% - 508px) / 2)',
      pointerEvents: 'none',
      position: 'absolute',
      top: 10,
      zIndex: 999,
      '&:before': {
        color: '#2796FF',
        bottom: 0,
        content: '" "',
        fontFamily: 'Lato, sans-serif',
        fontWeight: '400',
        position: 'absolute',
        right: -12,
        height: 19,
        width: 97,
        backgroundPositionY: -2,
        backgroundPositionX: 69,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'rgba(255 255 255 / 85%)',
      },
      '&:hover': {
        opacity: '5%',
      },
    },
    '& .see-more': {
      pointerEvents: 'all',
      position: 'absolute',
      bottom: 0,
      right: 0,
      marginBottom: 0,
      paddingLeft: '0.8rem',
      border: 'none',
      backgroundColor: 'white',
    },
  };
});
