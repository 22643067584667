import dompurify from 'dompurify';

export const sanitizer = (s: string) =>
  dompurify.sanitize(s, {
    USE_PROFILES: {html: true},
    ALLOWED_TAGS: ['p', 'strong', 'a', 'br'],
    ALLOWED_ATTR: [
      'class',
      'href',
      'data-entity-urn',
      'data-guid',
      'data-object-urn',
      'data-original-text',
      'spellcheck',
    ],
    FORBID_TAGS: ['style'],
    FORBID_ATTR: ['style', 'data-pp'],
  });
