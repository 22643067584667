import {Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress} from '@mui/material';
import {GetOneUserCommand, Team} from '@perfectpost/perfect-post-common';
import {PublicUser} from '@perfectpost/perfect-post-common';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {perfectPostServiceClient} from 'src/services';
import {useAppThunkDispatch} from 'src/store';
import {teamAcceptInvitation, teamLeave} from 'src/store/mainslice';
import {fetchTeams} from 'src/store/teamslice';
import SoftAvatar from 'src/theme/components/SoftAvatar';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';

import TipCard from '../tipscard/TipCards';

type TeamInvitationProps = {
  team: Team;
};

export default function TeamInvitation(props: TeamInvitationProps) {
  const {team} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const [user, setUser] = useState<PublicUser | undefined>();
  const [modalState, setModalState] = useState<'idle' | 'working'>('idle');

  useEffect(() => {
    const owner = team.users.find((member) => member.role === 'owner');
    if (owner) {
      perfectPostServiceClient.send(new GetOneUserCommand({id: owner.user})).then((x) => setUser(x));
    }
  }, [team]);

  const onReject = async () => {
    setModalState('working');
    await dispatch(teamLeave({teamId: team._id}));
    dispatch(fetchTeams());
  };
  const onAccept = async () => {
    setModalState('working');
    await dispatch(teamAcceptInvitation({teamId: team._id}));
    await dispatch(fetchTeams());
    navigate(`/team/${team._id}/settings/privacy`);
  };

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <SoftTypography>{t('teaminvitationmodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <SoftBox display="flex" justifyContent="center" mb={2}>
          <SoftAvatar src={team.picture} variant="rounded" shadow="sm" sx={{color: 'black !important'}}>
            {team.name[0]}
          </SoftAvatar>
        </SoftBox>
        <SoftTypography fontWeight="bold" sx={{textAlign: 'center', mb: 2}}>
          {t('teaminvitationmodal.description', {
            name: `${user?.firstname ?? ''} ${user?.firstname ?? ''}`,
            team: team.name,
          })}
        </SoftTypography>
        <TipCard informationkey="modalcreateteam" />
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={onReject} disabled={modalState === 'working'}>
          {modalState === 'working' ? <CircularProgress size={14} /> : t('teaminvitationmodal.reject')}
        </SoftButton>
        <SoftButton variant="gradient" color="primary" onClick={onAccept} disabled={modalState === 'working'}>
          {modalState === 'working' ? <CircularProgress size={14} color="info" /> : t('teaminvitationmodal.accept')}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
