/* eslint-disable prettier/prettier */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import {isShareURN, type OrganizationURN, type PersonURN} from '@perfectpost/linkedin-privateapi-ts-client';
import {
  GetLikeCommand,
  type Comment,
  GetCommentCommand,
  GetNoAnswerCommentCommand,
  GetOnePostCommand,
  SetTrackingPostCommand,
  StopTrackingPostCommand,
} from '@perfectpost/perfect-post-common';
import type {Like, LinkedinPost} from '@perfectpost/perfect-post-common';
import type {SocialData} from '@perfectpost/perfect-post-common';
import {parseISO} from 'date-fns';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {Column} from 'react-table';
import ActivityPreview from 'src/components/activitypreview/ActivityPreview';
import Chart, {Data} from 'src/components/chart/Chart';
import Datatable from 'src/components/datatable';
import MessageCard from 'src/components/messagecard/MessageCard';
import StatisticsCardSmall from 'src/components/statisticscardsmall/StatisticsCardSmall';
import TablePostRow, {RowDefinition} from 'src/components/tablepost/TablePostRow';
import { perfectPostServiceClient } from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {loadLinkedInPostAveragePerfectPost} from 'src/store/dataslice';
import {removePost, selectPostWithinInterval} from 'src/store/postslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormat} from 'src/utils/date-format';

type GlobalStats = {
  engagement: number;
  avgImpressions: number;
  avgComments: number;
  avgLikes: number;
  count: number;
};

type Progress = {
  engagement: number;
  impression: number;
  comment: number;
  like: number;
};

type PostData = RowDefinition & {
  click: number;
  score: string;
};

const sampleDatasets: Data[] = [
  {
    yAxisID: 'A',
    id: 'comment',
    label: 'Commentaire',
    color: 'dark',
    data: [],
  },
  {
    yAxisID: 'A',
    id: 'like',
    label: 'Like',
    color: 'info',
    data: [],
  },
  {
    yAxisID: 'B',
    id: 'view',
    label: 'Vue',
    color: 'primary',
    data: [],
  },
];

type TableRow = {
  _id: PersonURN | OrganizationURN;
  href?: string;
  name?: string;
  picture?: string;
  content?: string;
  date: Date;
};

export default function Statistics() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [oneContentState, setOneContentState] = useState({
    currentLinkedinPost: undefined as LinkedinPost | undefined,
    showRemoveDialog: false,
    loadingComment: false,
    commentMode: 'no-answer' as 'no-answer' | 'all',
    comment: [] as Comment[],
    like: [] as Like[],
  });
  const {currentLinkedinPost, showRemoveDialog, loadingComment, comment, commentMode, like} = oneContentState;

  const user = useAppSelector((state) => state.main.user);
  const premium = useAppSelector((state) => state.main.premium);
  const loadingAveragePostData = useAppSelector((state) => state.data.loadingPostData);
  const averagePerfectPost = useAppSelector((state) => state.data.linkedinPostAveragePerfectPost);
  const interval = useAppSelector((state) => state.post.interval);
  const postLastWeek = useAppSelector(selectPostWithinInterval);

  const isTracked = useMemo(() => {
    return currentLinkedinPost?.tracking === true;
  }, [currentLinkedinPost]);

  const [tableData, progress] = useMemo<[PostData | undefined, Progress | undefined]>(() => {
    if (currentLinkedinPost) {
      const date = parseISO(currentLinkedinPost.date);

      const lastRelever =
        currentLinkedinPost.latestSocialData;

      const socialDetailsInterval = postLastWeek
        .map((post) => post.latestSocialData)
        .filter((x): x is SocialData => x !== undefined);

      const data = socialDetailsInterval.reduce<GlobalStats>(
        (prev: GlobalStats, current: SocialData) => {
          return {
            ...prev,
            avgComments: prev.avgComments + (current.numComments ?? 0),
            avgImpressions: prev.avgImpressions + (current.numImpressions ?? 0),
            avgLikes: prev.avgLikes + (current.numLikes ?? 0),
            count: prev.count + 1,
          };
        },
        {
          avgImpressions: 0,
          avgComments: 0,
          avgLikes: 0,
          count: 0,
          engagement: 0,
        },
      );
      data.avgImpressions = data.avgImpressions / data.count;
      data.avgComments = data.avgComments / data.count;
      data.avgLikes = data.avgLikes / data.count;
      data.engagement = (data.avgLikes + data.avgComments) / data.avgImpressions;

      const table = {
        _id: currentLinkedinPost._id,
        content: currentLinkedinPost.text?.substring(0, 80) ?? '',
        comment: lastRelever?.numComments ?? 0,
        date,
        engagement:
          ((lastRelever?.numLikes ?? 0) + (lastRelever?.numComments ?? 0)) /
          Math.max(lastRelever?.numImpressions ?? 0, lastRelever?.numViews ?? 0),
        impression: lastRelever?.numImpressions ?? 0,
        like: lastRelever?.numLikes ?? 0,
        view: lastRelever?.numViews ?? 0,
        picture: undefined,
        href: `/analyse/content/${currentLinkedinPost._id}`,
        click: 0,
        score: 'B',
      };
      const progress: Progress = {
        engagement: table.engagement - data.engagement,
        comment: (table.comment - data.avgComments) / data.avgComments,
        like: (table.like - data.avgLikes) / data.avgLikes,
        impression: (table.impression - data.avgImpressions) / data.avgImpressions,
      };
      return [table, progress];
    }
    return [undefined, undefined];
  }, [currentLinkedinPost]);

  const ppProgress = useMemo(() => {
    if (averagePerfectPost === undefined) {
      return undefined;
    }
    const prog: Progress = tableData
      ? {
          engagement: tableData.engagement - averagePerfectPost.engagement,
          impression: (tableData.impression - averagePerfectPost.avgImpressions) / averagePerfectPost.avgImpressions,
          comment: (tableData.comment - averagePerfectPost.avgComments) / averagePerfectPost.avgComments,
          like: (tableData.like - averagePerfectPost.avgLikes) / averagePerfectPost.avgLikes,
        }
      : {
          comment: NaN,
          engagement: NaN,
          impression: NaN,
          like: NaN,
        };
    return prog;
  }, [averagePerfectPost, tableData]);

  const datasets = useMemo<Data[]>(() => {
    const datas: Data[] = [...sampleDatasets];

    const comment = datas.find((d) => d.id === 'comment');
    const like = datas.find((d) => d.id === 'like');
    const view = datas.find((d) => d.id === 'view');

    if (currentLinkedinPost !== undefined && comment !== undefined && like !== undefined && view !== undefined) {
      comment.data = [];
      like.data = [];
      view.data = [];
      currentLinkedinPost.socialDetails?.forEach((postdata) => {
        const date = parseISO(postdata.date);
        comment.data.push({x: date, y: postdata.numComments ?? 0});
        like.data.push({x: date, y: postdata.numLikes ?? 0});
        view.data.push({x: date, y: postdata.numImpressions ?? 0});
      });
    }
    return datas;
  }, [currentLinkedinPost]);

  const dispatch = useAppThunkDispatch();
  useDocumentTitle(t('onecontent.documenttitle', {defaultValue: 'Post'}));

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    perfectPostServiceClient.send(new GetOnePostCommand(id)).then((post) => {
      setOneContentState((prev) => ({...prev, currentLinkedinPost: post}));
    });
  }, [id]);

  useEffect(() => {
    if (premium && interval) {
      dispatch(loadLinkedInPostAveragePerfectPost({begin: interval.start, end: interval.end}));
    }
  }, [premium, interval]);

  const fetchComment = useCallback(
    (mode: 'all' | 'no-answer') => {
      if (premium && currentLinkedinPost?.socialDetails?.[0] !== undefined) {
        setOneContentState((prev) => ({...prev, loadingComment: true}));
        const promiseLike = perfectPostServiceClient.send(new GetLikeCommand(currentLinkedinPost._id));
        let promiseComment;
        if (mode === 'all') {
          promiseComment = perfectPostServiceClient.send(new GetCommentCommand(currentLinkedinPost._id));
        } else {
          promiseComment = perfectPostServiceClient.send(new GetNoAnswerCommentCommand(currentLinkedinPost._id));
        }
        Promise.all([promiseComment, promiseLike]).then(([comment, like]) => {
          setOneContentState((prev) => ({...prev, loadingComment: false, like, comment}));
        });
      }
    },
    [premium, currentLinkedinPost],
  );

  const commenterColumnDefinition: Column<TableRow>[] = [
    {
      Header: t('tablepost.name', {defaultValue: 'Nom'}),
      accessor: 'name',
      width: '10%',
      Cell: (props) => {
        return (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            <SoftBox mr={2}>
              <SoftBox component="img" src={props.row.original.picture} sx={{borderRadius: '50%', width: 20}} />
            </SoftBox>
            <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
              {props.row.original.name ?? 'LinkedIn User'}
            </SoftTypography>
          </SoftBox>
        );
      },
    },
    {
      Header: t('tablepost.date', {defaultValue: 'Date'}),
      accessor: 'date',
      width: '10%',
      Cell: (props) => (
        <SoftTypography
          variant="button"
          textTransform="none"
          fontWeight="regular"
          color="secondary"
          sx={{display: 'inline-block', width: 'max-content'}}>
          {localFormat(props.value, 'dd/MM/yyyy HH:mm:ss', user?.locale)}
        </SoftTypography>
      ),
    },
    {Header: t('tablepost.content', {defaultValue: 'Content'}), accessor: 'content', width: '50%'},
  ];
  const commenterTableRows = useMemo<TableRow[]>(() => {
    if (comment === undefined) {
      return [];
    }
    return comment.map((c) => ({
      _id: c.actor,
      name:
        c.actorDetail?.firstName || c.actorDetail?.lastName
          ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
          : undefined,
      picture: c.actorDetail?.profilePicture,
      content: c.content,
      href: `https://linkedin.com/feed/update/${c.object}?commentUrn=${c.urn}`,
      date: parseISO(c.createdAt),
    }));
  }, [comment]);

  const likerColumnDefinition: Column<TableRow>[] = [
    {
      Header: t('tablepost.name', {defaultValue: 'Nom'}),
      accessor: 'name',
      width: '10%',
      Cell: (props) => {
        return (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            <SoftBox mr={2}>
              <SoftBox component="img" src={props.row.original.picture} sx={{borderRadius: '50%', width: 20}} />
            </SoftBox>
            <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
              {props.row.original.name ?? 'LinkedIn User'}
            </SoftTypography>
          </SoftBox>
        );
      },
    },
    {
      Header: t('tablepost.date', {defaultValue: 'Date'}),
      accessor: 'date',
      width: '10%',
      Cell: (props) => (
        <SoftTypography
          variant="button"
          textTransform="none"
          fontWeight="regular"
          color="secondary"
          sx={{display: 'inline-block', width: 'max-content'}}>
          {localFormat(props.value, 'dd/MM/yyyy HH:mm:ss', user?.locale)}
        </SoftTypography>
      ),
    },
  ];
  const likerTableRows = useMemo<TableRow[]>(() => {
    if (like === undefined) {
      return [];
    }
    return like.map((c) => ({
      _id: c.actor,
      name:
        c.actorDetail?.firstName || c.actorDetail?.lastName
          ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
          : undefined,
      picture: c.actorDetail?.profilePicture,
      href: c.actorDetail?.vanityName ? `https://linkedin.com/in/${c.actorDetail.vanityName}` : undefined,
      date: parseISO(c.createdAt),
    }));
  }, [like]);

  useEffect(() => {
    void fetchComment(commentMode);
  }, [premium, currentLinkedinPost]);
  const onCommentModeChange = (mode: 'all' | 'no-answer') => {
    setOneContentState((prev) => ({...prev, mode}));
    fetchComment(mode);
  };

  const onTrackPost = () => {
    if (currentLinkedinPost?.urn === undefined || isShareURN(currentLinkedinPost.urn)) {
      return;
    }
    perfectPostServiceClient.send(new SetTrackingPostCommand({urn: currentLinkedinPost.urn}));
    setOneContentState((prev) => ({...prev, currentLinkedinPost: {...currentLinkedinPost, tracking: true}}));
  };

  const onStopTracking = async () => {
    await perfectPostServiceClient.send(new StopTrackingPostCommand())
    if (currentLinkedinPost !== undefined) {
      setOneContentState((prev) => ({...prev, currentLinkedinPost: {...currentLinkedinPost, tracking: undefined}}));
    }
  };

  const onRemove = async () => {
    setOneContentState((prev) => ({...prev, showRemoveDialog: false}));
    if (id) {
      await dispatch(removePost(id));
      navigate('/analyse/content/');
    }
  };

  const onExportCommentToCsv = () => {
    // export all comment to csv in html5 way
    if (comment) {
      const csv = comment.map((c) => ({
        name:
          c.actorDetail?.firstName || c.actorDetail?.lastName
            ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
            : undefined,
        content: c.content.replace(/"/g, '""').replace(/(\r\n|\n|\r)/g, ' '),
        date: c.createdAt,
        href: c.actorDetail?.vanityName ? `https://www.linkedin.com/in/${c.actorDetail?.vanityName}` : undefined,
      }));
      // export with html javascript code
      const csvContent =
        'data:text/csv;charset=utf-8,' +
        csv.map((e) => e.name + ',' + e.href + ',' + e.date + ',"' + e.content + '"').join('\n');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'comment.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  };

  const onExportLikeToCsv = () => {
    // export all comment to csv in html5 way
    if (like) {
      const csv = like.map((c) => ({
        name:
          c.actorDetail?.firstName || c.actorDetail?.lastName
            ? `${c.actorDetail?.firstName ?? ''} ${c.actorDetail?.lastName ?? ''}`
            : undefined,
        date: c.createdAt,
        href: c.actorDetail?.vanityName ? `https://www.linkedin.com/in/${c.actorDetail?.vanityName}` : undefined,
      }));
      // export with html javascript code
      const csvContent =
        'data:text/csv;charset=utf-8,' + csv.map((e) => e.name + ',' + e.href + ',' + e.date).join('\n');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'like.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  };

  return (
    <SoftBox>
      <SoftBox mb={3}>
        <SoftTypography variant="h2" textTransform="none" fontWeight="bold">
          {t('onecontent.title', {defaultValue: 'Your post'})}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        gridTemplateColumns="repeat(5, 1fr)"
        gridTemplateAreas={`
          "title title title title title"
          "content content graph graph stats"
          "comment comment comment comment stats"
          "like like like like stats"`}
        gap={2}
        display="grid">
        <SoftBox gridArea="title">{tableData && <TablePostRow {...tableData} />}</SoftBox>
        <SoftBox gridArea="content" sx={{minWidth: '572px'}}>
          {currentLinkedinPost && (
            <ActivityPreview
              fullname={`${user?.firstname} ${user?.lastname}`}
              picture={user?.lnPicture}
              content={currentLinkedinPost.text ?? ''}
            />
          )}
        </SoftBox>
        <SoftBox
          sx={({palette}) => ({
            height: 400,
            p: 2,
            borderRadius: '1em',
            backgroundColor: palette.white.main,
            position: 'relative',
          })}
          gridArea="graph">
          <Chart chartdataset={datasets} type="line" />
          {premium && currentLinkedinPost === undefined && (
            <SoftBox
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}>
              <MessageCard
                bgColor="dark"
                description={t('dashboard.graph.nographdescription', {
                  defaultValue: 'To view the details of your next publication, track this one',
                })}
                btnText={t('dashboard.graph.nographbtn', {defaultValue: 'Documentation'})}
                to="#"
              />
            </SoftBox>
          )}
        </SoftBox>
        <SoftBox
          gridArea="comment"
          sx={({palette}) => ({
            minHeight: 400,
            p: 2,
            borderRadius: '1em',
            backgroundColor: palette.white.main,
            position: 'relative',
          })}>
          <SoftBox sx={{display: 'flex', flexDirection: 'row'}}>
            <SoftTypography>{t('draft.commentnoanswer.title')}</SoftTypography>
            <SoftBox sx={{flexGrow: 1}} />
            <Tabs value={commentMode} onChange={(e, v) => onCommentModeChange(v as "no-answer" | "all")} sx={{minWidth: 400}}>
              <Tab label="All" value="all" />
              <Tab label="No Answer" value="no-answer" />
            </Tabs>
          </SoftBox>
          {loadingComment ? (
            <CircularProgress color="info" sx={{position: 'absolute', top: '50%', left: '50%'}} />
          ) : premium && comment.length === 0 ? (
            <SoftTypography sx={{position: 'absolute', top: '50%', left: '33%'}}>
              {t('draft.commentnoanswer.noresult')}
            </SoftTypography>
          ) : (
            <Datatable
              table={{rows: commenterTableRows, columns: commenterColumnDefinition}}
              entriesPerPage={{defaultValue: 30, entries: [10, 20, 30, 50, 80, 130]}}
              pagination={{variant: 'gradient', color: 'info'}}
              isSorted
              canSearch
              onExportData={onExportCommentToCsv}
            />
          )}
          <SoftBox
            sx={({breakpoints}) => ({
              borderRadius: '1rem',
              p: 1,
              marginLeft: '-16px',
              display: premium ? 'none' : 'flex',
              backgroundColor: 'rgba(0,0,0,75%)',
              position: 'absolute',
              top: 0,
              height: 'fit-content',
              minHeight: '100%',
              width: '50%',
              [breakpoints.up('xl')]: {
                width: '100%',
              },
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            })}>
            <SoftTypography color="white" variant="button" textTransform="none" sx={{maxWidth: '500px'}}>
              {t('draft.commentnoanswer.overlaypremium.title')}
            </SoftTypography>
            {/*
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore */}
            <SoftButton variant="gradient" color="dark" component={NavLink} to="/settings/subscription?o=">
              {t('draft.commentnoanswer.overlaypremium.btn', {defaultValue: 'Go premium'})}
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <SoftBox
          gridArea="like"
          sx={({palette}) => ({
            minHeight: 400,
            p: 2,
            borderRadius: '1em',
            backgroundColor: palette.white.main,
            position: 'relative',
          })}>
          <SoftBox sx={{display: 'flex', flexDirection: 'row'}}>
            <SoftTypography>{t('global.like')}</SoftTypography>
          </SoftBox>
          {loadingComment ? (
            <CircularProgress color="info" sx={{position: 'absolute', top: '50%', left: '50%'}} />
          ) : (
            <Datatable
              table={{rows: likerTableRows, columns: likerColumnDefinition}}
              entriesPerPage={{defaultValue: 30, entries: [10, 20, 30, 50, 80, 130]}}
              pagination={{variant: 'gradient', color: 'info'}}
              isSorted
              canSearch
              onExportData={onExportLikeToCsv}
            />
          )}
          <SoftBox
            sx={({breakpoints}) => ({
              borderRadius: '1rem',
              p: 1,
              marginLeft: '-16px',
              display: premium ? 'none' : 'flex',
              backgroundColor: 'rgba(0,0,0,75%)',
              position: 'absolute',
              top: 0,
              height: 'fit-content',
              minHeight: '100%',
              width: '50%',
              [breakpoints.up('xl')]: {
                width: '100%',
              },
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            })}>
            <SoftTypography color="white" variant="button" textTransform="none" sx={{maxWidth: '500px'}}>
              {t('draft.commentnoanswer.overlaypremium.title')}
            </SoftTypography>
            {/*
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore */}
            <SoftButton variant="gradient" color="dark" component={NavLink} to="/settings/subscription?o=">
              {t('draft.commentnoanswer.overlaypremium.btn', {defaultValue: 'Go premium'})}
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <SoftBox gridArea="stats" component="ul" display="flex" flexDirection="column" px={0} m={0}>
          {tableData && (
            <>
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                {isTracked ? (
                  <SoftButton fullWidth color="dark" onClick={onStopTracking}>
                    {t('onecontent.untrackpost')}
                  </SoftButton>
                ) : (
                  <SoftButton fullWidth color="dark" onClick={onTrackPost}>
                    {t('onecontent.trackpost')}
                  </SoftButton>
                )}
              </SoftBox>
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <SoftButton
                  fullWidth
                  color="dark"
                  LinkComponent="a"
                  href={`https://www.linkedin.com/feed/update/${currentLinkedinPost?.urn}`}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  target="_blank">
                  {t('onecontent.seepublicationlinkedin')}
                </SoftButton>
              </SoftBox>
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <SoftButton
                  fullWidth
                  color="error"
                  onClick={() => setOneContentState((prev) => ({...prev, showRemoveDialog: true}))}>
                  {t('global.delete')}
                </SoftButton>
                <Dialog
                  open={showRemoveDialog}
                  onClose={() => setOneContentState((prev) => ({...prev, showRemoveDialog: false}))}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description">
                  <DialogTitle id="alert-dialog-title">
                    {t('onecontent.revemodialog.title', {defaultValue: 'Confirm your deletion request'})}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t('onecontent.revemodialog.content', {
                        defaultValue: 'Are you sure you want to delete this post?',
                      })}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onRemove} color="error">
                      {t('global.delete')}
                    </Button>
                    <Button
                      onClick={() => setOneContentState((prev) => ({...prev, showRemoveDialog: false}))}
                      autoFocus>
                      {t('global.cancel')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </SoftBox>
              {/*
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <StatisticsCardSmall title="Score" mode="number" data="B" />
              </SoftBox>
               */}
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <StatisticsCardSmall
                  title={t('global.engagementrate', {defaultValue: 'Engagement'})}
                  mode="percentage"
                  data={tableData.engagement}
                  prevProgress={progress?.engagement}
                  ppProgress={ppProgress?.engagement}
                  loadingPpProgress={loadingAveragePostData}
                  premium={premium}
                />
              </SoftBox>
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <StatisticsCardSmall
                  title={t('global.impression', {defaultValue: 'Impression'})}
                  mode="number"
                  data={tableData.impression}
                  prevProgress={progress?.impression}
                  ppProgress={ppProgress?.impression}
                  loadingPpProgress={loadingAveragePostData}
                  premium={premium}
                />
              </SoftBox>
              {/*
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <StatisticsCardSmall
                  title={t('global.view', {defaultValue: 'View'})}
                  mode="number"
                  data={tableData.view}
                  prevProgress={0.043}
                  ppProgress={0.25}
                  loadingPpProgress={loadingAveragePostData}
                  premium={premium}
                />
              </SoftBox>
              */}
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <StatisticsCardSmall
                  title={t('global.like', {defaultValue: 'Like'})}
                  mode="number"
                  data={tableData.like}
                  prevProgress={progress?.like}
                  ppProgress={ppProgress?.like}
                  loadingPpProgress={loadingAveragePostData}
                  premium={premium}
                />
              </SoftBox>
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <StatisticsCardSmall
                  title={t('global.comment', {defaultValue: 'Comment'})}
                  mode="number"
                  data={tableData.comment}
                  prevProgress={progress?.comment}
                  ppProgress={ppProgress?.comment}
                  loadingPpProgress={loadingAveragePostData}
                  premium={premium}
                />
              </SoftBox>
              {/*
              <SoftBox component="li" display="flex" alignItems="baseline" flexDirection="column" mb={2}>
                <StatisticsCardSmall
                  title={t('global.websiteclick', {defaultValue: 'Click on Website'})}
                  mode="number"
                  data={tableData.click}
                  prevProgress={0.043}
                  ppProgress={0.25}
                  loadingPpProgress={loadingAveragePostData}
                  premium={premium}
                />
              </SoftBox>
              */}
            </>
          )}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}
