/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import {ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme} from '@mui/material/styles';
import borders from 'src/theme/theme/base/borders';
import boxShadows from 'src/theme/theme/base/boxShadows';
import colors from 'src/theme/theme/base/colors';
import typography from 'src/theme/theme/base/typography';
// Soft UI Dashboard PRO React helper functions
import pxToRem from 'src/theme/theme/functions/pxToRem';
type MuiMenuComponentsProps = {
  defaultProps?: ComponentsProps['MuiMenu'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenu'];
  variants?: ComponentsVariants['MuiMenu'];
};

const {lg} = boxShadows;
const {size} = typography;
const {text, white} = colors;
const {borderRadius} = borders;

const menu: MuiMenuComponentsProps = {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    paper: {
      minWidth: pxToRem(160),
      boxShadow: lg,
      padding: `${pxToRem(16)} ${pxToRem(8)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: 'left',
      backgroundColor: `${white.main} !important`,
      borderRadius: borderRadius.md,
    },
  },
};

export default menu;
