/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React Base Styles
import {ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme} from '@mui/material/styles';
import borders from 'src/theme/theme/base/borders';
import boxShadows from 'src/theme/theme/base/boxShadows';
import colors from 'src/theme/theme/base/colors';
// Soft UI Dashboard PRO React Helper Function
import rgba from 'src/theme/theme/functions/rgba';
type MuiCardComponentsProps = {
  defaultProps?: ComponentsProps['MuiCard'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCard'];
  variants?: ComponentsVariants['MuiCard'];
};

const {black, white} = colors;
const {borderWidth, borderRadius} = borders;
const {xxl} = boxShadows;

const card: MuiCardComponentsProps = {
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minWidth: 0,
      wordWrap: 'break-word',
      backgroundColor: white.main,
      backgroundClip: 'border-box',
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: xxl,
    },
  },
};

export default card;
