import {CircularProgress} from '@mui/material';
import Icon from '@mui/material/Icon';
import {
  AcceptSharedWithMeDraftCommand,
  GetOneUserCommand,
  GetSharedWithMeDraftCommand,
  GetUrlSharingDraftCommand,
  Proofreader,
  RejectSharedWithMeDraftCommand,
  RemoveSharedWithMeDraftCommand,
} from '@perfectpost/perfect-post-common';
import {PublicUser} from '@perfectpost/perfect-post-common';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Column} from 'react-table';
import Datatable from 'src/components/datatable';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';
import {localFormatISOString} from 'src/utils/date-format';
import {useQuery} from 'src/utils/hooks';

type TableRow = {
  _id: string;
  href?: string;
  owner: PublicUser;
  title?: string;
  picture?: string;
  content: string;
  status: string;
  lastUpdate: string;
  proofreaders: Proofreader[];
  invitationCode?: string;
};

export default function SharedWihMe() {
  const {t} = useTranslation();
  const query = useQuery();
  const user = useAppSelector((state) => state.main.user);
  const [loading, setLoading] = useState(false);
  const [tableRowData, setTableRowData] = useState<TableRow[]>([]);

  useEffect(() => {
    const acceptCode = query.get('code');
    const draftId = query.get('draft');
    if (acceptCode && draftId) {
      onAccepteInvitation(draftId, acceptCode)().then(() => loadSharedWithMe());
    }
  }, [query]);

  useEffect(() => {
    void loadSharedWithMe();
  }, []);

  const columnDefinition: Column<TableRow>[] = [
    {
      Header: t('sharedwithme.owner'),
      accessor: 'owner',
      width: '10%',
      Cell: (props) => (
        <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
          <SoftBox mr={2}>
            <SoftBox component="img" src={props.row.original.owner.lnPicture} width={42} sx={{borderRadius: '100%'}} />
          </SoftBox>
        </SoftBox>
      ),
    },
    {
      Header: t('sharedwithme.post'),
      accessor: 'content',
      width: '40%',
      Cell: (props) => {
        const content = props.row.original.content;
        // strip html tags
        const regex = /(<([^>]+)>)/gi;
        const result = content.replace(regex, '');
        // keep first 100 characters
        const text = result.substring(0, 70);
        return (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            <SoftBox mr={2}>
              <SoftBox component="img" width={48} src={props.row.original.picture} />
            </SoftBox>
            <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
              {text}
            </SoftTypography>
          </SoftBox>
        );
      },
    },
    {
      Header: t('sharedwithme.status'),
      accessor: 'status',
      width: '10%',
      Cell: (props) => (
        <SoftTypography
          variant="button"
          textTransform="none"
          fontWeight="regular"
          color="secondary"
          sx={{display: 'inline-block', width: 'max-content'}}>
          {props.value}
        </SoftTypography>
      ),
    },
    {
      Header: t('sharedwithme.date'),
      accessor: 'lastUpdate',
      width: '10%',
      Cell: (props) => (
        <SoftTypography
          variant="button"
          textTransform="none"
          fontWeight="regular"
          color="secondary"
          sx={{display: 'inline-block', width: 'max-content'}}>
          {localFormatISOString(props.value, 'dd/MM/yyyy HH:mm:ss', user?.locale)}
        </SoftTypography>
      ),
    },
    {
      Header: t('sharedwithme.proofreaders'),
      accessor: 'proofreaders',
      width: '10%',
      Cell: (props) => {
        const first4 = props.value.slice(0, 4);
        const rest = props.value.slice(4);

        return (
          <SoftBox sx={{height: 34, width: 142, position: 'relative'}}>
            {first4.map((p, index) => (
              <SoftBox
                key={p.user?._id ?? new Date().getTime()}
                sx={{
                  position: 'absolute',
                  width: 34,
                  height: 34,
                  left: index * 28,
                  border: '1px solid white',
                  borderRadius: 34,
                }}>
                <SoftBox component="img" src={p.user?.lnPicture} width={32} sx={{borderRadius: '100%'}} />
              </SoftBox>
            ))}
            {rest.length > 0 && (
              <SoftBox
                sx={(theme) => ({
                  position: 'absolute',
                  width: 34,
                  height: 34,
                  left: 112,
                  border: '1px solid white',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: '100%',
                })}>
                <SoftTypography variant="button" fontWeight="medium" color="light">
                  +{4 - rest.length}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        );
      },
    },
    {
      Header: '',
      accessor: 'invitationCode',
      width: '5%',
      Cell: (props) => {
        return (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            {props.value !== undefined ? (
              <SoftBox mr={2}>
                <SoftButton
                  iconOnly
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{mr: 1}}
                  onClick={onAccepteInvitation(props.row.original._id, props.value)}>
                  <Icon>check</Icon>
                </SoftButton>
                <SoftButton
                  iconOnly
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={onRejectInvitiation(props.row.original._id, props.value)}>
                  <Icon>close</Icon>
                </SoftButton>
              </SoftBox>
            ) : (
              <SoftBox mr={2}>
                <SoftButton
                  iconOnly
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={onRemoveMe(props.row.original._id)}>
                  <Icon>delete</Icon>
                </SoftButton>
              </SoftBox>
            )}
          </SoftBox>
        );
      },
    },
  ];

  const loadSharedWithMe = async () => {
    setLoading(true);
    const drafts = await perfectPostServiceClient.send(new GetSharedWithMeDraftCommand());
    const userCache: Record<string, PublicUser> = {};
    const tableRows = await Promise.all(
      drafts.map(async (draft) => {
        let picture: string | undefined = undefined;
        if (draft.sharing && draft.sharing.type !== 'poll' && draft.sharing.type !== 'images') {
          const {url, thumbnail} = await perfectPostServiceClient.send(new GetUrlSharingDraftCommand(draft._id));
          picture = thumbnail ?? url;
        }
        let owner = userCache[draft.user];
        if (owner === undefined) {
          owner = await perfectPostServiceClient.send(new GetOneUserCommand({id: draft.user}));
          userCache[draft.user] = owner;
        }
        const c: TableRow = {
          _id: draft._id,
          href: `/publish/shared/${draft._id}`,
          owner,
          title: undefined,
          picture,
          content: draft.content,
          status: draft.tag ?? 'idea',
          lastUpdate: draft.updatedAt ?? draft.date,
          proofreaders: draft.proofreaders?.filter((u) => u.invitationCode === undefined) ?? [],
          invitationCode: draft.proofreaders?.find((u) => u.email === user?.email || u.user?._id === user?._id)
            ?.invitationCode,
        };
        return c;
      }),
    );
    setTableRowData(tableRows);
    setLoading(false);
  };

  const onAccepteInvitation =
    (draftId: string, code: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.preventDefault();
      return perfectPostServiceClient.send(new AcceptSharedWithMeDraftCommand({draftId, code})).then((draft) => {
        setTableRowData((prev) => {
          const index = prev.findIndex((d) => d._id === draftId);
          prev[index] = {
            ...prev[index],
            invitationCode: undefined,
            proofreaders: draft.proofreaders?.filter((u) => u.invitationCode === undefined) ?? [],
          };
          return [...prev];
        });
      });
    };

  const onRejectInvitiation =
    (draftId: string, code: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.preventDefault();
      return perfectPostServiceClient.send(new RejectSharedWithMeDraftCommand({draftId, code})).then(() => {
        setTableRowData((prev) => {
          return prev.filter((d) => d._id !== draftId);
        });
      });
    };

  const onRemoveMe = (draftId: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault();
    perfectPostServiceClient.send(new RemoveSharedWithMeDraftCommand({draftId})).then(() => {
      setTableRowData((prev) => {
        return prev.filter((d) => d._id !== draftId);
      });
    });
  };

  return (
    <SoftBox sx={{position: 'relative'}}>
      <SoftBox sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
        <SoftBox>
          <SoftSelect placeholder="Filter tag" isClearable />
        </SoftBox>
      </SoftBox>
      <Datatable
        table={{rows: tableRowData, columns: columnDefinition}}
        entriesPerPage={{defaultValue: 30, entries: [10, 20, 30, 50, 80, 130]}}
        pagination={{variant: 'gradient', color: 'info'}}
        isSorted
        canSearch
      />
      {loading && (
        <SoftBox
          sx={{
            position: 'absolute',
            bottom: '40px',
            height: '50px',
            width: '97%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress color="info" />
        </SoftBox>
      )}
    </SoftBox>
  );
}
