import {
  GetMeCommand,
  LeaveTeamCommand,
  RemoveAccountUserCommand,
  type Notification,
  UnlinkLinkedInAccountUserCommand,
  UpdateMeCommand,
  GetOrganizationCommand,
  GetNotificationCommand,
  AcceptInvitationTeamCommand,
  GetLinkedInAccessTokenCommand,
} from '@perfectpost/perfect-post-common';
import type {User} from '@perfectpost/perfect-post-common';
import type {Organization} from '@perfectpost/perfect-post-common';
import {createSlice, createAsyncThunk, PayloadAction, isAnyOf} from '@reduxjs/toolkit';
import {JWT, fetchAuthSession, signOut} from 'aws-amplify/auth';
import {ConsoleLogger} from 'aws-amplify/utils';
import {isFuture, parseISO} from 'date-fns';
import localforage from 'localforage';
import i18n from 'src/i18n';
import {mixPanelService, perfectPostServiceClient} from 'src/services';
const logger = new ConsoleLogger('MainSlice');

const BETA_COGNITO_GROUP_NAME = 'BETA';
const PREMIUM_COGNITO_GROUP_NAME = 'CUSTOMER';

interface MainState {
  loadingUser?: boolean;
  user?: User;
  organizations: Organization[];
  lnAccessTokenValid?: boolean;

  premium: boolean;
  beta: boolean;
  notifications?: Notification;
}
const initialState: MainState = {
  organizations: [],
  premium: false,
  beta: false,
};

export const updateProfile = createAsyncThunk(
  'user/update',
  async ({firstname, lastname, email}: Partial<Pick<User, 'firstname' | 'lastname' | 'email'>>) =>
    perfectPostServiceClient.send(new UpdateMeCommand({firstname, lastname, email})),
);

export const updateLocale = createAsyncThunk('user/update/locale', async ({locale}: Pick<User, 'locale'>) => {
  i18n.changeLanguage(locale);
  return perfectPostServiceClient.send(new UpdateMeCommand({locale}));
});

export const logout = createAsyncThunk('user/logout', async () => {
  logger.info('logout');
  mixPanelService.logout();
  return signOut();
});

export const removeAccount = createAsyncThunk('user/removeaccount', async () => {
  await perfectPostServiceClient.send(new RemoveAccountUserCommand());
  return signOut();
});

export const authentification = createAsyncThunk('user/authentification', async () => {
  const groups = await fetchAuthSession().then((session) => {
    const idToken = session.tokens?.idToken;
    return idToken?.payload['cognito:groups'] as string[];
  });

  return {
    premium: groups?.includes(PREMIUM_COGNITO_GROUP_NAME) ?? false,
    beta: groups?.includes(BETA_COGNITO_GROUP_NAME) ?? false,
    userView: [],
  };
});

export const refreshCognitoSession = createAsyncThunk('user/refreshCognitoSession', async () => {
  const {tokens} = await fetchAuthSession({forceRefresh: true});
  const idToken = tokens?.idToken;
  const user = await perfectPostServiceClient.send(new GetMeCommand());
  const groups = (idToken?.payload['cognito:groups'] ?? []) as string[];
  const premium = groups?.includes(PREMIUM_COGNITO_GROUP_NAME) ?? false;
  const beta = groups?.includes(BETA_COGNITO_GROUP_NAME) ?? false;
  return {user, premium, beta};
});

export const getLinkedinAccessToken = createAsyncThunk('user/linkedinAccessToken', async (accessToken: string) =>
  perfectPostServiceClient.send(new GetLinkedInAccessTokenCommand({code: accessToken})),
);

export const unlinkLinkedin = createAsyncThunk('user/linkedinunlink', async () =>
  perfectPostServiceClient.send(new UnlinkLinkedInAccountUserCommand()),
);

export const getMe = createAsyncThunk('user/me', async () => {
  logger.info('getMe');
  const me = await perfectPostServiceClient.send(new GetMeCommand());
  const [organizations, notifications] = await Promise.all([
    perfectPostServiceClient.send(new GetOrganizationCommand()),
    perfectPostServiceClient.send(new GetNotificationCommand()),
  ]);
  let valid = false;
  if (me.lnExpiresAt !== undefined && me.lnAccessToken !== undefined) {
    const expireDate = parseISO(me.lnExpiresAt);
    valid = isFuture(expireDate);
  }
  return {me, valid, organizations, notifications};
});

export const teamAcceptInvitation = createAsyncThunk('user/teamAcceptInvitation', async ({teamId}: {teamId: string}) =>
  perfectPostServiceClient.send(new AcceptInvitationTeamCommand({teamId})),
);

export const teamLeave = createAsyncThunk('user/teamLeave', async ({teamId}: {teamId: string}) =>
  perfectPostServiceClient.send(new LeaveTeamCommand({teamId})),
);

const isEarlyAdoptersPremium = (user: User): boolean => {
  return user.earlyAdoptersPremiumExpireAt !== undefined && isFuture(parseISO(user.earlyAdoptersPremiumExpireAt));
};

export const mainSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    reloadGroup: (state, action: PayloadAction<{user: User; idToken: JWT}>) => {
      const groups = action.payload.idToken.payload['cognito:groups'] as string[];
      console.log('reloadGroup', {groups});
      state.user = action.payload.user;
      state.premium =
        (isEarlyAdoptersPremium(action.payload.user) || groups?.includes(PREMIUM_COGNITO_GROUP_NAME)) ?? false;
      state.beta = groups?.includes(BETA_COGNITO_GROUP_NAME) ?? false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeAccount.pending, (state) => {
        state.loadingUser = true;
      })
      .addCase(removeAccount.fulfilled, (state) => {
        state.lnAccessTokenValid = undefined;
        state.loadingUser = undefined;
        state.premium = false;
        state.beta = false;
        state.user = undefined;
        state.organizations = [];
        localStorage.clear();
      })
      .addCase(authentification.fulfilled, (state, action) => {
        state.premium = action.payload.premium;
        state.beta = action.payload.beta;
      })
      .addCase(refreshCognitoSession.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.premium = isEarlyAdoptersPremium(action.payload.user) || action.payload.premium;
        state.beta = action.payload.beta;
      })
      .addCase(getMe.pending, (state) => {
        state.loadingUser = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.loadingUser = false;
        state.user = action.payload.me;
        state.premium = isEarlyAdoptersPremium(action.payload.me) || state.premium;
        state.lnAccessTokenValid = action.payload.valid;
        state.organizations = action.payload.organizations;
        state.notifications = action.payload.notifications;
      })
      .addCase(teamAcceptInvitation.fulfilled, (state, action) => {
        if (state.notifications?.teamInvitations) {
          const index = state.notifications.teamInvitations.findIndex(
            (invitation) => invitation._id === action.meta.arg.teamId,
          );
          if (index !== -1) {
            state.notifications.teamInvitations.splice(index, 1);
          }
        }
      })
      .addCase(teamLeave.fulfilled, (state, action) => {
        if (state.notifications?.teamInvitations) {
          const index = state.notifications.teamInvitations.findIndex(
            (invitation) => invitation._id === action.meta.arg.teamId,
          );
          if (index !== -1) {
            state.notifications.teamInvitations.splice(index, 1);
          }
        }
      })
      .addCase(getLinkedinAccessToken.fulfilled, (state, action) => {
        state.loadingUser = false;
        state.user = action.payload;
      })
      .addCase(unlinkLinkedin.pending, (state) => {
        state.loadingUser = true;
      })
      .addCase(unlinkLinkedin.fulfilled, (state, action) => {
        state.loadingUser = false;
        state.user = action.payload;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateLocale.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addMatcher(isAnyOf(logout.fulfilled, logout.rejected), (state) => {
        state.lnAccessTokenValid = undefined;
        state.loadingUser = undefined;
        state.premium = false;
        state.beta = false;
        state.user = undefined;
        state.organizations = [];
        localStorage.clear();
        localforage.clear();
      });
  },
});

// Action creators are generated for each case reducer function
export const {reloadGroup} = mainSlice.actions;
export default mainSlice.reducer;
