import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {CircularProgress, IconButton} from '@mui/material';
import {ForgotPasswordUserCommand} from '@perfectpost/perfect-post-common';
import {confirmResetPassword, signIn} from 'aws-amplify/auth';
import {ConsoleLogger} from 'aws-amplify/utils';
import {isPast, parseISO} from 'date-fns';
import Lottie from 'lottie-react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, Link, useLocation, useOutletContext} from 'react-router-dom';
import animationSuccess from 'src/assets/lottie//animation-success.json';
import PasswordChecker from 'src/components/passwordchecker/passwordchecker';
import {perfectPostServiceClient} from 'src/services';
import {useAppThunkDispatch} from 'src/store';
import {useAppSelector} from 'src/store';
import {authentification, getMe} from 'src/store/mainslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
// import pattern from 'src/assets/svg/pattern-lines.svg';
import {useQuery} from 'src/utils/hooks';

const logger = new ConsoleLogger('Reset');

type ResetState = {
  state:
    | 'idle'
    | 'loading'
    | 'error'
    | 'EmailNotFoundException'
    | 'InvalidPasswordException'
    | 'LimitExceededException'
    | 'ExpiredCodeException'
    | 'playinganimationstart'
    | 'playinganimationended';
  codeAsked: boolean;
  email: string;
  code: string;
  newPassword: string;
  showPassword: boolean;
  invalidPassword?: boolean;
  error?: {message: string};
};

export default function Reset() {
  const query = useQuery();
  const {lang} = useOutletContext<{lang: 'fr' | 'en' | 'es'}>();
  const location = useLocation();

  const [resetState, setResetState] = useState<ResetState>({
    state: 'idle',
    codeAsked: query.get('code') !== null,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    email: query.get('email') ?? location.state?.email ?? '',
    code: query.get('code') ?? '',
    newPassword: '',
    showPassword: false,
  });
  const {state, codeAsked, email, error, code, newPassword, showPassword, invalidPassword} = resetState;

  const {t} = useTranslation();

  const sessionUser = useAppSelector((state) => state.main.user);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  useDocumentTitle(t('signin.documenttitle', {defaultValue: 'Signin'}));

  useEffect(() => {
    if (sessionUser === undefined) {
      return;
    }
    if (state === 'playinganimationended') {
      const tokenExpiration = sessionUser.lnExpiresAt ? parseISO(sessionUser.lnExpiresAt) : undefined;
      if (tokenExpiration === undefined || isPast(tokenExpiration)) {
        navigate('/auth/linkedin');
      } else {
        navigate('/');
      }
    }
  }, [sessionUser, state]);

  const onResetForm = () => {
    setResetState({
      state: 'idle',
      codeAsked: false,
      email: '',
      code: '',
      newPassword: '',
      showPassword: false,
    });
  };

  const onAskResetCode = async () => {
    query.delete('email');
    query.delete('code');
    setResetState((state) => ({...state, state: 'loading', codeAsked: false, error: undefined}));
    try {
      await perfectPostServiceClient.send(new ForgotPasswordUserCommand({email, locale: lang}));
      query.set('email', email);
      setResetState((state) => ({...state, state: 'idle', codeAsked: true, error: undefined}));
    } catch (error) {
      logger.error({error});
      if (
        typeof error === 'object' &&
        error !== undefined &&
        error !== null &&
        'message' in error &&
        error.message === 'not found'
      ) {
        setResetState((state) => ({
          ...state,
          state: 'EmailNotFoundException',
          error: {message: t('forgotpassword.emailnotfound', {defaultValue: 'Email not found'})},
        }));
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        setResetState((state) => ({...state, state: 'error', error: {message: `Unknown error: ${error}`}}));
      }
    }
  };

  const onSubmitNewPassword = async () => {
    setResetState((state) => ({...state, state: 'loading', error: undefined}));
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword,
      });
      const {isSignedIn} = await signIn({username: email, password: newPassword});
      if (isSignedIn) {
        setResetState((state) => ({...state, state: 'playinganimationstart', error: undefined}));
        await dispatch(authentification());
        await dispatch(getMe());
      } else {
        setResetState((state) => ({
          ...state,
          state: 'error',
          error: {
            message: 'The password change was successful, but we were unable to log you in. Please contact support',
          },
        }));
      }
    } catch (error) {
      if (typeof error === 'object' && error !== undefined && error !== null && 'name' in error) {
        const e = error;
        if (e.name === 'InvalidPasswordException') {
          setResetState((state) => ({
            ...state,
            state: 'InvalidPasswordException',
            invalidPassword: true,
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            error: {message: e.toString()},
          }));
        } else if (error.name === 'LimitExceededException') {
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          setResetState((state) => ({...state, state: 'LimitExceededException', error: {message: e.toString()}}));
        } else if (error.name === 'ExpiredCodeException') {
          setResetState((state) => ({
            ...state,
            state: 'ExpiredCodeException',
            error: {message: t('forgotpassword.expiredcode', {defaultValue: 'The code has expired'})},
          }));
        }
      } else {
        logger.error({error});
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        setResetState((state) => ({...state, state: 'error', error: {message: `Unknown error: ${error}`}}));
      }
    }
  };

  const onEmailChange = (email: string) => {
    setResetState((state) => ({...state, state: 'idle', email: email.toLowerCase(), error: undefined}));
  };

  return (
    <>
      <SoftBox pt={3} px={3}>
        <SoftBox mb={1}>
          <SoftTypography variant="h4" fontWeight="bold">
            {t('signin.forgot.title', {defaultValue: "🤫 I've forgotten my passord"})}
          </SoftTypography>
          <SoftTypography variant="body2" fontWeight="regular" color="text">
            {t('signin.forgot.subtitle', {
              defaultValue: 'Enter your email address, you will receive by email a code to reset your password',
            })}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox p={3}>
        <SoftBox component="form" role="form">
          <SoftBox mt={3}>
            {state === 'playinganimationstart' || state === 'playinganimationended' ? (
              <Lottie
                autoplay
                animationData={animationSuccess}
                initialSegment={[0, 100]}
                loop={false}
                onComplete={() => {
                  setResetState((state) => ({...state, state: 'playinganimationended'}));
                }}
                style={{height: 250}}
              />
            ) : (
              <>
                <SoftBox mb={2}>
                  <SoftInput
                    type="email"
                    autoComplete="email"
                    required
                    placeholder={t('form.email.placeholder', {defaultValue: 'E-mail'})}
                    size="large"
                    autoFocus={email === ''}
                    disabled={query.get('email') !== null || codeAsked}
                    value={email}
                    onChange={(event) => {
                      onEmailChange(event.target.value);
                    }}
                    error={state === 'LimitExceededException' || state === 'EmailNotFoundException'}
                  />
                  {state === 'EmailNotFoundException' && error?.message !== undefined && (
                    <SoftTypography variant="body2" fontWeight="bold" color="error">
                      {error.message}
                    </SoftTypography>
                  )}
                  {state === 'LimitExceededException' && (
                    <SoftTypography variant="body2" fontWeight="bold" color="error">
                      {t('forgotpassword.error.toomanyattempt', {
                        defaultValue: 'Too many attempts, you have to wait 15 minutes to try again',
                      })}
                    </SoftTypography>
                  )}
                  {codeAsked && state !== 'LimitExceededException' && query.get('code') === null && (
                    <>
                      <SoftTypography variant="body2" fontWeight="regular" color="text" sx={{mt: 1, ml: 1}}>
                        {t('signin.forgot.codeasked', {
                          defaultValue: 'A code has been sent to your email address.',
                        })}
                      </SoftTypography>
                      <SoftBox>
                        <SoftButton variant="text" color="info" onClick={onAskResetCode}>
                          {t('reset.forgot.resend', {
                            defaultValue: 'resend',
                          })}
                        </SoftButton>
                        -{' '}
                        <SoftButton variant="text" color="info" onClick={onResetForm}>
                          {t('reset.forgot.changeemail', {
                            defaultValue: 'Change email',
                          })}
                        </SoftButton>
                      </SoftBox>
                    </>
                  )}
                </SoftBox>
                {codeAsked && state !== 'LimitExceededException' && (
                  <>
                    <SoftBox mb={2}>
                      <SoftInput
                        type="text"
                        required
                        placeholder={t('form.verificationCode.placeholder', {defaultValue: 'Verification code'})}
                        size="large"
                        name="code"
                        autoComplete="code"
                        autoFocus={code === ''}
                        value={code}
                        disabled={query.get('code') !== null}
                        onChange={(event) => {
                          setResetState((state) => ({...state, code: event.target.value}));
                        }}
                        error={state === 'ExpiredCodeException' && error !== undefined}
                      />
                      {state === 'ExpiredCodeException' && error?.message !== undefined && (
                        <>
                          <SoftTypography variant="body2" fontWeight="bold" color="error">
                            {error.message}
                          </SoftTypography>
                          <SoftButton variant="text" color="info" onClick={onAskResetCode}>
                            {t('reset.forgot.requestanewone', {
                              defaultValue: 'Request a new one by email',
                            })}
                          </SoftButton>
                        </>
                      )}
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftInput
                        required
                        type={showPassword ? 'text' : 'password'}
                        placeholder={t('form.password.placeholder', {defaultValue: 'Password'})}
                        size="large"
                        autoComplete="new-password"
                        autoFocus={code !== '' && email !== ''}
                        value={newPassword}
                        onChange={(event) => {
                          setResetState((state) => ({...state, newPassword: event.target.value}));
                        }}
                        icon={{
                          component: (
                            <IconButton
                              aria-label={t('form.password.togglevisibility.arialabel', {
                                defaultValue: 'Toggle password visibility',
                              })}
                              sx={{p: 0}}
                              onClick={() => setResetState((state) => ({...state, showPassword: !state.showPassword}))}
                              size="small">
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                          direction: 'right',
                        }}
                        error={state === 'InvalidPasswordException' && error !== undefined}
                      />
                      {state === 'InvalidPasswordException' && error?.message !== undefined && (
                        <SoftTypography variant="body2" fontWeight="bold" color="error">
                          {error.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                    <PasswordChecker
                      password={newPassword}
                      repeatPassword={newPassword}
                      onPasswordInvalid={() => {
                        setResetState((state) => ({...state, invalidPassword: true}));
                      }}
                      onPasswordValid={() => {
                        setResetState((state) => ({...state, invalidPassword: undefined}));
                      }}
                    />
                  </>
                )}
                <SoftBox mt={4} mb={1}>
                  {state === 'loading' ? (
                    <CircularProgress color="info" />
                  ) : codeAsked ? (
                    state !== 'LimitExceededException' && (
                      <SoftButton
                        variant="gradient"
                        color="info"
                        size="large"
                        fullWidth
                        disabled={invalidPassword || code.trim().length === 0}
                        onClick={onSubmitNewPassword}>
                        {t('forgotpassword.savenewpassword.btn', {defaultValue: 'Save new password'})}
                      </SoftButton>
                    )
                  ) : (
                    <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={onAskResetCode}>
                      {t('forgotpassword.verificationcode.askbtn', {defaultValue: 'Request verification code'})}
                    </SoftButton>
                  )}
                </SoftBox>
                <SoftTypography variant="body2" fontWeight="regular" color="text">
                  {t('signin.forgot.backtologin', {defaultValue: '🤦 Wait ! I remember it'})}{' '}
                  {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore */}
                  <SoftButton variant="text" color="info" size="large" component={Link} state={{email}} to="/signin">
                    {t('signin.forgot.signbtn', {defaultValue: 'sign in'})}
                  </SoftButton>
                </SoftTypography>
              </>
            )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
}
