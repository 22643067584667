import {CircularProgress} from '@mui/material';
import {BotInfo, BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo} from 'detect-browser';
import {CustomContentProps, SnackbarContent, useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from 'src/store';

import SoftSnackbarContent from './SoftSnackbarContent';

interface EstablishingConnectionProps extends CustomContentProps {}

const EstablishingConnection = React.forwardRef<HTMLDivElement, EstablishingConnectionProps>(({id}, ref) => {
  const {closeSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const isEstablishingConnection = useAppSelector((state) => state.extension.isEstablishingConnection);
  const isOutdated = useAppSelector((state) => state.extension.isOutdated);
  const version = useAppSelector((state) => state.extension.version);
  const isConnected = useAppSelector((state) => state.extension.isConnected);

  const [browser, setBrowser] = useState<
    BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | undefined
  >();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  useEffect(() => {
    const browser = detect();
    setBrowser(browser ?? undefined);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (isConnected) {
      timer = setTimeout(() => handleDismiss(), 5000);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [isConnected]);

  return (
    <SnackbarContent ref={ref}>
      {isEstablishingConnection ? (
        <SoftSnackbarContent
          color="info"
          icon={<CircularProgress color="inherit" size={20} />}
          title={t('extension.establishingConnection', {defaultValue: "Recherche de l'extension"})}
          content={t('extension.establishingConnectionContent', {
            defaultValue: "L'extension PerfectPost permet d'automatiser la récupération de données depuis LinkedIn.",
          })}
          onClose={handleDismiss}
          bgWhite
          titleColor="info"
          dividerColor={false}
        />
      ) : version === undefined ? (
        <SoftSnackbarContent
          color="error"
          icon="warning"
          title={t(`extension.notfoundtitle`, {defaultValue: 'Extension non trouvé'})}
          content={
            <>
              {browser?.name === 'firefox' ? (
                <span dangerouslySetInnerHTML={{__html: t(`extension.notfound.firefox`)}} />
              ) : browser?.name === 'chrome' ? (
                <span dangerouslySetInnerHTML={{__html: t(`extension.notfound.chrome`)}} />
              ) : browser?.name === 'edge-chromium' ? (
                <span dangerouslySetInnerHTML={{__html: t(`extension.notfound.edge`)}} />
              ) : (
                <span dangerouslySetInnerHTML={{__html: t(`extension.notfound.unknow`)}} />
              )}
            </>
          }
          onClose={handleDismiss}
          bgWhite
          titleColor="info"
          dividerColor={false}
        />
      ) : isOutdated ? (
        <SoftSnackbarContent
          color="warning"
          icon="warning"
          title={t('extension.extensionOutdated', {defaultValue: 'Extension obsolète'})}
          content={t('extension.extensionOutdatedContent', {
            version,
            defaultValue:
              "La mise à jour est automatique, mais vous pouvez la forcer en cliquant sur l'icone de puzzle en haut à droite du navigateur",
          })}
          onClose={handleDismiss}
          bgWhite
          titleColor="info"
          dividerColor={false}
        />
      ) : isConnected === false ? (
        <SoftSnackbarContent
          color="warning"
          icon="warning"
          title={t('extension.extensionInfo', {defaultValue: 'Communication avec l’extension établie'})}
          content={
            <span
              dangerouslySetInnerHTML={{
                __html: t(`extension.notauthenticate`),
              }}
            />
          }
          onClose={handleDismiss}
          bgWhite
          titleColor="info"
          dividerColor={false}
        />
      ) : (
        <SoftSnackbarContent
          color="info"
          icon="verified"
          title={t('extension.extensionInfo', {defaultValue: 'Communication avec l’extension établie'})}
          content={t('extension.extensionInfoContent', {version, defaultValue: 'Version {{version}}'})}
          onClose={handleDismiss}
          bgWhite
          titleColor="info"
          dividerColor={false}
        />
      )}
    </SnackbarContent>
  );
});

export default EstablishingConnection;
