import Quill from 'quill';

import clean from './clean';
import {transform} from './transform';

export default function underline(_quill: Quill | boolean) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const quill: Quill = typeof _quill === 'boolean' ? (this.quill as Quill) : _quill;
  clean(quill);
  transform(quill, [
    {
      action: 'append',
      chars: [863],
    },
  ]);
}
