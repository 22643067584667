import Inline from 'quill/blots/inline';

export type HashtagItem = {
  id: string;
  value: string;
  follower?: number;
};
class Hashtag extends Inline {
  static blotName = 'hashtag';
  static tagName = 'STRONG';
  static className = 'ql-hashtag';

  static create(data?: HashtagItem | string | boolean): HTMLElement {
    const element: HTMLElement = super.create(data);
    if (data === undefined) {
      return element;
    }
    if (typeof data === 'boolean') {
      // does nothing
    } else if (typeof data === 'string') {
      element.textContent = data;
    } else if (data !== undefined && 'value' in data) {
      element.textContent = `#${data.value}`;
      element.setAttribute('data-follower', data.follower ? `${data.follower} followers` : '');
    }
    element.contentEditable = 'false';
    return element;
  }

  static formats() {
    return true;
  }

  static value(domNode: HTMLElement) {
    return domNode.textContent;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optimize(context: {[key: string]: any}) {
    super.optimize(context);
    if (this.domNode.tagName !== Hashtag.tagName) {
      this.replaceWith(Hashtag.blotName);
    }
  }
}

export default Hashtag;
