import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {Dialog, DialogContent, Grid, Card, OutlinedInput, InputAdornment, CircularProgress} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  Team,
  PublicUser,
  CreateTeamSubscriptionStripePostCommand,
  TeamPreviewSubscriptionStripeCommand,
} from '@perfectpost/perfect-post-common';
import {Elements, AddressElement} from '@stripe/react-stripe-js';
import {StripeAddressElementChangeEvent, loadStripe, type Stripe} from '@stripe/stripe-js';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import {localFormat} from 'src/utils/date-format';
import type StripeBackend from 'stripe';
import {useDebouncedCallback} from 'use-debounce';

const stripePromise: Promise<Stripe | null> = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '');

type TeamSubscriptionModalProps = {
  team: Team;
  newPlan: 'team' | 'business';
  currentPlan: 'free' | 'team' | 'business';
  currentMode?: 'monthly' | 'yearly';
  currentPremiumCount?: number;
  address?: StripeBackend.Address;
  handleChangePlan: () => void;
  handleClose: () => void;
};

type TeamSubscriptionModalState = {
  status: 'idle' | 'prefill' | 'loading' | 'error';
  premiumCount: number;
  plan: 'team' | 'business';
  mode: 'monthly' | 'yearly';
  alreadyPremiumUser: number;
  addressElement: StripeAddressElementChangeEvent['value'] | undefined;
};

export default function TeamSubscriptionModal(props: TeamSubscriptionModalProps) {
  const {t} = useTranslation();
  const {team, currentPlan, currentMode, address, currentPremiumCount, handleChangePlan} = props;
  const locale = useAppSelector((state) => state.main.user?.locale);
  const members = useAppSelector((state) => state.team.members);

  const [modalState, setModalState] = useState<TeamSubscriptionModalState>({
    status: 'idle',
    premiumCount: currentPremiumCount ?? 0,
    plan: props.newPlan,
    mode: currentMode ?? 'yearly',
    addressElement: {
      name: team.name,
      address: {
        line1: address?.line1 ?? '',
        line2: address?.line2 ?? null,
        city: address?.city ?? '',
        postal_code: address?.postal_code ?? '',
        state: address?.state ?? '',
        country: address?.country ?? 'FR',
      },
    },
    alreadyPremiumUser: 0,
  });
  const [preview, setPreview] = useState<{
    status?: 'no-change';
    invoice?: StripeBackend.Invoice;
    isDowngrade?: boolean;
    subscription?: {current_period_end: number; current_period_start: number};
  }>({status: 'no-change'});

  const onChangePremiumCount = (count: number) => {
    if (count < 0) {
      count = 0;
    } else if (count > team.users.length) {
      count = team.users.length;
    }
    setModalState((f) => ({
      ...f,
      premiumCount: count,
    }));
  };

  const onBuy = async () => {
    if (modalState.status === 'loading') {
      return;
    }
    setModalState((f) => ({...f, status: 'loading'}));
    const result = await perfectPostServiceClient.send(
      new CreateTeamSubscriptionStripePostCommand({
        teamId: team._id,
        plan: modalState.plan,
        mode: modalState.mode,
        optionPremiumCount: modalState.premiumCount > 0 ? modalState.premiumCount : undefined,
      }),
    );
    if ('url' in result) {
      window.location.href = result.url;
    } else if ('ok' in result) {
      handleChangePlan();
    }
  };

  const onAddressChange = useDebouncedCallback((address: StripeAddressElementChangeEvent) => {
    if (address.complete) {
      setModalState((f) => ({...f, addressElement: address.value}));
    }
  }, 1000);

  useEffect(() => {
    if (modalState.addressElement === undefined) {
      return;
    }
    setModalState((f) => ({...f, status: 'loading'}));
    perfectPostServiceClient
      .send(
        new TeamPreviewSubscriptionStripeCommand({
          teamId: team._id,
          plan: modalState.plan,
          mode: modalState.mode,
          optionPremiumCount: modalState.premiumCount > 0 ? modalState.premiumCount : undefined,
          address: modalState.addressElement,
        }),
      )
      .then((result) => {
        setPreview(result);
        setModalState((f) => ({...f, status: 'idle'}));
      })
      .catch(() => {});
  }, [modalState.mode, modalState.plan, modalState.premiumCount, modalState.addressElement]);

  useEffect(() => {
    const publicUser = team.users
      .map((user) => members.find((member) => member._id === user.user))
      .filter((member): member is PublicUser => member !== undefined);
    const premiumCount = publicUser.reduce((acc, user) => {
      if (user.premium) {
        acc++;
      }
      return acc;
    }, 0);
    setModalState((f) => ({...f, alreadyPremiumUser: premiumCount}));
  }, [team._id]);

  return (
    <Elements stripe={stripePromise}>
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        onClose={props.handleClose}
        fullWidth>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Card sx={{p: 3, mb: 3}}>
                <SoftTypography sx={{mb: 2}} fontWeight="bold">
                  {t('teamsubscriptionmodal.title', {defaultValue: 'Changer de forfait'})}
                </SoftTypography>
                <SoftBox sx={{display: 'flex', flexDirection: 'column', mb: 2}}>
                  <SoftTypography variant="caption" fontWeight="bold" sx={{mb: 1}}>
                    {t('teamsubscriptionmodal.planselectorlabel', {defaultValue: 'Forfait'})}
                  </SoftTypography>
                  <ToggleButtonGroup
                    value={modalState.plan}
                    exclusive
                    fullWidth
                    onChange={(e, c) =>
                      setModalState((f) => ({...f, plan: (c as 'team' | 'business' | undefined) ?? 'business'}))
                    }>
                    <ToggleButton value="business">
                      {t('teamsubscriptionmodal.planselectoroptionbusiness', {defaultValue: 'Entreprise'})}
                    </ToggleButton>
                    <ToggleButton value="team">
                      {t('teamsubscriptionmodal.planselectoroptionteam', {defaultValue: 'Equipe'})}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </SoftBox>
                <SoftBox sx={{display: 'flex', flexDirection: 'column', mb: 2}}>
                  <SoftTypography variant="caption" fontWeight="bold" sx={{mb: 1}}>
                    {t('teamsubscriptionmodal.billingperiodselectorlabel', {defaultValue: 'Période de facturation'})}
                  </SoftTypography>
                  <ToggleButtonGroup
                    value={modalState.mode}
                    exclusive
                    fullWidth
                    onChange={(e, c) =>
                      setModalState((f) => ({...f, mode: (c as 'monthly' | 'yearly' | undefined) ?? 'monthly'}))
                    }>
                    <ToggleButton value="monthly">
                      {t('teamsubscriptionmodal.billingperiodselectoroptionmonthly', {defaultValue: 'Mensuelle'})}
                    </ToggleButton>
                    <ToggleButton value="yearly">
                      {t('teamsubscriptionmodal.billingperiodselectoroptionannual', {defaultValue: 'Annuelle'})}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </SoftBox>
                <SoftBox sx={{display: 'flex', flexDirection: 'column', mb: 2}}>
                  <SoftTypography variant="caption" fontWeight="bold" sx={{mb: 1}}>
                    {t('teamsubscriptionmodal.optionalpremiumlicenses', {
                      defaultValue: 'Nombre de licence premium (option)',
                    })}
                  </SoftTypography>
                  <SoftBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <OutlinedInput
                      type="number"
                      fullWidth
                      value={modalState.premiumCount}
                      onChange={(e) => onChangePremiumCount(parseInt(e.target.value, 10))}
                      sx={{
                        width: 'fit-content !important',
                        height: 'fit-content !important',
                        padding: '12px !important',
                        '& input': {
                          textAlign: 'center',
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          disablePointerEvents={modalState.premiumCount <= 0}
                          onClick={() => onChangePremiumCount(modalState.premiumCount - 1)}
                          sx={{cursor: 'pointer'}}>
                          <RemoveIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment
                          position="end"
                          disablePointerEvents={
                            modalState.premiumCount >= team.users.length - modalState.alreadyPremiumUser
                          }
                          onClick={() => onChangePremiumCount(modalState.premiumCount + 1)}
                          sx={{cursor: 'pointer'}}>
                          <AddIcon />
                        </InputAdornment>
                      }
                    />
                    <SoftTypography variant="caption" sx={{ml: 2}}>
                      {t('teamsubscriptionmodal.optionalpremiumlicenseswarning', {
                        defaultValue:
                          'Pour que les données de vos collaborateurs soient relevées ils doivent tous être premium.',
                      })}
                      <br />
                      <strong>
                        {t('teamsubscriptionmodal.optionalpremiumlicenseswarningcount', {
                          defaultValue: '{{missingPremium}} / {{teamUsersLength}} ne sont pas premium.',
                          missingPremium: team.users.length - modalState.alreadyPremiumUser,
                          teamUsersLength: team.users.length,
                        })}
                      </strong>
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftTypography variant="caption" fontWeight="bold" sx={{mb: 2}}>
                  {t('teamsubscriptionmodal.billinginformation', {
                    defaultValue: 'Information de facturation',
                  })}
                </SoftTypography>
                <SoftBox>
                  {modalState.status !== 'prefill' && (
                    <AddressElement
                      options={{
                        mode: 'billing',
                        display: {name: 'organization'},
                        defaultValues: {
                          name: team.name,
                          address: {
                            line1: modalState.addressElement?.address?.line1,
                            line2: modalState.addressElement?.address?.line2,
                            city: modalState.addressElement?.address?.city,
                            postal_code: modalState.addressElement?.address?.postal_code,
                            country: modalState.addressElement?.address?.country ?? 'FR',
                          },
                        },
                      }}
                      onChange={onAddressChange}
                    />
                  )}
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={5}>
              <SoftTypography sx={{mb: 2}}>
                {t('teamsubscriptionmodal.preview.title', {
                  defaultValue: 'Récapitulatif',
                })}
              </SoftTypography>
              <SoftBox>
                {modalState.status === 'loading' ? (
                  <CircularProgress />
                ) : preview.status === 'no-change' ? (
                  <SoftBox>
                    <SoftTypography variant="body" fontWeight="bold">
                      {t('teamsubscriptionmodal.preview.nochange', {
                        defaultValue: 'Aucun changement',
                      })}
                    </SoftTypography>
                  </SoftBox>
                ) : (
                  <>
                    {preview.invoice && (preview.isDowngrade || currentPlan === 'free') ? (
                      <SoftBox sx={{mb: 2}}>
                        <SoftBox
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <SoftTypography variant="body2" fontWeight="bold">
                            {
                              // t('teamsubscriptionmodal.preview.plan.team', {defaultValue: 'Forfait équipe'})
                              // t('teamsubscriptionmodal.preview.plan.business', {defaultValue: 'Forfait entreprise'})
                              t(`teamsubscriptionmodal.preview.plan.${modalState.plan}`)
                            }
                          </SoftTypography>
                          <SoftTypography variant="caption" fontWeight="bold">
                            {(
                              preview.invoice.lines.data
                                .filter(
                                  (line) =>
                                    line.price?.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_YEARLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_MONTHLY_PRICE_ID,
                                )
                                .reduce((acc, line) => acc + line.amount, 0) / 100
                            ).toLocaleString(locale, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}{' '}
                            €
                          </SoftTypography>
                        </SoftBox>
                        {preview.invoice.lines.data
                          .filter(
                            (line) =>
                              line.price?.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_YEARLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_MONTHLY_PRICE_ID,
                          )
                          .map((line) => (
                            <SoftBox
                              key={line.id}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}>
                              <SoftTypography variant="caption">
                                {t('teamsubscriptionmodal.preview.upgradrefree.invoiceline', {
                                  defaultValue:
                                    '{{unit_amount}}€ par membre par {{interval}} x {{teamUsersLength}} membre',
                                  unit_amount: (line.price?.unit_amount ?? 0) / 100,
                                  teamUsersLength: team.users.length,
                                  interval:
                                    line.price?.recurring?.interval === 'month'
                                      ? t('teamsubscriptionmodal.preview.upgradrefree.invoiceline.interval.month', {
                                          defaultValue: 'mois',
                                        })
                                      : t('teamsubscriptionmodal.preview.upgradrefree.invoiceline.interval.year', {
                                          defaultValue: 'an',
                                        }),
                                })}
                              </SoftTypography>
                              <SoftTypography variant="caption">
                                {((line.amount ?? 0) / 100).toLocaleString(locale, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                                {' €'}
                              </SoftTypography>
                            </SoftBox>
                          ))}
                      </SoftBox>
                    ) : currentPlan !== modalState.plan ? (
                      <SoftBox sx={{mb: 2}}>
                        <SoftBox
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <SoftTypography variant="body2" fontWeight="bold">
                            {
                              // t('teamsubscriptionmodal.preview.changeplanto.team', {defaultValue: 'Changement de plan vers équipe'})
                              // t('teamsubscriptionmodal.preview.changeplanto.business', {defaultValue: 'Changement de plan vers entreprise'})
                              t(`teamsubscriptionmodal.preview.changeplanto.${modalState.plan}`)
                            }
                          </SoftTypography>
                          <SoftTypography variant="caption" fontWeight="bold">
                            {(
                              (
                                preview.invoice?.lines.data.filter(
                                  (line) =>
                                    line.price?.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_YEARLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_MONTHLY_PRICE_ID,
                                ) ?? []
                              ).reduce((acc, line) => acc + line.amount, 0) / 100
                            ).toLocaleString(locale, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}{' '}
                            €
                          </SoftTypography>
                        </SoftBox>
                        {preview.invoice?.lines.data
                          .filter(
                            (line) =>
                              line.price?.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_YEARLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_MONTHLY_PRICE_ID,
                          )
                          .map((line) => (
                            <SoftBox
                              key={line.id}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}>
                              <SoftTypography variant="caption">
                                {line.amount < 0 ? (
                                  <>
                                    {
                                      // t('teamsubscriptionmodal.preview.changeplan.invoiceline.team.unused', {defaultValue: 'Temps inutilisé avec plan équipe'})
                                      // t('teamsubscriptionmodal.preview.changeplan.invoiceline.business.unused', {defaultValue: 'Temps inutilisé avec plan entreprise'})
                                      t(
                                        `teamsubscriptionmodal.preview.changeplan.invoiceline.${
                                          line.price?.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                                          line.price?.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID
                                            ? 'team'
                                            : 'business'
                                        }.unused`,
                                      )
                                    }
                                  </>
                                ) : (
                                  <>
                                    {
                                      // t('teamsubscriptionmodal.preview.changeplan.invoiceline.team.remaining', {defaultValue: 'Temps restant avec plan équipe'})
                                      // t('teamsubscriptionmodal.preview.changeplan.invoiceline.business.remaining', {defaultValue: 'Temps restant avec plan entreprise'})
                                      t(
                                        `teamsubscriptionmodal.preview.changeplan.invoiceline.${
                                          line.price?.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                                          line.price?.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID
                                            ? 'team'
                                            : 'business'
                                        }.remaining`,
                                      )
                                    }
                                  </>
                                )}
                              </SoftTypography>
                              <SoftTypography variant="caption">
                                {((line.amount ?? 0) / 100).toLocaleString(locale, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                                {' €'}
                              </SoftTypography>
                            </SoftBox>
                          ))}
                      </SoftBox>
                    ) : currentMode !== modalState.mode ? (
                      <SoftBox sx={{mb: 2}}>
                        <SoftBox
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <SoftTypography variant="body2" fontWeight="bold">
                            {t('teamsubscriptionmodal.preview.billingperiodchange', {
                              defaultValue: 'Changement de prériode de facturation',
                            })}
                          </SoftTypography>
                          <SoftTypography variant="caption" fontWeight="bold">
                            {(
                              (
                                preview.invoice?.lines.data.filter(
                                  (line) =>
                                    line.price?.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_YEARLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_MONTHLY_PRICE_ID,
                                ) ?? []
                              ).reduce((acc, line) => acc + line.amount, 0) / 100
                            ).toLocaleString(locale, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}{' '}
                            €
                          </SoftTypography>
                        </SoftBox>
                        {preview.invoice?.lines.data
                          .filter(
                            (line) =>
                              line.price?.id === process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_YEARLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_ENTERPRISE_MONTHLY_PRICE_ID,
                          )
                          .map((line) => (
                            <SoftBox
                              key={line.id}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}>
                              <SoftTypography variant="caption">
                                {line.amount < 0 ? (
                                  <>
                                    {
                                      // t('teamsubscriptionmodal.preview.changeperiod.invoiceline.monthly.unused', {defaultValue: 'Temps inutilisé en mensuel'})
                                      // t('teamsubscriptionmodal.preview.changeperiod.invoiceline.yearly.unused', {defaultValue: 'Temps inutilisé en annuel'})
                                      t(
                                        `teamsubscriptionmodal.preview.changeperiod.invoiceline.${currentMode ?? 'yearly'}.unused`,
                                      )
                                    }
                                  </>
                                ) : (
                                  <>
                                    {
                                      // t('teamsubscriptionmodal.preview.changeperiod.invoiceline.monthly.remaining', {defaultValue: 'Temps restant en mensuel'})
                                      // t('teamsubscriptionmodal.preview.changeperiod.invoiceline.yearly.remaining', {defaultValue: 'Temps restant en annuel'})
                                      t(
                                        `teamsubscriptionmodal.preview.changeperiod.invoiceline.${modalState.mode}.remaining`,
                                      )
                                    }
                                  </>
                                )}
                              </SoftTypography>
                              <SoftTypography variant="caption">
                                {((line.amount ?? 0) / 100).toLocaleString(locale, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                                {' €'}
                              </SoftTypography>
                            </SoftBox>
                          ))}
                      </SoftBox>
                    ) : null}
                    {((currentPremiumCount === 0 || currentPremiumCount === undefined) &&
                      modalState.premiumCount > 0) ||
                    modalState.premiumCount === currentPremiumCount ? (
                      <SoftBox sx={{mb: 2}}>
                        <SoftBox
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <SoftTypography variant="body2" fontWeight="bold">
                            {t('teamsubscriptionmodal.preview.premiumlicenses.title', {
                              defaultValue: 'Licence premium',
                            })}
                          </SoftTypography>
                          <SoftTypography variant="caption" fontWeight="bold">
                            {(
                              (
                                preview.invoice?.lines.data.filter(
                                  (line) =>
                                    line.price?.id === process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_ID,
                                ) ?? []
                              ).reduce((acc, line) => acc + line.amount, 0) / 100
                            ).toLocaleString(locale, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}{' '}
                            €
                          </SoftTypography>
                        </SoftBox>
                        {preview.invoice?.lines.data
                          .filter(
                            (line) =>
                              line.price?.id === process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_ID,
                          )
                          .map((line) => (
                            <SoftBox
                              key={line.id}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}>
                              <SoftTypography variant="caption">
                                {t('teamsubscriptionmodal.preview.premiumlicenses.description', {
                                  defaultValue: '{{cost}} € par membre par {{interval}} x {{quantity}} licence',
                                  cost: ((line.price?.unit_amount ?? 0) / 100).toLocaleString(locale, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }),
                                  interval:
                                    line.price?.recurring?.interval === 'month'
                                      ? t('teamsubscriptionmodal.preview.upgradrefree.invoiceline.interval.month', {
                                          defaultValue: 'mois',
                                        })
                                      : t('teamsubscriptionmodal.preview.upgradrefree.invoiceline.interval.year', {
                                          defaultValue: 'an',
                                        }),
                                  quantity: line.quantity,
                                })}
                              </SoftTypography>
                              <SoftTypography variant="caption">
                                {((line.amount ?? 0) / 100).toLocaleString(locale, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                                {' €'}
                              </SoftTypography>
                            </SoftBox>
                          ))}
                      </SoftBox>
                    ) : modalState.premiumCount > (currentPremiumCount ?? 0) ? (
                      <SoftBox sx={{mb: 2}}>
                        <SoftBox
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <SoftTypography variant="body2" fontWeight="bold">
                            {t('teamsubscriptionmodal.preview.premiumlicenses.addpremium', {
                              defaultValue: 'Ajout de membres premium',
                              count: modalState.premiumCount - (currentPremiumCount ?? 0),
                            })}
                          </SoftTypography>
                          <SoftTypography variant="caption" fontWeight="bold">
                            {(
                              (
                                preview.invoice?.lines.data.filter(
                                  (line) =>
                                    line.price?.id === process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_ID ||
                                    line.price?.id === process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_ID,
                                ) ?? []
                              ).reduce((acc, line) => acc + line.amount, 0) / 100
                            ).toLocaleString(locale, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}{' '}
                            €
                          </SoftTypography>
                        </SoftBox>
                        {preview.invoice?.lines.data
                          .filter(
                            (line) =>
                              line.price?.id === process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_ID ||
                              line.price?.id === process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_ID,
                          )
                          .map((line) => (
                            <SoftBox
                              key={line.id}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}>
                              <SoftTypography variant="caption">
                                {line.amount < 0 ? (
                                  <>
                                    {t('teamsubscriptionmodal.preview.premiumlicenses.invoiceline.unused', {
                                      defaultValue: 'Temps inutilisé {{quantity}} x licence premium',
                                      quantity: line.quantity,
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {t('teamsubscriptionmodal.preview.premiumlicenses.invoiceline.remaining', {
                                      defaultValue: 'Temps restant {{quantity}} x licence premium',
                                      quantity: line.quantity,
                                    })}
                                  </>
                                )}
                              </SoftTypography>
                              <SoftTypography variant="caption">
                                {((line.amount ?? 0) / 100).toLocaleString(locale, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                                {' €'}
                              </SoftTypography>
                            </SoftBox>
                          ))}
                      </SoftBox>
                    ) : modalState.premiumCount < (currentPremiumCount ?? 0) ? (
                      <></>
                    ) : null}
                    {preview.isDowngrade && (
                      <SoftBox sx={{mb: 2}}>
                        <SoftTypography variant="caption" fontWeight="bold">
                          {t('teamsubscriptionmodal.preview.downgrade.info', {
                            defaultValue: 'Cette modification sera effective sur votre abonnement le {{date}}.',
                            date: localFormat(
                              new Date(
                                (preview.invoice?.next_payment_attempt
                                  ? preview.invoice.next_payment_attempt
                                  : (preview.subscription?.current_period_end ?? 0)) * 1000,
                              ),
                              'dd/MM/yyyy',
                              locale,
                            ),
                          })}
                        </SoftTypography>
                      </SoftBox>
                    )}
                    {preview.invoice?.total_excluding_tax && (
                      <SoftBox
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          borderTop: '1px solid rgb(214, 216, 231)',
                          pt: 2,
                        }}>
                        <SoftTypography variant="caption">
                          {t('teamsubscriptionmodal.preview.subtotal.label', {defaultValue: 'Total'})}
                        </SoftTypography>
                        <SoftTypography variant="caption">
                          {(preview.invoice.total_excluding_tax / 100).toLocaleString(locale, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}{' '}
                          €
                        </SoftTypography>
                      </SoftBox>
                    )}
                    {preview.invoice?.tax && (
                      <SoftBox
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          pt: 1,
                        }}>
                        <SoftTypography variant="caption">
                          {t('teamsubscriptionmodal.preview.subtotal.taxe', {defaultValue: 'TVA'})}
                        </SoftTypography>
                        <SoftTypography variant="caption">
                          {(preview.invoice.tax / 100).toLocaleString(locale, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}{' '}
                          €
                        </SoftTypography>
                      </SoftBox>
                    )}
                    <SoftBox display="flex" flexDirection="row" justifyContent="space-between" pt={2}>
                      <SoftTypography variant="body" fontWeight="bold">
                        {t('teamsubscriptionmodal.preview.subtotal.paynow', {defaultValue: 'A payer maintenant'})}
                      </SoftTypography>

                      <SoftTypography variant="body" fontWeight="bold">
                        {preview.isDowngrade
                          ? 0
                          : ((preview.invoice?.total ?? 0) / 100).toLocaleString(locale, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}{' '}
                        €
                      </SoftTypography>
                    </SoftBox>
                    {preview.isDowngrade && (
                      <SoftBox display="flex" flexDirection="row" justifyContent="space-between" pt={2}>
                        <SoftTypography variant="body" fontWeight="bold">
                          {t('teamsubscriptionmodal.preview.downgrade.nextinvoice', {
                            defaultValue: 'Prochaine facture le {{date}}',
                            date: localFormat(
                              new Date(
                                (preview.invoice?.next_payment_attempt
                                  ? preview.invoice.next_payment_attempt
                                  : (preview.subscription?.current_period_end ?? 0)) * 1000,
                              ),
                              'dd/MM/yyyy',
                              locale,
                            ),
                          })}
                        </SoftTypography>
                        <SoftTypography variant="body" fontWeight="bold">
                          {((preview.invoice?.total ?? 0) / 100).toLocaleString(locale, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}{' '}
                          €
                        </SoftTypography>
                      </SoftBox>
                    )}
                    <SoftButton
                      sx={{mt: 2}}
                      fullWidth
                      color="info"
                      onClick={onBuy}
                      disabled={preview?.invoice === undefined || preview?.status === 'no-change'}>
                      {preview.status === 'no-change'
                        ? t('teamsubscriptionmodal.preview.paybutton.nochange', {defaultValue: 'Aucun changement'})
                        : preview.isDowngrade
                          ? t('teamsubscriptionmodal.preview.paybutton.schedulechange', {
                              defaultValue: 'Programmer le changement de plan',
                            })
                          : t('teamsubscriptionmodal.preview.paybutton.ordernow', {defaultValue: 'Passer la commande'})}
                    </SoftButton>
                  </>
                )}
              </SoftBox>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Elements>
  );
}
