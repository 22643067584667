import {Dialog, DialogTitle, DialogContent, DialogActions, Grid, CircularProgress} from '@mui/material';
import {GetActivityCommand, UpdateGoalsActivityCommand} from '@perfectpost/perfect-post-common';
import {compareDesc, endOfToday, parseISO, startOfYesterday} from 'date-fns';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {perfectPostServiceClient} from 'src/services';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';

import TipCard from '../tipscard/TipCards';

type ActivityParamsModalProps = {
  handleClose: () => void;
  handleConfirm: () => void;
};

export default function ActivityParamsModal(props: ActivityParamsModalProps) {
  const {handleClose, handleConfirm} = props;
  const {t} = useTranslation();

  const [state, setState] = useState<'idle' | 'loading'>('loading');
  const [form, setForm] = useState({like: 0, comment: 0});
  useEffect(() => {
    perfectPostServiceClient
      .send(
        new GetActivityCommand({
          begin: startOfYesterday(),
          end: endOfToday(),
        }),
      )
      .then((activities) => {
        const sort = activities.sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        const params = sort.find((a) => a.objectifComment !== undefined);
        setForm({
          like: params?.objectifLike ?? 10,
          comment: params?.objectifComment ?? 3,
        });
        setState('idle');
      });
  }, []);

  const onSave = async () => {
    setState('loading');
    await perfectPostServiceClient.send(
      new UpdateGoalsActivityCommand({
        objectifLike: form.like,
        objectifComment: form.comment,
      }),
    );
    handleConfirm();
  };

  return (
    <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>
        <SoftTypography>{t('activity.titlesettings')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <TipCard informationkey="modalactivity" />
        <Grid sx={{mb: 6}} container spacing={2}>
          <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                {t('global.like', {defaultValue: 'Like'})}
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="number"
              disabled={state === 'loading'}
              value={form.like}
              onChange={(e) => setForm({...form, like: Number.parseInt(e.target.value, 10)})}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                {t('global.comment', {defaultValue: 'Comment'})}
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="number"
              disabled={state === 'loading'}
              value={form.comment}
              onChange={(e) => setForm({...form, comment: Number.parseInt(e.target.value, 10)})}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={handleClose} autoFocus>
          {t('global.cancel', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton variant="gradient" onClick={onSave} disabled={state === 'loading'}>
          {state === 'loading' ? <CircularProgress color="info" /> : t('global.save')}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
