import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, {HttpBackendOptions} from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    // Standard language used
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'fr'],
    debug: false,
    returnNull: false,
    //Detects and caches a cookie from the language provided
    detection: {
      order: ['querystring', 'cookie', 'navigator'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
  });

i18n.on('languageChanged', (lng) => {
  console.log('Language changed to: ', lng);
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
