import Quill from 'quill';
import Module from 'quill/core/module';

export default class PPSeemore extends Module {
  private seeMore: HTMLDivElement;
  constructor(quill: Quill) {
    super(quill);
    this.seeMore = document.createElement('div');
    this.seeMore.className = 'pp-seemore-overlay';

    const seeMoreButton = document.createElement('button');
    seeMoreButton.className = 'see-more';
    seeMoreButton.type = 'button';

    const span = document.createElement('span');
    span.className = 'see-more-text';
    span.textContent = '…see more';

    seeMoreButton.append(span);
    this.seeMore.append(seeMoreButton);

    quill.root.parentElement?.append(this.seeMore);
  }
}
