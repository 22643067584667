import {Grid, MenuItem, Menu} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import {
  GetOneDraftCommand,
  GetOneUserCommand,
  GetUrlSharingDraftCommand,
  Organization,
  PublicUser,
  PublishDraftCommand,
  RemoveSharingDraftCommand,
} from '@perfectpost/perfect-post-common';
import {Draft, MulitpleAssets, Poll, SingleAssets} from '@perfectpost/perfect-post-common';
import {isFuture, isPast, parseISO} from 'date-fns';
import React from 'react';
import {useState, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, useNavigate} from 'react-router-dom';
import noPic from 'src/assets/images/nopic.png';
import ActivityPreview from 'src/components/activitypreview/ActivityPreview';
import DraftChatBox from 'src/components/draftchatbox/DraftChatBox';
import ImportImageModal from 'src/components/modals/ImportImageModal';
import ImportPDFModal from 'src/components/modals/ImportPDFModal';
import ImportPollModal from 'src/components/modals/ImportPollModal';
import ImportVideoModal from 'src/components/modals/ImportVideoModal';
import ScheduleDialog from 'src/components/modals/ScheduleDialog';
import ShareDraftModal from 'src/components/modals/ShareDraftModal';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {cancelDraft, editDraft, removeFirstComment} from 'src/store/draftslice';
import SoftAlert from 'src/theme/components/SoftAlert';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftEditor from 'src/theme/components/SoftEditor';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
import {localFormatISOString} from 'src/utils/date-format';
import {sanitizer} from 'src/utils/sanitizer';
import {useDebouncedCallback} from 'use-debounce';

type FormState = {
  content?: string;
  firstComment: string | undefined;
  programmingDate: string | undefined;
  tag: 'draft' | 'scheduled' | 'idea' | 'ready' | 'published' | undefined;
  sharing: SingleAssets | MulitpleAssets | Poll | undefined;
  characterCountExceed: boolean;
  owner: PublicUser | Organization | undefined;
  authorPremium: boolean;
  authorAllowPublish: boolean;
  canWrite: boolean;
  state:
    | 'idle'
    | 'loading'
    | 'saving'
    | 'saved'
    | 'published'
    | 'showRemoveDialog'
    | 'showScheduleDialog'
    | 'showImportImageModal'
    | 'showImportVideoModal'
    | 'showImportPDFModal'
    | 'showImportPollModal'
    | 'showSharingDraftModal'
    | 'searching';
};

function isPublicUser(entity: PublicUser | Organization | undefined): entity is PublicUser {
  return entity !== undefined && 'vanityName' in entity && !('users' in entity);
}

export default function SharedDraft() {
  const {id} = useParams();
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();
  useDocumentTitle(t('draft.documenttitle', {defaultValue: 'Draft'}));
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.main.user);
  const teams = useAppSelector((state) => state.team.teams);
  const members = useAppSelector((state) => state.team.members);
  const userPremium = useAppSelector((state) => state.main.premium);
  const extensionInstalled = useAppSelector((state) => state.extension.version !== undefined);

  const [draft, setDraft] = useState<Draft | undefined>(undefined);
  const [form, setForm] = useState<FormState>({
    content: id === undefined ? '' : draft?.content ? sanitizer(draft.content) : undefined,
    firstComment: draft?.firstComment,
    owner: user,
    authorPremium: false,
    authorAllowPublish: false,
    programmingDate: draft?.programmingDate,
    tag: draft?.tag,
    sharing: undefined as SingleAssets | MulitpleAssets | Poll | undefined,
    characterCountExceed: false,
    canWrite: false,
    state: 'idle' as
      | 'idle'
      | 'loading'
      | 'saving'
      | 'saved'
      | 'published'
      | 'showRemoveDialog'
      | 'showScheduleDialog'
      | 'showImportImageModal'
      | 'showImportVideoModal'
      | 'showImportPDFModal'
      | 'showImportPollModal'
      | 'showSharingDraftModal'
      | 'searching',
  });
  const [memberSelector, setMemberSelector] = useState<(PublicUser | Organization)[]>(user !== undefined ? [user] : []);

  const formSaveActionState = useMemo(() => {
    if (draft?.linkedin && form.authorPremium === false) {
      return 'linkedin';
    } else if (form.characterCountExceed) {
      return 'sizeexceed';
    }
  }, [form.characterCountExceed, draft?.linkedin, form.authorPremium]);

  const debouncedSave = useDebouncedCallback(() => {
    onSave();
  }, 1000);

  useEffect(() => {
    if (id === undefined) {
      return;
    } else {
      setForm({...form, state: 'loading'});
      perfectPostServiceClient.send(new GetOneDraftCommand(id)).then(async (draft) => {
        setDraft(draft);
        let canWrite: boolean =
          draft.proofreaders?.some((p) => user && p.user?._id === user._id && p.role === 'readwrite') ||
          draft.user === user?._id;

        if (canWrite === false) {
          canWrite =
            draft.proofreaders?.some((p) => p.role === 'readwrite' && teams.some((t) => t._id === p.team)) ?? false;
        }
        if (canWrite === false) {
          canWrite = teams.some((t) =>
            t.users.some(
              (u) =>
                u.user === draft.user &&
                u.privacy &&
                (u.privacy.draft === 'write' ||
                  (u.privacy.scheduled === 'write' && draft.programmingDate !== undefined && draft.urn === undefined)),
            ),
          );
        }
        const authorUser = await perfectPostServiceClient.send(new GetOneUserCommand({id: draft.user}));
        const authorAllowPublish = teams.find((t) =>
          t.users.some((u) => u.user === authorUser._id && u.privacy?.publish === true),
        );
        setForm({
          content: sanitizer(draft.content),
          owner: authorUser,
          authorPremium: authorUser.premium ?? false,
          authorAllowPublish: authorAllowPublish !== undefined,
          firstComment: draft.firstComment,
          programmingDate: draft.programmingDate,
          tag: draft.tag,
          sharing: undefined,
          characterCountExceed: false,
          state: 'idle',
          canWrite,
        });
        const sharing = draft.sharing;
        if (sharing !== undefined && (sharing.type === 'image' || sharing.type === 'video' || sharing.type === 'pdf')) {
          perfectPostServiceClient.send(new GetUrlSharingDraftCommand(draft._id)).then(({url}) => {
            setForm((f) => ({
              ...f,
              sharing: {
                ...sharing,
                url,
              },
            }));
          });
        } else if (sharing !== undefined && sharing.type === 'poll') {
          setForm((f) => ({
            ...f,
            sharing,
          }));
        } else if (draft.sharing === undefined && form.sharing !== undefined) {
          setForm((f) => ({
            ...f,
            sharing: undefined,
          }));
        }
      });
    }
  }, [id, teams, user]);

  useEffect(() => {
    const member = new Set<PublicUser | Organization>();
    if (user !== undefined) {
      member.add(user);
    }
    if (members !== undefined && teams !== undefined) {
      const canWrite = members.filter((member) => {
        return teams.some((team) =>
          team.users.some((u) => u.user !== user?._id && u.user === member._id && u.privacy?.draft === 'write'),
        );
      });
      canWrite.forEach((item) => member.add(item));
    }
    setMemberSelector([...member]);
  }, [draft?.user, members]);

  const onChange = (value: string) => {
    setForm((prev) => ({...prev, content: value, state: 'idle'}));
    debouncedSave();
  };

  const onProgrammingDate = async (date: Date): Promise<void> => {
    if (draft !== undefined) {
      setForm((f) => ({...f, state: 'saving'}));
      await dispatch(
        editDraft({
          draftId: draft._id,
          programmingDate: date.toISOString(),
          organization: form.owner === undefined || isPublicUser(form.owner) ? undefined : form.owner._id,
        }),
      );
      setForm((f) => ({...f, state: 'idle'}));
    } else {
      return Promise.reject('draft is undefined');
    }
  };

  const onCancelProgramming = async () => {
    if (draft !== undefined && draft.programmingDate && isFuture(parseISO(draft.programmingDate))) {
      setForm((f) => ({...f, state: 'saving'}));
      await dispatch(cancelDraft(draft));
      setForm((f) => ({...f, state: 'idle'}));
    }
  };

  const onSave = async () => {
    if (form.canWrite === false) {
      return;
    }
    const isLinkedInPost = draft?.linkedin !== undefined && draft?.urn !== undefined;
    if (isLinkedInPost || form.state === 'searching' || form.content === undefined) {
      return;
    }
    setForm((prevForm) => ({...prevForm, state: 'saving'}));
    if (draft !== undefined) {
      const programmingDate = draft.programmingDate ? parseISO(draft.programmingDate) : undefined;
      await dispatch(
        editDraft({
          draftId: draft._id,
          content: sanitizer(form.content),
          firstComment: form.firstComment,
          programmingDate: programmingDate?.toISOString() ?? undefined,
          person: form.owner?._id,
        }),
      ).unwrap();
      if (isPublicUser(form.owner) && form.owner._id === user?._id && location.pathname.startsWith('/publish/shared')) {
        navigate(`/publish/draft/${draft._id}`);
      }
    }
    setForm((prevForm) => ({...prevForm, state: 'saved'}));
  };

  const onPublish = async () => {
    if (draft) {
      setForm((f) => ({...f, state: 'saving'}));
      await perfectPostServiceClient.send(new PublishDraftCommand(draft._id));
      setForm((f) => ({...f, state: 'published'}));
    }
  };

  const onMediaUploaded = (newDraft: Draft) => {
    if (id) {
      setForm((prevForm) => ({...prevForm, state: 'loading'}));
      perfectPostServiceClient.send(new GetOneDraftCommand(id)).then(setDraft);
      perfectPostServiceClient.send(new GetUrlSharingDraftCommand(id)).then(({url}) => {
        const newsharing = newDraft.sharing as SingleAssets;
        setForm((prevForm) => ({
          ...prevForm,
          state: 'idle',
          sharing: {
            ...newsharing,
            url,
          },
        }));
      });
    } else {
      setForm((prevForm) => ({...prevForm, state: 'idle'}));
    }
  };

  const onPDFUploaded = (newDraft: Draft) => {
    if (id) {
      setForm((prevForm) => ({...prevForm, state: 'loading'}));
      perfectPostServiceClient.send(new GetOneDraftCommand(id)).then(setDraft);
      perfectPostServiceClient.send(new GetUrlSharingDraftCommand(id)).then(({url}) => {
        const newsharing = newDraft.sharing as SingleAssets;
        setForm((prevForm) => ({
          ...prevForm,
          state: 'idle',
          sharing: {
            ...newsharing,
            url,
          },
        }));
      });
    } else {
      setForm((prevForm) => ({...prevForm, state: 'idle'}));
    }
  };

  const onPollChange = (newDraft: Draft) => {
    setForm((f) => ({
      ...f,
      state: 'idle',
      sharing: newDraft.sharing,
    }));
  };

  const onRemoveSharing = async () => {
    if (draft?.sharing) {
      setForm((f) => ({...f, state: 'loading'}));
      await onSave();
      await perfectPostServiceClient.send(new RemoveSharingDraftCommand(draft._id));
      const d = await perfectPostServiceClient.send(new GetOneDraftCommand(draft._id));
      setDraft(d);
      setForm((f) => ({...f, state: 'idle', sharing: undefined}));
    }
  };

  const [anchorElEntity, setAnchorElEntity] = useState<null | HTMLElement>(null);
  const openEntity = Boolean(anchorElEntity);
  const handleClickEntity = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElEntity(event.currentTarget);
  };
  const handleCloseEntity = (entity: PublicUser | Organization) => () => {
    setForm((f) => ({...f, owner: entity}));
    debouncedSave();
    setAnchorElEntity(null);
  };

  const onAddComment = () => {
    setForm({...form, firstComment: ''});
  };

  const onCommentChange = (value: string) => {
    setForm((prev) => ({...prev, firstComment: value, state: 'idle'}));
    debouncedSave();
  };

  const onRemoveComment = async () => {
    if (id) {
      setForm({...form, firstComment: undefined});
      await dispatch(removeFirstComment(id));
    }
  };

  return (
    <SoftBox>
      {form.state === 'showImportImageModal' ? (
        <ImportImageModal
          draftId={id}
          content={form.content}
          sharing={form?.sharing?.type === 'image' ? form.sharing : undefined}
          handleClose={() => setForm({...form, state: 'idle'})}
          handleConfirm={onMediaUploaded}
        />
      ) : form.state === 'showImportVideoModal' ? (
        <ImportVideoModal
          draftId={id}
          content={form.content}
          sharing={form?.sharing?.type === 'video' ? form.sharing : undefined}
          handleClose={() => setForm({...form, state: 'idle'})}
          handleConfirm={onMediaUploaded}
        />
      ) : form.state === 'showImportPDFModal' ? (
        <ImportPDFModal
          draftId={id}
          content={form.content}
          sharing={form?.sharing?.type === 'pdf' ? form.sharing : undefined}
          handleClose={() => setForm({...form, state: 'idle'})}
          handleConfirm={onPDFUploaded}
        />
      ) : form.state === 'showImportPollModal' ? (
        <ImportPollModal
          draftId={id}
          content={form.content}
          sharing={form?.sharing?.type === 'poll' ? form.sharing : undefined}
          handleClose={() => setForm({...form, state: 'idle'})}
          handleConfirm={onPollChange}
        />
      ) : form.state === 'showSharingDraftModal' && draft !== undefined ? (
        <ShareDraftModal draft={draft} handleClose={() => setForm((f) => ({...f, state: 'idle'}))} />
      ) : form.state === 'showScheduleDialog' ? (
        <ScheduleDialog
          handleClose={() => setForm((f) => ({...f, state: 'idle'}))}
          handleSchedule={onProgrammingDate}
        />
      ) : undefined}
      <SoftBox
        mb={3}
        p={1}
        sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <SoftBox>
          <SoftButton
            aria-haspopup="true"
            startIcon={
              <SoftBox sx={{flexShrink: 0, display: 'flex', position: 'relative', width: 30, height: 30}}>
                <SoftBox
                  component="img"
                  src={
                    form.owner === undefined
                      ? noPic
                      : isPublicUser(form.owner)
                        ? (form.owner.lnPicture ?? noPic)
                        : (form.owner.picture ?? noPic)
                  }
                  sx={{
                    objectFix: 'contain',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    objectPosition: 'center',
                    backgroundPosition: '50%',
                    borderRadius: '50%',
                  }}
                />
              </SoftBox>
            }
            onClick={handleClickEntity}
            endIcon={<Icon>keyboard_arrow_down</Icon>}>
            {isPublicUser(form.owner) ? (
              <span className="ph1">
                {form.owner.firstname} {form.owner.lastname}
              </span>
            ) : (
              <span className="ph1">{form.owner?.localizedName}</span>
            )}
          </SoftButton>

          {user !== undefined && (
            <Menu
              anchorEl={anchorElEntity}
              open={openEntity}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              {memberSelector.map((entity) => (
                <MenuItem
                  key={entity._id}
                  onClick={handleCloseEntity(entity)}
                  sx={{
                    cursor: !isPublicUser(entity) && form.authorPremium !== true ? 'not-allowed' : undefined,
                    opacity: !isPublicUser(entity) && form.authorPremium !== true ? '50%' : undefined,
                  }}>
                  <SoftBox sx={{flexShrink: 0, display: 'flex', position: 'relative', width: 30, height: 30, mr: 1}}>
                    <SoftBox
                      component="img"
                      src={isPublicUser(entity) ? (entity.lnPicture ?? noPic) : (entity.picture ?? noPic)}
                      sx={{
                        objectFix: 'contain',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        objectPosition: 'center',
                        backgroundPosition: '50%',
                        borderRadius: '50%',
                      }}
                    />
                  </SoftBox>
                  {isPublicUser(entity) ? `${entity.firstname} ${entity.lastname}` : entity.localizedName}
                </MenuItem>
              ))}
            </Menu>
          )}
          {form.state === 'saving' && (
            <SoftTypography variant="title" sx={{ml: 2}}>
              {t('draft.save')}
            </SoftTypography>
          )}
          {form.state === 'saved' && (
            <SoftTypography variant="title" sx={{ml: 2, textTransform: 'lowercase'}}>
              {t('draft.saved')} <Icon color="success">check</Icon>
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {draft?.programmingDate && (
            <SoftTypography fontWeight="bold" mr={1}>
              {t('draftcard.scheduledat', {
                date: localFormatISOString(draft.programmingDate, 'dd/MM/yyyy HH:mm', user?.locale),
                defaultValue: 'Publication scheduled for {{date}}',
              })}
            </SoftTypography>
          )}
          {formSaveActionState === 'sizeexceed' && (
            <SoftTypography color="error">{t('draft.sizeexceed')}</SoftTypography>
          )}
          {user && id && form.owner?._id === user?._id && (
            <>
              <SoftButton iconOnly onClick={() => setForm({...form, state: 'showSharingDraftModal'})}>
                <Icon>share</Icon>
              </SoftButton>
            </>
          )}
          {draft?.urn && draft.programmingDate && isPast(parseISO(draft.programmingDate)) && (
            <SoftButton
              color="dark"
              LinkComponent="a"
              sx={{ml: 1}}
              href={`https://www.linkedin.com/feed/update/${draft.urn}`}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target="_blank">
              {t('onecontent.seepublicationlinkedin')}
            </SoftButton>
          )}
          {draft?.urn && draft.linkedin === undefined && (
            <SoftButton
              fullWidth
              color="dark"
              LinkComponent="a"
              sx={{ml: 1}}
              href={`https://www.linkedin.com/feed/update/${draft.urn}`}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target="_blank">
              {t('onecontent.seepublicationlinkedin')}
            </SoftButton>
          )}
          {form.authorAllowPublish &&
            form.characterCountExceed === false &&
            draft?.tag !== 'published' &&
            draft?.tag !== 'scheduled' && (
              <SoftButton
                variant="gradient"
                color="dark"
                sx={{ml: 1}}
                disabled={form.state === 'saving'}
                startIcon={form.state === 'saving' ? <CircularProgress size={12} color="info" /> : undefined}
                onClick={onPublish}>
                {t('draft.postonlinkedin', {defaultValue: 'Publish'})}
              </SoftButton>
            )}
          {form.authorAllowPublish &&
            draft?.tag === 'scheduled' &&
            draft?.programmingDate &&
            isFuture(parseISO(draft.programmingDate)) && (
              <SoftBox>
                <SoftButton
                  variant="gradient"
                  color="warning"
                  disabled={form.state === 'saving' || (draft?.linkedin !== undefined && extensionInstalled === false)}
                  startIcon={form.state === 'saving' ? <CircularProgress size={12} color="info" /> : undefined}
                  sx={{ml: 1}}
                  onClick={onCancelProgramming}>
                  {t('draft.unschedule', {defaultValue: 'Cancel scheduled send'})}
                </SoftButton>
              </SoftBox>
            )}
          {form.authorAllowPublish &&
            form.characterCountExceed === false &&
            draft?.urn === undefined &&
            draft?.tag !== 'scheduled' && (
              <SoftButton
                variant="gradient"
                color="dark"
                disabled={form.state === 'saving'}
                startIcon={form.state === 'saving' ? <CircularProgress size={12} color="info" /> : undefined}
                sx={{ml: 1}}
                onClick={() => setForm((f) => ({...f, state: 'showScheduleDialog'}))}>
                {t('draft.schedule', {defaultValue: 'Schedule'})}
              </SoftButton>
            )}
        </SoftBox>
      </SoftBox>
      <Grid container spacing={2}>
        {draft && (
          <>
            {draft.linkedin && (
              <Grid item xs={12}>
                <SoftAlert>{form.authorPremium ? t('draft.linkedininfo') : t('draft.linkedinpreview')}</SoftAlert>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12} sm={6}>
          <SoftBox display="flex" flexDirection="column">
            {form.content !== undefined && (
              <SoftEditor
                initialValue={form.content}
                premium={userPremium}
                onChange={onChange}
                onCharactereCountExceed={(b) => {
                  setForm((prev) => ({...prev, characterCountExceed: b}));
                }}
                readOnly={form.canWrite === false || draft?.linkedin !== undefined}
                mention
              />
            )}
            <Grid container spacing={3} sx={{mt: 1}}>
              {draft?.linkedin === undefined && form.canWrite && (
                <Grid
                  item
                  xs={12}
                  sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', columnGap: 1}}>
                  {draft?.sharing === undefined ? (
                    <>
                      <SoftBox>
                        <SoftButton
                          variant="outlined"
                          color="dark"
                          disabled={form.authorPremium !== true || draft?.linkedin !== undefined}
                          startIcon={<Icon>panorama</Icon>}
                          onClick={
                            draft?.sharing === undefined
                              ? () => setForm({...form, state: 'showImportImageModal'})
                              : undefined
                          }>
                          {t('draft.photo', {defaultValue: 'Photo'})}
                        </SoftButton>
                      </SoftBox>
                      <SoftBox>
                        <SoftButton
                          variant="outlined"
                          color="dark"
                          disabled={form.authorPremium !== true || draft?.linkedin !== undefined}
                          startIcon={<Icon>play_circle</Icon>}
                          onClick={
                            draft?.sharing === undefined
                              ? () => setForm({...form, state: 'showImportVideoModal'})
                              : undefined
                          }>
                          {t('draft.video', {defaultValue: 'Video'})}
                        </SoftButton>
                      </SoftBox>
                      <SoftBox>
                        <SoftButton
                          variant="outlined"
                          color="dark"
                          disabled={form.authorPremium !== true || draft?.linkedin !== undefined}
                          startIcon={<Icon>picture_as_pdf</Icon>}
                          onClick={
                            draft?.sharing === undefined
                              ? () => setForm({...form, state: 'showImportPDFModal'})
                              : undefined
                          }>
                          {t('draft.carrousel', {defaultValue: 'Carrousel'})}
                        </SoftButton>
                      </SoftBox>
                      <SoftBox>
                        <SoftButton
                          variant="outlined"
                          color="dark"
                          disabled={form.authorPremium !== true || draft?.linkedin !== undefined}
                          startIcon={<Icon>equalizer</Icon>}
                          onClick={
                            draft?.sharing === undefined
                              ? () => setForm({...form, state: 'showImportPollModal'})
                              : undefined
                          }>
                          {t('draft.poll', {defaultValue: 'poll'})}
                        </SoftButton>
                      </SoftBox>
                    </>
                  ) : (
                    <>
                      <SoftBox>
                        <SoftButton
                          variant="outlined"
                          color="dark"
                          startIcon={
                            draft.sharing.type === 'image' ? (
                              <Icon>panorama</Icon>
                            ) : draft.sharing.type === 'video' ? (
                              <Icon>play_circle</Icon>
                            ) : draft.sharing.type === 'pdf' ? (
                              <Icon>picture_as_pdf</Icon>
                            ) : (
                              <Icon>equalizer</Icon>
                            )
                          }
                          onClick={onRemoveSharing}>
                          {form.state === 'loading' ? (
                            <CircularProgress color="info" size={24} />
                          ) : (
                            // t('draft.remove_video')
                            // t('draft.remove_image')
                            // t('draft.remove_poll')
                            // t('draft.remove_pdf')
                            // t('draft.remove_images')
                            t(`draft.remove_${draft.sharing.type}`)
                          )}
                        </SoftButton>
                      </SoftBox>
                      <SoftBox>
                        <SoftButton
                          variant="outlined"
                          color="dark"
                          onClick={() =>
                            setForm((f) => ({
                              ...f,
                              state:
                                draft.sharing?.type === 'image'
                                  ? 'showImportImageModal'
                                  : draft.sharing?.type === 'video'
                                    ? 'showImportVideoModal'
                                    : draft.sharing?.type === 'pdf'
                                      ? 'showImportPDFModal'
                                      : 'showImportPollModal',
                            }))
                          }>
                          {
                            // t('draft.edit_video', {defaultValue: 'Modifier la vidéo'})
                            // t('draft.edit_image', {defaultValue: 'Modifier l\'image'})
                            // t('draft.edit_poll', {defaultValue: 'Modifier le sondage'})
                            // t('draft.edit_pdf', {defaultValue: 'Modifier le pdf'})
                            // t('draft.edit_images', {defaultValue: 'Modifier les images'})
                            t(`draft.edit_${draft.sharing.type}`)
                          }
                        </SoftButton>
                      </SoftBox>
                    </>
                  )}
                  {form.firstComment === undefined && (
                    <SoftBox>
                      <SoftButton
                        variant="outlined"
                        color="dark"
                        startIcon={<Icon>add_comment</Icon>}
                        onClick={onAddComment}>
                        {t('draft.firstcomment', {defaultValue: '1st comment'})}
                      </SoftButton>
                    </SoftBox>
                  )}
                </Grid>
              )}
              {form.firstComment !== undefined && (
                <Grid item xs={12}>
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                      {t('draft.firstcomment', {defaultValue: '1st comment'})}
                    </SoftTypography>
                    <SoftInput
                      value={form.firstComment}
                      onChange={(e) => onCommentChange(e.target.value)}
                      readOnly={form.canWrite === false}
                    />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="row" sx={{mt: 1}}>
                    {form.canWrite && (
                      <SoftButton variant="outlined" color="dark" onClick={onRemoveComment}>
                        Supprimer
                      </SoftButton>
                    )}
                    <SoftTypography variant="caption" sx={{ml: form.canWrite ? 2 : 0}}>
                      {t('draft.firstcommentinfo')}
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              )}
            </Grid>
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          {form.owner !== undefined && form.content !== undefined && (
            <ActivityPreview
              fullname={
                isPublicUser(form.owner) ? `${form.owner.firstname} ${form.owner.lastname}` : form.owner.localizedName
              }
              picture={isPublicUser(form.owner) ? (form.owner.lnPicture ?? noPic) : (form.owner.picture ?? noPic)}
              content={form.content}
              headline={isPublicUser(form.owner) ? form.owner.headline : undefined}
              showMore={draft?.sharing !== undefined ? '3-lines' : '5-lines'}
              sharing={form.sharing}
            />
          )}
          <SoftBox display="flex" flexDirection="column" sx={{mt: 2, height: 500}}>
            {draft && (
              <DraftChatBox
                draft={draft}
                onAddProofreader={() => setForm((i) => ({...i, state: 'showSharingDraftModal'}))}
              />
            )}
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
