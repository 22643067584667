/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import {NavLink} from 'react-router-dom';
import {useAppSelector} from 'src/store';
import SoftBox from 'src/theme/components/SoftBox';
import SoftTypography from 'src/theme/components/SoftTypography';
import borders from 'src/theme/theme/base/borders';
import colors from 'src/theme/theme/base/colors';
import {localFormat} from 'src/utils/date-format';

export type RowDefinition = {
  _id: string;
  picture?: string;
  content: string;
  date: Date;
  view: number;
  impression: number;
  comment: number;
  like: number;
  engagement: number;
  href?: string;
  component?: 'td';
};

export default function TablePostRow(row: RowDefinition) {
  const locale = useAppSelector((state) => state.main.user?.locale);
  const {light} = colors;
  const {borderWidth} = borders;

  return (
    <>
      <SoftBox
        component={row.component}
        sx={{display: 'table-cell', verticalAlign: 'middle'}}
        px={3}
        py={1.5}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}>
        <SoftBox
          component={row.href ? NavLink : 'span'}
          to={row.href}
          sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
          <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
            <SoftBox mr={2}>
              <SoftBox component="img" src={row.picture} />
            </SoftBox>
            <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
              {row.content}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component={row.component}
        sx={{display: 'table-cell', verticalAlign: 'middle'}}
        px={3}
        py={1.5}
        textAlign="left"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}>
        <SoftBox
          component={row.href ? NavLink : 'span'}
          to={row.href}
          sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
          <SoftTypography
            variant="button"
            textTransform="none"
            fontWeight="regular"
            color="secondary"
            sx={{display: 'inline-block', width: 'max-content'}}>
            {localFormat(row.date, 'dd/MM/yyyy HH:mm:ss', locale)}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      {/*
      <SoftBox
        component={row.component}
        sx={{display: 'table-cell', verticalAlign: 'middle'}}
        px={3}
        py={1.5}
        textAlign="left"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}>
        <SoftBox
          component={row.href ? NavLink : 'span'}
          to={row.href}
          sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
          <SoftTypography
            variant="button"
            textTransform="none"
            fontWeight="regular"
            color="secondary"
            sx={{display: 'inline-block', width: 'max-content'}}>
            {row.view}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      */}
      <SoftBox
        component={row.component}
        sx={{display: 'table-cell', verticalAlign: 'middle'}}
        px={3}
        py={1.5}
        textAlign="left"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}>
        <SoftBox
          component={row.href ? NavLink : 'span'}
          to={row.href}
          sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
          <SoftTypography
            variant="button"
            textTransform="none"
            fontWeight="regular"
            color="secondary"
            sx={{display: 'inline-block', width: 'max-content'}}>
            {row.impression}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component={row.component}
        sx={{display: 'table-cell', verticalAlign: 'middle'}}
        px={3}
        py={1.5}
        textAlign="left"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}>
        <SoftBox
          component={row.href ? NavLink : 'span'}
          to={row.href}
          sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
          <SoftTypography
            variant="button"
            textTransform="none"
            fontWeight="regular"
            color="secondary"
            sx={{display: 'inline-block', width: 'max-content'}}>
            {row.comment}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component={row.component}
        sx={{display: 'table-cell', verticalAlign: 'middle'}}
        px={3}
        py={1.5}
        textAlign="left"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}>
        <SoftBox
          component={row.href ? NavLink : 'span'}
          to={row.href}
          sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
          <SoftTypography
            variant="button"
            textTransform="none"
            fontWeight="regular"
            color="secondary"
            sx={{display: 'inline-block', width: 'max-content'}}>
            {row.like}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component={row.component}
        sx={{display: 'table-cell', verticalAlign: 'middle'}}
        px={3}
        py={1.5}
        textAlign="left"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}>
        <SoftBox
          component={row.href ? NavLink : 'span'}
          to={row.href}
          sx={({palette}) => ({textDecoration: 'none', color: palette.dark.main})}>
          <SoftTypography
            variant="button"
            textTransform="none"
            fontWeight="regular"
            color="secondary"
            sx={{display: 'inline-block', width: 'max-content'}}>
            {(row.engagement * 100).toLocaleString(locale, {maximumFractionDigits: 2})}%
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
}
