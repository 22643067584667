import LinkedinIcon from '@mui/icons-material/LinkedIn';
import {Card, Grid, Divider} from '@mui/material';
import Lottie from 'lottie-react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import linkedinWaiting from 'src/assets/lottie//linkedin-waiting.json';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {submitMessage} from 'src/store/extension/slice';
import {getLinkedinAccessToken, getMe, unlinkLinkedin} from 'src/store/mainslice';
import SoftAvatar from 'src/theme/components/SoftAvatar';
import SoftButton from 'src/theme/components/SoftButton';
import SoftSocialButton from 'src/theme/components/SoftSocialButton';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';

import SoftBox from '../theme/components/SoftBox';
import SoftSelect from '../theme/components/SoftSelect';

type QueryParams = {
  response_type: string;
  client_id: string;
  redirect_uri: string;
  state: string;
  scope: string;
};

export default function LinkedInAuth() {
  const user = useAppSelector((state) => state.main.user);
  const organizations = useAppSelector((state) => state.main.organizations);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<'connect' | 'loading' | 'review'>('connect');
  const [lang, setlang] = useState<'fr' | 'en' | 'es'>('fr');
  const {i18n, t} = useTranslation();
  useDocumentTitle(t('linkedinauth.documenttitle', {defaultValue: 'Connect your LinkedIn account'}));
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    const finalize = searchParams.get('finalize');
    const state = searchParams.get('state');
    const code = searchParams.get('code');
    if (finalize) {
      setState('review');
    } else if (state === `${process.env.REACT_APP_LINKEDIN_STATE}` && code) {
      setState('loading');
      dispatch(getLinkedinAccessToken(code))
        .then(() => dispatch(getMe()).unwrap())
        .then(() => {
          setState('review');
          setSearchParams({finalize: 'true'});
          dispatch(submitMessage({source: 'perfectpost', type: 'reload_session'}));
        });
    }
  }, [dispatch, searchParams]);

  const signInWithLinkedIn = () => {
    const getURLWithQueryParams = (base: string, params: QueryParams) => {
      const query = Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');
      return `${base}?${query}`;
    };

    const LINKEDIN_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
      response_type: 'code',
      client_id: `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`,
      redirect_uri: `${process.env.REACT_APP_LINKEDIN_REDIRECT_URI}`,
      state: `${process.env.REACT_APP_LINKEDIN_STATE}`,
      scope: `${process.env.REACT_APP_LINKEDIN_SCOPE}`,
    });
    window.location.href = LINKEDIN_URL;
  };

  const resetLinkedIn = async () => {
    await dispatch(unlinkLinkedin());
    signInWithLinkedIn();
  };

  const onSaveAccount = () => {
    const state: unknown = location.state;
    if (typeof state === 'object' && state !== null && 'from' in state && typeof state.from === 'string') {
      navigate(state.from, {state: {}});
    } else {
      navigate('/');
    }
  };

  return (
    <SoftBox width="100vw" height="fit-content" minHeight="100vh" bgColor="white">
      <Grid container>
        <Grid item xs={12} lg={8} sx={{mx: 'auto'}}>
          <SoftBox
            sx={{
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
            {state === 'loading' ? (
              <SoftBox pt={3} px={3}>
                <Lottie animationData={linkedinWaiting} style={{height: 150}} />
                <SoftTypography variant="body2" mt={2} fontWeight="regular" textAlign="center" color="text">
                  {t('linkedinauth.loading.message', {defaultValue: 'Retrieving your information'})}
                </SoftTypography>
              </SoftBox>
            ) : state === 'connect' ? (
              <>
                <SoftBox pt={3} px={3}>
                  <SoftBox mb={1}>
                    <SoftTypography variant="h4" fontWeight="bold">
                      {t('linkedinauth.connect.title', {
                        defaultValue: 'To continue you musty link your LinkedIn account',
                      })}
                    </SoftTypography>
                    <SoftSocialButton color="linkedin" onClick={signInWithLinkedIn}>
                      <LinkedinIcon />
                      &nbsp;LinkedIn
                    </SoftSocialButton>
                  </SoftBox>
                  <SoftBox sx={{mt: 1}}>
                    {/* @ts-ignore */}
                    <SoftButton component={Link} variant="text" size="small" color="dark" to="/settings">
                      {t('linkedinauth.connect.skip', {
                        defaultValue: 'You can skip this step and connect your account later',
                      })}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </>
            ) : (
              <Card id="accounts">
                <SoftBox display="flex" flexDirection="row" justifyContent="space-between" p={3} lineHeight={1}>
                  <SoftBox display="flex" flexDirection="column" lineHeight={1}>
                    <SoftBox mb={1}>
                      <SoftTypography variant="h5">
                        {t('linkedinauth.accounts.hello', {
                          firstname: user?.firstname,
                          lastname: user?.lastname,
                          defaultValue: 'Hello {{firstname}} {{lastname}} 👋',
                        })}
                      </SoftTypography>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="regular" textTransform="none">
                      {t('linkedinauth.accounts.description', {
                        defaultValue: 'Here are the accounts associated with your LinkedIn profile',
                      })}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox pt={2} pb={3} px={3}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{xs: 'flex-start', sm: 'center'}}
                    flexDirection={{xs: 'column', sm: 'row'}}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftAvatar src={user?.lnPicture} variant="rounded" />
                      <SoftBox ml={2} lineHeight={0}>
                        <SoftTypography variant="h5" fontWeight="medium">
                          {user?.firstname} {user?.lastname}
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="regular" textTransform="none">
                          {t('linkedinauth.accounts.personalaccount', {defaultValue: 'Personal account'})}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  {organizations.map((organization) => (
                    <React.Fragment key={organization._id}>
                      <Divider />
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{xs: 'flex-start', sm: 'center'}}
                        flexDirection={{xs: 'column', sm: 'row'}}>
                        <SoftBox display="flex" alignItems="center">
                          <SoftAvatar src={organization.picture} variant="rounded" />
                          <SoftBox ml={2} lineHeight={0}>
                            <SoftTypography variant="h5" fontWeight="medium">
                              {organization.localizedName}
                            </SoftTypography>
                            <SoftTypography variant="button" color="text" fontWeight="regular" textTransform="none">
                              {t('linkedinauth.accounts.organizationaccount', {defaultValue: 'Organization account'})}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </React.Fragment>
                  ))}
                </SoftBox>
                <SoftBox pt={2} pb={3} px={3} display="flex" justifyContent={{md: 'space-between'}} alignItems="center">
                  <SoftSocialButton color="linkedin" onClick={resetLinkedIn}>
                    <LinkedinIcon />
                    &nbsp;{t('linkedinauth.accounts.btnreconnect', {defaultValue: 'Connect another account'})}
                  </SoftSocialButton>
                  <SoftButton onClick={onSaveAccount} color="info">
                    {t('linkedinauth.accounts.continue', {defaultValue: 'Continue with these accounts'})}
                  </SoftButton>
                </SoftBox>
              </Card>
            )}
          </SoftBox>
          <SoftBox sx={{position: 'fixed', bottom: 0, left: 0, mb: 2, ml: 2}}>
            <SoftSelect
              menuPlacement="top"
              placeholder={t('global.language.placeholder')}
              options={[
                {value: 'fr', label: t('global.language.fr')},
                {value: 'en', label: t('global.language.en')},
                {value: 'es', label: t('global.language.es')},
              ]}
              onChange={(e) => {
                setlang(e !== null ? (e.value as 'fr' | 'en' | 'es') : 'fr');
              }}
            />
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
