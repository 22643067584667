/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import LinearProgress, {LinearProgressProps} from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';

export type SoftProgressRootProps = LinearProgressProps & {
  ownerState: {
    variant?: 'contained' | 'gradient';
    color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
    value?: number;
    label?: boolean;
  };
};
export default styled(LinearProgress)<SoftProgressRootProps>(({theme, ownerState}) => {
  const {palette, functions} = theme;
  const {color, value, variant} = ownerState;

  const {text, gradients} = palette;
  const {linearGradient} = functions;

  // background value
  let backgroundValue;

  if (variant === 'gradient') {
    backgroundValue =
      color && gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : linearGradient(gradients.info.main, gradients.info.state);
  } else {
    backgroundValue = color && palette[color] ? palette[color].main : palette.info.main;
  }

  return {
    '& .MuiLinearProgress-bar': {
      background: backgroundValue,
      width: `${value}%`,
      color: text.main,
    },
  };
});
