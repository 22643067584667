/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {forwardRef} from 'react';

// Custom styles for the SoftBadge
import SoftBadgeRoot, {SoftBadgeRootProps} from './SoftBadgeRoot';

const SoftBadge = forwardRef<
  HTMLSpanElement,
  Omit<SoftBadgeRootProps, 'ownerState' | 'variant' | 'color'> & SoftBadgeRootProps['ownerState']
>(
  (
    {
      color = 'info',
      variant = 'gradient',
      size = 'sm',
      circular = false,
      indicator = false,
      border = false,
      container = false,
      children = false,
      ...rest
    },
    ref,
  ) => (
    <SoftBadgeRoot
      {...rest}
      ownerState={{color, variant, size, circular, indicator, border, container, children}}
      ref={ref}
      color="default">
      {children}
    </SoftBadgeRoot>
  ),
);

export default SoftBadge;
