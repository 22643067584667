import {User} from '@perfectpost/perfect-post-common';
import mixpanel from 'mixpanel-browser';
declare global {
  interface Window {
    Rewardful?: {
      referral?: string;
    };
  }
}

export class MixPanelService {
  constructor() {
    mixpanel.init('4258fd0662441212e44d8ab268b1e1be', {debug: true, track_pageview: true, persistence: 'localStorage'});
  }

  public logout() {
    mixpanel.reset();
  }

  public trackUser(user: User) {
    mixpanel.identify(user._id);
  }

  public tackSignup() {
    let referral;
    if (window.Rewardful !== undefined && window.Rewardful.referral !== undefined && window.Rewardful.referral !== '') {
      referral = window.Rewardful.referral;
    }
    // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
    mixpanel.track('Sign Up', {
      referral,
    });
  }
}
