import {useEffect} from 'react';

function useDocumentTitle(title: string, prevailOnUnmount?: boolean): void {
  // const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `PerfectPost | ${title}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = `PerfectPost`;
      }
    },
    [],
  );
}

export default useDocumentTitle;
