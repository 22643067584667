import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {CircularProgress} from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import {confirmSignIn} from 'aws-amplify/auth';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';

import PasswordChecker from '../passwordchecker/passwordchecker';

type Props = {
  onConfirm: () => void;
};

export default function SignupChangePass(props: Props) {
  const {t} = useTranslation();
  const [signupChangePassState, setSignupChangePassState] = useState({
    pass1: '',
    pass2: '',
    errorMessageNew: undefined as string | undefined,
    showPassword: false,
    state: 'idle' as 'idle' | 'loading' | 'invalidPassword' | 'error',
  });

  const {pass1, pass2, errorMessageNew, showPassword, state} = signupChangePassState;

  const handleClickShowPassword = () => {
    setSignupChangePassState({...signupChangePassState, showPassword: !showPassword});
  };

  const onConfirm = async () => {
    setSignupChangePassState({...signupChangePassState, state: 'loading', errorMessageNew: undefined});
    await confirmSignIn({challengeResponse: pass1})
      .then(() => {
        props.onConfirm();
      })
      .catch((error) => {
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (error.code === 'InvalidPasswordException') {
          setSignupChangePassState({
            ...signupChangePassState,
            state: 'invalidPassword',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            errorMessageNew: error.message,
          });
        } else {
          setSignupChangePassState({
            ...signupChangePassState,
            state: 'error',
            errorMessageNew: `unkown error: ${error}`,
          });
          throw error;
        }
      });
  };

  return (
    <>
      <SoftBox mb={2}>
        <SoftInput
          required
          name="password"
          placeholder={t('form.password.placeholder', {defaultValue: 'Password'})}
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="new-password"
          size="large"
          value={pass1}
          onChange={(event) => {
            setSignupChangePassState({
              ...signupChangePassState,
              state: 'idle',
              errorMessageNew: undefined,
              pass1: event.target.value,
            });
          }}
          error={errorMessageNew !== undefined}
          icon={{
            component: (
              <IconButton
                aria-label={t('form.password.togglevisibility.arialabel', {defaultValue: 'Toggle password visibility'})}
                sx={{p: 0}}
                onClick={handleClickShowPassword}
                size="small">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
            direction: 'right',
          }}
        />
        {errorMessageNew !== undefined && (
          <SoftTypography variant="body2" fontWeight="bold" color="error">
            {errorMessageNew}
          </SoftTypography>
        )}
      </SoftBox>
      <SoftBox mb={2}>
        <SoftInput
          required
          name="repeat-password"
          placeholder={t('form.passwordrepeat.placeholder', {defaultValue: 'Repeat your password'})}
          type={showPassword ? 'text' : 'password'}
          id="repeat-password"
          autoComplete="repeat-password"
          size="large"
          value={pass2}
          onChange={(event) => {
            setSignupChangePassState({
              ...signupChangePassState,
              state: 'idle',
              errorMessageNew: undefined,
              pass2: event.target.value,
            });
          }}
          icon={{
            component: (
              <IconButton
                aria-label={t('form.password.togglevisibility.arialabel', {defaultValue: 'Toggle password visibility'})}
                sx={{p: 0}}
                onClick={handleClickShowPassword}
                size="small">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
            direction: 'right',
          }}
        />
      </SoftBox>
      <PasswordChecker
        password={pass1}
        repeatPassword={pass2}
        onPasswordInvalid={() => {
          setSignupChangePassState({...signupChangePassState, state: 'invalidPassword'});
        }}
        onPasswordValid={() => {
          setSignupChangePassState({...signupChangePassState, state: 'idle'});
        }}
      />
      <SoftBox mt={4} mb={1}>
        <SoftButton
          variant="gradient"
          color="info"
          size="large"
          disabled={state === 'invalidPassword' || pass1 !== pass2 || pass1.trim().length === 0}
          onClick={onConfirm}>
          {state === 'loading' ? (
            <CircularProgress color="info" />
          ) : (
            t('signin.form.changepassbtn', {defaultValue: 'Change password'})
          )}
        </SoftButton>
      </SoftBox>
    </>
  );
}
