import emojiRegex from 'emoji-regex';
import i18n from 'src/i18n';
import {v4 as uuidv4} from 'uuid';

const regexEmoji: RegExp = emojiRegex();

const style = document.createElement('style');
style.textContent = `
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes markerunderlinewidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@keyframes markerunderlineopacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.85;
  }
}
.pp-copy {
	height: 0;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 990;
}

.mainbox {
	box-sizing: content-box;
	overflow: hidden;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 508px;
	height: 100%;
  margin: 0 calc((100% - 508px) / 2);
}
.overlay {
	-ms-overflow-style: none;
	overflow: auto;
	position: relative;
	scrollbar-width: none;
	left: 0px;
	top: 10px;
  width: 100%;
	height: 100%;
}
.markers {
	left: 0;
  position: absolute;
  top: 0;
}
.mark {
	position: absolute;
	background: transparent;
	transition: background-color 0.25s;
}
.mark::after {
	animation: markerunderlineopacity 0s ease forwards;
	background-color: #0096ff;
	border-radius: 60px;
	bottom: 0px;
	content: "";
	height: 2px;
	left: 0;
	opacity: 0;
	position: absolute;
	width: 100%;
}
`;

type ErrorType =
  | 'adverb'
  | 'dots'
  | 'hashtag'
  | 'url'
  | 'hashtag-headline'
  | 'dear-network'
  | 'more-than-30-word'
  | 'too-much-emoji'
  | 'parenthesis'
  | 'hello'
  | 'too-much-line'
  | 'too-much-line-headline'
  | 'no-end-of-line-colon';

const adverb = [
  'beaucoup',
  'autant',
  'assez',
  'aussi',
  'moins',
  'très fort',
  'si',
  'davantage',
  'environ',
  'presque',
  'seulement',
  'à peine',
  'approximativement',
  'entièrement',
  'grandement',
  'totalement',
  'tant',
  'peu',
  'suffisamment',
  'tellement',
  'plus',
  'quasiment',
  'trop',
  'tout à fait',
  'complètement',
  'excessivement',
  'apparemment',
  'peut-être',
  'a priori',
  'probablement',
  'vraisemblablement',
  'sans doute',
];

export default class CopyWrittingAssistant {
  private readonly ppextension: HTMLElement;
  private readonly ppextensionNotifier: HTMLElement;
  private readonly copywritingcontainer: HTMLDivElement;
  private readonly shadow: ShadowRoot;
  private readonly mainbox: HTMLDivElement;
  private readonly overlay: HTMLDivElement;
  private readonly markers: HTMLDivElement;

  private readonly errors = new Map<string, ErrorType>();

  constructor(private readonly editor: HTMLDivElement) {
    this.ppextension = document.createElement('pp-extension');
    this.ppextension.dataset.role = 'overlay';
    this.editor.parentElement?.append(this.ppextension);
    this.shadow = this.ppextension.attachShadow({mode: 'open'});
    this.shadow.append(style);

    const notifier = document.querySelector('html > pp-extension');
    if (notifier !== null) {
      notifier.remove();
    }

    this.ppextensionNotifier = document.createElement('pp-extension');
    document.body.parentElement?.append(this.ppextensionNotifier);

    this.copywritingcontainer = document.createElement('div');
    this.copywritingcontainer.className = 'pp-copy';
    this.shadow.append(this.copywritingcontainer);

    this.mainbox = document.createElement('div');
    this.mainbox.className = 'mainbox';
    this.copywritingcontainer.append(this.mainbox);

    this.overlay = document.createElement('div');
    this.overlay.className = 'overlay';
    this.mainbox.append(this.overlay);

    this.markers = document.createElement('div');
    this.markers.className = 'markers';
    this.overlay.append(this.markers);

    this.editor.addEventListener('click', (e) => this.onclick(e));
  }

  public process() {
    const editor = this.editor;
    for (const i of Array.from(this.markers.children)) {
      i.remove();
    }

    const lines = Array.from(editor.children ?? []).filter(
      (element): element is HTMLParagraphElement => element instanceof HTMLParagraphElement,
    );
    let topPosition = 0;
    for (const [index, line] of lines.entries()) {
      const nbLine = line.getBoundingClientRect().height / 21;
      const topPositionCopy = topPosition;
      const mks = Array.from(line.childNodes).flatMap((child) => {
        if (child.nodeName === '#text') {
          return this.processText(child, topPositionCopy, nbLine, index, lines.length);
        }

        if (child instanceof HTMLElement && child.tagName.toUpperCase() === 'STRONG') {
          return this.processText(child.childNodes[0], topPositionCopy, nbLine, index, lines.length);
        }

        return [];
      });
      if (mks.length > 0) {
        const ids = mks.map((i) => i.id);
        line.dataset.pp = ids.join(',');
        this.markers.append(...mks);
      } else {
        line.removeAttribute('data-pp');
      }

      topPosition += line.getBoundingClientRect().height;
    }
  }

  public dispose() {
    this.ppextensionNotifier.remove();
    while (this.markers.firstChild) {
      this.markers.firstChild.remove();
    }
  }

  private processText(
    textElement: ChildNode,
    topPosition: number,
    nbLineThisParagraph: number,
    lineIndex: number,
    nbLineTotal: number,
  ): HTMLDivElement[] {
    const mks = [];
    const textContent = textElement.textContent?.toLowerCase() ?? '';

    const clientRects = this.editor.getClientRects();
    if (clientRects.length === 0) {
      return [];
    }

    if (nbLineThisParagraph > 2 && topPosition < 105) {
      const mark = this.positionDetector(0, textContent.length, textElement, clientRects);
      this.errors.set(mark.id, 'too-much-line-headline');
      mks.push(mark);
    }

    if (nbLineThisParagraph > 3) {
      const mark = this.positionDetector(0, textContent.length, textElement, clientRects);
      this.errors.set(mark.id, 'too-much-line');
      mks.push(mark);
    }

    const has2Dot = [...textContent.matchAll(/:[^/]/g)];
    if (
      has2Dot.length > 0 &&
      has2Dot[0].index !== textContent.trim().length - 1 &&
      textContent.toLowerCase().slice(0, has2Dot[0].index).trim() !== 'ps'
    ) {
      const mark = this.positionDetector(has2Dot[0].index ?? 0, textContent.length, textElement, clientRects);
      this.errors.set(mark.id, 'no-end-of-line-colon');
      mks.push(mark);
    }

    const markArrays = adverb.map((adv) => {
      const marks: HTMLDivElement[] = [];
      for (const index of this.getAllIndexes(textContent, adv)) {
        const mark = this.positionDetector(index, index + adv.length, textElement, clientRects);
        this.errors.set(mark.id, 'adverb');
        marks.push(mark);
      }

      return marks;
    });
    for (const markArray of markArrays) {
      if (markArray !== undefined) {
        mks.push(...markArray);
      }
    }

    const dots = [...textContent.matchAll(/\.{3}/g)];
    if (dots.length >= 2) {
      mks.push(
        ...dots.map((dot) => {
          const mark = this.positionDetector(
            dot.index ?? 0,
            (dot.index ?? 0) + dot[0].length,
            textElement,
            clientRects,
          );
          this.errors.set(mark.id, 'dots');
          return mark;
        }),
      );
    }

    if (textContent.startsWith('#') && lineIndex < nbLineTotal - 3) {
      const mark = this.positionDetector(0, textContent.length, textElement, clientRects);
      this.errors.set(mark.id, topPosition < 105 ? 'hashtag-headline' : 'hashtag');
      mks.push(mark);
    }

    const urlRegex = /https?:\/\/(www\.)?[-\w@:%.+~#=]{1,256}\.[a-zA-Z\d()]{1,6}\b([-\w()@:%+.~#?&/=]*)/g;
    const urlMatch = [...textContent.matchAll(urlRegex)];
    if (urlMatch.length > 0 && lineIndex < nbLineTotal - 3) {
      mks.push(
        ...urlMatch.map((url) => {
          const mark = this.positionDetector(
            url.index ?? 0,
            (url.index ?? 0) + url[0].length,
            textElement,
            clientRects,
          );
          this.errors.set(mark.id, 'url');
          return mark;
        }),
      );
    }

    const parenthesisRegex = /[([]([^)\]]+)[)\]]/g;
    const parenthesisMatch = [...textContent.matchAll(parenthesisRegex)];
    if (parenthesisMatch.length > 0) {
      mks.push(
        ...parenthesisMatch.map((parenthes) => {
          const mark = this.positionDetector(
            parenthes.index ?? 0,
            (parenthes.index ?? 0) + parenthes[0].length,
            textElement,
            clientRects,
          );
          this.errors.set(mark.id, 'parenthesis');
          return mark;
        }),
      );
    }

    if (textContent.split(' ').length > 30) {
      const mark = this.positionDetector(0, textContent.length, textElement, clientRects);
      this.errors.set(mark.id, 'more-than-30-word');
      mks.push(mark);
    }

    const match = [...textContent.matchAll(regexEmoji)];
    if (match.length >= 2) {
      mks.push(
        ...Array.from(match).map((emoji) => {
          const mark = this.positionDetector(
            emoji.index ?? 0,
            (emoji.index ?? 0) + emoji[0].length,
            textElement,
            clientRects,
          );
          this.errors.set(mark.id, 'too-much-emoji');
          return mark;
        }),
      );
    }

    const hasCherReseau = [
      textContent.toLowerCase().indexOf('cher réseau'),
      textContent.toLowerCase().indexOf('chers réseau'),
      textContent.toLowerCase().indexOf('cher reseau'),
    ].filter((i) => i !== -1);
    if (hasCherReseau.length > 0) {
      mks.push(
        ...Array.from(hasCherReseau).map((dear) => {
          const mark = this.positionDetector(dear, dear + 11, textElement, clientRects);
          this.errors.set(mark.id, 'dear-network');
          return mark;
        }),
      );
    }

    const hello = textContent.toLowerCase().indexOf('bonjour');
    if (hello !== -1 && topPosition === 0) {
      const mark = this.positionDetector(hello, hello + 7, textElement, clientRects);
      this.errors.set(mark.id, 'hello');
      mks.push(mark);
    }

    return mks;
  }

  private getAllIndexes(textContent: string, adv: string): number[] {
    const regex = new RegExp('\\b' + adv + '\\b', 'g');
    const indexes = [];
    let match;
    while ((match = regex.exec(textContent)) !== null) {
      indexes.push(match.index);
    }
    return indexes;
  }

  private positionDetector(
    begin: number,
    end: number,
    textElement: ChildNode,
    clientRects: DOMRectList,
  ): HTMLDivElement {
    const range = document.createRange();
    range.setStart(textElement, begin);
    range.setEnd(textElement, end);
    const rects = range.getClientRects();
    const top = rects[0].top - clientRects[0].top;
    const left = rects[0].left - clientRects[0].left;
    const width = rects[0].width;
    const height = rects[0].height;
    return this.markGenerator(width, height, left, top);
  }

  private markGenerator(width: number, height: number, left: number, top: number): HTMLDivElement {
    const mark = document.createElement('div');
    const id = uuidv4();
    mark.id = id;
    mark.className = 'mark';
    mark.style.width = `${width}px`;
    mark.style.height = `${height}px`;
    mark.style.left = `${left}px`;
    mark.style.top = `${top}px`;
    return mark;
  }

  private onclick(event: MouseEvent) {
    let lineError;
    if (event.target instanceof HTMLParagraphElement) {
      for (const i of this.ppextensionNotifier.querySelectorAll('.pp-popper-assistant') ?? []) {
        i.remove();
      }

      lineError = event.target.getAttribute('data-pp')?.split(',');
    } else if (event.target instanceof HTMLElement && event.target.tagName.toLowerCase() === 'strong') {
      for (const i of this.ppextensionNotifier.querySelectorAll('.pp-popper-assistant') ?? []) {
        i.remove();
      }

      lineError = event.target.parentElement?.getAttribute('data-pp')?.split(',');
    }

    if (lineError) {
      for (const error of lineError) {
        const div = this.shadow.querySelector(`[id='${error}']`);
        if (div instanceof HTMLDivElement) {
          const rects = div.getClientRects()[0];
          if (
            rects.x <= event.clientX &&
            rects.width + rects.x >= event.clientX &&
            rects.y <= event.clientY &&
            rects.height + rects.y >= event.clientY
          ) {
            const id = div.id;
            const error = this.errors.get(id);
            if (error) {
              const popper = this.popperGenerator(error);
              popper.style.left = `${event.clientX}px`;
              popper.style.top = `${event.clientY}px`;
              this.ppextensionNotifier.append(popper);
            }
          }
        }
      }
    }
  }

  private popperGenerator(error: ErrorType) {
    const container = document.createElement('div');
    container.className = 'pp-popper-assistant';
    container.addEventListener('mouseleave', () => {
      container.remove();
    });

    const elevation = document.createElement('div');
    elevation.className = 'pp-paper';

    container.append(elevation);

    const button = document.createElement('div');
    button.className = '';

    /*
  "copywritting.dear-network": "Tu t'es cru dans un journal ? Met une accroche plutôt",
  "copywritting.hashtag-headline": "Bah non, c'est tout en bas les hashtags. On commence toujours par une accroche ici !",
  "copywritting.hashtag": "Attention tu es train de parler à des humains. Est-ce que tu te vois en plein milieu d'une phrase à l'oral utiliser un hashtag ? Met les plutôt à la fin.",
  "copywritting.more-than-30-word": "Oh bah c'est une très (trop) longue phrase ça.",
  "copywritting.too-much-emoji": "Hop hop hop, il y en a un peu trop là.",
  "copywritting.parenthesis": "Tu t'es cru dans un journal ? Les parenthèses nuancent un propos va à l'essentiel",
  "copywritting.hello": "On ne comence plus un message par Bonjour sur les réseaux sociaux depuis 1903",
  "copywritting.too-much-line": "Aérez-moi tout ça avec des sauts de lignes svp !",
  "copywritting.too-much-line-headline": "Oula, elle est dense cette accroche. On peut pas la faire respirer un peu ?",
  "copywritting.no-end-of-line-colon": "On pourrait peut-être sauter une ligne là, non ?",
  "copywritting.adverb": "On limite au max les adverbes pour aller droit au but.",
  "copywritting.dots": "Les ... ça rend le texte tout molasson. Et ça fait un peu boomer aussi si on est honnête.",
  "copywritting.url": "Les liens, c'est mieux à la fin quand même.",
  "copywritting.default": "Je ne sais pas quoi te dire, mais ça me paraît louche.",
    */
    switch (error) {
      case 'dear-network':
        button.textContent = i18n.t('copywritting.dear-network', {
          defaultValue: 'Did you think you were in a newspaper? Put a hook instead',
        });
        break;
      case 'hashtag-headline':
        button.textContent = i18n.t('copywritting.hashtag-headline', {
          defaultValue: "Well no, it's at the bottom of the hashtags. We always start with a hook here!",
        });
        break;
      case 'hashtag':
        button.textContent = i18n.t('copywritting.hashtag', {
          defaultValue:
            "Be careful, you're talking to humans. Do you see yourself in the middle of a spoken sentence using a hashtag? Put them at the end instead.",
        });
        break;
      case 'more-than-30-word':
        button.textContent = i18n.t('copywritting.more-than-30-word', {
          defaultValue: "Oh well, that's a very (too) long sentence.",
        });
        break;
      case 'too-much-emoji':
        button.textContent = i18n.t('copywritting.too-much-emoji', {
          defaultValue: "Hop hop hop, there's a little too much there.",
        });
        break;
      case 'parenthesis':
        button.textContent = i18n.t('copywritting.parenthesis', {
          defaultValue:
            'Did you think you were in a newspaper? Parentheses qualify a statement that goes straight to the point',
        });
        break;
      case 'hello':
        button.textContent = i18n.t('copywritting.hello', {
          defaultValue: "We haven't started a message with Bonjour on social networks since 1903",
        });
        break;
      case 'too-much-line':
        button.textContent = i18n.t('copywritting.too-much-line', {
          defaultValue: 'Air it all out for me with line breaks please!',
        });
        break;
      case 'too-much-line-headline':
        button.textContent = i18n.t('copywritting.too-much-line-headline', {
          defaultValue: "Wow, this hook is dense. Can't we make it breathe a little?",
        });
        break;
      case 'no-end-of-line-colon':
        button.textContent = i18n.t('copywritting.no-end-of-line-colon', {
          defaultValue: 'Maybe we could skip a line there, right?',
        });
        break;
      case 'adverb':
        button.textContent = i18n.t('copywritting.adverb', {
          defaultValue: 'We limit adverbs as much as possible to get straight to the point.',
        });
        break;
      case 'dots':
        button.textContent = i18n.t('copywritting.dots', {
          defaultValue: "The ... it makes the text all soft. And it's also a bit boomer if we're honest.",
        });
        break;
      case 'url':
        button.textContent = i18n.t('copywritting.url', {defaultValue: 'Ties are better in the end anyway.'});
        break;
      default:
        button.textContent = i18n.t('copywritting.default', {
          defaultValue: "I don't know what to tell you, but it seems fishy to me.",
        });
        break;
    }

    elevation.append(button);
    return container;
  }
}
