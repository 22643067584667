import {Grid, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

type Props = {
  password: string;
  repeatPassword?: string;
  onPasswordValid: () => void;
  onPasswordInvalid: () => void;
};

export default function PasswordChecker(props: Props) {
  const {password, repeatPassword, onPasswordValid, onPasswordInvalid} = props;
  const {t} = useTranslation();
  const checkLength = password.length >= 8;
  const checkUppercase = /[A-Z]/.test(password);
  const checkLowercase = /[a-z]/.test(password);
  const checkSpecialChar = /[\^$*.[\]{}()?"!@#%&/\\,><':;|\-=_~`]/.test(password);
  const checkNumber = /\d/.test(password);
  const samePassword = password === repeatPassword;

  React.useEffect(() => {
    if (checkLength && checkUppercase && checkLowercase && checkSpecialChar && checkNumber && password) {
      onPasswordValid();
    } else {
      onPasswordInvalid();
    }
  }, [password, repeatPassword]);

  return (
    <Grid style={{display: 'flex', flexDirection: 'row'}}>
      <Grid style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
        <Typography variant="caption" color={checkLength ? 'primary' : 'error'}>
          {checkLength ? <span>✅</span> : <span>❌</span>}
          {t('passwordchecker.length', {defaultValue: 'length 8'})}
        </Typography>
        <Typography variant="caption" color={checkUppercase ? 'primary' : 'error'}>
          {checkUppercase ? <span>✅</span> : <span>❌</span>}
          {t('passwordchecker.upperCaseLetters', {defaultValue: 'capital letters'})}
        </Typography>
        <Typography variant="caption" color={checkLowercase ? 'primary' : 'error'}>
          {checkLowercase ? <span>✅</span> : <span>❌</span>}
          {t('passwordchecker.lowerCaseLetters', {defaultValue: 'lowercase'})}
        </Typography>
      </Grid>
      <Grid style={{display: 'flex', flexDirection: 'column', marginLeft: 10, textAlign: 'left'}}>
        <Typography variant="caption" color={checkSpecialChar ? 'primary' : 'error'}>
          {checkSpecialChar ? <span>✅</span> : <span>❌</span>}
          {t('passwordchecker.specialChars', {defaultValue: 'special characters'})}
        </Typography>
        <Typography variant="caption" color={checkNumber ? 'primary' : 'error'}>
          {checkNumber ? <span>✅</span> : <span>❌</span>}
          {t('passwordchecker.digits', {defaultValue: 'digits'})}
        </Typography>
        <Typography variant="caption" color={samePassword ? 'primary' : 'error'}>
          {samePassword ? <span>✅</span> : <span>❌</span>}
          {t('passwordchecker.repeatPassword', {defaultValue: 'repeat password'})}
        </Typography>
      </Grid>
    </Grid>
  );
}
