import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {Grid, Card, CircularProgress} from '@mui/material';
import Icon from '@mui/material/Icon';
import {GetOneUserCommand} from '@perfectpost/perfect-post-common';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {SingleValue} from 'react-select';
import {Column} from 'react-table';
import Datatable from 'src/components/datatable';
import {perfectPostServiceClient} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {
  inviteTeam,
  removeFromTeam,
  editRoleTeam,
  assignLicenceTeam,
  removeLicenceTeam,
  selectTeamById,
} from 'src/store/teamslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';

import NavigationBar from './NavigationBar';

type TableRow = {
  _id: string;
  name?: string;
  picture?: string;
  role: string;
  email: string;
  premium?: boolean;
  teampremiumLicense?: boolean;
};

type State = {
  email: string;
  tableRowData: TableRow[];
  loadingUserTeam?: string;
  isOwnerOrAdmin: boolean;
  premiumLicenceLeft: number;
};

export default function TeamSettingsTeam() {
  const {id} = useParams();
  const team = useAppSelector((state) => selectTeamById(state, id));
  const user = useAppSelector((state) => state.main.user);

  const [state, setState] = useState<State>({
    email: '',
    tableRowData: [],
    isOwnerOrAdmin: false,
    premiumLicenceLeft: 0,
  });
  const {email, tableRowData, loadingUserTeam, isOwnerOrAdmin, premiumLicenceLeft} = state;

  const {t} = useTranslation();
  const dispatch = useAppThunkDispatch();

  const onRoleChange =
    (row: TableRow) =>
    (
      newValue: SingleValue<{
        value: string;
        label: string;
      }>,
    ) => {
      if (team) {
        setState((f) => ({...f, loadingUserTeam: row._id}));
        if (newValue && ['admin', 'owner', 'member'].includes(newValue.value)) {
          dispatch(editRoleTeam({id: team._id, user: row._id, role: newValue.value as 'admin' | 'owner' | 'member'}));
        } else if (newValue && newValue.value === 'delete') {
          dispatch(removeFromTeam({id: team._id, body: {user: row._id}}));
        }
      }
    };

  const onInvite = () => {
    const regex = /\S+@\S+\.\S+/;
    if (regex.test(email) && team) {
      dispatch(inviteTeam({id: team._id, email}));
      setState((f) => ({...f, email: ''}));
    }
  };

  const onRemoveInvite = (row: TableRow) => () => {
    if (team) {
      dispatch(
        removeFromTeam({id: team._id, body: {user: row.role === 'invited' ? undefined : row._id, email: row.email}}),
      );
    }
  };

  const onAssignLicense = (row: TableRow) => () => {
    if (team) {
      setState((f) => ({...f, loadingUserTeam: row._id}));
      dispatch(assignLicenceTeam({teamId: team._id, user: row._id}));
    }
  };

  const onRemoveLicense = (row: TableRow) => () => {
    if (team) {
      setState((f) => ({...f, loadingUserTeam: row._id}));
      dispatch(removeLicenceTeam({teamId: team._id, user: row._id}));
    }
  };

  const columnDefinition: Column<TableRow>[] = [
    {
      Header: t('teamsettingsteam.name'),
      accessor: 'name',
      width: '10%',
      Cell: (props) => (
        <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
          <SoftBox mr={2}>
            <SoftBox component="img" src={props.row.original.picture} sx={{borderRadius: '50%', width: 20}} />
          </SoftBox>
          <SoftTypography variant="button" textTransform="none" fontWeight="medium" sx={{width: 'max-content'}}>
            {props.row.original.name ?? 'LinkedIn User'} {props.row.original.premium && <WorkspacePremiumIcon />}
          </SoftTypography>
        </SoftBox>
      ),
    },
    {
      Header: t('teamsettingsteam.email'),
      accessor: 'email',
      width: '10%',
    },

    {
      Header: t('teamsettingsteam.role'),
      accessor: 'role',
      width: '5%',
      Cell: (props) => {
        if (loadingUserTeam === props.row.original._id) {
          return <CircularProgress color="info" />;
        }
        if (!isOwnerOrAdmin) {
          return t(`teamsettingsteam.level.${props.value}`);
        } else {
          return props.row.original._id === user?._id || props.row.original.role === 'owner' ? (
            t(`teamsettingsteam.level.${props.value}`)
          ) : props.row.original.role === 'invited' ? (
            <SoftButton color="error" size="small" onClick={onRemoveInvite(props.row.original)}>
              Supprimer l&acute;invitation
            </SoftButton>
          ) : (
            <SoftSelect
              defaultValue={{value: props.value, label: t(`teamsettingsteam.level.${props.value}`)}}
              options={[
                {
                  value: 'owner',
                  label: t('teamsettingsteam.level.owner'),
                },
                {value: 'admin', label: t('teamsettingsteam.level.admin')},
                {value: 'member', label: t('teamsettingsteam.level.member')},
                {value: 'delete', label: t('global.delete')},
              ]}
              onChange={onRoleChange(props.row.original)}
              isOptionDisabled={(option) => option.value === props.row.original.role}
              isDisabled={props.row.original.role === 'owner'}
            />
          );
        }
      },
    },

    {
      Header: t('teamsettingsteam.licence'),
      accessor: 'teampremiumLicense',
      width: '5%',
      Cell: (props) => {
        if (loadingUserTeam === props.row.original._id) {
          return <CircularProgress color="info" />;
        }
        if (props.row.original.role === 'invited') {
          return null;
        }
        if (isOwnerOrAdmin) {
          return props.value === true ? (
            <SoftButton color="error" size="small" onClick={onRemoveLicense(props.row.original)}>
              {t('teamsettingsteam.removelicense')}
            </SoftButton>
          ) : props.row.original.premium ? (
            <SoftTypography variant="caption">{t('teamsettingsteam.userhaspremiumnotfromthisteam')}</SoftTypography>
          ) : premiumLicenceLeft > 0 ? (
            <SoftButton variant="gradient" color="dark" size="small" onClick={onAssignLicense(props.row.original)}>
              {t('teamsettingsteam.assignlicense')}
            </SoftButton>
          ) : null;
        } else {
          return (
            <SoftTypography variant="caption">
              {props.row.original.premium ? t('teamsettingsteam.premiumuser') : t('teamsettingsteam.normaluser')}
            </SoftTypography>
          );
        }
      },
    },
  ];

  useEffect(() => {
    const ids = team?.users.map((user) => user.user) ?? [];
    const invitations = team?.invitations ?? [];

    Promise.all(
      ids.map((id) => {
        return perfectPostServiceClient.send(new GetOneUserCommand({id}));
      }),
    ).then((users) => {
      const tableData: TableRow[] = users.map((user) => {
        const teamuser = team?.users.find((u) => u.user === user._id);
        return {
          _id: user._id,
          name: user.firstname + ' ' + user.lastname,
          picture: user.lnPicture ?? '',
          role: teamuser?.role ?? 'member',
          email: teamuser?.email ?? '',
          premium: user.premium,
          teampremiumLicense: teamuser?.premiumLicense,
        };
      });
      invitations.forEach((invitation) => {
        tableData.push({
          _id: invitation,
          name: invitation,
          role: 'invited',
          email: invitation,
        });
      });
      setState((prevState) => ({
        ...prevState,
        team,
        tableRowData: tableData,
        isOwnerOrAdmin:
          team?.users.some((u) => u.user === user?._id && (u.role === 'owner' || u.role === 'admin')) ?? false,
        loadingUserTeam: undefined,
        premiumLicenceLeft: (team?.teamPremiumLicense ?? 0) - (team?.users.filter((u) => u.premiumLicense).length ?? 0),
      }));
    });
  }, [team]);

  return (
    <SoftBox>
      <Grid container spacing={3}>
        <Grid item lg={11} xl={10}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {id && <NavigationBar teamId={id} value="team" teamPremium={team?.teamPlan !== undefined} />}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} display="flex" flexDirection="column">
                    <Card sx={{p: 3}}>
                      <SoftBox flex={1} display="flex" flexDirection="row">
                        <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2, flex: 1}}>
                          {t('teamsettingsteam.teamsummary')}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox flex={1} display="flex" flexDirection="row" justifyContent="space-between">
                        <Icon>people</Icon>
                        <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
                          {t('teamsettingsteam.users', {nb: team?.users.length ?? 0})}
                        </SoftTypography>
                        <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
                          {t('teamsettingsteam.premiumlicence', {
                            nb: premiumLicenceLeft,
                            total: team?.teamPremiumLicense ?? 0,
                          })}
                        </SoftTypography>
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{p: 3}}>
                      <SoftBox>
                        <SoftTypography variant="h5" fontWeight="bold" sx={{mb: 2}}>
                          {t('teamsettingsteam.invitemember')}
                        </SoftTypography>
                        <SoftBox sx={{display: 'flex', flexDirection: 'row'}}>
                          <SoftInput
                            type="text"
                            value={email}
                            onChange={(e) => setState((f) => ({...f, email: e.target.value}))}
                            disabled={!isOwnerOrAdmin}
                          />
                          <SoftButton
                            variant="gradient"
                            color="dark"
                            size="small"
                            sx={{ml: 1}}
                            onClick={onInvite}
                            disabled={!isOwnerOrAdmin}>
                            {t('teamsettingsteam.invite')}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card sx={{p: 3, minHeight: 500}}>
                      <Datatable
                        table={{rows: tableRowData, columns: columnDefinition}}
                        entriesPerPage={{defaultValue: 30, entries: [10, 20, 30, 50, 80, 130]}}
                        pagination={{variant: 'gradient', color: 'info'}}
                        isSorted
                        noExport
                        sx={{flex: 1}}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
