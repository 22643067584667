import {
  GetUrlSharingDraftCommand,
  type Draft,
  type Proofreader,
  EditCategoriesDraftCommand,
  CancelDraftCommand,
  CreateDraftCommand,
  GetDraftCommand,
  UpdateDraftCommand,
  RemoveDraftCommand,
  RemoveSharingDraftCommand,
  RemoveFirstCommentDraftCommand,
  TogglePublicDraftCommand,
  ShareProofreaderDraftCommand,
  CancelShareProofreaderDraftCommand,
  ProofreaderEditRoleDraftCommand,
} from '@perfectpost/perfect-post-common';
import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import type {AxiosRequestConfig} from 'axios';
import axios from 'axios';
import {perfectPostServiceClient} from 'src/services';

import {RootState} from '.';

export interface DraftState {
  loadingDraft: boolean;
  drafts: Draft[];
}
const initialState: DraftState = {
  loadingDraft: true,
  drafts: [],
};

const selectDrafts = (state: RootState) => state.draft.drafts;
export const selectDraftById = createSelector(
  [selectDrafts, (state: RootState, draftId: string | undefined) => draftId],
  (drafts, draftId) => drafts.find((item) => item._id === draftId),
);

export const draftLoad = createAsyncThunk('draft/load', async () =>
  perfectPostServiceClient.send(new GetDraftCommand()),
);

type SaveDraftParam = {
  content: string;
  sharing?: {
    video?: string | undefined;
    image?: string | undefined;
    pdf?: string | undefined;
    poll?: {
      option3?: string;
      option4?: string;
      question: string;
      option1: string;
      option2: string;
      duration: 1 | 3 | 7 | 14;
    };
    title?: string;
  };
  organization?: string;
  person?: string;
  firstComment?: string;
  tag?: 'idea' | 'draft' | 'ready' | 'scheduled' | 'published';
};
export const saveDraft = createAsyncThunk<{status: string; url?: string; draft: Draft}, SaveDraftParam>(
  'draft/create',
  async ({content, sharing, organization, person, firstComment, tag}) => {
    return perfectPostServiceClient.send(
      new CreateDraftCommand({
        content,
        sharing,
        organization,
        person,
        firstComment,
        tag,
      }),
    );
  },
);

export const editDraft = createAsyncThunk<
  {status: string; url?: string; draft: Draft; uploadUrlComment?: string},
  Partial<SaveDraftParam> & {
    draftId: string;
    firstCommentContent?: string;
    programmingDate?: string;
    tag?: 'idea' | 'draft' | 'ready' | 'scheduled' | 'published';
    position?: number;
    urn?: string;
  }
>(
  'draft/edit',
  async ({
    draftId,
    content,
    programmingDate,
    tag,
    position,
    sharing,
    organization,
    person,
    firstComment,
    firstCommentContent,
  }) => {
    return perfectPostServiceClient.send(
      new UpdateDraftCommand({
        draftId,
        content,
        programmingDate,
        tag,
        position,
        sharing,
        organization,
        person,
        firstComment,
        firstCommentContent,
      }),
    );
  },
);

export const updateDraftCategories = createAsyncThunk<string[], {draftId: string; categories: string[]}>(
  'draft/categories',
  async ({draftId, categories}) => {
    await perfectPostServiceClient.send(new EditCategoriesDraftCommand({id: draftId, categories}));
    return categories;
  },
);

export const cancelDraft = createAsyncThunk('draft/cancel', async (draft: Draft) => {
  return perfectPostServiceClient.send(new CancelDraftCommand(draft._id));
});

export const cloneDraft = createAsyncThunk('draft/clone', async (draft: Draft) => {
  let newDraftId;
  if (draft.sharing) {
    const {url: mediaUrl} = await perfectPostServiceClient.send(new GetUrlSharingDraftCommand(draft._id));
    const blob = await fetch(mediaUrl).then(async (r) => r.blob());
    const {draft: draftNew, url} = await perfectPostServiceClient.send(
      new CreateDraftCommand({
        content: draft.content,
        organization: draft.organization,
        sharing: {
          video: draft.sharing.type === 'video' ? blob.type : undefined,
          image: draft.sharing.type === 'image' ? blob.type : undefined,
          pdf: draft.sharing.type === 'pdf' ? blob.type : undefined,
        },
        firstComment: draft.firstComment,
      }),
    );
    newDraftId = draftNew._id;
    if (url) {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': blob.type,
        },
      };
      void axios.put(url, blob, config);
    }
  } else {
    const {draft: draftNew} = await perfectPostServiceClient.send(
      new CreateDraftCommand({
        content: draft.content,
        organization: draft.organization,
        firstComment: draft.firstComment,
      }),
    );
    newDraftId = draftNew._id;
  }

  const newDrafts = await perfectPostServiceClient.send(new GetDraftCommand());
  return {draftId: newDraftId, drafts: newDrafts};
});

export const removeDraft = createAsyncThunk('draft/remove', async (draftId: string) => {
  return perfectPostServiceClient.send(new RemoveDraftCommand(draftId));
});

export const removeDraftSharing = createAsyncThunk('draft/remove/sharing', async (draftId: string) => {
  await perfectPostServiceClient.send(new RemoveSharingDraftCommand(draftId));
  return perfectPostServiceClient.send(new GetDraftCommand());
});

export const removeFirstComment = createAsyncThunk('draft/remove/firstcomment', async (draftId: string) =>
  perfectPostServiceClient.send(new RemoveFirstCommentDraftCommand(draftId)),
);

export const togglePublicSharing = createAsyncThunk('draft/togglepublic', async (draftId: string) =>
  perfectPostServiceClient.send(new TogglePublicDraftCommand(draftId)),
);

export const inviteToDraft = createAsyncThunk(
  'draft/invite',
  async ({draftId, proofreaders}: {draftId: string; proofreaders: Required<Pick<Proofreader, 'email' | 'role'>>[]}) =>
    perfectPostServiceClient.send(new ShareProofreaderDraftCommand({id: draftId, proofreaders})),
);

export const editProofreader = createAsyncThunk(
  'draft/edit/proofreader',
  async ({draftId, proofreader}: {draftId: string; proofreader: Pick<Proofreader, 'email' | 'role' | 'team'>}) =>
    perfectPostServiceClient.send(new ProofreaderEditRoleDraftCommand({id: draftId, proofreader})),
);

export const removeProofreader = createAsyncThunk(
  'draft/remove/proofreader',
  async ({draftId, proofreader}: {draftId: string; proofreader: {email?: string; user?: string; team?: string}}) =>
    perfectPostServiceClient.send(new CancelShareProofreaderDraftCommand({id: draftId, proofreader})),
);

export const draftSlice = createSlice({
  name: 'draft',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(draftLoad.pending, (state) => {
        state.loadingDraft = false;
      })
      .addCase(draftLoad.fulfilled, (state, action) => {
        state.drafts = action.payload;
        state.loadingDraft = false;
      })
      .addCase(removeDraft.fulfilled, (state, action) => {
        state.drafts = state.drafts.filter((d) => d._id !== action.meta.arg);
      })
      .addCase(removeDraftSharing.fulfilled, (state, action) => {
        state.drafts = action.payload;
      })
      .addCase(removeFirstComment.fulfilled, (state, action) => {
        state.loadingDraft = false;
        const draft = state.drafts.find((d) => d._id === action.meta.arg);
        if (draft) {
          draft.firstComment = undefined;
        }
      })
      .addCase(togglePublicSharing.fulfilled, (state, action) => {
        const draftId = action.meta.arg;
        const draftIndex = state.drafts.findIndex((d) => d._id === draftId);
        if (draftIndex !== -1) {
          state.drafts[draftIndex].public = state.drafts[draftIndex].public ? false : true;
        }
      })
      .addCase(cancelDraft.fulfilled, (state, action) => {
        state.drafts = action.payload;
      })
      .addCase(cloneDraft.pending, (state) => {
        state.loadingDraft = true;
      })
      .addCase(cloneDraft.fulfilled, (state, action) => {
        state.loadingDraft = false;
        state.drafts = action.payload.drafts;
      })
      .addCase(saveDraft.pending, (state) => {
        state.loadingDraft = true;
      })
      .addCase(saveDraft.fulfilled, (state, action) => {
        state.loadingDraft = false;
        state.drafts = [...state.drafts, action.payload.draft];
      })
      .addCase(editDraft.pending, (state) => {
        state.loadingDraft = true;
      })
      .addCase(editDraft.fulfilled, (state, action) => {
        state.loadingDraft = false;
        state.drafts = state.drafts.map((d) => (d._id === action.payload.draft._id ? action.payload.draft : d));
      })
      .addCase(updateDraftCategories.fulfilled, (state, action) => {
        state.loadingDraft = false;
        const draft = state.drafts.find((d) => d._id === action.meta.arg.draftId);
        if (draft) {
          draft.categories = action.payload;
        }
      })
      .addCase(inviteToDraft.fulfilled, (state, action) => {
        state.loadingDraft = false;
        const draft = state.drafts.find((d) => d._id === action.meta.arg.draftId);
        if (draft) {
          draft.proofreaders = action.payload.proofreaders;
        }
      })
      .addCase(removeProofreader.fulfilled, (state, action) => {
        state.loadingDraft = false;
        const draft = state.drafts.find((d) => d._id === action.meta.arg.draftId);
        if (draft) {
          draft.proofreaders = action.payload.proofreaders;
        }
      })
      .addCase(editProofreader.fulfilled, (state, action) => {
        state.loadingDraft = false;
        const draft = state.drafts.find((d) => d._id === action.meta.arg.draftId);
        if (draft) {
          draft.proofreaders = action.payload.proofreaders;
        }
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = draftSlice.actions;
export default draftSlice.reducer;
