import {Divider, Icon} from '@mui/material';
import React, {forwardRef} from 'react';
import typography from 'src/theme/theme/base/typography';

import SoftBox from '../../theme/components/SoftBox';
import SoftSnackbarIconRoot from '../../theme/components/SoftSnackbar/SoftSnackbarIconRoot';
import SoftTypography, {ExtraProps} from '../../theme/components/SoftTypography';

interface SoftSnackbarContentProps {
  icon: React.ReactNode;
  title: string;
  titleColor: ExtraProps['color'];
  color: ExtraProps['color'];
  content: React.ReactNode;
  dividerColor: boolean;
  bgWhite?: boolean;
  onClose?: () => void;
}

const SoftSnackbarContent = forwardRef<HTMLDivElement, SoftSnackbarContentProps>(
  ({icon, title, titleColor, color, content, dividerColor, bgWhite, onClose}, ref) => {
    const isValidIconColor = (
      color: string | undefined,
    ): color is Exclude<ExtraProps['color'], 'inherit' | 'text' | 'white'> => {
      return ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light', undefined].includes(
        color,
      );
    };

    return (
      <SoftBox
        ref={ref}
        variant={bgWhite ? 'contained' : 'gradient'}
        bgColor={bgWhite ? 'white' : color}
        minWidth="21.875rem"
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" color="dark" p={1.5}>
          <SoftBox
            display="flex"
            alignItems="center"
            lineHeight={0}
            color={bgWhite || color === 'light' ? 'text' : 'white'}>
            <SoftSnackbarIconRoot
              fontSize="small"
              ownerState={{color: isValidIconColor(color) ? color : undefined, bgWhite}}>
              {icon}
            </SoftSnackbarIconRoot>
            <SoftTypography variant="button" fontWeight="medium" color={titleColor} textGradient={bgWhite}>
              {title}
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" alignItems="center" lineHeight={0}>
            {onClose && (
              <Icon
                sx={{
                  color: ({palette: {dark, white}}) => (bgWhite || color === 'light' ? dark.main : white.main),
                  fontWeight: ({typography: {fontWeightBold}}) => fontWeightBold,
                  cursor: 'pointer',
                  marginLeft: 2,
                  transform: 'translateY(-1px)',
                }}
                onClick={onClose}>
                close
              </Icon>
            )}
          </SoftBox>
        </SoftBox>
        <Divider sx={{margin: 0}} light={dividerColor} />
        <SoftBox p={1.5} color={bgWhite || color === 'light' ? 'text' : 'white'} fontSize={typography.size.sm}>
          {content}
        </SoftBox>
      </SoftBox>
    );
  },
);

export default SoftSnackbarContent;
