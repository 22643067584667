/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon, {IconProps} from '@mui/material/Icon';
import {styled} from '@mui/material/styles';

type SoftInputIconRootProps = IconProps & {
  ownerState: {
    size?: 'small' | 'medium' | 'large';
  };
};
export default styled(Icon)<SoftInputIconRootProps>(({theme, ownerState}) => {
  const {typography} = theme;
  const {size} = ownerState;

  const {fontWeightBold, size: fontSize} = typography;

  return {
    fontWeight: fontWeightBold,
    fontSize: size === 'small' ? `${fontSize.md} !important` : undefined,
  };
});
