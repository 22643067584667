import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';

import TipCard from '../tipscard/TipCards';

type CreateTeamModalProps = {
  handleClose: () => void;
  handleConfirm: () => void;
};

export default function CreateTeamModal(props: CreateTeamModalProps) {
  const {handleClose, handleConfirm} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth>
      <DialogTitle>
        <SoftTypography>{t('createteammodal.title')}</SoftTypography>
      </DialogTitle>
      <DialogContent>
        <SoftTypography fontWeight="bold" sx={{textAlign: 'center'}}>
          {t('createteammodal.description')}
        </SoftTypography>
        <TipCard informationkey="modalcreateteam" />
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" onClick={handleClose}>
          {t('global.cancel', {defaultValue: 'Cancel'})}
        </SoftButton>
        <SoftButton variant="gradient" color="primary" onClick={handleConfirm}>
          {t('createteammodal.create')}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}
