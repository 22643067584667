/* eslint-disable @typescript-eslint/no-unused-vars */
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import SettingsIcon from '@mui/icons-material/Settings';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Divider from '@mui/material/Divider';
import {DrawerProps} from '@mui/material/Drawer/Drawer';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import {Team} from '@perfectpost/perfect-post-common';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, NavLink, useNavigate} from 'react-router-dom';
import logo from 'src/assets/images/pp_icon512.png';
import {crispService as crisp} from 'src/services';
import {useAppSelector, useAppThunkDispatch} from 'src/store';
import {logout as mainsliceLogout, updateLocale} from 'src/store/mainslice';
import SoftAvatar from 'src/theme/components/SoftAvatar';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';

import SidenavCollapse from './SidenavCollapse';
import SidenavItem from './SidenavItem';
import SidenavList from './SidenavList';
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';

type TeamRendererProps = {
  team: Team;
  itemName: string;
  collapseName: string;
  pathname: string;
  setCollapseName: (s: string) => void;
};
const TeamRenderer = ({team, itemName, collapseName, pathname, setCollapseName}: TeamRendererProps) => {
  const {t} = useTranslation();
  const user = useAppSelector((state) => state.main.user);
  const myInformation = team.users.find((m) => m.user === user?._id);

  return (
    <SidenavCollapse
      name={team.name}
      icon={
        <SoftAvatar
          alt="team-image"
          variant="rounded"
          size="xs"
          shadow="sm"
          sx={{color: 'black !important', boxShadow: 'none'}}>
          {team.name[0]}
        </SoftAvatar>
      }
      open={collapseName === team.name}
      active={collapseName === team.name}
      onClick={() => setCollapseName(team.name)}>
      <SidenavList>
        <NavLink to={`/team/${team._id}/kanban`}>
          <SidenavItem
            name={t('sidenav.draft', {defaultValue: 'Draft'})}
            active={itemName === team._id && pathname.endsWith('kanban')}></SidenavItem>
        </NavLink>
        <NavLink to={`/team/${team._id}/calendar`}>
          <SidenavItem
            name={t('sidenav.calendar', {defaultValue: 'Calendar'})}
            active={itemName === team._id && pathname.endsWith('calendar')}></SidenavItem>
        </NavLink>
        {/*<NavLink to={`/team/${team._id}/content`}>
          <SidenavItem
            name={t('sidenav.content', {defaultValue: 'Content'})}
            active={itemName === team._id && pathname.endsWith('content')}></SidenavItem>
        </NavLink>
        <NavLink to={`/team/${team._id}/statistics`}>
          <SidenavItem
            name={t('sidenav.statistics', {defaultValue: 'Statistics'})}
            active={itemName === team._id && pathname.endsWith('statistics')}></SidenavItem>
        </NavLink>*/}
        {(myInformation?.role === 'owner' || myInformation?.role === 'admin') && (
          <NavLink to={`/team/${team._id}/tips`}>
            <SidenavItem
              name={t('sidenav.tips', {defaultValue: 'Tips'})}
              active={itemName === team._id && pathname.endsWith('tips')}></SidenavItem>
          </NavLink>
        )}
        <NavLink to={`/team/${team._id}/settings`}>
          <SidenavItem
            name={t('sidenav.settings', {defaultValue: 'Settings'})}
            active={
              itemName === team._id &&
              (pathname.endsWith('settings') || pathname.endsWith('privacy') || pathname.endsWith('team'))
            }></SidenavItem>
        </NavLink>
      </SidenavList>
    </SidenavCollapse>
  );
};

type SidenavProps = DrawerProps & {
  onShowCreateTeamModal: () => void;
};

export default function Sidenav({onShowCreateTeamModal, ...props}: SidenavProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const {t} = useTranslation();

  const locale = useAppSelector((state) => state.main.user?.locale);
  const notifications = useAppSelector((state) => state.main.notifications);
  const teams = useAppSelector((state) => state.team.teams);

  const {pathname} = location;
  const [collapseName, setCollapseName] = useState(pathname === '/' ? '' : pathname.split('/').slice(1)[0]);
  const itemName = pathname.split('/').slice(1)[1];

  const logout = async () => {
    await dispatch(mainsliceLogout());
    navigate('/signin');
  };

  const openFeedback = () => {
    crisp.openChat();
  };

  const [miniSidenav, setMiniSidenav] = useState(false);
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(window.innerWidth < 1400);
    }
    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);
    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, []);

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      <SoftBox
        sx={({breakpoints}) => ({
          position: 'absolute',
          zIndex: 1000,
          top: 24,
          [breakpoints.up('xxl')]: {
            display: 'none',
          },
        })}>
        <SoftButton iconOnly variant="gradient" onClick={handleOnMouseEnter}>
          <Icon>menu</Icon>
        </SoftButton>
      </SoftBox>
      <SidenavRoot
        {...props}
        variant="permanent"
        ownerState={{transparentSidenav: false, miniSidenav}}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}>
        <SoftBox pt={3} pb={1} px={4} textAlign="center">
          <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
            <SoftBox component="img" src={logo} alt="PerfectPost Logo" width="2rem" />
            <SoftBox sx={(theme) => sidenavLogoLabel(theme, {miniSidenav: false})}>
              <SoftTypography component="h6" variant="button" fontWeight="medium" color="text">
                PerfectPost
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Divider />
        <List sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
          <NavLink to="/" onClick={() => setCollapseName('')}>
            <SidenavCollapse
              name={t('sidenav.dashboard', {defaultValue: 'Dashboard'})}
              icon={<HomeIcon />}
              noCollapse
              active={collapseName === ''}
            />
          </NavLink>
          <SidenavCollapse
            name={t('sidenav.publish', {defaultValue: 'Publish'})}
            icon={<TextSnippetIcon />}
            open={collapseName === 'publish'}
            active={collapseName === 'publish'}
            onClick={() => setCollapseName('publish')}>
            <SidenavList>
              <NavLink to="/publish/draft">
                <SidenavItem
                  name={t('sidenav.draft', {defaultValue: 'Draft'})}
                  active={itemName === 'draft'}></SidenavItem>
              </NavLink>
              {/*
              <NavLink to="/publish/queue">
                <SidenavItem
                  name={t('sidenav.queue', {defaultValue: 'Queue'})}
                  active={itemName === 'queue'}></SidenavItem>
              </NavLink>
              */}
              <NavLink to="/publish/calendar">
                <SidenavItem
                  name={t('sidenav.calendar', {defaultValue: 'Calendar'})}
                  active={itemName === 'calendar'}></SidenavItem>
              </NavLink>
              <NavLink to="/publish/shared">
                <SidenavItem
                  name={t('sidenav.shared', {defaultValue: 'Shared with me'})}
                  notifications={notifications?.newSharedWithMe}
                  active={itemName === 'shared'}></SidenavItem>
              </NavLink>
            </SidenavList>
          </SidenavCollapse>
          <SidenavCollapse
            name={t('sidenav.analyse', {defaultValue: 'Analyse'})}
            icon={<InsightsIcon />}
            open={collapseName === 'analyse'}
            active={collapseName === 'analyse'}
            onClick={() => setCollapseName('analyse')}>
            <SidenavList>
              <NavLink to="/analyse/activity">
                <SidenavItem
                  name={t('sidenav.activity', {defaultValue: 'Activity'})}
                  active={itemName === 'activity'}></SidenavItem>
              </NavLink>
              <NavLink to="/analyse/content">
                <SidenavItem
                  name={t('sidenav.content', {defaultValue: 'Content'})}
                  active={itemName === 'content'}></SidenavItem>
              </NavLink>
              <NavLink to="/analyse/profile">
                <SidenavItem
                  name={t('sidenav.profile', {defaultValue: 'Profile'})}
                  active={itemName === 'profile'}></SidenavItem>
              </NavLink>
              <NavLink to="/analyse/statistics">
                <SidenavItem
                  name={t('sidenav.statistics', {defaultValue: 'Statistics'})}
                  active={itemName === 'statistics'}></SidenavItem>
              </NavLink>
            </SidenavList>
          </SidenavCollapse>
          <NavLink to="/feed" onClick={() => setCollapseName('feed')}>
            <SidenavCollapse
              name={t('sidenav.list', {defaultValue: 'Custom feed'})}
              icon={<DynamicFeedIcon />}
              noCollapse
              active={collapseName === 'feed'}
            />
          </NavLink>
          <Divider />
          <SoftTypography
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            ml={1}>
            {t('sidenav.yourteam')}
          </SoftTypography>
          {teams?.map((team) => (
            <TeamRenderer
              key={team._id}
              team={team}
              itemName={itemName}
              collapseName={collapseName}
              pathname={pathname}
              setCollapseName={setCollapseName}
            />
          ))}
          <SoftBox>
            <SoftButton
              variant="text"
              color="dark"
              size="small"
              startIcon={<Icon>add</Icon>}
              sx={{fontSize: '12px', textTransform: 'lowercase', width: '90%', textAlign: 'center', mt: 2}}
              onClick={onShowCreateTeamModal}>
              {t('sidebav.createteam')}
            </SoftButton>
          </SoftBox>
          <SoftBox sx={{flexGrow: 1}} />
          <Divider />
          <SidenavCollapse
            name={t('sidenav.progress', {defaultValue: 'Progress'})}
            icon={<TrendingUpIcon />}
            open={collapseName === 'progress'}
            active={collapseName === 'progress'}
            onClick={() => setCollapseName('progress')}>
            <SidenavList>
              <NavLink to="/progress/guides">
                <SidenavItem
                  name={t('sidenav.guides', {defaultValue: 'Guides'})}
                  active={itemName === 'guides'}></SidenavItem>
              </NavLink>
              {/*
              <NavLink to="/progress/success">
                <SidenavItem
                  name={t('sidenav.success', {defaultValue: 'Achievement'})}
                  active={itemName === 'success'}></SidenavItem>
              </NavLink>
              <NavLink to="/progress/template">
                <SidenavItem
                  name={t('sidenav.template', {defaultValue: 'Template'})}
                  active={itemName === 'template'}></SidenavItem>
              </NavLink>
              */}
              <NavLink to="/progress/inspiration">
                <SidenavItem
                  name={t('sidenav.inspiration', {defaultValue: 'Inspiration'})}
                  active={itemName === 'inspiration'}></SidenavItem>
              </NavLink>
              <NavLink to="/progress/hook">
                <SidenavItem
                  name={t('sidenav.hook', {defaultValue: 'Hook'})}
                  active={itemName === 'hook'}></SidenavItem>
              </NavLink>
            </SidenavList>
          </SidenavCollapse>
          <SidenavCollapse
            name={t('sidenav.settings', {defaultValue: 'Settings'})}
            icon={<SettingsIcon />}
            open={collapseName === 'settings'}
            active={collapseName === 'settings'}
            onClick={() => setCollapseName('settings')}>
            <SidenavList>
              <NavLink to="/settings">
                <SidenavItem
                  name={t('sidenav.myaccount', {defaultValue: 'My Account'})}
                  active={itemName === undefined}></SidenavItem>
              </NavLink>
              <NavLink to="/settings/subscription">
                <SidenavItem
                  name={t('sidenav.subscription', {defaultValue: 'Subscription'})}
                  active={itemName === 'subscription'}></SidenavItem>
              </NavLink>
              <SidenavItem
                name={t('sidenav.feedback', {defaultValue: 'Feedback'})}
                onClick={openFeedback}></SidenavItem>
              <NavLink to="https://perfectpost.canny.io/" target="_blank">
                <SidenavItem name={t('sidenav.roadmap', {defaultValue: 'Roadmap'})} component={NavLink}></SidenavItem>
              </NavLink>
              <SidenavItem name={t('sidenav.disconnect', {defaultValue: 'Disconnect'})} onClick={logout}></SidenavItem>
            </SidenavList>
          </SidenavCollapse>
        </List>
        {/*
        <SoftBox pt={2} my={2} mx={2}>
          <SidenavCard />
        </SoftBox>
        */}
        <SoftBox pt={2} my={2} mx={2}>
          <SoftSelect
            menuPlacement="top"
            placeholder={t('global.language.placeholder', {defaultValue: 'Change language'})}
            value={locale ? {value: locale, label: t(`global.language.${locale}`)} : undefined}
            options={[
              {value: 'fr', label: t('global.language.fr', {defaultValue: 'Français'})},
              {value: 'en', label: t('global.language.en', {defaultValue: 'English'})},
              {value: 'es', label: t('global.language.es', {defaultValue: 'Español'})},
            ]}
            onChange={(e) => {
              dispatch(updateLocale({locale: e !== null ? (e.value as 'fr' | 'en' | 'es') : 'en'}));
            }}
          />
        </SoftBox>
      </SidenavRoot>
    </>
  );
}
