/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {SxProps, Theme} from '@mui/system';
import React, {useMemo, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  Column,
  HeaderGroup,
  useRowSelect,
} from 'react-table';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftPagination from 'src/theme/components/SoftPagination';
import SoftSelect from 'src/theme/components/SoftSelect';
import SoftTypography from 'src/theme/components/SoftTypography';

import DataTableBodyCell from './DataTableBodyCell';
import DataTableHeadCell from './DataTableHeadCell';

type DataTableProps<T extends object> = {
  entriesPerPage: {
    defaultValue?: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  table: {columns: Column<T>[]; rows: T[]};
  pagination: {
    variant: 'contained' | 'gradient';
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark' | 'light';
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  noExport?: boolean;
  onExportData?: () => void;
  onRowClick?: (row: T) => void;
  sx?: SxProps<Theme> | undefined;
};

function DataTable<T extends object>({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  noExport,
  onExportData,
  sx,
  onRowClick,
}: DataTableProps<T>) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const {t} = useTranslation();

  const tableInstance = useTable({columns, data}, useGlobalFilter, useSortBy, usePagination, useRowSelect);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    state: {pageIndex, pageSize, globalFilter},
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({value}: {value: string; label: string}) => setPageSize(Number.parseInt(value));

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination item key={option} onClick={() => gotoPage(Number(option))} active={pageIndex === option}>
      {option + 1}
    </SoftPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({
    target: {value},
  }) => {
    const intValue = Number.parseInt(value);
    return intValue > pageOptions.length || intValue < 0 ? gotoPage(0) : gotoPage(intValue);
  };

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Search input value state
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column: HeaderGroup<T>): false | 'desc' | 'asce' | 'none' => {
    let sortedValue: false | 'desc' | 'asce' | 'none';

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const _onExportData = () => {
    if (onExportData) {
      onExportData();
      return;
    }
    const csvRows = [];
    const headers = columns.map((column) => column.accessor as string);
    csvRows.push(headers.join(','));

    for (const row of table.rows) {
      const values = headers.map((column) => {
        const value = row[column as keyof T];
        if (typeof value === 'string') {
          const s: string = value;
          return `"${s.replace(/"/g, '""').replace(/(\r\n|\n|\r)/g, ' ')}"`;
        } else {
          return value;
        }
      });
      csvRows.push(values.join(','));
    }

    const csvString = csvRows.join('\n');
    // download csv file WITH HTML5
    const blob = new Blob([csvString], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{boxShadow: 'none', ...sx}}>
      {entriesPerPage || canSearch || noExport !== true ? (
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <SoftBox display="flex" alignItems="center">
              <SoftSelect
                defaultValue={{value: `${defaultValue}`, label: `${defaultValue}`}}
                options={entries.map((entry) => ({value: `${entry}`, label: `${entry}`}))}
                onChange={(value) => value && setEntriesPerPage(value)}
                size="small"
              />
              <SoftTypography variant="caption" color="secondary">
                &nbsp;&nbsp;{t('datatable.entries')}
              </SoftTypography>
            </SoftBox>
          )}
          {canSearch && (
            <SoftBox width="12rem" ml="auto">
              <SoftInput
                placeholder="Search..."
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={search}
                onChange={({currentTarget}) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </SoftBox>
          )}
          {noExport !== true && (
            <SoftBox display="flex" alignItems="center" height={40} ml={2}>
              <SoftButton variant="outlined" color="dark" sx={{height: '100%'}} size="small" onClick={_onExportData}>
                {t('datatable.export')}
              </SoftButton>
            </SoftBox>
          )}
        </SoftBox>
      ) : null}
      <Table {...getTableProps()}>
        <SoftBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={`thead-row-${key}`}>
              {headerGroup.headers.map((column, key) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted ? column.getSortByToggleProps() : undefined)}
                  key={`thead-datatableheadcell-${key}`}
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}>
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </SoftBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            const href =
              'href' in row.original && typeof row.original.href === 'string' ? row.original.href : undefined;
            return (
              <TableRow
                {...row.getRowProps()}
                sx={({palette}) => ({
                  '&:hover': {
                    backgroundColor: palette.grey['200'],
                  },
                })}
                key={`table-row-${key}`}
                onClick={() => onRowClick && onRowClick(row.original)}
                component={href ? NavLink : 'tr'}
                to={href}
                target={href?.startsWith('https://linkedin.com') ? '_blank' : undefined}>
                {row.cells.map((cell, key) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : 'left'}
                    {...cell.getCellProps()}
                    key={`datatableheadcell-${key}`}>
                    {cell.render('Cell')}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <SoftBox
        display="flex"
        flexDirection={{xs: 'column', sm: 'row'}}
        justifyContent="space-between"
        alignItems={{xs: 'flex-start', sm: 'center'}}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}>
        {showTotalEntries && (
          <SoftBox mb={{xs: 3, sm: 0}}>
            <SoftTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </SoftTypography>
          </SoftBox>
        )}
        {pageOptions.length > 1 && (
          <SoftPagination
            variant={pagination.variant ? pagination.variant : 'gradient'}
            color={pagination.color ? pagination.color : 'info'}>
            {canPreviousPage && (
              <SoftPagination item onClick={() => previousPage()}>
                <Icon sx={{fontWeight: 'bold'}}>chevron_left</Icon>
              </SoftPagination>
            )}
            {renderPagination.length > 6 ? (
              <SoftBox width="5rem" mx={1}>
                <SoftInput
                  inputProps={{type: 'number', min: 1, max: customizedPageOptions.length}}
                  value={customizedPageOptions[pageIndex]}
                  onChange={handleInputPagination}
                />
              </SoftBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <SoftPagination item onClick={() => nextPage()}>
                <Icon sx={{fontWeight: 'bold'}}>chevron_right</Icon>
              </SoftPagination>
            )}
          </SoftPagination>
        )}
      </SoftBox>
    </TableContainer>
  );
}

export default DataTable;
