import {CustomContentProps, SnackbarContent, useSnackbar} from 'notistack';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import SoftButton from 'src/theme/components/SoftButton';
import SoftTypography from 'src/theme/components/SoftTypography';

import SoftSnackbarContent from './SoftSnackbarContent';

const NoLinkedInConnection = React.forwardRef<HTMLDivElement, CustomContentProps>(({id}, ref) => {
  const {closeSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <SoftSnackbarContent
        color="error"
        icon="warning"
        title={t('notification.needlinkedintoken.title', {
          defaultValue: 'La connexion entre Perfectpost et Linkedin vient d’expirer',
        })}
        content={
          <>
            <SoftTypography variant="body2">
              {t('notification.needlinkedintoken.content', {
                defaultValue:
                  "Tant que la connexion n'est pas rétablie nous ne pourrons pas publier de post sur votre compte Linkedin",
              })}
            </SoftTypography>
            {}
            <SoftButton
              component={Link}
              variant="text"
              size="small"
              color="info"
              // @ts-ignore
              to="/auth/linkedin"
              sx={{pl: 0, mt: 2}}>
              {t('settings.linkedaccount.updatewithlinkedinbtn', {defaultValue: 'Reconnect LinkedIn'})}
            </SoftButton>
          </>
        }
        onClose={handleDismiss}
        bgWhite
        titleColor="error"
        dividerColor={false}
      />
    </SnackbarContent>
  );
});

export default NoLinkedInConnection;
