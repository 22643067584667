import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {CircularProgress} from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import {ValidateEmailUserCommand} from '@perfectpost/perfect-post-common';
import {signIn} from 'aws-amplify/auth';
import {ConsoleLogger} from 'aws-amplify/utils';
import {isPast, parseISO} from 'date-fns';
import Lottie from 'lottie-react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, Link, useLocation} from 'react-router-dom';
import animationSuccess from 'src/assets/lottie//animation-success.json';
import SignupChangePass from 'src/components/signupchangepass/signupchangepass';
import {perfectPostServiceClient} from 'src/services';
import {useAppThunkDispatch} from 'src/store';
import {useAppSelector} from 'src/store';
import {authentification, getMe} from 'src/store/mainslice';
import SoftBox from 'src/theme/components/SoftBox';
import SoftButton from 'src/theme/components/SoftButton';
import SoftInput from 'src/theme/components/SoftInput';
import SoftTypography from 'src/theme/components/SoftTypography';
import useDocumentTitle from 'src/useDocumentTitle';
// import pattern from 'src/assets/svg/pattern-lines.svg';

const logger = new ConsoleLogger('Signin');

type SigninState = {
  state: 'idle' | 'loading' | 'error' | 'changepassword' | 'playinganimationstart' | 'playinganimationended';
  email: string;
  password: string;
  error?: {message: string};
  showPassword: boolean;
};

export default function Signin() {
  const location = useLocation();
  const [signinState, setSigninState] = useState<SigninState>({
    state: 'idle',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    email: location.state?.email ?? '',
    password: '',
    error: undefined,
    showPassword: false,
  });

  const {state, email, password, error, showPassword} = signinState;

  const {t} = useTranslation();

  const sessionUser = useAppSelector((state) => state.main.user);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  useDocumentTitle(t('signin.documenttitle', {defaultValue: 'Signin'}));

  useEffect(() => {
    if (sessionUser === undefined) {
      return;
    }
    if (state === 'playinganimationended') {
      const tokenExpiration = sessionUser.lnExpiresAt ? parseISO(sessionUser.lnExpiresAt) : undefined;
      if (tokenExpiration === undefined || isPast(tokenExpiration)) {
        navigate('/auth/linkedin');
      } else {
        const state: unknown = location.state;
        if (typeof state === 'object' && state !== null && 'from' in state && typeof state.from === 'string') {
          navigate(state.from, {state: {}});
        } else {
          navigate('/');
        }
      }
    }
  }, [sessionUser, state]);

  const onLogin = async () => {
    setSigninState((state) => ({...state, state: 'loading', error: undefined}));
    try {
      const {isSignedIn, nextStep} = await signIn({username: email, password});
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        setSigninState((state) => ({...state, state: 'changepassword'}));
      } else if (isSignedIn) {
        setSigninState((state) => ({...state, state: 'playinganimationstart'}));
        await dispatch(authentification());
        return dispatch(getMe());
      }
    } catch (error) {
      if (error instanceof Error && error.message !== undefined) {
        const e = error;
        setSigninState((state) => ({...state, state: 'error', error: e}));
      } else {
        setSigninState((state) => ({
          ...state,
          state: 'error',
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          error: {message: error !== undefined && error !== null ? error.toString() : 'Unknown error'},
        }));
        logger.error(error);
        throw error;
      }
    }
  };

  const onConfirmChangePass = async () => {
    setSigninState((state) => ({...state, state: 'playinganimationstart'}));
    await dispatch(authentification());
    perfectPostServiceClient.send(new ValidateEmailUserCommand());
    await dispatch(getMe());
  };

  const handleClickShowPassword = () => {
    setSigninState((prevState) => ({...prevState, showPassword: !prevState.showPassword}));
  };

  const onEmailChange = (email: string) => {
    setSigninState((state) => ({...state, email: email.toLowerCase(), state: 'idle', error: undefined}));
  };

  const onPasswordChange = (pass: string) => {
    setSigninState((state) => ({...state, password: pass, state: 'idle', error: undefined}));
  };

  return (
    <>
      <SoftBox pt={3} px={3}>
        {state === 'changepassword' ? (
          <>
            <SoftBox mb={1}>
              <SoftTypography variant="h4" fontWeight="bold">
                {t('password.haveToChange', {
                  defaultValue: 'Your password has expired you must choose a new one',
                })}
              </SoftTypography>
            </SoftBox>
          </>
        ) : (
          <>
            <SoftBox mb={1}>
              <SoftTypography variant="h4" fontWeight="bold">
                {t('signin.login.title', {defaultValue: 'Welcome back 👋 !'})}
              </SoftTypography>
            </SoftBox>
            <SoftTypography variant="body2" fontWeight="regular" color="text">
              {t('signin.login.subtitle', {defaultValue: 'Use your PerfectPost identifier to sign in'})}
            </SoftTypography>
          </>
        )}
      </SoftBox>
      <SoftBox p={3}>
        <SoftBox component="form" role="form">
          <SoftBox mt={3}>
            {state === 'changepassword' ? (
              <SignupChangePass onConfirm={onConfirmChangePass} />
            ) : state === 'playinganimationstart' || state === 'playinganimationended' ? (
              <Lottie
                autoplay
                animationData={animationSuccess}
                initialSegment={[0, 100]}
                loop={false}
                onComplete={() => {
                  setSigninState((state) => ({...state, state: 'playinganimationended'}));
                }}
                style={{height: 250}}
              />
            ) : (
              <>
                <SoftBox mb={2}>
                  <SoftInput
                    type="email"
                    autoComplete="email"
                    required
                    placeholder={t('form.email.placeholder', {defaultValue: 'E-mail'})}
                    size="large"
                    autoFocus
                    value={email}
                    onChange={(event) => {
                      onEmailChange(event.target.value);
                    }}
                    error={error !== undefined}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftInput
                    required
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('form.password.placeholder', {defaultValue: 'Password'})}
                    size="large"
                    autoComplete="current-password"
                    value={password}
                    onChange={(event) => {
                      onPasswordChange(event.target.value);
                    }}
                    error={error !== undefined}
                    icon={{
                      component: (
                        <IconButton
                          aria-label={t('form.password.togglevisibility.arialabel', {
                            defaultValue: 'Toggle password visibility',
                          })}
                          sx={{p: 0}}
                          onClick={handleClickShowPassword}
                          size="small">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                      direction: 'right',
                    }}
                  />
                  {error?.message !== undefined && (
                    <SoftTypography variant="body2" fontWeight="bold" color="error">
                      {error.message}
                    </SoftTypography>
                  )}

                  <SoftButton
                    variant="text"
                    color="info"
                    size="large"
                    sx={{mt: 1}}
                    component={Link}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    state={{email}}
                    to="/reset">
                    {t('signin.form.forgotbtn', {defaultValue: 'I forgot my password'})}
                  </SoftButton>
                </SoftBox>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    size="large"
                    disabled={state === 'loading'}
                    fullWidth
                    onClick={onLogin}>
                    {state === 'loading' ? <CircularProgress size={24} /> : t('signin.login.signbtn', 'sign in')}
                  </SoftButton>
                </SoftBox>
                <SoftTypography variant="body2" fontWeight="regular" color="text">
                  {t('signin.login.ctasignup', {defaultValue: 'Need to create an account?'})}{' '}
                  <SoftButton
                    variant="text"
                    color="info"
                    size="large"
                    component={Link}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    state={{email}}
                    to="/signup">
                    {t('signin.login.signupbtn', {defaultValue: 'Sign up'})}
                  </SoftButton>
                </SoftTypography>
              </>
            )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
}
